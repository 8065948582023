import * as Syntax from "features/formulas-syntax";
import { YMDtoMDY } from "features/utils";
import * as T from "types/generated-types";

export function printFormula(expr: Syntax.Expression): string {
  switch (expr.kind) {
    // Infix operators
    case "equality-test":
    case "inequality-test":
    case "less-than":
    case "greater-than":
    case "less-than-or-equal":
    case "greater-than-or-equal":
    case "addition":
    case "subtraction":
    case "multiplication":
    case "division":
    case "exponentiation": {
      let left = printFormula(expr.left);
      const op = Syntax.infixOperatorsByKind[expr.kind];
      let right = printFormula(expr.right);

      const leftPrec = Syntax.syntaxNodePrecedenceByKind[expr.left.kind];
      const opPrec = Syntax.syntaxNodePrecedenceByKind[expr.kind];
      const rightPrec = Syntax.syntaxNodePrecedenceByKind[expr.right.kind];

      const assoc = Syntax.infixOperatorAssociativitityByKind[expr.kind];
      if (leftPrec < opPrec || (assoc === "right" && leftPrec === opPrec)) {
        left = "(" + left + ")";
      }
      if (rightPrec < opPrec || (assoc === "left" && rightPrec === opPrec)) {
        right = "(" + right + ")";
      }

      return left + op + right;
    }
    // Prefix unary operators
    case "numeric-negation": {
      const op = Syntax.prefixOperatorsByKind[expr.kind];
      let term = printFormula(expr.term);

      const opPrec = Syntax.syntaxNodePrecedenceByKind[expr.kind];
      const termPrec = Syntax.syntaxNodePrecedenceByKind[expr.term.kind];

      if (termPrec < opPrec) {
        term = "(" + term + ")";
      }

      return op + term;
    }
    case "function-call":
      return (
        expr.func.text.toUpperCase() +
        "(" +
        expr.args.map(printFormula).join(", ") +
        ")"
      );
    case "property-access":
      return (
        printFormula(expr.objectName) + "." + printFormula(expr.propertyName)
      );
    case "identifier":
      return expr.text;
    case "boolean-literal":
      return expr.value ? "true" : "false";
    case "duration-literal":
      if (expr.count.kind !== "number-literal") {
        throw new Error(
          "duration literals with non-literal counts not yet implemented",
        );
      }

      return (
        printFormula(expr.count) +
        " " +
        printDurationUnit(expr.unit, +expr.count.value !== 1)
      );
    case "number-literal":
      return expr.value;
    case "string-literal":
      return JSON.stringify(expr.value);
    case "date-literal":
      return `DATE(${YMDtoMDY(expr.value)})`;
  }
}

function printDurationUnit(unit: T.DurationUnit, plural: boolean): string {
  if (plural) {
    switch (unit) {
      case "days":
        return "days";
      case "weeks":
        return "weeks";
      case "fortnights":
        return "fortnights";
      case "half-months":
        return "halfmonths";
      case "months":
        return "months";
      case "quarters":
        return "quarters";
      case "years":
        return "years";
    }
  }

  switch (unit) {
    case "days":
      return "day";
    case "weeks":
      return "week";
    case "fortnights":
      return "fortnight";
    case "half-months":
      return "halfmonth";
    case "months":
      return "month";
    case "quarters":
      return "quarter";
    case "years":
      return "year";
  }
}
