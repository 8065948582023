import React, { useState } from "react";
import { CreditApplicationFieldDefinition } from "types/generated-types";
import { BaseFieldDefinition } from "types/engine-types";
import Select from "design/atoms/select";
import TextInput from "design/atoms/text-input";
import { ALL_US_STATES } from "features/us-states";
import { ALL_US_COUNTIES_SORT_BY_STATE_CODE } from "features/us-counties";
import { Label } from "design/atoms/label/styles";
import { EnumWrapper } from "./styles";

export default React.memo(
  ({
    field,
    format,
    showLabel = true,
  }: {
    field: CreditApplicationFieldDefinition | BaseFieldDefinition;
    format: string;
    showLabel?: boolean;
  }) => {
    type usState = {
      name: string;
      twoLetterCode: string;
      twoDigitCode: string;
    };

    type usCounty = {
      name: string;
      fiveDigitCode: string;
      usState: {
        name: string;
        twoLetterCode: string;
        twoDigitCode: string;
      };
    };
    const [selectedState, setSelectedState] = useState<usState>();
    const [selectedCounty, setSelectedCounty] = useState<usCounty>();

    const allStates = ALL_US_STATES as usState[];
    const allCounties = ALL_US_COUNTIES_SORT_BY_STATE_CODE as usCounty[];

    switch (format) {
      case "plain": {
        return <Label children={<TextInput label={field.name} />} />;
      }
      case "us-state-code": {
        return (
          <EnumWrapper>
            <Select
              label={field.name}
              options={allStates}
              value={selectedState!}
              getOptionLabel={(value: usState) => value.name}
              getOptionValue={(value: usState) => value.name}
              onChange={(e) => {
                setSelectedState(e!);
              }}
            />
          </EnumWrapper>
        );
      }
      case "us-county-code": {
        return (
          <EnumWrapper>
            <Select
              label={field.name}
              options={allCounties}
              value={selectedCounty!}
              getOptionLabel={(value: usCounty) => value.name}
              getOptionValue={(value: usCounty) => value.name}
              onChange={(e) => setSelectedCounty(e!)}
            />
          </EnumWrapper>
        );
      }
    }
    return <></>;
  },
);
