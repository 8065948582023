import React from "react";
import { Box, Button, TextField } from "@material-ui/core";
import * as Api from "api";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import * as T from "types/engine-types";
import { SecondaryTitle } from "design/atoms/typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import { useTableStyles } from "pages/object-patterns/create";
import { filterJoins } from "features/object-patterns";
import { localAccessId } from "features/access-id";

interface State {
  clientId: string;
  investorId: string;
  patternId: string;
}

interface Props {
  joins: T.ObjectPatternInvestorJoin[];
  setter: (patterns: T.ObjectPatternInvestorJoin[]) => void;
}

export const PatternInvestorsPage = React.memo(() => {
  const [joinsLoad, setJoinsLoad] = Api.Admin.useObjectPatternInvestorJoins();

  if (joinsLoad.status === "error") {
    return <>"Error"</>;
  }

  if (joinsLoad.status === "loading") {
    return <Loader loaded={false} />;
  }

  return (
    <LoadedPatternInvestorsPage joins={joinsLoad.value} setter={setJoinsLoad} />
  );
});

export class LoadedPatternInvestorsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const state: Readonly<State> = {
      clientId: "",
      investorId: "",
      patternId: "",
    };
    this.state = state;

    this.handleChangeClientId = this.handleChangeClientId.bind(this);
    this.handleChangeInvestorId = this.handleChangeInvestorId.bind(this);
    this.handleChangePatternId = this.handleChangePatternId.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeClientId(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ clientId: event.target.value });
  }

  handleChangeInvestorId(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ investorId: event.target.value });
  }

  handleChangePatternId(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ patternId: event.target.value });
  }

  async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const { clientId, investorId, patternId } = this.state;
    const join: T.ObjectPatternInvestorJoin = {
      clientId: clientId as T.ClientId,
      investorId: investorId as T.InvestorId,
      objectPatternId: patternId as T.ObjectPatternId,
    };
    const returned = await Api.Admin.createObjectPatternInvestorJoin(join);
    this.props.setter([...this.props.joins, returned]);
  }

  render() {
    const joinCount = this.props.joins.length;
    const accessId: string = localAccessId() || "super";
    return (
      <div>
        <Button
          component={Link}
          to={`/c/${accessId}/__admin/object-patterns`}
          variant="outlined"
          startIcon={<ArrowBackIcon />}
        >
          Back to Object Patterns
        </Button>
        <Box px={2} my={2}>
          <SecondaryTitle>{joinCount} Joins</SecondaryTitle>
          <form onSubmit={(e) => this.handleSubmit(e)}>
            {InputField("ClientId", this.state.clientId, (e) => {
              this.handleChangeClientId(e);
            })}
            {InputField("InvestorId", this.state.investorId, (e) => {
              this.handleChangeInvestorId(e);
            })}
            {InputField("PatternId", this.state.patternId, (e) => {
              this.handleChangePatternId(e);
            })}
            <input type="submit" value="Submit" />
          </form>
        </Box>
        <Box px={2} my={2}>
          <SecondaryTitle>Patterns</SecondaryTitle>
          <Table patterns={filterJoins(this.props.joins, this.state)} />
        </Box>
      </div>
    );
  }
}

function InputField(
  label: string,
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
) {
  return (
    <Box my={1}>
      <TextField
        style={{ width: "600px" }}
        label={label}
        variant="outlined"
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e);
        }}
      />
    </Box>
  );
}

interface TableProps {
  patterns: T.ObjectPatternInvestorJoin[];
}

const Table = (props: TableProps) => {
  const C = useTableStyles();
  return (
    <table className={C.table}>
      <thead>
        <tr>
          <th className={C.columnLabel}>clientId</th>
          <th className={C.columnLabel}>investorId</th>
          <th className={C.columnLabel}>objectPatternId</th>
        </tr>
      </thead>
      {props.patterns.map(({ clientId, investorId, objectPatternId }) => (
        <tr>
          <td className={C.rowLabel}>{clientId}</td>
          <td className={C.field}>{investorId}</td>
          <td className={C.field}>{objectPatternId}</td>
        </tr>
      ))}
    </table>
  );
};
