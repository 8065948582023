import * as DateFns from "date-fns";
import { Map as IMap } from "immutable";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Box } from "@material-ui/core";
import { investorsSelector, getInvestors } from "features/investors";
import WarningIcon from "@material-ui/icons/Warning";
import * as Api from "api";
import * as T from "types/engine-types";
import { useHistory, useLocation } from "react-router-dom";

import {
  FieldValueState,
  newFieldValueState,
  convertStateToFieldValue,
} from "design/organisms/field-value-editor";
import {
  UiValidationError,
  useAsyncLoaderWithAbort,
  useById,
  useDebounce,
  useIMapSetter,
  unreachable,
  getErrorMessage,
} from "features/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  loansSelector,
  loadScenario,
  persistCurrentFormValues,
} from "features/loans";
import {
  productsSelector,
  productsLoadingSelector,
  getProducts,
} from "features/products";
import { summarySelector } from "features/pricing-summaries";
import { rulesSelector, getRules } from "features/rules";
import { setSummary, resetFiltersToDefault } from "features/pricing-summaries";
import { isObjectRefValid } from "features/objects";
import FieldsColumn from "./_components/fields-column";
import IFrameListener from "design/application/iframe-listener";
import ProductResults from "./_components/product-results";
import Errorbar from "./_components/error-bar";
import { useStyles } from "./styles";
import {
  expandedConfigSelector,
  nonNullApplicationInitializationSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import { hasAdminSelector } from "features/roles";

export default React.memo(() => {
  const C = useStyles();
  const dispatch = useDispatch();
  const { investors } = useSelector(investorsSelector);
  const productsState = useSelector(productsSelector);
  const productsLoading = useSelector(productsLoadingSelector);
  const productsById = useById(
    productsState.products ? productsState.products : [],
  );
  const { rules } = useSelector(rulesSelector);
  const { scenarioToLoad } = useSelector(loansSelector);
  const { myPricingProfile, myPricingProfiles, myDefaultFieldValues } =
    useSelector(nonNullApplicationInitializationSelector);
  const summaryState = useSelector(summarySelector);
  const [alertOpen, setAlertOpen] = useState(true);
  const [skippedScenarioValues, setSkippedScenarioValues] = useState<string[]>(
    [],
  );
  const history = useHistory();
  const location = useLocation();
  const [hashRead, setHashRead] = useState(false);
  const [missingScenarioFields, setMissingScenarioFields] = useState<string[]>(
    [],
  );
  const config = useSelector(expandedConfigSelector);
  const objectDetails = useSelector(objectDetailsMapSelector);
  const rulesById = useById(rules);
  const applicationFields = config.creditApplicationFields;
  const userHasAdminRole = useSelector(hasAdminSelector);
  const investorsById = useById(investors);
  const applicationFieldsById = useById(applicationFields);

  useEffect(() => {
    if (!productsLoading) dispatch(getProducts());
  }, [dispatch, productsLoading]);

  useEffect(() => {
    if (investors.length === 0) dispatch(getInvestors());
  }, [dispatch, investors.length]);

  useEffect(() => {
    if (rules.length === 0) dispatch(getRules());
  }, [dispatch, rules.length]);

  const initialApplicationFieldValueStates: IMap<T.FieldId, FieldValueState> =
    useMemo(
      () =>
        IMap<T.FieldId, FieldValueState>(
          applicationFields.map((f) => [f.id, newFieldValueState(f.valueType)]),
        ),
      [applicationFields],
    );

  const [applicationFieldValueStates, setApplicationFieldValueStates] =
    useState(initialApplicationFieldValueStates);

  const applicationFieldValueSetter = useIMapSetter(
    setApplicationFieldValueStates,
  );
  // reset fields to base values
  const resetFields = useCallback(() => {
    setApplicationFieldValueStates(initialApplicationFieldValueStates);
  }, [setApplicationFieldValueStates, initialApplicationFieldValueStates]);

  const [debouncedApplicationFieldValueStates, fieldValueStatesPending] =
    useDebounce(applicationFieldValueStates, 500);

  const fieldValueMappings: T.FieldValueMapping[] | null | undefined =
    useMemo(() => {
      try {
        const loggedMissing: string[] = [];
        setMissingScenarioFields(loggedMissing);

        const debouncedResult = debouncedApplicationFieldValueStates
          .toArray()
          .flatMap(([fieldId, state]) => {
            const valueType = applicationFieldsById.get(fieldId)?.valueType;
            if (!valueType) {
              console.warn("Field is missing:", fieldId);
              loggedMissing.push(fieldId);
              return [];
            } else {
              try {
                const fieldValue = convertStateToFieldValue(valueType, state);
                return fieldValue
                  ? [
                      {
                        fieldId,
                        value: fieldValue,
                      },
                    ]
                  : [];
              } catch (err) {
                if (err instanceof UiValidationError) {
                  console.warn(
                    "Could not set field value",
                    {
                      fieldId,
                      state,
                    },
                    err,
                  );
                  newrelic.noticeError(getErrorMessage(err));
                  loggedMissing.push(fieldId);
                  return [];
                } else {
                  const error = new Error("Error while mapping field");
                  const fieldIdErr = new Error(fieldId);
                  console.error(err, fieldId, err);
                  newrelic.noticeError(error);
                  newrelic.noticeError(fieldIdErr);
                  newrelic.noticeError(getErrorMessage(err));
                  throw err;
                }
              }
            }
          });

        setMissingScenarioFields(loggedMissing);
        dispatch(persistCurrentFormValues(debouncedResult));
        return debouncedResult;
      } catch (err) {
        if (err instanceof UiValidationError) {
          return null;
        }
        throw err;
      }
    }, [dispatch, applicationFieldsById, debouncedApplicationFieldValueStates]);

  const setValues = useCallback(
    (scenario: T.FieldValueMapping, loggedSkips: string[]) => {
      const key: T.FieldId = scenario.fieldId;
      // validate if stored enum/object selections are still available in the system
      switch (scenario.value.type) {
        case "enum":
          const savedEnum = config.enumTypesById.get(scenario.value.enumTypeId);
          const savedEnumFound = savedEnum?.variants
            .map((v) => v.id)
            .includes(scenario.value.variantId);

          if (savedEnumFound) {
            applicationFieldValueSetter.withKey(key)(scenario.value);
          } else if (savedEnum?.name) {
            // log fields with invalid stored enum
            loggedSkips.push(savedEnum?.name);
          }
          break;
        case "object-ref":
          if (
            isObjectRefValid(
              config.original,
              objectDetails,
              scenario.value.objectRef,
            )
          ) {
            applicationFieldValueSetter.withKey(key)({
              type: "object-ref",
              objectType: scenario.value.objectRef.type,
              objectRef: scenario.value.objectRef,
            });
          } else {
            // log fields with invalid stored object id
            loggedSkips.push(
              `${scenario.value.objectRef.type}-${scenario.value.objectRef.id}`,
            );
          }
          break;
        case "duration":
        case "date":
        case "number":
        case "string":
          applicationFieldValueSetter.withKey(key)(scenario.value);
          break;
        default:
          return unreachable(scenario.value);
      }
    },
    [
      config.enumTypesById,
      config.original,
      objectDetails,
      applicationFieldValueSetter,
    ],
  );

  // sets form inputs when scenarioToLoad is set
  useEffect(() => {
    if (!!scenarioToLoad?.length) {
      // ensure a clean slate before setting inputs
      resetFields();

      // clear skippedScenarioValues
      const loggedSkips: string[] = [];
      setSkippedScenarioValues([]);

      // set inputs
      scenarioToLoad?.forEach((fieldValueMapping) => {
        setValues(fieldValueMapping, loggedSkips);
      });

      // clear the current scenarioToLoad
      dispatch(loadScenario(null));

      // alert skips
      if (loggedSkips.length) {
        setAlertOpen(true);
        setSkippedScenarioValues(loggedSkips);
      }
    }
  }, [dispatch, scenarioToLoad, resetFields, setValues]);

  type HiddenDefaultObject = {
    fieldId: string;
    value: string | object;
  };

  type hiddenDefaultArray = HiddenDefaultObject[];

  const [hiddenDefaults, setHiddenDefaults] =
    useState<hiddenDefaultArray | null>(null);

  const fieldNameRegex = /\b(?!field\b)([\w'])+/g;

  useEffect(() => {
    // generates array of hidden fields with default values
    const handleHiddenDefaults = (): void => {
      if (myDefaultFieldValues?.length) {
        const newHiddenDefaults = myDefaultFieldValues
          ?.filter((field) => field.hidden)
          ?.map((field) => {
            switch (field.value.type) {
              case "enum":
                return {
                  fieldId: field.fieldId,
                  value: field.value.variantId,
                };
              case "duration":
                return {
                  fieldId: field.fieldId,
                  value: field.value.count + " " + field.value.unit,
                };
              case "object-ref":
                return {
                  fieldId: field.fieldId,
                  value: field.value,
                };
              default:
                return {
                  fieldId: field.fieldId,
                  value: field.value.value,
                };
            }
          });
        setAlertOpen(true);
        setHiddenDefaults(newHiddenDefaults);
      } else {
        setHiddenDefaults(null);
      }
    };

    handleHiddenDefaults();
  }, [myDefaultFieldValues, scenarioToLoad]);

  const requestTime = useMemo(
    () => DateFns.formatISO(new Date()),
    // unnecessary depency on fieldValueMappings, meant to recompute when it changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fieldValueMappings],
  );
  // TODO: update useAsyncLoader|useAsyncLoaderWithAbort to be more consistent with
  //       the LoadState pattern used elsewhere in the app, or switch to an external
  //       library for loading promises in React components with hooks
  const { client } = useSelector(nonNullApplicationInitializationSelector);
  const [summary, summaryLoading] = useAsyncLoaderWithAbort(() => {
    if (
      !fieldValueMappings ||
      !myPricingProfile?.id ||
      !myPricingProfiles?.length
    ) {
      return [Promise.resolve(null), null];
    }

    // create a list of current fieldValueMappings exclusive of any default values
    const fieldValueMappingsWithoutDefaults: T.FieldValueMapping[] = [];
    fieldValueMappings.forEach((fieldValueMapping) => {
      // find if fieldValueMapping matches a default value
      const match = myDefaultFieldValues.find((defaultFieldValueMapping) => {
        return fieldValueMapping.fieldId === defaultFieldValueMapping.fieldId;
      });

      // if so compare values
      // else go ahead and include this fieldValueMapping
      if (match) {
        // fieldValueMapping and match types do not match
        // copy into correct type
        const copy: T.FieldValueMapping = {
          fieldId: match.fieldId,
          value: match.value,
        };

        // if values DO NOT match, include this fieldValueMapping
        if (JSON.stringify(fieldValueMapping) !== JSON.stringify(copy)) {
          fieldValueMappingsWithoutDefaults.push(fieldValueMapping);
        }
      } else {
        fieldValueMappingsWithoutDefaults.push(fieldValueMapping);
      }
    });
    // Get client from store

    if (fieldValueMappingsWithoutDefaults.length) {
      history.push(
        `/c/${client.accessId}/loan-pricing#/${encodeURI(
          JSON.stringify(fieldValueMappingsWithoutDefaults),
        )}`,
      );
    } else {
      history.push(`/c/${client.accessId}/loan-pricing`);
    }

    // results are unsorted here, they are sorted later in redux
    return Api.executeSummary({
      currentTime: requestTime,
      pricingProfileId:
        myPricingProfiles.length > 1 ? myPricingProfile.id : null,
      creditApplicationFields: fieldValueMappings,
      outputFieldsFilter: { type: "none" },
    });
  }, [
    history,
    requestTime,
    myPricingProfile,
    myPricingProfiles,
    fieldValueMappings,
    myDefaultFieldValues,
    client.accessId,
  ]);

  if (summary) {
    dispatch(setSummary(summary));
  }

  useEffect(() => {
    config && dispatch(resetFiltersToDefault({ config }));

    return () => {
      config && dispatch(resetFiltersToDefault({ config }));
    };
  }, [dispatch, config]);

  // reads url hash and updates form inputs on initial render
  useEffect(() => {
    // read url hash
    const hash = decodeURI(location.hash || "").replace("#/", "");

    // set inputs if initial render
    if (!hashRead && hash) {
      // eslint-disable-next-line  @typescript-eslint/no-unsafe-assignment
      const hashJSON: T.FieldValueMapping[] = !!hash && JSON.parse(hash);

      // ensure a clean slate before setting inputs
      resetFields();

      // clear skippedScenarioValues
      const loggedSkips: string[] = [];
      setSkippedScenarioValues([]);

      // set inputs and mark hash as read
      hashJSON?.forEach((fieldValueMapping) => {
        setHashRead(true);
        setValues(fieldValueMapping, loggedSkips);
      });

      // alert skips
      if (loggedSkips.length) {
        setAlertOpen(true);
        setSkippedScenarioValues(loggedSkips);
      }
    }
  }, [dispatch, hashRead, location.hash, resetFields, setValues]);

  return (
    <Box className={`${C.pageContainer} flatten-for-print`}>
      {/* Renders an error message if an admin attempts to load a scenario that has hidden default values while using non-admin permissions. */}
      {hiddenDefaults && userHasAdminRole && scenarioToLoad && (
        <Errorbar
          className={C.snackbar}
          alertOpen={alertOpen}
          setAlertOpen={setAlertOpen}
        >
          <WarningIcon style={{ color: "red", marginRight: "16px" }} /> The
          currently selected scenario contains hidden fields with default
          values.
          <br />
          <br />
          {hiddenDefaults?.map((field) => {
            return (
              <>
                {String(field.fieldId.match(fieldNameRegex)?.join(" ")) +
                  ": " +
                  String(field.value)}
                <br />
              </>
            );
          })}
        </Errorbar>
      )}

      {/* Render invalid stored scenario fields in flash message */}
      {/* Occurs when a field has been removed or its id has chanhged, for example */}
      {/* added summary dependency to prevent warning message flashing */}
      {myPricingProfiles.length === 0 && summary && (
        <Errorbar
          className={C.snackbar}
          alertOpen={alertOpen}
          setAlertOpen={setAlertOpen}
        >
          <WarningIcon style={{ color: "red", marginRight: "16px" }} /> Your
          account is missing a Pricing Profile. Consult with your admin to
          resolve this issue.
        </Errorbar>
      )}

      {/* Render invalid stored scenario fields in flash message */}
      {/* Occurs when a field has been removed or its id has chanhged, for example */}
      {!!missingScenarioFields.length && (
        <Errorbar
          className={C.snackbar}
          alertOpen={alertOpen}
          setAlertOpen={setAlertOpen}
        >
          <WarningIcon style={{ color: "red", marginRight: "16px" }} /> The
          fields with the following IDs are no longer in the system or have
          changed, and as a result have been omitted:{" "}
          <strong style={{ display: "contents", margin: "0 4px" }}>
            {missingScenarioFields?.join(", ")}
          </strong>
          . It may be best to delete the scenario.{" "}
        </Errorbar>
      )}

      {/* Render invalid stored scenario values in flash message */}
      {/* Occurs when a field enum has been removed, for example */}
      {!!skippedScenarioValues.length && (
        <Errorbar
          className={C.snackbar}
          alertOpen={alertOpen}
          setAlertOpen={setAlertOpen}
        >
          <WarningIcon style={{ color: "red", marginRight: "16px" }} /> The
          saved value(s) for the following fields are no longer in the system,
          and as a result have been removed:{" "}
          <strong style={{ display: "contents", margin: "0 4px" }}>
            {skippedScenarioValues?.join(", ")}
          </strong>{" "}
        </Errorbar>
      )}

      <FieldsColumn
        applicationFields={applicationFields}
        applicationFieldValueStatesById={applicationFieldValueStates}
        setApplicationFieldValueStatesById={setApplicationFieldValueStates}
        fieldValueMappings={fieldValueMappings}
        defaultFieldValues={myDefaultFieldValues ?? []}
      />

      {!summaryState.summary && <Box flex="1" />}
      {summaryState.summary && (
        <ProductResults
          summary={summaryState.summary}
          requestTime={requestTime}
          fieldValueMappings={fieldValueMappings}
          stale={fieldValueStatesPending || summaryLoading}
          productsById={productsById}
          rulesById={rulesById}
          investors={investors}
          investorsById={investorsById}
          config={config}
          objectDetails={objectDetails}
        />
      )}
      <IFrameListener />
    </Box>
  );
});
