import styled from "styled-components";
import breakpoints from "design/subatomics/breakpoints";
import whitespace from "design/subatomics/whitespace";
import color from "design/subatomics/colors";
import { styles } from "design/atoms/typography";
import transitions from "design/subatomics/transitions";
import layers from "design/subatomics/layers";
import shadowOptions from "design/subatomics/shadows";

export const ProductResult = styled.div`
  position: sticky;
  top: 0;
  z-index: ${layers({ layer: "above" })};
  border-top: 1px solid ${color({ color: "lightBorder" })};
  border-bottom: 1px solid ${color({ color: "lightBorder" })};
  transition: background-color ${transitions.default},
    border-color ${transitions.default}, color ${transitions.default};
  box-shadow: ${shadowOptions.dropdowns};

  * {
    transition: background-color ${transitions.default},
      color ${transitions.default};
  }

  h2 {
    svg {
      transition: color ${transitions.default}, opacity ${transitions.default};
    }
  }

  &.green {
    background-color: ${color({ color: "green", shade: 10 })};

    h2 {
      svg:first-of-type {
        color: ${color({ color: "green", shade: 5 })};
      }
    }
  }

  &.blue {
    background-color: ${color({ color: "white" })};

    h2 {
      svg:first-of-type {
        color: ${color({ color: "blue", shade: 5 })};
      }
    }
  }

  &.gray {
    background-color: ${color({ color: "gray", shade: 9 })};

    * {
      color: ${color({ color: "gray", shade: 6 })};
    }

    h2 {
      svg:first-of-type,
      path {
        color: ${color({ color: "gray", shade: 7 })};
      }
    }
  }

  &.red {
    background-color: ${color({ color: "red", shade: 10 })};

    h2 {
      svg:first-of-type {
        color: ${color({ color: "red", shade: 5 })};
      }
    }
  }

  &.orange {
    background-color: ${color({ color: "orange", shade: 10 })};

    h2 {
      svg:first-of-type {
        color: ${color({ color: "orange", shade: 5 })};
      }
    }
  }

  &.yellow {
    background-color: ${color({ color: "yellow", shade: 10 })};

    h2 {
      svg:first-of-type {
        color: ${color({ color: "yellow", shade: 5 })};
      }
    }
  }

  ${breakpoints.mobile} {
    padding: ${whitespace("least")} ${whitespace("least")};

    h2 {
      ${styles.text} !important;
    }

    .select-product,
    .pricing-popover {
      opacity: 1 !important;
    }

    &.closed {
      flex-basis: 0%;
    }

    &.open {
      flex-basis: 100vw;
    }

    .key-value-group {
      margin-left: calc(${whitespace("most")} + ${whitespace("least")});
    }
  }

  ${breakpoints.desktop} {
    padding: ${whitespace("least")} ${whitespace("least")};
    margin: 0 ${whitespace("least", true)};

    &.closed {
      flex-basis: 0%;
    }

    &.open {
      flex-basis: calc(100vw - 20%);
    }

    .key-value-group {
      margin-left: calc(${whitespace("most")} + ${whitespace()});

      .key-value {
        width: 200px;
      }
    }
  }
`;

export const InlineGroup = styled.div.attrs(() => ({
  className: "page-loan-pricing-component-product-result-inline-group",
}))`
  ${styles.secondaryTitleSub};
  margin-top: ${whitespace("least")};

  ${breakpoints.mobile} {
    display: block;

    > span {
      display: block;
      margin-left: calc(${whitespace("most")} + ${whitespace("least")});
    }
  }

  ${breakpoints.desktop} {
    display: block;

    > span {
      flex: 0 1 100%;
      display: block;
      margin-left: calc(${whitespace("most")} + ${whitespace()});

      &.investor-name {
      }
    }
  }
`;

export const ResultActions = styled.div.attrs(() => ({
  className: "page-loan-pricing-component-product-result-result-actions",
}))`
  display: block;
  position: absolute;
  top: ${whitespace("least")};
  right: ${whitespace("least")};
  display: flex;
  flex-direction: column;
  gap: ${whitespace()};
`;
