import * as T from "./generated-types";

export const ALL_ACTION_TYPES: T.RuleActionType[] = [
  "reject",
  "add-rate-adjustment",
  "add-price-adjustment",
  "add-margin-adjustment",
  "add-stipulation",
];

export function actionTypeToString(a: T.RuleActionType): string {
  switch (a) {
    case "reject":
      return "Reject";
    case "require-review":
      return "Require Review";
    case "add-price-adjustment":
      return "Add Price Adjustment";
    case "add-rate-adjustment":
      return "Add Rate Adjustment";
    case "add-margin-adjustment":
      return "Add Margin Adjustment";
    case "add-stipulation":
      return "Add Stipulation";
  }
}
