import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { LoanPricing, FieldsPanel } from "./styles";
import FieldsColumnWelcome from "./_components/fields-column-welcome";
import ProductResults from "./_components/product-results";
import ProductDetails from "./_components/product-details";
import { loanPricingStateSelector } from "features/loan-pricing";
import FieldsColumn from "./_components/fields-column";
import { Map as IMap } from "immutable";
import * as T from "types/engine-types";
import { useHistory, useLocation } from "react-router-dom";
import * as Api from "api";
import { rulesSelector, getRules } from "features/rules";
import {
  loansSelector,
  loadScenario,
  persistCurrentFormValues,
  mostRequiredFieldsSelector,
  setSummary,
} from "features/loans";
import {
  FieldValueState,
  newFieldValueState,
  convertStateToFieldValue,
} from "design/organisms/field-value-editor";
import { useAppDispatch } from "features/store";
import * as DateFns from "date-fns";
import {
  UiValidationError,
  useById,
  useDebounce,
  unreachable,
  useIMapSetter,
  getErrorMessage,
  useAsyncLoaderWithAbort,
} from "features/utils";
import { isObjectRefValid } from "features/objects";
import {
  expandedConfigSelector,
  nonNullApplicationInitializationSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import { setSummary as setPricingSummary } from "features/pricing-summaries";
import { investorsSelector, getInvestors } from "features/investors";
import { productsLoadingSelector, getProducts } from "features/products";
import { localAccessId } from "features/access-id";
import { createManualOpenEvent } from "design/molecules/accordion";

export default React.memo(() => {
  const objectDetails = useSelector(objectDetailsMapSelector);
  const productsLoading = useSelector(productsLoadingSelector);
  const { rules } = useSelector(rulesSelector);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [alertOpen, setAlertOpen] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const params = useParams<{ productId: string }>();
  const detailsIsOpen = !!params.productId;
  const config = useSelector(expandedConfigSelector);
  const { myPricingProfile, myDefaultFieldValues } = useSelector(
    nonNullApplicationInitializationSelector,
  );
  const { investors } = useSelector(investorsSelector);

  const applicationFields = config.creditApplicationFields;
  const { queryParams } = useSelector(loanPricingStateSelector);
  const [initialFetch, setInitialFetch] = useState(false);
  const fieldsAreOpen =
    !detailsIsOpen &&
    (!queryParams.activeView || queryParams.activeView === "inputs");
  const { scenarioToLoad } = useSelector(loansSelector);

  const [hashRead, setHashRead] = useState(false);
  const initialApplicationFieldValueStates: IMap<T.FieldId, FieldValueState> =
    useMemo(
      () =>
        IMap<T.FieldId, FieldValueState>(
          applicationFields.map((f) => [f.id, newFieldValueState(f.valueType)]),
        ),
      [applicationFields],
    );

  const applicationFieldsById = useById(applicationFields);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [missingScenarioFields, setMissingScenarioFields] = useState<string[]>(
    [],
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [skippedScenarioValues, setSkippedScenarioValues] = useState<string[]>(
    [],
  );

  const [applicationFieldValueStates, setApplicationFieldValueStates] =
    useState(initialApplicationFieldValueStates);
  const applicationFieldValueSetter = useIMapSetter(
    setApplicationFieldValueStates,
  );
  const [debouncedApplicationFieldValueStates] = useDebounce(
    applicationFieldValueStates,
    500,
  );

  const mostRequiredFields = useSelector(mostRequiredFieldsSelector);
  const [fieldToHighlight, setFieldToHighlight] = useState<
    T.CreditApplicationFieldDefinition | undefined
  >(undefined);

  const localSet: string | null =
    localStorage.getItem("loanPASS::setForceCollapseResults") || "false";

  const [forceCollapseResults, setForceCollapseResults] = useState<boolean>(
    localSet === "true",
  );

  useEffect(() => {
    if (rules.length === 0) dispatch(getRules());
  }, [dispatch, rules.length]);

  const fieldValueMappings: T.FieldValueMapping[] | null | undefined =
    useMemo(() => {
      try {
        const loggedMissing: string[] = [];
        setMissingScenarioFields(loggedMissing);

        const debouncedResult = debouncedApplicationFieldValueStates
          .toArray()
          .flatMap(([fieldId, state]) => {
            const valueType = applicationFieldsById.get(fieldId)?.valueType;
            if (!valueType) {
              console.warn("Field is missing:", fieldId);
              loggedMissing.push(fieldId);
              return [];
            } else {
              try {
                const fieldValue = convertStateToFieldValue(valueType, state);
                return fieldValue
                  ? [
                      {
                        fieldId,
                        value: fieldValue,
                      },
                    ]
                  : [];
              } catch (err) {
                if (err instanceof UiValidationError) {
                  console.warn(
                    "Could not set field value",
                    {
                      fieldId,
                      state,
                    },
                    err,
                  );
                  newrelic.noticeError(getErrorMessage(err));
                  loggedMissing.push(fieldId);
                  return [];
                } else {
                  const error = new Error("Error while mapping field");
                  const fieldIdErr = new Error(fieldId);
                  console.error("Error while mapping field", fieldId, err);
                  newrelic.noticeError(error);
                  newrelic.noticeError(fieldIdErr);
                  newrelic.noticeError(getErrorMessage(err));
                  throw err;
                }
              }
            }
          });

        setMissingScenarioFields(loggedMissing);

        dispatch(persistCurrentFormValues(debouncedResult));
        return debouncedResult;
      } catch (err) {
        if (err instanceof UiValidationError) {
          return null;
        }

        throw err;
      }
    }, [dispatch, applicationFieldsById, debouncedApplicationFieldValueStates]);

  const [summary, summaryLoading] = useAsyncLoaderWithAbort(() => {
    if (
      scenarioToLoad ||
      !hashRead ||
      !fieldValueMappings ||
      !myPricingProfile?.id
    ) {
      return [Promise.resolve(null), null];
    }

    const currentTime = DateFns.formatISO(new Date());

    // create a list of current fieldValueMappings exclusive of any default values
    const fieldValueMappingsWithoutDefaults: T.FieldValueMapping[] = [];
    fieldValueMappings.forEach((fieldValueMapping) => {
      // find if fieldValueMapping matches a default value
      const match = myDefaultFieldValues.find((defaultFieldValueMapping) => {
        return fieldValueMapping.fieldId === defaultFieldValueMapping.fieldId;
      });

      // if so compare values
      // else go ahead and include this fieldValueMapping
      if (match) {
        // fieldValueMapping and match types do not match
        // copy into correct type
        const copy: T.FieldValueMapping = {
          fieldId: match.fieldId,
          value: match.value,
        };

        // if values DO NOT match, include this fieldValueMapping
        if (JSON.stringify(fieldValueMapping) !== JSON.stringify(copy)) {
          fieldValueMappingsWithoutDefaults.push(fieldValueMapping);
        }
      } else {
        fieldValueMappingsWithoutDefaults.push(fieldValueMapping);
      }
    });

    const oldHash = decodeURIComponent(location.hash).replace("#", "");
    const newHash = !!fieldValueMappingsWithoutDefaults.length
      ? JSON.stringify(fieldValueMappingsWithoutDefaults)
      : "";
    const extraFields =
      config.settings.priceScenarioTable?.type === "rate-with-columns"
        ? config.settings.priceScenarioTable?.columns
        : config.settings.priceScenarioTable?.extraColumns;

    if (!initialFetch && fieldValueMappings) {
      history.push(
        `${location.pathname}${location.search}#${encodeURIComponent(newHash)}`,
      );

      return Api.executeSummary({
        currentTime,
        pricingProfileId: myPricingProfile.id,
        creditApplicationFields: fieldValueMappings,
        outputFieldsFilter: { type: "only", fieldIds: extraFields || [] },
      });
    } else if (fieldValueMappingsWithoutDefaults.length) {
      history.push(`${location.pathname}${location.search}#${newHash}`);

      if (oldHash !== newHash) {
        return Api.executeSummary({
          currentTime,
          pricingProfileId: myPricingProfile.id,
          creditApplicationFields: fieldValueMappings,
          outputFieldsFilter: { type: "only", fieldIds: extraFields || [] },
        });
      } else {
        return [Promise.resolve(null), null];
      }
    } else {
      history.push(`${location.pathname}${location.search}`);
      return [Promise.resolve(null), null];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    config,
    myPricingProfile,
    fieldValueMappings,
    history,
    hashRead,

    initialFetch,
  ]);

  useEffect(() => {
    if (!productsLoading) dispatch(getProducts());
  }, [dispatch, productsLoading]);

  useEffect(() => {
    if (investors.length === 0) dispatch(getInvestors());
  }, [dispatch, investors.length]);

  const accessId = localAccessId();
  const highlightNextField = useCallback(
    (fields: T.FieldId[] | undefined) => {
      applicationFields.find((field) => {
        if (fields?.includes(field.id)) {
          createManualOpenEvent(field?.id as string);
          history.push(`/c/${accessId}/v2/loan-pricing${location.hash}`);
          setFieldToHighlight(field);
          return true;
        }
      });
    },
    [history, accessId, location.hash, applicationFields],
  );

  useEffect(() => {
    if (summary) {
      setInitialFetch(false);
      dispatch(setSummary(summary));
      dispatch(setPricingSummary(summary));
    }

    return () => {
      dispatch(setSummary(null));
      dispatch(setPricingSummary(null));
    };
  }, [summary, dispatch]);

  const resetFields = useCallback(() => {
    setInitialFetch(false);
    setApplicationFieldValueStates(initialApplicationFieldValueStates);
  }, [initialApplicationFieldValueStates]);

  const setValues = useCallback(
    (scenario: T.FieldValueMapping, loggedSkips: string[]) => {
      const key: T.FieldId = scenario.fieldId;
      // validate if stored enum/object selections are still available in the system
      switch (scenario.value.type) {
        case "enum":
          const savedEnum = config.enumTypesById.get(scenario.value.enumTypeId);
          const savedEnumFound = savedEnum?.variants
            .map((v) => v.id)
            .includes(scenario.value.variantId);

          if (savedEnumFound) {
            applicationFieldValueSetter.withKey(key)(scenario.value);
          } else if (savedEnum?.name) {
            // log fields with invalid stored enum
            loggedSkips.push(savedEnum?.name);
          }
          break;
        case "object-ref":
          if (
            isObjectRefValid(
              config.original,
              objectDetails,
              scenario.value.objectRef,
            )
          ) {
            applicationFieldValueSetter.withKey(key)({
              type: "object-ref",
              objectType: scenario.value.objectRef.type,
              objectRef: scenario.value.objectRef,
            });
          } else {
            // log fields with invalid stored object id
            loggedSkips.push(
              `${scenario.value.objectRef.type}-${scenario.value.objectRef.id}`,
            );
          }
          break;
        case "duration":
        case "date":
        case "number":
        case "string":
          applicationFieldValueSetter.withKey(key)(scenario.value);
          break;
        default:
          return unreachable(scenario.value);
      }
    },
    [
      config.enumTypesById,
      config.original,
      objectDetails,
      applicationFieldValueSetter,
    ],
  );

  useEffect(() => {
    // set fields in saved scenarios
    if (!!scenarioToLoad?.length) {
      resetFields();
      const loggedSkips: string[] = [];
      setSkippedScenarioValues([]);
      scenarioToLoad?.forEach((scenario) => {
        setValues(scenario, loggedSkips);
      });

      dispatch(loadScenario(null));

      if (loggedSkips.length) {
        setAlertOpen(true);
        setSkippedScenarioValues(loggedSkips);
      }
    }
  }, [
    dispatch,
    scenarioToLoad,
    applicationFieldValueSetter,
    applicationFieldsById,
    config.enumTypesById,
    config.original,
    objectDetails,
    resetFields,
    setValues,
  ]);

  useEffect(() => {
    // set fields in saved scenarios
    const hash = decodeURIComponent(location.hash || "").replace("#", "");

    if (!hashRead && hash) {
      // eslint-disable-next-line  @typescript-eslint/no-unsafe-assignment
      const hashJSON: T.FieldValueMapping[] = !!hash && JSON.parse(hash);
      resetFields();
      const loggedSkips: string[] = [];
      setSkippedScenarioValues([]);

      hashJSON?.forEach((scenario) => {
        setValues(scenario, loggedSkips);
      });

      dispatch(loadScenario(null));

      if (loggedSkips.length) {
        setAlertOpen(true);
        setSkippedScenarioValues(loggedSkips);
      }
    }
    setHashRead(true);
  }, [dispatch, hashRead, location.hash, resetFields, setValues]);

  return (
    <LoanPricing className="page-loan-pricing">
      <FieldsPanel className={fieldsAreOpen ? "open" : "closed"}>
        <FieldsColumnWelcome
          loading={summaryLoading}
          resetFields={resetFields}
          fieldValueMappings={fieldValueMappings}
          highlightNextField={() => highlightNextField(mostRequiredFields)}
        />

        <FieldsColumn
          applicationFields={applicationFields}
          applicationFieldValueStatesById={applicationFieldValueStates}
          setApplicationFieldValueStatesById={setApplicationFieldValueStates}
          defaultFieldValues={myDefaultFieldValues ?? []}
          setFieldToHighlight={setFieldToHighlight}
          fieldToHighlight={fieldToHighlight}
        />
      </FieldsPanel>

      <ProductResults
        setForceCollapseResults={setForceCollapseResults}
        className={
          detailsIsOpen || fieldsAreOpen
            ? forceCollapseResults || !params.productId
              ? "force-closed"
              : "closed"
            : "open"
        }
        loading={summaryLoading}
        highlightNextField={highlightNextField}
      />

      <ProductDetails
        forceCollapseResults={forceCollapseResults}
        setForceCollapseResults={setForceCollapseResults}
        fieldValueMappings={fieldValueMappings}
        className={detailsIsOpen ? "open" : "closed"}
        showControlButtons={false}
      />
    </LoanPricing>
  );
});
