import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";
import * as T from "types/engine-types";
import { ProductsState } from "features/products";
import { InvestorsState, investorsSelector } from "features/investors";
import { parseFloatOr, isPresent, unreachable } from "features/utils";
import { Configuration } from "config";
import { Set as ISet, Map as IMap } from "immutable";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

const params = new URLSearchParams(document.location.search);

export function findScenarioFieldValue(
  fieldId: T.FieldId,
  scenario: T.PriceScenarioResult,
): T.FieldValue | null {
  const mappings = [
    ...scenario.priceScenarioFields,
    ...scenario.calculatedFields,
  ];

  return mappings.find((mapping) => mapping.fieldId === fieldId)?.value ?? null;
}

type PriceScenarioPricing = {
  status: "approved" | "review-required";
  adjustedPrice: string | null;
  adjustedRateLockPeriod: T.DurationValue | null;
  adjustedRate: string | null;
  calculatedFields: T.OptionalFieldValueMapping[];
  priceScenarioFields: T.FieldValueMapping[];
};

export function getScenarioPricing(
  priceScenario: T.PriceScenarioSummary,
): PriceScenarioPricing | null {
  if (
    priceScenario.status !== "approved" &&
    priceScenario.status !== "review-required"
  ) {
    return null;
  }

  const {
    adjustedRate,
    adjustedPrice,
    adjustedRateLockPeriod,
    calculatedFields,
    priceScenarioFields,
  } = priceScenario;

  return {
    status: priceScenario.status,
    adjustedRate,
    adjustedPrice,
    adjustedRateLockPeriod,
    calculatedFields,
    priceScenarioFields,
  };
}

export type PricingFiltersState = T.DefaultPricingFilters & {
  rateLockPeriods: T.DurationValue[] | null;
  statuses: T.ExecutionProductSummary["status"][] | null;
  investorNames: string[] | null;
};

export type ExtraColumnsFilters = {
  id: T.FieldId | string | null;
  columnMin: string | null;
  columnMax: string | null;
};

export type DynamicPricingFiltersState = {
  adjustedPriceFieldId: {
    id: string | null;
    values: {
      min: string | null;
      max: string | null;
    };
  } | null;
  adjustedRateFieldId: {
    id: string;
    values: {
      min: string | null;
      max: string | null;
    };
  } | null;
  adjustedRateLockPeriodFieldId: {
    id: string | null;
    rateLockPeriods: T.DurationValue[] | null;
  } | null;
  extraColumns: T.FieldId[] | null;
  columns: T.FieldId[] | null;
  type: string | null;
  columnsValues: ExtraColumnsFilters[] | null;
};

export type DynamicFiltersObject = {
  sortedFilters: T.BaseFieldDefinition[];
  filtersObj: DynamicPricingFiltersState;
  dynamicColumns: ExtraColumnsFilters[];
};

export function defaultPricingFiltersState(
  defaultFilters: T.DefaultPricingFilters,
): PricingFiltersState {
  return {
    ...defaultFilters,
    rateLockPeriods: null,
    statuses: null,
    investorNames: null,
  };
}

export function defaultDynamicPricingFiltersState(): DynamicPricingFiltersState {
  return {
    adjustedPriceFieldId: null,
    adjustedRateFieldId: null,
    adjustedRateLockPeriodFieldId: null,
    extraColumns: null,
    columns: null,
    columnsValues: null,
    type: null,
  };
}

export function emptyPricingFiltersState(): PricingFiltersState {
  let base: PricingFiltersState = {
    minPrice: null,
    maxPrice: null,
    minRate: null,
    maxRate: null,
    rateLockPeriods: null,
    statuses: null,
    investorNames: null,
  };

  params.forEach((value, key) => {
    if (key === "filters" && value) {
      base = JSON.parse(value) as PricingFiltersState;
    }
  });

  return base;
}

export function emptyDynamicFiltersState(): DynamicPricingFiltersState {
  let base: DynamicPricingFiltersState = {
    adjustedPriceFieldId: null,
    adjustedRateFieldId: null,
    adjustedRateLockPeriodFieldId: null,
    extraColumns: null,
    columns: null,
    columnsValues: null,
    type: null,
  };

  params.forEach((value, key) => {
    if (key === "dynamicFilters" && value) {
      base = JSON.parse(value) as DynamicPricingFiltersState;
    }
  });

  return base;
}

export type SummaryState = {
  summary: T.ExecutionSummary | null;
  searchTerm: string;
  sortField: string;
  sortDir: string;
  loading: boolean;
  errors: string;
  filters: PricingFiltersState;
  dynamicFilters: DynamicPricingFiltersState | null;
};

const initialState: SummaryState = {
  summary: null,
  loading: false,
  searchTerm: params.get("searchTerm") || "",
  errors: "",
  sortField: params.get("sortField") || "productName",
  sortDir: params.get("sortDir") || "asc",
  filters: emptyPricingFiltersState(),
  dynamicFilters: defaultDynamicPricingFiltersState(),
};

function comparePriceScenarios(
  a: PriceScenarioPricing,
  b: PriceScenarioPricing,
): number {
  const aStatus = statusSortIndex(a.status);
  const aRate = parseFloatOr(a.adjustedRate, Number.POSITIVE_INFINITY);
  const aPrice = parseFloatOr(a.adjustedPrice, Number.POSITIVE_INFINITY);
  const aLock = parseFloatOr(
    a.adjustedRateLockPeriod?.count,
    Number.POSITIVE_INFINITY,
  );

  const bStatus = statusSortIndex(b.status);
  const bRate = parseFloatOr(b.adjustedRate, Number.POSITIVE_INFINITY);
  const bPrice = parseFloatOr(b.adjustedPrice, Number.POSITIVE_INFINITY);
  const bLock = parseFloatOr(
    b.adjustedRateLockPeriod?.count,
    Number.POSITIVE_INFINITY,
  );

  if (aStatus < bStatus) {
    return -1;
  } else if (aStatus > bStatus) {
    return 1;
  }

  if (aLock < bLock) {
    return -1;
  } else if (aLock > bLock) {
    return 1;
  }

  const aDelta = Math.abs(100 - aPrice);
  const bDelta = Math.abs(100 - bPrice);

  if (aDelta < bDelta) {
    return -1;
  } else if (aDelta > bDelta) {
    return 1;
  }

  if (aRate < bRate) {
    return -1;
  } else if (aRate > bRate) {
    return 1;
  }

  return 0;
}

export const getLowest = (
  result: T.ExecutionProductSummary,
): PriceScenarioPricing | null => {
  if (result.status !== "approved" && result.status !== "review-required") {
    return null;
  }

  const approvedScenarios = result.priceScenarios.flatMap((priceScenario) => {
    const approvedScenario = getScenarioPricing(priceScenario);

    if (approvedScenario != null) {
      return [approvedScenario];
    } else {
      return [];
    }
  });

  approvedScenarios.sort(comparePriceScenarios);

  return approvedScenarios[0];
};

/// returns a new Array with `value` added or removed, depending on `included`.
/// if `value` is already present, will not add it again.
function updateArraySet<T>(array: T[], value: T, included: boolean): T[] {
  if (included) {
    if (array.some((val) => _.isEqual(val, value))) {
      return array;
    } else {
      return [...array, value];
    }
  } else {
    return array.filter((val) => !_.isEqual(val, value));
  }
}

const summarySlice = createSlice({
  name: "Summary",
  initialState,
  reducers: {
    reset: (state) => {
      return {
        ...initialState,
        ...state.summary,
      };
    },
    resetFiltersToNone: (state) => {
      return {
        ...state,
        searchTerm: "",
        filters: emptyPricingFiltersState(),
        dynamicFilters: emptyDynamicFiltersState(),
      };
    },
    resetFiltersToDefault: (
      state,
      { payload }: PayloadAction<{ config: Configuration }>,
    ) => {
      return {
        ...state,
        filters: defaultPricingFiltersState(
          payload.config.settings.defaultPricingFilters,
        ),
      };
    },

    setStatusIncluded: (
      state,
      {
        payload,
      }: PayloadAction<{
        status: T.ExecutionProductSummary["status"];
        included: boolean;
      }>,
    ) => {
      const statuses =
        state.filters.statuses ||
        _.uniq(state.summary?.products.map((p) => p.status));

      state.filters.statuses = updateArraySet(
        statuses,
        payload.status,
        payload.included,
      );
    },

    setInvestorNameIncluded: (
      state,
      {
        payload,
      }: PayloadAction<{
        investorName: string;
        included: boolean;
      }>,
    ) => {
      const investorNames =
        state.filters.investorNames ||
        _.uniq(state.summary?.products.map((p) => p.investorName));

      state.filters.investorNames = updateArraySet(
        investorNames,
        payload.investorName,
        payload.included,
      );
    },

    setMinRate: (
      state,
      { payload }: PayloadAction<PricingFiltersState["minRate"]>,
    ) => {
      state.filters.minRate = payload;
    },
    setMaxRate: (
      state,
      { payload }: PayloadAction<PricingFiltersState["maxRate"]>,
    ) => {
      state.filters.maxRate = payload;
    },

    setMinPrice: (
      state,
      { payload }: PayloadAction<PricingFiltersState["minPrice"]>,
    ) => {
      state.filters.minPrice = payload;
    },
    setMaxPrice: (
      state,
      { payload }: PayloadAction<PricingFiltersState["maxPrice"]>,
    ) => {
      state.filters.maxPrice = payload;
    },

    setColumnsValues: (
      state,
      { payload }: PayloadAction<DynamicPricingFiltersState["columnsValues"]>,
    ) => {
      state.dynamicFilters!.columnsValues = payload;
    },

    setRateLockPeriodIncluded: (
      state,
      {
        payload,
      }: PayloadAction<{
        rateLockPeriod: T.DurationValue;
        included: boolean;
      }>,
    ) => {
      const rateLockPeriods =
        state.filters.rateLockPeriods ||
        _.uniqWith(
          state.summary?.products
            .map((p) => getLowest(p)?.adjustedRateLockPeriod)
            .filter(isPresent),
          _.isEqual.bind(_),
        );

      state.filters.rateLockPeriods = updateArraySet(
        rateLockPeriods,
        payload.rateLockPeriod,
        payload.included,
      );
    },

    setSort: (state, { payload }: PayloadAction<string>) => {
      if (payload === "asc" || payload === "desc") {
        return {
          ...state,
          sortDir: payload,
        };
      } else {
        return {
          ...state,
          sortField: payload,
        };
      }
    },
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },
    setSummary: (
      state,
      { payload }: PayloadAction<T.ExecutionSummary | null>,
    ) => {
      state.summary = payload;
    },
    setDynamicFilters: (
      state,
      { payload }: PayloadAction<DynamicPricingFiltersState>,
    ) => {
      state.dynamicFilters = payload;
    },
  },
});

export const {
  reset,
  resetFiltersToNone,
  resetFiltersToDefault,
  setLoading,
  setErrors,
  setSummary,
  setSearchTerm,
  setSort,
  setStatusIncluded,
  setInvestorNameIncluded,
  setMinRate,
  setMaxRate,
  setMinPrice,
  setMaxPrice,
  setRateLockPeriodIncluded,
  setDynamicFilters,
  setColumnsValues,
} = summarySlice.actions;

export default summarySlice.reducer;

export const summarySelector = (state: { summary: SummaryState }) =>
  state.summary;

export const dynamicFilterSelector = createSelector(
  [
    nonNullApplicationInitializationSelector,
    (state: { summary: SummaryState }) => state.summary?.dynamicFilters,
    (state: { summary: SummaryState }) => state.summary.filters,
  ],
  (
    applicationInitializationState,
    dynamicFilters,
    filters,
  ): DynamicFiltersObject => {
    const defaultFilters: T.PriceScenarioTable | null =
      applicationInitializationState.config.settings.priceScenarioTable;
    const defaultFilterMinMax: T.DefaultPricingFilters =
      applicationInitializationState.config.settings.defaultPricingFilters;
    let newFilterObj: DynamicPricingFiltersState = initialState.dynamicFilters!;

    if (dynamicFilters !== null && defaultFilters !== null) {
      for (const [key, value] of Object.entries(defaultFilters)) {
        if (value !== null) {
          if (key === "adjustedPriceFieldId") {
            newFilterObj = {
              ...newFilterObj,
              [key]: {
                id: value as string,
                values: {
                  min:
                    filters.minPrice || filters.minPrice === ""
                      ? filters.minPrice
                      : defaultFilterMinMax.minPrice,
                  max:
                    filters.maxPrice || filters.maxPrice === ""
                      ? filters.maxPrice
                      : defaultFilterMinMax.maxPrice,
                },
              },
            };
          } else if (key === "adjustedRateFieldId") {
            newFilterObj = {
              ...newFilterObj,
              [key]: {
                id: value as string,
                values: {
                  min:
                    filters.minRate || filters.minRate === ""
                      ? filters.minRate
                      : defaultFilterMinMax.minRate,
                  max:
                    filters.maxRate || filters.maxRate === ""
                      ? filters.maxRate
                      : defaultFilterMinMax.maxRate,
                },
              },
            };
          } else {
            newFilterObj = {
              ...newFilterObj,
              [key]: value,
            };
          }
        }
      }

      for (const [key, value] of Object.entries(dynamicFilters)) {
        if (value !== null && key !== "columnMin" && key !== "columnMax") {
          newFilterObj = {
            ...newFilterObj,
            [key]: value,
          };
        }
      }
    }

    const FieldIdArray: T.BaseFieldDefinition[] = [];
    for (const [key, value] of Object.entries(newFilterObj)) {
      if (
        value !== "rate-with-lock-period" &&
        value !== "rate-with-columns" &&
        !Array.isArray(value) &&
        value !== null
      ) {
        if (typeof value === "string") {
          const filterField =
            applicationInitializationState.config.allFieldsById.get(
              value as T.FieldId,
            );
          FieldIdArray.push(filterField!);
        }
        if (
          typeof value !== "string" &&
          typeof value !== "boolean" &&
          "id" in value
        ) {
          try {
            const filterField =
              applicationInitializationState.config.allFieldsById.get(
                value.id as T.FieldId,
              );
            FieldIdArray.push(filterField!);
          } catch (err) {
            console.error(err);
          }
        }
      }
      if (Array.isArray(value) && key !== "columnsValues") {
        value.forEach((column) => {
          const filterField =
            applicationInitializationState.config.allFieldsById.get(
              column as T.FieldId,
            );
          FieldIdArray.push(filterField!);
        });
      }
    }

    const columnsValues: ExtraColumnsFilters[] = [];

    newFilterObj.columns?.map((column) => {
      const filterValue: ExtraColumnsFilters = {
        id: column,
        columnMin: null,
        columnMax: null,
      };
      columnsValues.push(filterValue);
    });

    newFilterObj.extraColumns?.map((column) => {
      const filterValue: ExtraColumnsFilters = {
        id: column,
        columnMin: null,
        columnMax: null,
      };
      columnsValues.push(filterValue);
    });

    return {
      sortedFilters: FieldIdArray,
      filtersObj: newFilterObj,
      dynamicColumns: dynamicFilters?.columnsValues
        ? dynamicFilters?.columnsValues
        : columnsValues,
    };
  },
);

export const uniqueStatusSelector = createSelector(
  [(state: { summary: SummaryState }) => state.summary?.summary?.products],
  (products) => {
    return _.sortBy(_.uniq(products?.map((p) => p.status)), statusSortIndex);
  },
);

export const uniqueLockSelector = createSelector(
  [(state: { summary: SummaryState }) => state.summary?.summary?.products],
  (products) => {
    const allLockPeriods = products
      ?.map((p) => getLowest(p)?.adjustedRateLockPeriod)
      .filter(isPresent);

    return _.uniqWith(allLockPeriods, _.isEqual.bind(_));
  },
);

export const uniqueInvestorNamesSelector = createSelector(
  [(state: { summary: SummaryState }) => state.summary?.summary?.products],

  (products) => {
    return _.uniq(products?.map((p) => p.investorName)).sort();
  },
);

export const isFilteringByInvestorNameSelector = createSelector(
  [
    (state: { summary: SummaryState }) => state.summary.summary,
    (state: { summary: SummaryState }) => state.summary.filters.investorNames,
  ],
  (summary, investorNames) => {
    if (!investorNames || !summary) {
      return false;
    }

    const allInvestors = ISet(summary.products.map((p) => p.investorName));

    return !allInvestors.equals(ISet(investorNames));
  },
);

export const isFilteringByStatusSelector = createSelector(
  [
    (state: { summary: SummaryState }) => state.summary.summary,
    (state: { summary: SummaryState }) => state.summary.filters.statuses,
  ],
  (summary, statuses) => {
    if (!statuses || !summary) {
      return false;
    }

    const allStatuses = ISet(summary.products.map((p) => p.status));

    return !allStatuses.equals(ISet(statuses));
  },
);

export const isFilteringByRateLockPeriodSelector = createSelector(
  [
    (state: { summary: SummaryState }) => state.summary.summary,
    (state: { summary: SummaryState }) => state.summary.filters.rateLockPeriods,
  ],
  (summary, rateLockPeriods) => {
    if (!rateLockPeriods) {
      return false;
    }

    const allRateLockPeriods = ISet(
      summary?.products.flatMap((p) => {
        const lowest = getLowest(p);

        if (!lowest || !lowest.adjustedRateLockPeriod) {
          return [];
        }

        // convert object to IMap, so that the ISet.equals call below will use value equality
        return [IMap(lowest.adjustedRateLockPeriod)];
      }),
    );

    return !allRateLockPeriods.equals(
      ISet(rateLockPeriods.map((p) => IMap(p))),
    );
  },
);

export const filteredSummaryProductsSelector = createSelector(
  [
    nonNullApplicationInitializationSelector,
    dynamicFilterSelector,
    (state: { investors: InvestorsState }) => state.investors.investors,
    (state: { products: ProductsState }) => state.products.products,
    (state: { summary: SummaryState }) => state.summary.filters,
    (state: { summary: SummaryState }) => state.summary.summary,
    (state: { summary: SummaryState }) => state.summary.searchTerm,
    (state: { summary: SummaryState }) => state.summary.sortField,
    (state: { summary: SummaryState }) => state.summary.sortDir,
  ],
  (
    applicationInitializationState,
    dynamicFilters,
    investors,
    products,
    filters,
    summary,
    searchTerm,
    sortField,
    sortDir,
  ) => {
    const decoratedProducts = summary?.products.map((p) => {
      if (p.status === "approved" || p.status === "review-required") {
        const lowest = getLowest(p);
        return {
          ...p,
          adjustedPrice: lowest?.adjustedPrice,
          adjustedRate: lowest?.adjustedRate,
          adjustedRateLockPeriod: lowest?.adjustedRateLockPeriod?.count,
        };
      } else {
        return p;
      }
    });
    const filtered = decoratedProducts?.filter((product) => {
      // TODO: define a DecoratedProductSummary type to get access to the `lowest` fields
      // in `decoratedProducts`
      const lowest = getLowest(product);

      const statusMatches =
        !filters.statuses || filters.statuses.includes(product.status);

      const investorNameMatches =
        !filters.investorNames ||
        filters.investorNames.some((name) => name === product.investorName);

      const rate = parseFloatOr(lowest?.adjustedRate, null);
      const dynamicRateMin =
        dynamicFilters.filtersObj.adjustedRateFieldId!.values.min!;
      const dynamicRateMax =
        dynamicFilters.filtersObj.adjustedRateFieldId!.values.max!;

      const minRate = filters.minRate
        ? parseFloatOr(filters.minRate, Number.NEGATIVE_INFINITY)
        : parseFloatOr(dynamicRateMin, Number.NEGATIVE_INFINITY);
      const maxRate = filters.maxRate
        ? parseFloatOr(filters.maxRate, Number.POSITIVE_INFINITY)
        : parseFloatOr(dynamicRateMax, Number.POSITIVE_INFINITY);

      const rateMatches =
        !isPresent(rate) || (rate >= minRate && rate <= maxRate);

      const price = parseFloatOr(lowest?.adjustedPrice, null);
      const dynamiPriceMin =
        dynamicFilters.filtersObj.adjustedPriceFieldId?.values.min!;
      const dynamiPriceMax =
        dynamicFilters.filtersObj.adjustedPriceFieldId?.values.max!;

      const minPrice = filters.minPrice
        ? parseFloatOr(filters.minPrice, Number.NEGATIVE_INFINITY)
        : parseFloatOr(dynamiPriceMin, Number.NEGATIVE_INFINITY);
      const maxPrice = filters.maxPrice
        ? parseFloatOr(filters.maxPrice, Number.POSITIVE_INFINITY)
        : parseFloatOr(dynamiPriceMax, Number.POSITIVE_INFINITY);

      const priceMatches =
        !isPresent(price) || (price >= minPrice && price <= maxPrice);

      const rateLockPeriod = lowest?.adjustedRateLockPeriod;
      const rateLockPeriodMatches =
        !rateLockPeriod ||
        !filters.rateLockPeriods ||
        filters.rateLockPeriods.some((period) =>
          _.isEqual(period, rateLockPeriod),
        );

      const searchTermMatches =
        !searchTerm ||
        [product.productName]
          .map((s) => (s || "").toLowerCase())
          .some((s) => s.includes(searchTerm.toLowerCase()));

      let columnMatches = true;
      const extraColumns = lowest?.calculatedFields.length
        ? lowest?.priceScenarioFields.length
          ? [...lowest.calculatedFields, ...lowest.priceScenarioFields]
          : lowest.calculatedFields
        : lowest?.priceScenarioFields.length
        ? lowest?.priceScenarioFields
        : null;

      if (extraColumns) {
        extraColumns.map((column) => {
          const currentFilter = dynamicFilters.dynamicColumns.find(
            (filter) => filter.id === column.fieldId,
          );

          if ("value" in column.value! && currentFilter) {
            const columnValue = Number(column.value.value);
            const columnMax = parseFloatOr(
              currentFilter.columnMax,
              Number.POSITIVE_INFINITY,
            );
            const columnMin = parseFloatOr(
              currentFilter.columnMin,
              Number.NEGATIVE_INFINITY,
            );

            if (!(columnValue >= columnMin && columnValue <= columnMax)) {
              columnMatches = false;
            }
          }
        });
      }

      return (
        columnMatches &&
        statusMatches &&
        investorNameMatches &&
        rateLockPeriodMatches &&
        rateMatches &&
        priceMatches &&
        searchTermMatches
      );
    });

    if (!filtered) return [];
    if (!products) return [];
    // sorts approved products together to facilitate dynamic columns
    let sorted = [...filtered].sort((a, b) => {
      if (a.status < b.status) {
        return -1;
      }
      if (a.status > b.status) {
        return 1;
      }
      return 0;
    });
    sorted?.sort((a, b) => {
      if (sortField === "productName") {
        const decoratedProductA: T.DecoratedProductHeader | undefined =
          products.find((p) => p.id === a.productId) || undefined;
        const decoratedProductB: T.DecoratedProductHeader | undefined =
          products.find((p) => p.id === b.productId) || undefined;

        const aName = decoratedProductA?.name?.toLowerCase() || "";
        const bName = decoratedProductB?.name?.toLowerCase() || "";
        if (aName < bName) return -1;
        if (aName > bName) return 1;
      } else if (sortField === "investorName") {
        const decoratedProductA: T.DecoratedProductHeader | undefined =
          products.find((p) => p.id === a.productId) || undefined;
        const decoratedProductB: T.DecoratedProductHeader | undefined =
          products.find((p) => p.id === b.productId) || undefined;

        const investorNameA =
          investors?.find((i) => i.id === decoratedProductA?.investorId)
            ?.name || "";
        const investorNameB =
          investors?.find((i) => i.id === decoratedProductB?.investorId)
            ?.name || "";

        if (investorNameA < investorNameB) return -1;
        if (investorNameA > investorNameB) return 1;
      } else if (sortField === "adjustedPrice") {
        const aLowest = getLowest(a);
        const bLowest = getLowest(b);

        const aLowestPrice = parseFloatOr(
          aLowest?.adjustedPrice,
          Number.POSITIVE_INFINITY,
        );
        const bLowestPrice = parseFloatOr(
          bLowest?.adjustedPrice,
          Number.POSITIVE_INFINITY,
        );

        if (aLowestPrice < bLowestPrice) return -1;
        if (aLowestPrice > bLowestPrice) return 1;
      } else if (sortField === "adjustedRate") {
        const aLowest = getLowest(a);
        const bLowest = getLowest(b);

        const aLowestRate = parseFloatOr(
          aLowest?.adjustedRate,
          Number.POSITIVE_INFINITY,
        );
        const bLowestRate = parseFloatOr(
          bLowest?.adjustedRate,
          Number.POSITIVE_INFINITY,
        );

        if (aLowestRate < bLowestRate) return -1;
        if (aLowestRate > bLowestRate) return 1;

        const lowestPriceA = parseFloatOr(
          aLowest?.adjustedPrice,
          Number.POSITIVE_INFINITY,
        );
        const lowestPriceB = parseFloatOr(
          bLowest?.adjustedPrice,
          Number.POSITIVE_INFINITY,
        );

        if (lowestPriceA < lowestPriceB) return 1;
        if (lowestPriceA > lowestPriceB) return -1;
      } else if (sortField === "arlp") {
        const aLowest = getLowest(a);
        const bLowest = getLowest(b);

        const aLowestRate = parseFloatOr(
          aLowest?.adjustedRateLockPeriod?.count,
          Number.POSITIVE_INFINITY,
        );
        const bLowestRate = parseFloatOr(
          bLowest?.adjustedRateLockPeriod?.count,
          Number.POSITIVE_INFINITY,
        );

        if (aLowestRate < bLowestRate) return -1;
        if (aLowestRate > bLowestRate) return 1;
      } else {
        const aLowest = getLowest(a);
        const bLowest = getLowest(b);

        const aField =
          aLowest?.calculatedFields.find(
            (field) => field.fieldId === sortField,
          ) ??
          aLowest?.priceScenarioFields.find(
            (field) => field.fieldId === sortField,
          );
        const bField =
          bLowest?.calculatedFields.find(
            (field) => field.fieldId === sortField,
          ) ??
          bLowest?.priceScenarioFields.find(
            (field) => field.fieldId === sortField,
          );

        let sortReturn = 0;

        if (aLowest && bLowest) {
          if (aField && bField) {
            if ("value" in aField && "value" in bField) {
              if ("value" in aField.value! && "value" in bField.value!) {
                const aNumber = Number(aField?.value.value);
                const bNumber = Number(bField?.value.value);

                if (aNumber < bNumber) {
                  sortReturn = -1;
                }
                if (aNumber > bNumber) {
                  sortReturn = 1;
                }
              }
            }
          }
        }
        return sortReturn;
      }

      return 0;
    });

    if (sortDir === "desc") {
      sorted = _.reverse(sorted);
    }

    sorted.sort((a, b) => {
      return statusSortIndex(a.status) - statusSortIndex(b.status);
    });

    return sorted;
  },
);

export const ActiveFiltersSelector = createSelector(
  [
    dynamicFilterSelector,
    investorsSelector,
    uniqueLockSelector,
    (state: { summary: SummaryState }) => state.summary.filters,
    (state: { summary: SummaryState }) => state.summary.searchTerm,
  ],
  (dynamicFilters, investors, lockRates, filters, searchTerm) => {
    let count = 0;
    if (
      filters.investorNames &&
      investors.investors.length !== filters.investorNames.length
    ) {
      count++;
    }
    if (
      filters.rateLockPeriods &&
      lockRates.length !== filters.rateLockPeriods.length
    ) {
      count++;
    }
    if (filters.minPrice || filters.maxPrice) {
      count++;
    }
    if (filters.minRate || filters.maxRate) {
      count++;
    }
    if (searchTerm) {
      count++;
    }
    dynamicFilters.dynamicColumns.forEach((column) => {
      if (column.columnMin || column.columnMax) {
        count++;
      }
    });
    return count;
  },
);

function statusSortIndex(status: T.ProductSummaryStatus["status"]): number {
  switch (status) {
    case "approved":
      return 0;
    case "review-required":
      return 1;
    case "available":
      return 2;
    case "rejected":
      return 3;
    case "no-pricing":
      return 4;
    case "error":
    case "missing-configuration":
      return 5;
    default:
      unreachable(status);
  }
}
