import React from "react";
import { useSelector } from "react-redux";
import { ProductResults, AccordionWrapper } from "./styles";
import ProductResult from "../product-result";
import ProductsActionPanel from "../products-action-panel";
import Accordion from "design/molecules/accordion";
import {
  faExclamationCircle,
  faCheckCircle,
  faCircleXmark,
  faCircleQuestion,
  faCircle as faFilledCircle,
} from "@fortawesome/free-solid-svg-icons";
import Icon from "design/atoms/icon";
import { productGroupsSelector } from "features/loans";
import * as T from "types/engine-types";
import { ActiveFiltersSelector } from "features/pricing-summaries";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
export default React.memo(
  ({
    className,
    highlightNextField,
    loading,
    setForceCollapseResults,
  }: {
    setForceCollapseResults: React.Dispatch<React.SetStateAction<boolean>>;
    className: string;
    highlightNextField: (field: T.FieldId[] | undefined) => void;
    loading: boolean;
  }) => {
    const productGroups = useSelector(productGroupsSelector);
    const activeFilters = useSelector(ActiveFiltersSelector);

    return (
      <ProductResults
        className={
          className
            ? `page-loan-pricing-component-product-results ${className} ${
                loading ? "loading" : ""
              }`
            : "page-loan-pricing-component-product-results"
        }
      >
        <ProductsActionPanel
          setForceCollapseResults={setForceCollapseResults}
          loading={loading}
        />

        {!productGroups["approved"].length &&
          !productGroups["available"].length &&
          !productGroups["reviewRequired"].length &&
          !productGroups["noPricing"].length &&
          !productGroups["missingConfiguration"].length &&
          !productGroups["error"].length &&
          !productGroups["rejected"].length && (
            <>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {activeFilters
                  ? "No products found."
                  : "Updating products and prices."}
              </div>
            </>
          )}

        <AccordionWrapper>
          {!!productGroups["approved"].length && (
            <Accordion
              open={true}
              label={`Approved (${productGroups["approved"].length})`}
              color="green"
              icon={<Icon icon={faCheckCircle} />}
              manualTargets={productGroups["approved"].map((p) => p.productId)}
            >
              {productGroups["approved"].map((product) => {
                return (
                  <ProductResult
                    product={product}
                    key={product.productId}
                    color="green"
                    icon={<Icon icon={faCheckCircle} />}
                    highlightNextField={highlightNextField}
                  />
                );
              })}
            </Accordion>
          )}

          {!!productGroups["reviewRequired"].length && (
            <Accordion
              open={true}
              label={`Review Required (${productGroups["reviewRequired"].length})`}
              color="yellow"
              icon={<Icon icon={faCircleQuestion} />}
              manualTargets={productGroups["reviewRequired"].map(
                (p) => p.productId,
              )}
            >
              {productGroups["reviewRequired"].map((product) => {
                return (
                  <ProductResult
                    product={product}
                    key={product.productId}
                    color="yellow"
                    icon={<Icon icon={faCircleQuestion} />}
                    highlightNextField={highlightNextField}
                  />
                );
              })}
            </Accordion>
          )}

          {!!productGroups["available"].length && (
            <Accordion
              open={true}
              label={`Data Required (${productGroups["available"].length})`}
              color="blue"
              icon={<Icon icon={faFilledCircle} />}
              manualTargets={productGroups["available"].map((p) => p.productId)}
            >
              {productGroups["available"].map((product) => {
                return (
                  <ProductResult
                    product={product}
                    key={product.productId}
                    color="blue"
                    icon={<Icon icon={faCircle} />}
                    highlightNextField={highlightNextField}
                  />
                );
              })}
            </Accordion>
          )}

          {!!productGroups["rejected"].length && (
            <Accordion
              open={false}
              label={`Rejected (${productGroups["rejected"].length})`}
              color="gray"
              icon={<Icon icon={faCircleXmark} />}
              manualTargets={productGroups["rejected"].map((p) => p.productId)}
            >
              {productGroups["rejected"].map((product) => {
                return (
                  <ProductResult
                    product={product}
                    key={product.productId}
                    color="gray"
                    icon={<Icon icon={faCircleXmark} />}
                    highlightNextField={highlightNextField}
                  />
                );
              })}
            </Accordion>
          )}

          {!!productGroups["error"].length && (
            <Accordion
              open={false}
              label={`Error (${productGroups["error"].length})`}
              color="red"
              icon={<Icon icon={faExclamationCircle} />}
              manualTargets={productGroups["error"].map((p) => p.productId)}
            >
              {productGroups["error"].map((product) => {
                return (
                  <ProductResult
                    product={product}
                    key={product.productId}
                    color="red"
                    icon={<Icon icon={faExclamationCircle} />}
                    highlightNextField={highlightNextField}
                  />
                );
              })}
            </Accordion>
          )}

          {!!productGroups["missingConfiguration"].length && (
            <Accordion
              open={false}
              label={`Missing Configuration (${productGroups["missingConfiguration"].length})`}
              color="red"
              icon={<Icon icon={faExclamationCircle} />}
              manualTargets={productGroups["missingConfiguration"].map(
                (p) => p.productId,
              )}
            >
              {productGroups["missingConfiguration"].map((product) => {
                return (
                  <ProductResult
                    product={product}
                    key={product.productId}
                    color="red"
                    icon={<Icon icon={faExclamationCircle} />}
                    highlightNextField={highlightNextField}
                  />
                );
              })}
            </Accordion>
          )}

          {!!productGroups["noPricing"].length && (
            <Accordion
              open={false}
              label={`No Pricing (${productGroups["noPricing"].length})`}
              color="red"
              icon={<Icon icon={faExclamationCircle} />}
              manualTargets={productGroups["noPricing"].map((p) => p.productId)}
            >
              {productGroups["noPricing"].map((product) => {
                return (
                  <ProductResult
                    product={product}
                    key={product.productId}
                    color="red"
                    icon={<Icon icon={faExclamationCircle} />}
                    highlightNextField={highlightNextField}
                  />
                );
              })}
            </Accordion>
          )}
        </AccordionWrapper>
      </ProductResults>
    );
  },
);
