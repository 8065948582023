import React from "react";
import { Box, Button, TextField } from "@material-ui/core";
import * as Api from "api";
import Loader from "react-loader";
import * as T from "types/engine-types";
import { SecondaryTitle } from "design/atoms/typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import { patternMatchesKey, massagedPatterns } from "features/object-patterns";
import { localAccessId } from "features/access-id";
interface State {
  key: string;
  keyContains: string;
  keyContainsOptional: string;
  name: string;
}

interface Props {
  patterns: T.ObjectPattern[];
  setter: (patterns: T.ObjectPattern[]) => void;
}

export const PatternPage = React.memo(() => {
  const [patternsLoad, setPatternsLoad] = Api.Admin.useObjectPatterns();

  if (patternsLoad.status === "error") {
    return <>"Error"</>;
  }

  if (patternsLoad.status === "loading") {
    return <Loader loaded={false} />;
  }

  return (
    <LoadedPatternPage patterns={patternsLoad.value} setter={setPatternsLoad} />
  );
});

export class LoadedPatternPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const state: Readonly<State> = {
      key: "",
      keyContains: "",
      keyContainsOptional: "",
      name: "",
    };
    this.state = state;

    this.handleChangeKey = this.handleChangeKey.bind(this);
    this.handleChangeKeyContains = this.handleChangeKeyContains.bind(this);
    this.handleChangeKeyContainsOptional =
      this.handleChangeKeyContainsOptional.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeKey(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ key: event.target.value });
  }

  handleChangeKeyContains(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ keyContains: event.target.value });
  }

  handleChangeKeyContainsOptional(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ keyContainsOptional: event.target.value });
  }

  handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ name: event.target.value });
  }

  async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const pattern = await Api.Admin.createObjectPattern({
      keyContains: this.state.keyContains,
      keyContainsOptional: this.state.keyContainsOptional,
      name: this.state.name,
    });
    this.props.setter([...this.props.patterns, pattern]);
  }

  render() {
    const patternCount = this.props.patterns.length;
    const key = this.state.key;
    const matchingPatterns = this.props.patterns.filter((p) =>
      patternMatchesKey(p, key),
    );
    const accessId: string = localAccessId() || "super";
    return (
      <div>
        <Button
          component={Link}
          to={`/c/${accessId}/__admin/investor-automations`}
          variant="outlined"
          startIcon={<ArrowBackIcon />}
        >
          Back to Investor Automations
        </Button>
        <Box px={2} my={2}>
          <SecondaryTitle>
            Matches {matchingPatterns.length} out of {patternCount} Object
            Patterns
          </SecondaryTitle>
          <form onSubmit={(e) => this.handleSubmit(e)}>
            {InputField("Example Key", this.state.key, (e) => {
              this.handleChangeKey(e);
            })}
            {InputField("Key Contains", this.state.keyContains, (e) => {
              this.handleChangeKeyContains(e);
            })}
            {InputField(
              "Key Contains [Optional]",
              this.state.keyContainsOptional,
              (e) => {
                this.handleChangeKeyContainsOptional(e);
              },
            )}
            {InputField("Name", this.state.name, (e) => {
              this.handleChangeName(e);
            })}
            <input type="submit" value="Submit" />
          </form>
        </Box>
        <Box px={2} my={2}>
          <SecondaryTitle>Patterns</SecondaryTitle>
          <Table
            patterns={massagedPatterns(
              this.state.key ? matchingPatterns : this.props.patterns,
            )}
          />
        </Box>
      </div>
    );
  }
}

function InputField(
  label: string,
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
) {
  return (
    <Box my={1}>
      <TextField
        style={{ width: "600px" }}
        label={label}
        variant="outlined"
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e);
        }}
      />
    </Box>
  );
}

interface FProps {
  patterns: T.ObjectPattern[];
}

export const useTableStyles = makeStyles((t) =>
  createStyles({
    table: {
      border: "1px solid #e0e0e0",
      borderCollapse: "collapse",
    },
    columnLabel: {
      borderLeft: "1px solid #e0e0e0",
      padding: "6px",
    },
    rowLabel: {
      borderTop: "1px solid #e0e0e0",
      padding: "0 12px 0 18px",
      textAlign: "right",
    },
    field: {
      borderTop: "1px solid #e0e0e0",
      padding: "0 12px 0 18px",
    },
  }),
);

const Table = (props: FProps) => {
  const C = useTableStyles();
  return (
    <table className={C.table}>
      <thead>
        <tr>
          <th className={C.columnLabel}>name</th>
          <th className={C.columnLabel}>id</th>
          <th className={C.columnLabel}>keyContains</th>
          <th className={C.columnLabel}>keyContainsOptional</th>
        </tr>
      </thead>
      {props.patterns.map(({ name, id, keyContains, keyContainsOptional }) => (
        <tr>
          <td className={C.rowLabel}>{name}</td>
          <td className={C.field}>{id}</td>
          <td className={C.field}>{keyContains}</td>
          <td className={C.field}>{keyContainsOptional}</td>
        </tr>
      ))}
    </table>
  );
};
