import * as DateFns from "date-fns";
import { Map as IMap, Set as ISet } from "immutable";
import _ from "lodash";
import React, { useMemo, useCallback } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  TextField as MaterialTextField,
  Paper,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { expandedConfigSelector } from "features/application-initialization";
import { useSelector } from "react-redux";

import Field from "design/molecules/field";
import SearchableMultiSelectList from "design/organisms/searchable-multi-select-list";
import { Configuration } from "config";
import * as T from "types/engine-types";
import {
  FieldValueState,
  newFieldValueState,
  convertFieldValueToState,
  convertStateToFieldValue,
} from "design/organisms/field-value-editor";
import {
  Setter,
  UiValidationError,
  getValidationError,
  useById,
  useIMapSetter,
  usePropertySetter,
} from "features/utils";
import { evaluateFieldCondition, getParentState } from "features/loans";

// memoize text field to reduce unneccesary re-renders
const TextField = React.memo(MaterialTextField);

export type ProductState = {
  name: string;
  code: string;
  description: string;
  activationTimestamp: Date | null;
  deactivationTimestamp: Date | null;
  fieldValuesById: IMap<T.FieldId, FieldValueState>;
  ruleIdsByStageId: IMap<T.ExecutionStageId, ISet<T.RuleId>>;
  investorId: T.InvestorId | null;
};

export function newProductState(config: Configuration): ProductState {
  return {
    name: "",
    code: "",
    description: "",
    activationTimestamp: new Date(),
    deactivationTimestamp: null,
    fieldValuesById: IMap(
      config.productFields.map((f) => [f.id, newFieldValueState(f.valueType)]),
    ),
    ruleIdsByStageId: IMap(config.rulesStages.map((s) => [s.id, ISet()])),
    investorId: null,
  };
}

export function validateProductState(
  config: Configuration,
  state: ProductState,
  existingNamesByInvestorId: IMap<T.InvestorId, ISet<string>>,
  existingCodes: ISet<string>,
): UiValidationError | null {
  return getValidationError(() =>
    convertStateToProduct(
      state,
      config,
      existingNamesByInvestorId,
      existingCodes,
    ),
  );
}

export function convertProductToState(
  config: Configuration,
  product: T.ProductChangeset,
  rulesById: IMap<T.RuleId, T.DecoratedRuleHeader>,
): ProductState {
  const savedFieldValuesById = IMap(
    product.fieldValues.map((pair) => [pair.fieldId, pair.value]),
  );

  return {
    name: product.name,
    code: product.code,
    description: product.description,
    activationTimestamp: product.activationTimestamp
      ? DateFns.parseISO(product.activationTimestamp)
      : null,
    deactivationTimestamp: product.deactivationTimestamp
      ? DateFns.parseISO(product.deactivationTimestamp)
      : null,
    fieldValuesById: IMap(
      config.productFields.map((field) => {
        const fieldValue = savedFieldValuesById.get(field.id);
        const fieldValueState = fieldValue
          ? convertFieldValueToState(fieldValue)
          : newFieldValueState(field.valueType);

        return [field.id, fieldValueState];
      }),
    ),
    ruleIdsByStageId: IMap(
      config.rulesStages.map((s) => [
        s.id,
        ISet(product.ruleIds.filter((r) => rulesById.get(r)!.stageId === s.id)),
      ]),
    ),
    investorId: product.investorId,
  };
}

export function convertStateToProduct(
  state: ProductState,
  config: Configuration,
  existingNamesByInvestorId: IMap<T.InvestorId, ISet<string>>,
  existingCodes: ISet<string>,
): T.ProductChangeset {
  if (!state.name.trim()) {
    throw new UiValidationError("Product name is required");
  }

  const existingNames = state.investorId
    ? existingNamesByInvestorId.get(state.investorId, ISet<never>())!
    : ISet<never>();

  if (existingNames.contains(state.name)) {
    throw new UiValidationError("Product name must be unique per investor");
  }

  if (!state.code.trim()) {
    throw new UiValidationError("Product code is required");
  }

  if (existingCodes.contains(state.code)) {
    throw new UiValidationError("Product code must be unique");
  }

  if (!state.investorId) {
    throw new UiValidationError("Select an investor");
  }

  return {
    name: state.name,
    code: state.code,
    description: state.description,
    activationTimestamp: state.activationTimestamp
      ? DateFns.formatISO(state.activationTimestamp)
      : null,
    deactivationTimestamp: state.deactivationTimestamp
      ? DateFns.formatISO(state.deactivationTimestamp)
      : null,
    fieldValues: config.productFields.flatMap((f) => {
      if (
        !evaluateFieldCondition(
          f.id,
          config.productFieldsById,
          state.fieldValuesById,
          IMap(),
        )
      ) {
        return [];
      }

      const fieldValueState = state.fieldValuesById.get(f.id);

      if (!fieldValueState) {
        throw new UiValidationError(`Missing field: ${f.name}`);
      }

      const fieldValue = convertStateToFieldValue(f.valueType, fieldValueState);

      if (!fieldValue) {
        throw new UiValidationError(`Field is required: ${f.name}`);
      }

      return [
        {
          fieldId: f.id,
          value: fieldValue,
        },
      ];
    }),
    ruleIds: Array.from(ISet.union(state.ruleIdsByStageId.values())),
    investorId: state.investorId,
  };
}

const useProductEditorStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      margin: theme.spacing(2),
      padding: theme.spacing(3, 0),
    },
    title: {
      margin: theme.spacing(0, 3, 4),
    },
    nameField: {
      margin: theme.spacing(0, 2, 0, 0),
      width: 300,
    },
    codeField: {
      margin: theme.spacing(0, 3, 0, 0),
      width: 220,
    },
    descriptionField: {
      width: "100%",
      maxWidth: 800,
      margin: theme.spacing(2, 0, 3),
    },
    investorField: {
      display: "inline-flex",
      margin: theme.spacing(0, 2, 0, 3),
      width: 270,
    },
    fieldsHeading: {
      margin: theme.spacing(1, 3),
    },
    fieldsContainer: {
      display: "flex",
      flexWrap: "wrap",
      margin: theme.spacing(2),
    },
    rulesHeading: {
      margin: theme.spacing(1, 3),
    },
    ruleSelectorsContainer: {
      display: "flex",
      flexWrap: "wrap",
      margin: theme.spacing(3, 0, 3, 3),
    },
    ruleSelector: {
      marginRight: theme.spacing(3),
    },
  }),
);

export const ProductEditor = React.memo(
  ({
    title,
    rules,
    investors,
    existingNamesByInvestorId,
    existingCodes,
    state,
    showValidationErrors,
    setState,
  }: {
    title: string;
    rules: T.DecoratedRuleHeader[];
    investors: T.DecoratedInvestorHeader[];
    existingNamesByInvestorId: IMap<T.InvestorId, ISet<string>>;
    existingCodes: ISet<string>;
    state: ProductState;
    showValidationErrors: boolean;
    setState: Setter<ProductState>;
  }) => {
    const C = useProductEditorStyles();

    const setName = usePropertySetter(setState, "name");
    const setCode = usePropertySetter(setState, "code");
    const setDescription = usePropertySetter(setState, "description");
    const setInvestorId = usePropertySetter(setState, "investorId");
    const setFieldValuesById = usePropertySetter(setState, "fieldValuesById");
    const setRuleIdsByStageId = usePropertySetter(setState, "ruleIdsByStageId");

    return (
      <Paper className={C.contentContainer}>
        <Typography className={C.title} variant="h4">
          {title}
        </Typography>
        <ProductAttributes
          investors={investors}
          existingNamesByInvestorId={existingNamesByInvestorId}
          existingCodes={existingCodes}
          investorId={state.investorId}
          name={state.name}
          code={state.code}
          description={state.description}
          showErrors={showValidationErrors}
          setInvestorId={setInvestorId}
          setName={setName}
          setCode={setCode}
          setDescription={setDescription}
        />
        <ProductFieldsSection
          fieldValuesById={state.fieldValuesById}
          showErrors={showValidationErrors}
          setFieldValuesById={setFieldValuesById}
        />
        <RuleSelectorsSection
          rules={rules}
          ruleIdsByStageId={state.ruleIdsByStageId}
          setRuleIdsByStageId={setRuleIdsByStageId}
        />
      </Paper>
    );
  },
);

const ProductAttributes = React.memo(
  ({
    investors,
    existingNamesByInvestorId,
    existingCodes,
    investorId,
    name,
    code,
    description,
    showErrors,
    setInvestorId,
    setName,
    setCode,
    setDescription,
  }: {
    investors: T.DecoratedInvestorHeader[];
    existingNamesByInvestorId: IMap<T.InvestorId, ISet<string>>;
    existingCodes: ISet<string>;

    investorId: T.InvestorId | null;
    name: string;
    code: string;
    description: string;

    showErrors: boolean;

    setInvestorId: Setter<T.InvestorId | null>;
    setName: Setter<string>;
    setCode: Setter<string>;
    setDescription: Setter<string>;
  }) => {
    const C = useProductEditorStyles();

    const sortedInvestors = useMemo(
      () => _.sortBy(investors, (i) => i.name.toLowerCase()),
      [investors],
    );

    const existingNames = investorId
      ? existingNamesByInvestorId.get(investorId, ISet())
      : ISet();

    return (
      <Box>
        <Box>
          <Autocomplete
            className={C.investorField}
            options={sortedInvestors}
            getOptionLabel={(investor) => investor.name}
            value={investors.find((i) => i.id === investorId) || null}
            onChange={(
              e: unknown,
              investor: T.DecoratedInvestorHeader | null,
            ) => {
              if (investor) {
                setInvestorId(investor.id);
              } else {
                setInvestorId(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Investor"}
                error={showErrors && !investorId}
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
          <TextField
            className={C.nameField}
            label="Product Name"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={name}
            error={showErrors && (!name.trim() || existingNames.contains(name))}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            className={C.codeField}
            label="Product Code"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={code}
            error={showErrors && (!code.trim() || existingCodes.contains(code))}
            onChange={(e) =>
              setCode(e.target.value.toUpperCase().replace(/[^A-Z0-9-_]/g, ""))
            }
          />
        </Box>
        <Box mx={3}>
          <TextField
            className={C.descriptionField}
            label="Description"
            InputLabelProps={{ shrink: true }}
            multiline
            rows={3}
            rowsMax={8}
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Box>
      </Box>
    );
  },
);

const ProductFieldsSection = React.memo(
  ({
    fieldValuesById,
    showErrors,
    setFieldValuesById,
  }: {
    fieldValuesById: IMap<T.FieldId, FieldValueState>;
    showErrors: boolean;
    setFieldValuesById: Setter<IMap<T.FieldId, FieldValueState>>;
  }) => {
    const C = useProductEditorStyles();

    const config = useSelector(expandedConfigSelector);

    const fieldValueSetters = useIMapSetter(setFieldValuesById);

    // For the product editor, don't apply any default fields
    // when evaluation conditions
    const emptyDefaultFieldValues = IMap<T.FieldId, T.DefaultFieldValue>();

    return (
      <>
        <Typography className={C.fieldsHeading} variant="h5">
          Product specifications
        </Typography>
        {config.productFields.length === 0 && (
          <Typography style={{ marginLeft: "24px", color: "#666" }}>
            No product specification fields have been configured.
            <br />
            Click the Field Library tab on the left to set up product
            specification fields.
          </Typography>
        )}
        <Box className={`${C.fieldsContainer} product-specs`}>
          {config.productFields
            .filter((field) =>
              evaluateFieldCondition(
                field.id,
                config.productFieldsById,
                fieldValuesById,
                emptyDefaultFieldValues,
              ),
            )
            .map((field) => (
              <Field
                key={field.id}
                field={field}
                fieldState={
                  field
                    ? fieldValuesById.get(field.id) ||
                      newFieldValueState(field.valueType)
                    : null
                }
                parentState={getParentState(
                  field.conditions || null,
                  config.productFieldsById,
                  fieldValuesById,
                  emptyDefaultFieldValues,
                )}
                showErrors={showErrors}
                setState={fieldValueSetters.withKey(field.id)}
              />
            ))}
        </Box>
      </>
    );
  },
);

const RuleSelectorsSection = React.memo(
  ({
    rules,
    ruleIdsByStageId,
    setRuleIdsByStageId,
  }: {
    rules: T.DecoratedRuleHeader[];
    ruleIdsByStageId: IMap<T.ExecutionStageId, ISet<T.RuleId>>;
    setRuleIdsByStageId: Setter<IMap<T.ExecutionStageId, ISet<T.RuleId>>>;
  }) => {
    const C = useProductEditorStyles();

    const config = useSelector(expandedConfigSelector);

    const rulesById = useById(rules);
    const sortedRules = useMemo(
      () => _.sortBy(rules, (r) => r.name.toLowerCase()),
      [rules],
    );

    const ruleIdsSetters = useIMapSetter(setRuleIdsByStageId);

    const getRuleName = useCallback(
      (id: T.RuleId) => rulesById.get(id)?.name || "<Unknown Rule>",
      [rulesById],
    );

    const stageInfo = useMemo(
      () =>
        IMap(
          config.rulesStages.map((stage) => {
            const availableRuleIds = sortedRules
              .filter((r) => r.stageId === stage.id)
              .map((r) => r.id);

            return [
              stage.id,
              {
                availableRuleIds,
              },
            ];
          }),
        ),
      [sortedRules, config.rulesStages],
    );

    return (
      <>
        <Typography className={C.rulesHeading} variant="h5">
          Rules
        </Typography>
        <Box className={C.ruleSelectorsContainer}>
          {config.rulesStages.map((stage) => {
            const { availableRuleIds } = stageInfo.get(stage.id)!;

            return (
              <SearchableMultiSelectList<T.RuleId>
                key={stage.id}
                label={stage.name + " Rules"}
                className={C.ruleSelector}
                noItemsMessage="No rules available."
                noResultsMessage="No rules found with that name."
                options={availableRuleIds}
                selected={ruleIdsByStageId.get(stage.id)!}
                getOptionLabel={getRuleName}
                onChange={ruleIdsSetters.withKey(stage.id)}
              />
            );
          })}
        </Box>
      </>
    );
  },
);
