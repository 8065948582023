import React, { ReactNode, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { updateQueryParams } from "features/loan-pricing";

type LoanPricingQueryParams =
  | "activeRole"
  | "activePricingProfile"
  | "activeScenario"
  | "inputsHash"
  | "sort"
  | "sortDir"
  | "filters"
  | "activeView";

export default React.memo(({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const getQueryParamValues = (
    queryParams: URLSearchParams,
    paramName: LoanPricingQueryParams,
  ) => queryParams.get(paramName) || undefined;

  useEffect(() => {
    const paramUpdates = {
      activeView: getQueryParamValues(queryParams, "activeView"),
      activeRole: getQueryParamValues(queryParams, "activeRole"),
      activePricingProfile: getQueryParamValues(
        queryParams,
        "activePricingProfile",
      ),
      activeScenario: getQueryParamValues(queryParams, "activeScenario"),
      inputsHash: getQueryParamValues(queryParams, "inputsHash"),
      sort: getQueryParamValues(queryParams, "sort"),
      sortDir: getQueryParamValues(queryParams, "sortDir"),
      filters: getQueryParamValues(queryParams, "filters"),
    };

    dispatch(updateQueryParams(paramUpdates));
  }, [dispatch, location.search, queryParams]);

  return <>{children}</>;
});
