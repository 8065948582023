import React, { useState } from "react";
import { ProductDetailActionPanel } from "./styles";
import Button from "design/atoms/button";
import ActionButtons from "../action-buttons";
import { useHistory, useLocation } from "react-router-dom";
import { SmallText } from "design/atoms/typography";
import {
  faCaretLeft,
  faCaretRight,
  faEllipsisV,
  faFileExport,
  faMaximize,
  faMinimize,
} from "@fortawesome/free-solid-svg-icons";
import Icon from "design/atoms/icon";
import Popover from "design/layout/popover";
import { LinkList } from "design/molecules/link-list/styles";
import IconLink from "design/atoms/icon-link";
import { filteredSummaryProductsSelector } from "features/pricing-summaries";
import { useSelector } from "react-redux";
import * as T from "types/engine-types";
import iFrameMessageTransport from "features/iframe-message-transport";
import {
  nonNullApplicationInitializationSelector,
  expandedConfigSelector,
} from "features/application-initialization";
import { investorsSelector } from "features/investors";
import { useVisibleFields } from "features/roles";
import { UiValidationError } from "features/utils";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { getLowest } from "features/pricing-summaries";
import { createManualOpenEvent } from "design/molecules/accordion";
import { loansSelector, resolveEffectiveFieldValues } from "features/loans";

export default React.memo(
  ({
    productResultLoading,
    product,
    scenario,
    compareMode = false,
    productId,
    setPricingSelection,
    setForceCollapseResults,
    forceCollapseResults,
  }: {
    productResultLoading?: boolean;
    product?: T.ProductHeader;
    scenario?: T.PriceScenarioResult;
    compareMode?: boolean;
    productId?: T.ProductId;
    setPricingSelection?: React.Dispatch<React.SetStateAction<string | null>>;
    setForceCollapseResults?: React.Dispatch<React.SetStateAction<boolean>>;
    forceCollapseResults?: boolean;
  }) => {
    const config = useSelector(expandedConfigSelector);
    const investorsState = useSelector(investorsSelector);
    const loggedInInfo = useSelector(nonNullApplicationInitializationSelector);
    const userInfo = { user: loggedInInfo.user, client: loggedInInfo.client };
    const history = useHistory();
    const location = useLocation();
    const summary = useSelector(filteredSummaryProductsSelector);
    const currentProduct = summary.find(
      (product) => product.productId === productId,
    );
    const currentIndex = summary.indexOf(currentProduct!);
    const nextProduct = summary[currentIndex + 1];
    const previousProduct = summary[currentIndex - 1];
    const {
      client: { accessId },
    } = useSelector(nonNullApplicationInitializationSelector);

    const [lockRequestSent, setLockRequestSent] = useState<boolean>(false);

    // const compare = () =>
    //   history.push(
    //     "/v2/loan-pricing/compare/productId+pricingScenarioId/productId+pricingScenarioId/productId+pricingScenarioId/productId+pricingScenarioId",
    //   );
    // const stopCompare = () =>
    //   history.push(`${location.pathname}/${location.hash}`);

    const backToProducts = () =>
      history.push(
        `/c/${accessId}/v2/loan-pricing?activeView=products/${location.hash}`,
      );

    const { priceLockingConfig, currentFormValues } =
      useSelector(loansSelector);

    const { myRole, myDefaultFieldValues, myPricingProfile } = useSelector(
      nonNullApplicationInitializationSelector,
    );
    const investor = investorsState.investors.find(
      (i) => i.id === product?.investorId,
    );
    const hasVisibleField = useVisibleFields();

    return (
      <ProductDetailActionPanel className="page-loan-pricing-product-detail-action-panel">
        <ActionButtons>
          {forceCollapseResults && (
            <Button
              disabled={productResultLoading}
              onClick={() => {
                backToProducts();
              }}
            >
              Back to Products
            </Button>
          )}

          {!forceCollapseResults && (
            <Button
              disabled={productResultLoading}
              onClick={() => {
                backToProducts();
              }}
            >
              Close Product
            </Button>
          )}

          <Button
            grow={false}
            onClick={() => {
              createManualOpenEvent(previousProduct?.productId as string);

              const lowest = getLowest(previousProduct);

              if (
                lowest?.adjustedRate &&
                lowest.adjustedRateLockPeriod?.count
              ) {
                setPricingSelection && setPricingSelection(null);
                history.push(
                  `/c/${accessId}/v2/loan-pricing/products/${previousProduct.productId}/${lowest.adjustedRate}/${lowest.adjustedRateLockPeriod.count}${location.hash}`,
                );
              } else {
                history.push(
                  `/c/${accessId}/v2/loan-pricing/products/${previousProduct.productId}${location.hash}`,
                );
              }
            }}
            disabled={productResultLoading || !!!summary[currentIndex - 1]}
          >
            <Icon icon={faCaretLeft} />
          </Button>

          <SmallText>
            {currentIndex + 1} of {summary.length}
          </SmallText>

          <Button
            grow={false}
            onClick={() => {
              createManualOpenEvent(nextProduct?.productId as string);

              const lowest = getLowest(nextProduct);

              if (
                lowest?.adjustedRate &&
                lowest.adjustedRateLockPeriod?.count
              ) {
                setPricingSelection && setPricingSelection(null);
                history.push(
                  `/c/${accessId}/v2/loan-pricing/products/${nextProduct.productId}/${lowest.adjustedRate}/${lowest.adjustedRateLockPeriod.count}${location.hash}`,
                );
              } else {
                history.push(
                  `/c/${accessId}/v2/loan-pricing/products/${nextProduct.productId}${location.hash}`,
                );
              }
            }}
            disabled={productResultLoading || !!!summary[currentIndex + 1]}
          >
            <Icon icon={faCaretRight} />
          </Button>

          {/* <Button className="hide-for-mobile" grow={false}>
            <Icon icon={faPlus} />
          </Button> */}

          {/* <Button className="hide-for-mobile" grow={false}>
            <Icon icon={faMagnifyingGlass} /> Compare (4)
          </Button> */}

          {/* {compareMode && (
            <Button className="compare" onClick={stopCompare}>
              Stop Comparing
            </Button>
          )} */}

          <Button
            isPrimary={currentProduct?.status === "approved"}
            className="hide-for-mobile"
            onClick={() => {
              window.print();
            }}
          >
            Export to PDF
          </Button>

          <Popover
            trigger={
              <Button grow={false}>
                <Icon icon={faEllipsisV} />
              </Button>
            }
            content={
              <>
                {!compareMode && (
                  <LinkList>
                    {priceLockingConfig.enabled && !lockRequestSent && (
                      <IconLink
                        className="hide-for-mobile"
                        inline={true}
                        icon={<Icon icon={faFileExport} />}
                        onClick={() => {
                          if (currentFormValues != null) {
                          } else {
                            console.warn(
                              "currentFormValues was not set while sending price lock message",
                            );
                            return;
                          }

                          if (
                            investor &&
                            product &&
                            scenario &&
                            currentFormValues &&
                            myRole &&
                            myPricingProfile
                          ) {
                            // Resolve fields based on the input field values, field
                            // conditions, and default values. For more details,
                            // see DEV-1137: https://loanpass.atlassian.net/browse/DEV-1137
                            const effectiveFieldValues =
                              resolveEffectiveFieldValues(
                                config.creditApplicationFields,
                                currentFormValues,
                                myDefaultFieldValues ?? [],
                              );
                            iFrameMessageTransport.postMessage({
                              message: "price-lock",
                              role: {
                                id: myRole.id,
                                name: myRole.name,
                              },
                              pricingProfile: myPricingProfile,
                              investor,
                              product,
                              productSpecifications:
                                config.productFields.filter((field) =>
                                  hasVisibleField(field.id),
                                ),
                              scenario,
                              creditApplicationFields: effectiveFieldValues,
                            });

                            setLockRequestSent(true);
                          } else {
                            throw new UiValidationError(
                              "Select at least one pricing table to import",
                            );
                          }
                        }}
                      >
                        {lockRequestSent ? (
                          <>
                            {priceLockingConfig.lockRequestLabel} Sent{" "}
                            <Icon icon={faCheckCircle} />
                          </>
                        ) : (
                          <>{priceLockingConfig.lockRequestLabel}</>
                        )}
                      </IconLink>
                    )}

                    {forceCollapseResults && (
                      <IconLink
                        className="hide-for-mobile"
                        inline={true}
                        icon={<Icon icon={faMaximize} />}
                        onClick={() => {
                          if (setForceCollapseResults) {
                            localStorage.setItem(
                              "loanPASS::setForceCollapseResults",
                              "false",
                            );
                            setForceCollapseResults(false);
                          }
                        }}
                      >
                        Expand Product List
                      </IconLink>
                    )}

                    {!forceCollapseResults && (
                      <IconLink
                        className="hide-for-mobile"
                        inline={true}
                        icon={<Icon icon={faMinimize} />}
                        onClick={() => {
                          if (setForceCollapseResults) {
                            localStorage.setItem(
                              "loanPASS::setForceCollapseResults",
                              "true",
                            );
                            setForceCollapseResults(true);
                          }
                        }}
                      >
                        Collapse Product List
                      </IconLink>
                    )}

                    <IconLink
                      className="hide-for-desktop"
                      external={true}
                      to={`mailto:${userInfo.user.emailAddress}?subject=Pricing Reminder&body=${window.location.href}`}
                      inline={true}
                      icon={<Icon icon={faFileExport} />}
                    >
                      Share Link
                    </IconLink>

                    {/* <IconLink
                      onClick={compare}
                      inline={true}
                      to="#"
                      icon={<Icon icon={faMagnifyingGlass} />}
                    >
                      Compare 4 Selected Scenarios
                    </IconLink>

                    <IconLink
                      inline={true}
                      to="#"
                      icon={<Icon icon={faPlus} />}
                    >
                      Select for Compare
                    </IconLink> */}
                  </LinkList>
                )}
              </>
            }
          />
        </ActionButtons>
      </ProductDetailActionPanel>
    );
  },
);
