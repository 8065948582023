import React, { useState } from "react";
import { CreditApplicationFieldDefinition } from "types/generated-types";
import { BaseFieldDefinition } from "types/engine-types";
import Select from "design/atoms/select";
import TextInput from "design/atoms/text-input";
import {
  DurationWrapper,
  DurationEnumWrapper,
  TextWrapper,
  NoTextWrapper,
} from "./styles";

export default React.memo(
  ({
    showLabel = true,
    field,
  }: {
    showLabel?: boolean;
    field: CreditApplicationFieldDefinition | BaseFieldDefinition;
  }) => {
    const durationOptions = [
      {
        id: "days",
        name: "day(s)",
      },
      {
        id: "weeks",
        name: "week(s)",
      },
      {
        id: "fortnights",
        name: "fortnight(s)",
      },
      {
        id: "half-months",
        name: "half month(s)",
      },
      {
        id: "months",
        name: "month(s)",
      },
      {
        id: "quarters",
        name: "quarter(s)",
      },
      {
        id: "years",
        name: "year(s)",
      },
    ];

    const [currentDuration, setCurrentDuration] = useState<{
      id: string;
      name: string;
    }>({ id: "", name: "" });

    switch ("expression" in field) {
      case false: {
        return (
          <DurationWrapper>
            {showLabel && (
              <TextWrapper>
                <TextInput label={field.name} />
              </TextWrapper>
            )}
            <DurationEnumWrapper>
              <Select
                options={durationOptions}
                value={currentDuration}
                getOptionLabel={(value: { id: string; name: string }) =>
                  value.name
                }
                getOptionValue={(value: { id: string; name: string }) =>
                  value.id
                }
                onChange={(e) => setCurrentDuration(e!)}
              />
            </DurationEnumWrapper>
          </DurationWrapper>
        );
      }
      case true: {
        const availableEnums: { id: string; name: string }[] = [];
        for (const [key, value] of Object.entries(field.valueType)) {
          if (key !== "type") {
            availableEnums.push({
              id: value?.toString()!,
              name: value?.toString()!,
            });
          }
        }

        return (
          <NoTextWrapper>
            <Select
              label={showLabel ? field.name : ""}
              options={availableEnums}
              value={currentDuration}
              getOptionLabel={(value: { id: string; name: string }) =>
                value.name
              }
              getOptionValue={(value: { id: string; name: string }) => value.id}
              onChange={(e) => setCurrentDuration(e!)}
            />
          </NoTextWrapper>
        );
      }
    }
    return <></>;
  },
);
