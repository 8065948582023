import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import * as DateFns from "date-fns";
import { Set as ISet } from "immutable";
import _ from "lodash";
import React, { useState, useMemo, useEffect } from "react";
import Loader from "react-loader";
import { useParams, useHistory } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CopyIcon from "@material-ui/icons/FileCopy";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import { getInvestorNameById } from "pages/products/list";
import { actionTypeToString } from "types/action-types";
import * as Api from "api";
import { DataTableLookupViewer } from "../_components/data-table-lookup-viewer";
import { RenderedField } from "design/molecules/field-viewer";
import {
  NumberFieldExpressionViewer,
  StringFieldExpressionViewer,
} from "../_components/field-expression-viewer";
import MessageBar from "design/atoms/message-bar";
import MultiSelectListViewer from "design/molecules/multi-select-list-viewer";
import * as T from "types/engine-types";
import * as Execution from "features/execution";
import { RuleConditionViewer } from "../_components/rule-condition-viewer";
import { useById } from "features/utils";
import { useSelector, useDispatch } from "react-redux";
import { filteredRulesSelector, getRules, rulesSelector } from "features/rules";
import DetailHeader from "design/molecules/detail-header";
import DetailActions from "design/molecules/detail-actions";
import NextButton from "design/atoms/next-button";
import PrevButton from "design/atoms/prev-button";
import { usePermissions } from "features/roles";
import {
  expandedConfigSelector,
  nonNullApplicationInitializationSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import { localAccessId } from "features/access-id";

type Params = {
  id: T.RuleId;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    rightSideButtons: {
      "& > .MuiButton-root": {
        marginLeft: theme.spacing(2),
      },
    },
  }),
);

export default function ViewRulePage() {
  const { id } = useParams<Params>();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [productsLoadState] = Api.useProducts();
  const [ruleLoadState, reloadRule] = Api.useRule(id);
  const [investorsState] = Api.useInvestors();
  const [loading, setLoading] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const rules = useSelector(filteredRulesSelector);
  const hasPermission = usePermissions();
  const hasEditPerm = hasPermission("rules-edit");
  const hasCreatePerm = hasPermission("rules-create");
  const hasDeletePerm = hasPermission("rules-delete");
  const rulesState = useSelector(rulesSelector);
  const ruleMatch = rules.find((i) => i.id === id);
  const positionInList = ruleMatch && rules.indexOf(ruleMatch) + 1;
  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);

  useEffect(() => {
    if (rules.length === 0) {
      dispatch(getRules());
    }
  }, [dispatch, rules.length]);

  if (
    productsLoadState.status === "loading" ||
    ruleLoadState.status === "loading" ||
    investorsState.status === "loading" ||
    loading
  ) {
    return <Loader loaded={false} />;
  }
  if (
    productsLoadState.status === "error" ||
    ruleLoadState.status === "error" ||
    investorsState.status === "error"
  ) {
    return (
      <MessageBar
        text="Something went wrong, please check your internet connection and try again later. If you see this message again, please contact support."
        type="error"
      />
    );
  }

  const products = productsLoadState.value;
  const rule = ruleLoadState.value;
  const investors = investorsState.value;

  async function deleteRule() {
    setLoading(true);
    await Api.deleteRule(id);
    setLoading(false);
    dispatch(getRules());
    history.push(`/c/${accessId}/rules`);
  }

  const toggleActivation = async () => {
    setLoading(true);
    const rule = await Api.getRule(id);

    if (rule.activationTimestamp) {
      rule.activationTimestamp = null;
    } else {
      rule.activationTimestamp = DateFns.formatISO(new Date());
    }
    await Api.saveRule(rule);

    reloadRule();
    dispatch(getRules());
    setLoading(false);
  };

  return (
    <>
      <div>
        <DetailActions>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => history.push(`/c/${accessId}/rules`)}
          >
            Back to Rules ({positionInList}/{rules.length})
            <span
              style={{
                fontWeight: 100,
                marginLeft: "8px",
                textTransform: "none",
              }}
            >
              sorted: {rulesState.sortField} {rulesState.sortDir}
            </span>
            {rulesState.searchTerm && (
              <span style={{ fontWeight: 100, textTransform: "none" }}>
                , filtered: {rulesState.searchTerm}
              </span>
            )}
          </Button>

          <PrevButton
            list={rules}
            path={`/c/${accessId}/rules`}
            id={id}
            label="Previous Rule"
          />
          <NextButton
            list={rules}
            path={`/c/${accessId}/rules`}
            id={id}
            label="Next Rule"
          />

          <Box flex="1" />
          <Box className={classes.rightSideButtons}>
            {rule.activationTimestamp ? (
              <CheckIcon
                className="is-active"
                style={{
                  fill: "#90d18c",
                  fontSize: "32px",
                  marginRight: "16px",
                  position: "relative",
                  top: "12px",
                }}
              />
            ) : (
              <CloseIcon
                className="is-inactive"
                style={{
                  fill: "red",
                  fontSize: "32px",
                  marginRight: "16px",
                  position: "relative",
                  top: "12px",
                }}
              />
            )}

            <Button
              disabled={!hasEditPerm}
              variant="outlined"
              startIcon={<PowerSettingsNew />}
              onClick={toggleActivation}
            >
              {rule.activationTimestamp ? "Deactivate" : "Activate"}
            </Button>
            <Button
              disabled={!hasDeletePerm}
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={() => setDeleteConfirmOpen(true)}
            >
              Delete
            </Button>
            <Button
              disabled={!hasEditPerm}
              variant="outlined"
              startIcon={<EditIcon />}
              onClick={() => history.push(`/c/${accessId}/rules/${id}/edit`)}
            >
              Edit
            </Button>
            <Button
              disabled={!hasCreatePerm}
              variant="outlined"
              startIcon={<CopyIcon />}
              onClick={() =>
                history.push(`/c/${accessId}/create-rule?basedOnRuleId=${id}`)
              }
            >
              Copy
            </Button>
          </Box>
        </DetailActions>
      </div>
      <RuleViewer investors={investors} products={products} rule={rule} />

      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Delete this rule?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            All rule specifications will be lost, and any products associated
            with the rule will automatically detach from the rule.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button
            color="secondary"
            onClick={() => {
              setDeleteConfirmOpen(false);
              deleteRule();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useRuleViewerStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      overflow: "hidden",
      margin: theme.spacing(2),
    },
    title: {
      display: "flex",
      padding: theme.spacing(3),
    },
    description: {
      margin: theme.spacing(0, 3),
      maxWidth: 800,
      color: "#666",
    },
    fieldsContainer: {
      display: "flex",
      flexWrap: "wrap",
      margin: theme.spacing(0, 3),
    },
    sectionHeading: {
      margin: theme.spacing(3, 3, 1.5),
    },
    ruleSelectorsContainer: {
      display: "flex",
      flexWrap: "wrap",
      margin: theme.spacing(2, 0, 3, 3),
    },
    ruleSelector: {
      marginRight: theme.spacing(3),
    },
  }),
);

const RuleViewer = React.memo(
  ({
    investors,
    products,
    rule,
  }: {
    investors: T.DecoratedInvestorHeader[];
    products: T.DecoratedProductHeader[];
    rule: T.Rule;
  }) => {
    const classes = useRuleViewerStyles();
    const objectDetails = useSelector(objectDetailsMapSelector);
    const config = useSelector(expandedConfigSelector);
    const accessId = localAccessId();

    const { stageId } = rule;

    const environment = useMemo(
      () => Execution.predictExecutionEnvironment(config, "before", stageId),
      [config, stageId],
    );
    const localFields = useMemo(
      () => (rule.body?.lookup ? rule.body.lookup.fields : []),
      [rule.body],
    );

    const productsById = useById(products);
    const sortedProductIds = useMemo(
      () =>
        _.sortBy(products, [
          (p) => getInvestorNameById(investors, p.investorId).toLowerCase(),
          (p) => p.name.toLowerCase(),
        ]).map((p) => p.id),
      [products, investors],
    );
    const selectedProductIds = ISet(rule.productIds);

    return (
      <>
        <DetailHeader style={{ flexDirection: "column" }}>
          <Typography style={{ display: "flex" }} variant="h4">
            {rule.name}
          </Typography>
          <Typography>
            {`${config.stagesById.get(stageId)?.name || ""} Rule`}
          </Typography>
        </DetailHeader>

        <div style={{ flex: "1 1 auto", overflow: "auto" }}>
          {rule.body?.precondition && (
            <>
              <Typography className={classes.sectionHeading} variant="h5">
                Rule precondition
              </Typography>
              <Box mx={3} my={1}>
                <RuleConditionViewer
                  condition={rule.body?.precondition || null}
                  localFields={localFields}
                  environment={environment}
                  config={config}
                  objectDetails={objectDetails}
                />
              </Box>
            </>
          )}
          {rule.body?.lookup && (
            <>
              <Typography className={classes.sectionHeading} variant="h5">
                Data table lookup
              </Typography>
              <Box mx={3} my={1}>
                <DataTableLookupViewer
                  lookup={rule.body.lookup}
                  environment={environment}
                  config={config}
                />
              </Box>
            </>
          )}
          {rule.body?.condition && (
            <>
              <Typography className={classes.sectionHeading} variant="h5">
                Rule condition
              </Typography>
              <Box mx={3} my={1}>
                <RuleConditionViewer
                  condition={rule.body?.condition || null}
                  localFields={localFields}
                  environment={environment}
                  config={config}
                  objectDetails={objectDetails}
                />
              </Box>
            </>
          )}
          {rule.body !== null && (
            <>
              <Typography className={classes.sectionHeading} variant="h5">
                Rule action
              </Typography>
              <Box className={classes.fieldsContainer}>
                <RuleActionViewer
                  action={rule.body.action}
                  localFields={localFields}
                  environment={environment}
                />
              </Box>
            </>
          )}
          <Typography className={classes.sectionHeading} variant="h5">
            Associated products
          </Typography>
          <Box className={classes.ruleSelectorsContainer}>
            <MultiSelectListViewer
              label={"Products"}
              className={classes.ruleSelector}
              noneSelectedMessage="No products associated."
              options={sortedProductIds}
              width="600px"
              selected={selectedProductIds}
              getOptionLabel={(productId) => {
                const product = productsById.get(productId);
                if (product) {
                  return `${getInvestorNameById(
                    investors,
                    product.investorId,
                  )} - ${product.name}`;
                } else {
                  return "<Undefined>"; // should never happen
                }
              }}
              getOptionLink={(productId) =>
                `/c/${accessId}/products/${productId}`
              }
            />
          </Box>
        </div>
      </>
    );
  },
);

const rejectionReasonValueType: T.FieldValueType.String = {
  type: "string",
  format: "plain",
};
const adjustmentValueValueType: T.FieldValueType.Number = {
  type: "number",
  minimum: null,
  maximum: null,
  precision: 3,
  style: "plain",
};
const adjustmentNameValueType: T.FieldValueType.String = {
  type: "string",
  format: "plain",
};
const stipulationTextValueType: T.FieldValueType.String = {
  type: "string",
  format: "plain",
};

const RuleActionViewer = React.memo(
  ({
    action,
    localFields,
    environment,
  }: {
    action: T.RuleAction;
    localFields: readonly T.RuleDataTableLookupFieldDefinition[];
    environment: ISet<T.FieldId>;
  }) => {
    const config = useSelector(expandedConfigSelector);

    return (
      <Box mx={-1} display="flex">
        <RenderedField
          name="Action Type"
          value={actionTypeToString(action.type)}
          align="left"
          width="auto"
        />

        {(action.type === "reject" || action.type === "require-review") && (
          <StringFieldExpressionViewer
            label={
              action.type === "reject" ? "Rejection Reason" : "Review Reason"
            }
            expression={action.reason}
            valueType={rejectionReasonValueType}
            environment={environment}
            localFields={localFields}
            config={config}
          />
        )}

        {(action.type === "add-rate-adjustment" ||
          action.type === "add-price-adjustment" ||
          action.type === "add-margin-adjustment") && (
          <>
            <NumberFieldExpressionViewer
              label="Amount"
              expression={action.adjustmentValue}
              valueType={adjustmentValueValueType}
              environment={environment}
              localFields={localFields}
              config={config}
            />
            <StringFieldExpressionViewer
              label="Description"
              expression={action.adjustmentName}
              valueType={adjustmentNameValueType}
              environment={environment}
              localFields={localFields}
              config={config}
            />
          </>
        )}

        {action.type === "add-stipulation" && (
          <StringFieldExpressionViewer
            label="Stipulation Text"
            expression={action.stipulationText}
            valueType={stipulationTextValueType}
            environment={environment}
            localFields={localFields}
            config={config}
          />
        )}
      </Box>
    );
  },
);
