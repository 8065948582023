import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type QueryParamsState = {
  activeView: String | undefined;
  activeRole: String | undefined;
  activePricingProfile: String | undefined;
  activeScenario: String | undefined;
  inputsHash: String | undefined;
  sort: String | undefined;
  sortDir: String | undefined;
  filters: String | undefined;
};

export type LoanPricingState = {
  queryParams: QueryParamsState;
};

const initialState: LoanPricingState = {
  queryParams: {
    activeView: undefined,
    activeRole: undefined,
    activePricingProfile: undefined,
    activeScenario: undefined,
    inputsHash: undefined,
    sort: undefined,
    sortDir: undefined,
    filters: undefined,
  },
};

const loanPricingSlice = createSlice({
  name: "LoanPricing",
  initialState,
  reducers: {
    updateQueryParams: (
      state,
      { payload }: PayloadAction<QueryParamsState>,
    ) => {
      state.queryParams = payload;
    },
  },
});

export const { updateQueryParams } = loanPricingSlice.actions;

export default loanPricingSlice;

export const loanPricingStateSelector = (state: {
  loanPricing: LoanPricingState;
}) => state.loanPricing;
