import React, { ReactNode } from "react";
import { ProductResult, InlineGroup } from "./styles";
import KeyValue, { KeyValueGroup } from "design/atoms/key-value";
import { AsLink, SecondaryTitle, SmallText } from "design/atoms/typography";
import { useParams } from "react-router-dom";
import * as T from "types/engine-types";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import { useSelector } from "react-redux";
import { getLowest } from "features/pricing-summaries";

type Params = {
  productId: T.ProductId;
  pricingScenarioId: string;
  pricingScenarioRate: string;
  pricingScenarioLock: string;
};

export default React.memo(
  ({
    icon,
    color,
    product,
    highlightNextField,
  }: {
    icon: ReactNode;
    color?: string;
    product?: T.ExecutionProductSummary;
    highlightNextField: (field: T.FieldId[] | undefined) => void;
  }) => {
    const params = useParams<Params>();
    const applicationState = useSelector(
      nonNullApplicationInitializationSelector,
    );
    const nextField = applicationState.config.creditApplicationFields.find(
      (f) =>
        product?.status === "available" && f.id === product.requiredFieldIds[0],
    );

    if (!product) return <></>;

    const settings = applicationState.config.settings;
    const settingsType = settings.priceScenarioTable?.type;
    const allFields = applicationState.config.allFieldsById;
    const lowest = getLowest(product);
    let match: T.PriceScenarioSummary | undefined;

    if (product.status === "approved" || product.status === "review-required") {
      match = product.priceScenarios.find((scenario) => {
        return (
          scenario.adjustedRate === params.pricingScenarioRate &&
          scenario.adjustedRateLockPeriod?.count === params.pricingScenarioLock
        );
      });
    }

    const extraFields =
      product.status === "approved" || product.status === "review-required"
        ? [
            ...(match?.calculatedFields || []),
            ...(match?.priceScenarioFields || []),
          ]
        : [];
    const extraColumns =
      applicationState.config.priceScenarioTableFieldInfo &&
      (applicationState.config.priceScenarioTableFieldInfo.type ===
      "rate-with-lock-period"
        ? applicationState.config.priceScenarioTableFieldInfo.extraColumnFields
        : applicationState.config.priceScenarioTableFieldInfo.columnFields);
    const extraColumnsMap: Map<T.FieldId, T.BaseFieldDefinition> = new Map();
    extraColumns?.map((item) => {
      extraColumnsMap.set(item.id, item);
    });

    const sortedColumns = extraFields?.sort((a, b) => {
      const aItem: T.BaseFieldDefinition = extraColumnsMap.get(a.fieldId)!;
      const bItem: T.BaseFieldDefinition = extraColumnsMap.get(b.fieldId)!;
      return extraColumns!.indexOf(aItem) - extraColumns!.indexOf(bItem);
    });

    let price = lowest?.adjustedPrice;
    if (product.status === "approved" || product.status === "review-required") {
      const scenario = product.priceScenarios.find((s) => {
        return (
          params.pricingScenarioRate === s.adjustedRate &&
          params.pricingScenarioLock === s.adjustedRateLockPeriod?.count
        );
      });

      price = scenario?.adjustedPrice || "";
    }

    let lock = lowest?.adjustedRateLockPeriod;
    if (product.status === "approved" || product.status === "review-required") {
      const scenario = product.priceScenarios.find((s) => {
        return (
          params.pricingScenarioRate === s.adjustedRate &&
          params.pricingScenarioLock === s.adjustedRateLockPeriod?.count
        );
      });

      lock = scenario?.adjustedRateLockPeriod;
    }

    let rate = lowest?.adjustedRate;
    if (product.status === "approved" || product.status === "review-required") {
      const scenario = product.priceScenarios.find((s) => {
        return (
          params.pricingScenarioRate === s.adjustedRate &&
          params.pricingScenarioLock === s.adjustedRateLockPeriod?.count
        );
      });

      rate = scenario?.adjustedRate || "";
    }

    const adjustedRateObj =
      settings.priceScenarioTable?.adjustedRateFieldId &&
      applicationState.config.allFieldsById.get(
        settings.priceScenarioTable?.adjustedRateFieldId,
      );
    const adjustedRateValue =
      adjustedRateObj &&
      adjustedRateObj.valueType.type === "number" &&
      parseFloat(rate || "").toFixed(adjustedRateObj.valueType.precision);
    const adjustedPriceObj =
      settingsType === "rate-with-lock-period" &&
      settings.priceScenarioTable?.adjustedPriceFieldId &&
      applicationState.config.allFieldsById.get(
        settings.priceScenarioTable?.adjustedPriceFieldId,
      );
    const adjustedPriceValue =
      settingsType === "rate-with-lock-period" &&
      adjustedPriceObj &&
      adjustedPriceObj.valueType.type === "number" &&
      parseFloat(price || "").toFixed(adjustedPriceObj.valueType.precision);

    return (
      <ProductResult
        className={
          color
            ? `page-loan-pricing-component-product-result ${color}`
            : `page-loan-pricing-component-product-result`
        }
      >
        <SecondaryTitle>
          {icon} <strong>{product.productName}</strong>
        </SecondaryTitle>

        <InlineGroup>
          <SmallText className="investor-name">
            {product.investorName}
          </SmallText>

          {product.status === "available" && nextField && (
            <SmallText className="next-required">
              Next Field:{" "}
              <AsLink onClick={() => highlightNextField([nextField.id])}>
                {nextField?.name}
              </AsLink>
            </SmallText>
          )}
        </InlineGroup>

        {(product.status === "approved" ||
          product.status === "review-required") && (
          <KeyValueGroup>
            {settingsType === "rate-with-columns" &&
              settings.priceScenarioTable && (
                <>
                  <KeyValue
                    label={
                      allFields.get(
                        settings.priceScenarioTable.adjustedRateFieldId,
                      )?.name || ""
                    }
                    value={adjustedRateValue || ""}
                  />
                </>
              )}

            {settingsType === "rate-with-lock-period" &&
              settings.priceScenarioTable && (
                <>
                  <KeyValue
                    label={
                      allFields.get(
                        settings.priceScenarioTable.adjustedPriceFieldId,
                      )?.name || ""
                    }
                    value={adjustedPriceValue || ""}
                  />

                  <KeyValue
                    label={
                      allFields.get(
                        settings.priceScenarioTable.adjustedRateFieldId,
                      )?.name || ""
                    }
                    value={adjustedRateValue || ""}
                  />

                  <KeyValue
                    label={
                      allFields.get(
                        settings.priceScenarioTable
                          .adjustedRateLockPeriodFieldId,
                      )?.name || ""
                    }
                    value={`${lock?.count || ""} ${lock?.unit || ""}`}
                  />
                </>
              )}

            {sortedColumns &&
              sortedColumns.map((field, i) => {
                let value;
                switch (field.value?.type) {
                  case "duration":
                    value = `${field.value.count} + ${field.value.unit}`;
                    break;
                  case "enum":
                    value = applicationState.config.enumTypesById
                      .get(field.value.enumTypeId)
                      ?.variants.find(
                        (v) =>
                          v.id ===
                          (field.value?.type === "enum" &&
                            field.value?.variantId),
                      )?.name;
                    break;
                  case "number":
                    const fieldObj = applicationState.config.allFieldsById.get(
                      field.fieldId,
                    );

                    if (fieldObj?.valueType.type === "number") {
                      value = parseFloat(field.value.value).toFixed(
                        fieldObj.valueType.precision,
                      );
                    } else {
                      value = field.value.value;
                    }

                    if (
                      fieldObj?.valueType.type === "number" &&
                      fieldObj?.valueType.style === "dollar"
                    ) {
                      value = "$" + value;
                    }

                    if (
                      fieldObj?.valueType.type === "number" &&
                      fieldObj?.valueType.style === "percent"
                    ) {
                      value = value + "%";
                    }

                    break;
                  case "object-ref":
                    value = field.value.objectRef.id;
                    break;
                  case "string":
                    value = field.value.value;
                    break;
                  case "date":
                    value = field.value.value;
                    break;
                  default:
                    break;
                }

                return (
                  <KeyValue
                    key={i}
                    label={allFields.get(field.fieldId)?.name || ""}
                    value={value || ""}
                  />
                );
              })}
          </KeyValueGroup>
        )}
      </ProductResult>
    );
  },
);
