import React, { useEffect } from "react";
import { Set as ISet } from "immutable";
import ClearIcon from "@material-ui/icons/Clear";
import WarningIcon from "@material-ui/icons/Warning";
import { Configuration, PriceScenarioTableFieldInfo } from "config";
import * as T from "types/engine-types";
import * as Fields from "features/fields";
import { LazyMemoCache } from "features/utils";
import MiddotIcon from "pages/loans-v2/loan-pricing/_components/middot-icon/";
import { findScenarioFieldValue } from "features/pricing-summaries";
import { ObjectDetails } from "features/objects";
import { useTableStyles, useTableCellStyles } from "./styles";
import { useVisibleFields } from "features/roles";
import { useParams } from "react-router-dom";
import color from "design/subatomics/colors";

type Params = {
  productId: T.ProductId;
  pricingScenarioId: string;
  pricingScenarioRate: string;
  pricingScenarioLock: string;
};

const ScenarioCell = React.memo(
  ({
    config,
    objectDetails,
    scenario,
    adjustedPriceField,
    openPriceScenario,
  }: {
    config: Configuration;
    objectDetails: ObjectDetails;
    scenario: T.PriceScenarioResult | null;
    adjustedPriceField: T.BaseFieldDefinition;
    openPriceScenario?: () => void;
  }): JSX.Element => {
    const C = useTableCellStyles();
    const horizontalBarChar = String.fromCharCode(8213);
    const params = useParams<Params>();

    useEffect(() => {
      if (
        openPriceScenario &&
        ((params.pricingScenarioRate === scenario?.adjustedRate &&
          params.pricingScenarioLock ===
            scenario?.adjustedRateLockPeriod?.count) ||
          params.pricingScenarioId === scenario?.id)
      ) {
        openPriceScenario();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.pricingScenarioRate, params.pricingScenarioLock, scenario]);

    if (!scenario) {
      return (
        <td className={`${C.cell} ${C.noScenario}`}>{horizontalBarChar}</td>
      );
    }

    switch (scenario.status) {
      case "missing-configuration":
        throw new Error(
          "we shouldn't be displaying a table cell if missing configuration",
        );
      case "approved":
        return (
          <td
            style={
              (params.pricingScenarioRate === scenario.adjustedRate &&
                params.pricingScenarioLock ===
                  scenario.adjustedRateLockPeriod?.count) ||
              params.pricingScenarioId === scenario.id
                ? {
                    background: color({ color: "green", shade: 5 }),
                    color: color({ color: "white" }),
                  }
                : {}
            }
            className={`${C.cell} ${C.scenario} ${C.approved}`}
            onClick={openPriceScenario}
            data-selector="product-detail-cell"
          >
            <div>
              {Fields.fieldValueToString(
                config,
                objectDetails,
                adjustedPriceField.valueType,
                findScenarioFieldValue(adjustedPriceField.id, scenario),
              )}
            </div>
          </td>
        );
      case "review-required":
        return (
          <td
            style={
              (params.pricingScenarioRate === scenario.adjustedRate &&
                params.pricingScenarioLock ===
                  scenario.adjustedRateLockPeriod?.count) ||
              params.pricingScenarioId === scenario.id
                ? {
                    background: color({ color: "yellow", shade: 5 }),
                    color: color({ color: "gray", shade: 4 }),
                  }
                : {}
            }
            className={`${C.cell} ${C.scenario} ${C.reviewRequired}`}
            onClick={openPriceScenario}
          >
            <div>
              {Fields.fieldValueToString(
                config,
                objectDetails,
                adjustedPriceField.valueType,
                findScenarioFieldValue(adjustedPriceField.id, scenario),
              )}
            </div>
          </td>
        );
      case "rejected":
        return (
          <td
            className={`${C.cell} ${C.scenario} ${C.rejected}`}
            onClick={openPriceScenario}
            data-selector="product-detail-cell"
          >
            <div>
              <ClearIcon />
            </div>
          </td>
        );
      case "error": {
        const isMissingData = scenario.errors.every(
          (err) => err.type === "blank-field",
        );

        if (isMissingData) {
          return (
            <td
              style={
                (params.pricingScenarioRate === scenario.adjustedRate &&
                  params.pricingScenarioLock ===
                    scenario.adjustedRateLockPeriod?.count) ||
                params.pricingScenarioId === scenario.id
                  ? {
                      background: color({ color: "blue", shade: 5 }),
                      color: color({ color: "white" }),
                    }
                  : {}
              }
              className={`${C.cell} ${C.scenario} ${C.missingData}`}
              onClick={openPriceScenario}
              data-selector="product-detail-cell"
            >
              <div>
                <MiddotIcon />
              </div>
            </td>
          );
        }

        return (
          <td
            className={`${C.cell} ${C.scenario} ${C.error}`}
            onClick={openPriceScenario}
            data-selector="product-detail-cell"
          >
            <div>
              <WarningIcon />
            </div>
          </td>
        );
      }
    }
  },
);

const ExtraFieldValueCell = React.memo(
  ({
    config,
    rowScenarios,
    fieldDef,
    objectDetails,
  }: {
    config: Configuration;
    rowScenarios: ISet<T.PriceScenarioResult>;
    fieldDef: T.BaseFieldDefinition;
    objectDetails: ObjectDetails;
  }) => {
    const C = useTableCellStyles();

    const values = rowScenarios.map((scenario) =>
      findScenarioFieldValue(fieldDef.id, scenario),
    );

    if (values.size === 0) {
      return (
        <td className={`${C.cell} ${C.invalidValue}`}> {"(Not Found)"} </td>
      );
    }

    const [first, ...rest] = values.toArray();
    if (!rest.every((value) => Fields.fieldValuesEqual(first, value))) {
      return (
        <td className={`${C.cell} ${C.invalidValue}`}>{"(Multiple Values)"}</td>
      );
    }

    if (!first) {
      const horizontalBarChar = String.fromCharCode(8213);

      return (
        <td className={`${C.cell} ${C.invalidValue}`}>{horizontalBarChar}</td>
      );
    }

    return (
      <td className={C.cell}>
        {Fields.fieldValueToString(
          config,
          objectDetails,
          fieldDef.valueType,
          first,
        )}
      </td>
    );
  },
);

export const RateWithLockPeriodPriceScenarioTable = React.memo(
  ({
    fieldInfo,
    scenarios,
    openPriceScenarioById,
    config,
    objectDetails,
  }: {
    fieldInfo: PriceScenarioTableFieldInfo.RateWithLockPeriod;
    scenarios: ISet<T.PriceScenarioResult>;
    openPriceScenarioById: LazyMemoCache<T.PriceScenarioResultId, () => void>;
    config: Configuration;
    objectDetails: ObjectDetails;
  }) => {
    const hasVisibleField = useVisibleFields();
    const {
      adjustedRateField,
      adjustedRateLockPeriodField,
      adjustedPriceField,
      extraColumnFields,
    } = fieldInfo;

    function extractLabels(labelField: T.BaseFieldDefinition): T.FieldValue[] {
      const values = scenarios.toArray().flatMap((scenario) => {
        const value = findScenarioFieldValue(labelField.id, scenario);
        return value === null ? [] : [value];
      });
      return Fields.sortedFieldValues(
        config,
        labelField.valueType,
        Fields.uniqueFieldValues(config, labelField.valueType, values),
      );
    }

    const C = useTableStyles();

    const rowLabels = extractLabels(adjustedRateField);
    const columnLabels = extractLabels(adjustedRateLockPeriodField);

    const rateColumnHeader = (
      // empty header cell
      <th></th>
    );

    const dynamicColumnHeaders = columnLabels.map((columnLabelValue, i) => (
      <th key={i} align="center" className={C.columnLabel}>
        {Fields.fieldValueToString(
          config,
          objectDetails,
          adjustedRateLockPeriodField.valueType,
          columnLabelValue,
        )}
      </th>
    ));

    const fieldNameHeaders = extraColumnFields.map(
      (fieldDef) =>
        hasVisibleField(fieldDef.id) && (
          <th
            key={fieldDef.id}
            align="center"
            className={C.fieldNameColumnLabel}
          >
            {fieldDef.name}
          </th>
        ),
    );

    const rows = rowLabels.map((rowLabelValue, i) => {
      const rowScenarios = scenarios.filter((scenario) =>
        Fields.fieldValuesEqual(
          findScenarioFieldValue(adjustedRateField.id, scenario),
          rowLabelValue,
        ),
      );

      const rowLabel = (
        <th className={C.rowLabel}>
          <div>
            {Fields.fieldValueToString(
              config,
              objectDetails,
              adjustedRateField.valueType,
              rowLabelValue,
            )}
          </div>
        </th>
      );

      const dynamicColumnCells = columnLabels.map((columnLabelValue, ii) => {
        const scenario =
          rowScenarios.find((scenario) =>
            Fields.fieldValuesEqual(
              findScenarioFieldValue(adjustedRateLockPeriodField.id, scenario),
              columnLabelValue,
            ),
          ) || null;

        return (
          <ScenarioCell
            key={ii}
            config={config}
            objectDetails={objectDetails}
            scenario={scenario}
            adjustedPriceField={adjustedPriceField}
            openPriceScenario={
              scenario === null
                ? undefined
                : openPriceScenarioById.get(scenario.id)
            }
          />
        );
      });

      const extraColumnCells = extraColumnFields.map(
        (fieldDef) =>
          hasVisibleField(fieldDef.id) && (
            <ExtraFieldValueCell
              config={config}
              rowScenarios={rowScenarios}
              fieldDef={fieldDef}
              objectDetails={objectDetails}
            />
          ),
      );

      return (
        <tr key={i}>
          {rowLabel}
          {dynamicColumnCells}
          {extraColumnCells}
        </tr>
      );
    });

    return (
      <table className={C.table}>
        <thead>
          <tr>
            {rateColumnHeader}
            {dynamicColumnHeaders}
            {fieldNameHeaders}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  },
);
