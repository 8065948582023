import { Map as IMap, Set as ISet } from "immutable";
import XLSX from "xlsx";
import { parse as parseCsv } from "papaparse";
import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from "react";
import Moment from "moment";
import { paramCase } from "change-case";
import moment from "moment";
import * as Types from "../../types/engine-types";

export { generateSafeId } from "./generate-safe-id";

export const CSV_ACCEPT_EXTENSION = ".csv";
export const PDF_ACCEPT_EXTENSION = ".pdf";
export const XLS_ACCEPT_EXTENSION = ".xls";
export const XLSX_ACCEPT_EXTENSION = ".xlsx";

export const CSV_APPLICATION_MIME_TYPE = "application/csv";
export const CSV_TEXT_MIME_TYPE = "text/csv";
export const PDF_MIME_TYPE = "application/pdf";
export const XLS_MIME_TYPE = "application/vnd.ms-excel";
export const XLSX_MIME_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export class UiValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ValidationError";
  }
}

export function validation<T1, T2>(
  convert: (state: T1) => T2,
): (state: T1) => UiValidationError | null {
  return (state: T1) =>
    getValidationError(() => {
      convert(state);
    });
}

export function getValidationError(
  thunk: () => void,
): UiValidationError | null {
  try {
    thunk();
    return null;
  } catch (err) {
    if (err instanceof UiValidationError) {
      newrelic.noticeError(err);
      return err;
    }

    throw err;
  }
}

export function useByCustomId<T, K extends keyof T>(
  key: K,
  objects: readonly T[],
): IMap<T[K], T> {
  return useMemo(() => toMapByCustomId(key, objects), [key, objects]);
}

export interface ObjectWithId<Id> {
  id: Id;
}

export function useById<Id, Obj extends ObjectWithId<Id>>(
  objects: readonly Obj[],
): IMap<Obj["id"], Obj> {
  return useByCustomId("id", objects);
}

export function toMapById<Id, Obj extends ObjectWithId<Id>>(
  objects: readonly Obj[],
): IMap<Obj["id"], Obj> {
  return toMapByCustomId("id", objects);
}

export function toMapByCustomId<T, K extends keyof T>(
  key: K,
  objects: readonly T[],
): IMap<T[K], T> {
  return IMap(objects.map((o) => [o[key], o]));
}

export function useDebounce<T>(value: T, delayMs: number): [T, boolean] {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    setIsPending(true);

    const timeoutHandle = setTimeout(() => {
      setDebouncedValue(value);
      setIsPending(false);
    }, delayMs);

    return () => {
      clearTimeout(timeoutHandle);
    };
  }, [value, delayMs]);

  return [debouncedValue, isPending];
}

export function useAsyncLoader<O>(
  loaderUnmemoized: () => Promise<O>,
  dependencies: unknown[],
): [O | undefined, boolean] {
  return useAsyncLoaderWithAbort<O>(
    () => [loaderUnmemoized(), null],
    // trust the caller-provided dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies,
  );
}

export function useAsyncLoaderWithAbort<O>(
  loaderUnmemoized: () => [Promise<O>, (() => void) | null],
  dependencies: unknown[],
): [O | undefined, boolean] {
  // Memoize the loader using the caller-provided dependencies.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loader = useCallback(loaderUnmemoized, dependencies);

  const [output, setOutput] = useState<O | undefined>(undefined);
  const [isUpdating, setIsUpdating] = useState(false);

  // TODO error handling
  // It looks like this just hangs with `isUpdating = true` if loader has an error,
  // unless the error is caused by us aborting the promise, in which case
  // we've already got a new promise from calling the loader again.
  useEffect(() => {
    const status = {
      cancelled: false,
    };

    setIsUpdating(true);

    console.log("running or re-running the loader");
    const [promise, abort] = loader();

    promise
      .then((newOutput) => {
        if (!status.cancelled) {
          // promise was resolved, but update was cancelled. This can
          // happen if the caller does provide an abort function.
          setOutput(newOutput);
          setIsUpdating(false);
        }
      })
      .catch((err) => {
        if (status.cancelled) {
          console.log("promise rejected, but ignoring due to cancellation");
        } else {
          const error = new Error("promise rejected, but wasn't cancelled: ");
          console.error(error, err);
          newrelic.noticeError(error);
          newrelic.noticeError(getErrorMessage(err));
          // TODO: handle this error. Current behaviour is that isUpdating will stay true,
          // and nothing will happen except that this will be an unhandled promise rejection
          throw err;
        }
      });

    // cleanup function for the effect.
    // aborts the promise, and tells .then callback know to ignore the result, if there is one.
    // (the abort function, if provided, should cause the promise to fail.)
    return () => {
      console.log("cancelling update");
      status.cancelled = true;

      if (abort) {
        console.log("calling abort function");
        try {
          abort();
        } catch (err) {
          const error = new Error("caught an error when calling abort(): ");
          console.error(error, err);
          newrelic.noticeError(error);
          newrelic.noticeError(getErrorMessage(err));
          throw err;
        }
      } else {
        console.log("no abort function provided");
      }
    };
  }, [loader]);

  return [output, isUpdating];
}

/** a hook for profiling. Call at the beginning of a
 * functional component, to list the props that have changed
 * and caused the component to re-render
 */
// taken from https://stackoverflow.com/a/51082563/4302668, and modified for TypeScript
export function useTraceUpdate<P extends { [key: string]: unknown }>(
  componentName: string,
  props: P,
) {
  const ref = useRef(props);

  useEffect(() => {
    const oldProps = ref.current;
    ref.current = props;

    const changedKeys = Object.entries(props)
      .filter(([key, newVal]) => newVal !== oldProps[key as keyof P])
      .map(([key]) => key);

    console.log(
      `rendering ${componentName}, changed keys: ${JSON.stringify(
        changedKeys,
      )}`,
    );
  });
}

// takes an onChange function that must be called with the new state,
// and gives you a version of it that can be called with an updater function taking the old state
// as an argument. This means you don't have to pass the entire current state down as a prop
// to subcomponents, because the function they call onChange with is given the old state as a
// parameter.
// The callback returned is memoized, and is only updated when `onChange` is updated.
export function useMemoizedOnChange<State>(
  onChange: (newState: State) => void,
  currentState: State,
): (updateState: (oldState: State) => State) => void {
  const stateRef = useRef(currentState);

  useEffect(() => {
    stateRef.current = currentState;
  });

  const callback = useCallback(
    (updateState: (oldState: State) => State) => {
      const oldState = stateRef.current;

      onChange(updateState(oldState));
    },
    [onChange],
  );

  return callback;
}

export type Setter<V> = (newValueOrTransform: SetterArgument<V>) => void;
export type SetterArgument<V> = V | SetterTransform<V>;
export type SetterTransform<V> = (oldValue: V) => V;

// NOTE: useSetter must be added to the additionalHooks option of the
// exhaustive-deps rule in eslintrc.
export function useSetter<Inner, Outer>(
  setOuter: Setter<Outer>,
  reduceUnmemoized: (
    oldOuterValue: Outer,
    innerTransform: SetterTransform<Inner>,
  ) => Outer,
  dependencies: unknown[],
): Setter<Inner> {
  // We ignore new reduce functions unless the caller-provided dependency
  // list or setOuter has changed.
  //
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reduce = useCallback(reduceUnmemoized, dependencies);

  return useCallback(
    (valueOrTransform: SetterArgument<Inner>) => {
      if (typeof valueOrTransform === "function") {
        const innerTransform = valueOrTransform as SetterTransform<Inner>;
        setOuter((oldOuterValue) => reduce(oldOuterValue, innerTransform));
      } else {
        const newInnerValue = valueOrTransform;
        const innerTransform = (oldInnerValue: Inner) => newInnerValue;
        setOuter((oldOuterValue) => reduce(oldOuterValue, innerTransform));
      }
    },
    [reduce, setOuter],
  );
}

export function usePropertySetter<Obj, PropKey extends keyof Obj>(
  setObject: Setter<Obj>,
  propKey: PropKey,
): Setter<Obj[PropKey]> {
  return useSetter(
    setObject,
    (oldObject, propTransform) => {
      const newObject = { ...oldObject };
      newObject[propKey] = propTransform(oldObject[propKey]);
      return newObject;
    },
    [],
  );
}

export function usePropertySetter2<
  Obj,
  PropKey1 extends keyof Obj,
  PropKey2 extends keyof Obj[PropKey1],
>(
  setObject: Setter<Obj>,
  propKey1: PropKey1,
  propKey2: PropKey2,
): Setter<Obj[PropKey1][PropKey2]> {
  return useSetter(
    setObject,
    (oldObject, propTransform) => {
      const newObject = { ...oldObject };
      newObject[propKey1][propKey2] = propTransform(
        oldObject[propKey1][propKey2],
      );
      return newObject;
    },
    [],
  );
}

// NOTE: useSetter must be added to the additionalHooks option of the
// exhaustive-deps rule in eslintrc.
export function useGuardedSetter<Outer, Inner extends Outer>(
  setOuter: Setter<Outer>,
  guardUnmemoized: (outer: Outer) => outer is Inner,
  dependencies: unknown[],
): Setter<Inner> {
  // We ignore new guard functions unless the caller-provided dependency
  // list has changed.
  //
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const guard = useCallback(guardUnmemoized, dependencies);

  return useSetter(
    setOuter,
    (oldOuter, transform) => {
      if (!guard(oldOuter)) {
        console.warn(
          "Guarded setter failed to run because old value did not pass the type guard",
        );
        return oldOuter;
      }

      const oldInner: Inner = oldOuter;
      const newInner: Inner = transform(oldInner);
      const newOuter: Outer = newInner;

      return newOuter;
    },
    [guard],
  );
}

export function useNonNullSetter<T>(setValue: Setter<T | null>): Setter<T> {
  return useGuardedSetter(setValue, (v): v is T => v !== null, []);
}

export type LazyMemoCache<K, V> = {
  get(cacheKey: K): V;
};

// NOTE: useLazyMemo must be added to the additionalHooks option of the
// exhaustive-deps rule in eslintrc.
export function useLazyMemo<K, V>(
  compute: (cacheKey: K) => V,
  dependencies: unknown[],
): LazyMemoCache<K, V> {
  const cache = useMemo(
    () => ({
      compute,
      store: IMap<K, V>(),
    }),

    // We ignore new compute functions unless the caller-provided dependency
    // list has changed (in which case the cache is reset).
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies,
  );

  return useMemo(
    () => ({
      get(cacheKey: K): V {
        const cached = cache.store.get(cacheKey);
        if (cached) {
          return cached;
        }

        const computed = cache.compute(cacheKey);
        cache.store = cache.store.set(cacheKey, computed);
        return computed;
      },
    }),
    [cache],
  );
}

export type ArraySetter<V> = {
  withIndex: (index: number) => Setter<V>;
};

export function useArraySetter<V>(
  setArray: Setter<readonly V[]>,
): ArraySetter<V> {
  // Maintain a cache of setters in each component for each ArraySetter
  const setterCache = useLazyMemo(
    (index: number) => {
      return (valueOrTransform: SetterArgument<V>) => {
        if (typeof valueOrTransform === "function") {
          const transform = valueOrTransform as SetterTransform<V>;
          setArray((oldArray) => {
            if (index >= oldArray.length) {
              console.log(
                "useArraySetter invariant broken: array index out of bounds. " +
                  "The array should already contain the object before " +
                  "ArraySetter.withIndex(index) is called.",
              );
            }

            const newArray = [...oldArray];
            newArray[index] = transform(oldArray[index]!);
            return newArray;
          });
        } else {
          const newValue = valueOrTransform;
          setArray((oldArray) => {
            if (index >= oldArray.length) {
              console.log(
                "useArraySetter invariant broken: array index out of bounds. " +
                  "The array should already contain the object before " +
                  "ArraySetter.withIndex(index) is called.",
              );
            }

            const newArray = [...oldArray];
            newArray[index] = newValue;
            return newArray;
          });
        }
      };
    },
    [setArray],
  );

  return useMemo(
    () => ({
      withIndex(index: number): Setter<V> {
        return setterCache.get(index);
      },
    }),
    [setterCache],
  );
}

export type IMapSetter<K, V> = {
  withKey: (key: K) => Setter<V>;
};

export function useIMapSetter<K, V>(
  setMap: Setter<IMap<K, V>>,
): IMapSetter<K, V> {
  // Maintain a cache of setters in each component for each IMapSetter
  const keyedSetterCache = useLazyMemo(
    (key: K) => {
      return (valueOrTransform: SetterArgument<V>) => {
        if (typeof valueOrTransform === "function") {
          const transform = valueOrTransform as SetterTransform<V>;
          setMap((oldMap) => {
            if (!oldMap.has(key)) {
              console.log(
                "useIMapSetter invariant broken: missing map entry. " +
                  "The map should already contain key before IMapSetter.withKey(key) is called.",
              );
            }

            return oldMap.set(key, transform(oldMap.get(key)!));
          });
        } else {
          const newValue = valueOrTransform;
          setMap((oldMap) => oldMap.set(key, newValue));
        }
      };
    },
    [setMap],
  );

  return useMemo(
    () => ({
      withKey(key: K): Setter<V> {
        return keyedSetterCache.get(key);
      },
    }),
    [keyedSetterCache],
  );
}

export function useTargetValue<V>(
  setTargetValue: Setter<V>,
): (event: { target: { value: V } }) => void {
  return useCallback(
    (event: { target: { value: V } }) => setTargetValue(event.target.value),
    [setTargetValue],
  );
}

// memoizes a generic component, e.g. `genericReactMemo(<T>(props: {x: T}) => {...})`
// returns the same type as the component, because otherwise it wouldn't be a generic
// component anymore (this is a typescript limitation, only functions and classes can be generic,
// not arbitrary values)
export function genericReactMemo<T extends React.FunctionComponent<never>>(
  component: T,
): T {
  return React.memo(component) as unknown as T;
}

export function readFileText(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e?.target) {
        reject(new Error("file reader failed to read file"));
        return;
      }

      resolve(e.target.result as string);
    };
    reader.readAsText(file);
  });
}

export function readFileBytes(file: File): Promise<Uint8Array> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e?.target) {
        reject(new Error("file reader failed to read file"));
        return;
      }

      resolve(new Uint8Array(e.target.result as ArrayBuffer));
    };
    reader.readAsArrayBuffer(file);
  });
}

export function enumerate<E>(elements: E[]): [E, number][] {
  return elements.map((e, index) => [e, index]);
}

export function multimap<K, V>(arr: [K, V][]): IMap<K, ISet<V>> {
  return IMap<K, ISet<V>>().withMutations((map) => {
    for (const [key, val] of arr) {
      map.set(key, map.get(key, ISet<V>()).add(val));
    }
  });
}

export function formatDateTime(
  dateTime: string | Date | Moment.Moment,
): string {
  return Moment(dateTime).format("MMM D YYYY [at] h:mma");
}

export function createSlugId(from: string, prefix?: string): string {
  return (prefix || "") + paramCase(from);
}

export function unPrefixId(id: string, prefix: string): string {
  if (id.startsWith(prefix)) {
    return id.substring(prefix.length);
  }
  return id;
}

export function isPresent<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

/** attempts to parse `string` as a float. If it fails, returns `defaultValue`*/
export function parseFloatOr<T>(
  string: string | null | undefined,
  defaultValue: T,
): number | T {
  if (isPresent(string)) {
    const parsed = parseFloat(string);
    if (isNaN(parsed)) {
      return defaultValue;
    } else {
      return parsed;
    }
  } else {
    return defaultValue;
  }
}

export function resolveEnum(
  rawEnumType: Types.RawEnumType,
  systemEnumTypesById: IMap<Types.EnumTypeId, Types.EnumType>,
  displayNewInheritedEnumVariants: boolean,
): Types.EnumType {
  if (rawEnumType.disposition.kind === "native") {
    return {
      oldId: rawEnumType.oldId,
      id: rawEnumType.id,
      name: rawEnumType.disposition.name,
      variants: rawEnumType.disposition.variants.map((v) => ({
        oldId: v.oldId,
        id: v.id,
        name: v.name,
      })),
    };
  } else {
    const systemEnum = systemEnumTypesById.get(rawEnumType.id);

    if (systemEnum !== undefined) {
      const resolvedVariants = [];

      // List of all the variants that are explicitly included or excluded. Used to
      // resolve `displayNewInheritedEnumVariants`.
      const explicitVariants = rawEnumType.disposition.excludeVariants
        .map((v) => v.id)
        .concat(rawEnumType.disposition.includeVariants.map((v) => v.id));

      // Add all the new variants.
      // DO NOT REVERSE THIS LOOP (loop over system variants and
      // find the corresponding include variant). Looping over
      // the `includeVariants` and finding the corresponding system
      // variant for each is necessary to respect the position
      // overrides.
      for (const includeVariant of rawEnumType.disposition.includeVariants) {
        const systemVariant = systemEnum.variants.find(
          (v) => v.id === includeVariant.id,
        );
        if (systemVariant !== undefined) {
          resolvedVariants.push({
            oldId: systemVariant?.oldId,
            id: systemVariant?.id,
            name:
              includeVariant.nameAlias !== null
                ? includeVariant.nameAlias
                : systemVariant.name,
          });
        }
      }

      // If the client has enabled `displayNewInheritedEnumVariants`,
      // we add any variants that weren't explicitly included or excluded
      // to the end of the variant list, in the same order that they appear
      // in the system enum.
      if (displayNewInheritedEnumVariants) {
        for (const systemVariant of systemEnum.variants) {
          if (!explicitVariants.includes(systemVariant.id))
            resolvedVariants.push({ ...systemVariant });
        }
      }

      return {
        oldId: rawEnumType.oldId,
        id: rawEnumType.id,
        name:
          rawEnumType.disposition.nameAlias !== null
            ? rawEnumType.disposition.nameAlias
            : systemEnum.name,
        variants: resolvedVariants,
      };
    } else {
      return {
        oldId: rawEnumType.oldId,
        id: rawEnumType.id,
        name:
          rawEnumType.disposition.nameAlias !== null
            ? rawEnumType.disposition.nameAlias
            : "<INHERITED>",
        variants: [],
      };
    }
  }
}

/**
 * Assert at compile time that a given value cannot exist, and throw an
 * error at runtime if this function somehow gets called. This is especially
 * useful for the `default` case of a `switch` statement used on a
 * discriminated union.
 *
 * # Example
 *
 * ```ts
 * type Foo = { type: "fizz", value: number } | { type: "buzz", value: string };
 * const foo: Foo = { type: "fizz", value: 123 } as Foo;
 * switch (foo.type) {
 *   case "fizz":
 *     console.log(`Called with fizz, number ${foo.value}`);
 *     break;
 *   case "buzz":
 *     console.log(`Called with buzz, string ${foo.value}`);
 *     break;
 *   default:
 *     unreachable(foo);
 * }
 * ```
 */
export function unreachable(value: never): never {
  throw new Error(
    `Unreachable called with value: \`${JSON.stringify(value)}\``,
  );
}

/**
 * Return a promise that resolves after the specified number of milliseconds
 * has elapsed.
 */
export function sleep(durationMs: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, durationMs);
  });
}

export function joinClasses(
  ...classes: (string | null | undefined | false)[]
): string {
  return classes.filter((c) => c).join(" ");
}

// Worksheets sometimes inaccurately self-report their range. This method resets the range
// to encompass all of the filled cells.
//
// This issue is mentioned here: https://github.com/SheetJS/sheetjs/issues/2212
// Solution is from here: https://github.com/SheetJS/sheetjs/wiki/General-Utility-Functions#updating-worksheet-range
function updateSheetRange(sheet: XLSX.WorkSheet) {
  const range = { s: { r: Infinity, c: Infinity }, e: { r: 0, c: 0 } };

  Object.keys(sheet)
    .filter((x) => !x.startsWith("!"))
    .map((x) => XLSX.utils.decode_cell(x))
    .forEach((x) => {
      range.s.c = Math.min(range.s.c, x.c);
      range.s.r = Math.min(range.s.r, x.r);
      range.e.c = Math.max(range.e.c, x.c);
      range.e.r = Math.max(range.e.r, x.r);
    });

  sheet["!ref"] = XLSX.utils.encode_range(range);
}

export function spreadsheetFromXlsx(data: Uint8Array): Types.Spreadsheet {
  const xlsx = XLSX.read(data, { type: "array" });
  return {
    sheets: xlsx.SheetNames.map((sheetName) => {
      const sheet = xlsx.Sheets[sheetName];
      updateSheetRange(sheet);
      const sheetRangeStr = sheet["!ref"];

      if (!sheetRangeStr) {
        return {
          name: sheetName,
          rows: [],
        };
      }

      // Tweak range to make sure range starts at A1
      // Otherwise, some worksheets might skip empty rows or columns
      const sheetRange = XLSX.utils.decode_range(sheetRangeStr);
      sheetRange.s.r = 0;
      sheetRange.s.c = 0;

      const rawRows: unknown[][] = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        defval: "",
        range: sheetRange,
      });
      const rows = rawRows.map((row: unknown[]) =>
        row.map((cell: unknown) =>
          cell === null || cell === undefined ? "" : String(cell) + "",
        ),
      );

      return {
        name: sheetName,
        rows,
      };
    }),
  };
}

export function spreadsheetFromCsv(data: Uint8Array): Types.Spreadsheet {
  const string = new TextDecoder("utf-8").decode(data);
  const contents = parseCsv(string);
  return {
    sheets: [
      {
        // Sheet1 is the name selected by the spreadsheet service when it parses a csv
        // so it's hard coded here so that it will match, for example, for a check item in a spreadsheet template.
        name: "Sheet1",
        rows: contents.data as string[][],
      },
    ],
  };
}

export function parseNumberAsExcelDate(
  cellValue: number,
  workbookEpoch: 1900 | 1904,
): Moment.Moment {
  // Excel stores dates internally as "serial numbers", which (with some caveats) correspond
  // to the number of days from a specific starting date. The starting date depends on which
  // "date system" the workbook is using. The first is the "1900 date system", which starts
  // on Jan 1, 1900, which is considered "Day 1". The other is the "1904 date system", which
  // starts on Jan 1, 1904, which is considered "Day 0". Either way, the number stored in
  // the cell is (more or less) a number of days from an epoch. We'll take the number and
  // make adjustments for the workbook's date system quirks below.
  let excelDateSerialNumber = cellValue;

  // We're going to convert these numbers back into dates by adding that number of days to the
  // epoch date for the workbook. Since the 1900 system is 1-indexed (Jan 1 is Day 1, not Day 0),
  // we need to subtract 1. If we don't, then if the serial number is 1, we'll add 1 day to
  // Jan 1, 1900. That would make "Day 1" land on Jan 2.
  if (workbookEpoch === 1900) {
    excelDateSerialNumber -= 1;

    // Now for the real hairy part: The reason there are two date systems is because the 1900
    // system has a bug. It is, unfortunately, also the default system. The bug is that it
    // assumes 1900 was a leap year, which it was not (leap years don't occur at the turn of
    // a century unless it's divisible by 400, i.e., 1600, 2000, etc.). In this system, Day 60
    // is Feb 29, 1900, which did not exist. If we simply add the serial number of days onto
    // Jan 1, 1900 and we don't account for this, all the dates from "Day 60" onward will be
    // off by 1. We correct for this by subtracting 1 from the serial number if it's greater
    // than or equal to 60, which effectively replaces the false Feb 29 with a second Feb 28
    // and corrects all the dates going forward.
    //
    // More information on this bug here:
    // https://docs.microsoft.com/en-us/office/troubleshoot/excel/wrongly-assumes-1900-is-leap-year
    //
    // More info on the general differences between the date systems here:
    // https://docs.microsoft.com/en-us/office/troubleshoot/excel/1900-and-1904-date-system
    //
    if (excelDateSerialNumber >= 60) {
      excelDateSerialNumber -= 1;
    }
  }

  // Notice that all the adjustments above are only done for the 1900 system. The 1904 system
  // doesn't have any false leap days and is 0-indexed, so turning it into a date is a
  // straightforward matter of adding the serial number of days to Jan 1, 1904.

  // Finally, we get January 1 of whatever epoch we're using (months are 0-indexed in Moment),
  // and add our number of days to it, and we have our final date.
  const date = moment
    .utc([workbookEpoch, 0, 1])
    .add(excelDateSerialNumber, "days");

  return date;
}

export function YMDtoMDY(ymd: string): string {
  return moment(ymd, "YYYY-MM-DD").format("MM/DD/YYYY");
}

export function MDYtoYMD(mdy: string): string {
  return moment(mdy, "MM/DD/YYYY").format("YYYY-MM-DD");
}

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) return new Error(error.message);
  return new Error(String(error));
}
