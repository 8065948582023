import React from "react";
import * as T from "types/generated-types";
import _ from "lodash";
import CheckIcon from "@material-ui/icons/Check";

const cellStyle = {
  borderLeft: "1px solid #e0e0e0",
  borderTop: "1px solid #e0e0e0",
  borderRight: "0px",
  borderBottom: "0px",
  background: "white",
  padding: "3px 8px",
  cursor: "pointer",
  fontWeight: 700,
  color: "#151515",
};

const labelStyle = {
  ...cellStyle,
  padding: "8px",
};

const RateCell = (props: { rate: string }) => (
  <td align="center" style={cellStyle}>
    {props.rate}
  </td>
);

const ValueCell = () => (
  <td align="center" style={cellStyle}>
    Value
  </td>
);

const StatusCell = () => (
  <td style={cellStyle}>
    <CheckIcon />
  </td>
);

const RateWithColumnsExampleTable = ({
  adjustedRateFieldId,
  columnIds,
  getFieldName,
}: {
  adjustedRateFieldId: T.FieldId | null;
  columnIds: T.FieldId[] | null;
  getFieldName: (id: T.FieldId) => string;
}) => {
  const rows = _.times(10, (i) => {
    const rate = 2.5 + i * 0.125;
    const rateString = rate.toFixed(3).toString() + "%";

    const valueCells = columnIds?.map((fieldId) => <ValueCell key={fieldId} />);

    return (
      <tr>
        <RateCell rate={rateString} />
        {valueCells}
        <StatusCell />
      </tr>
    );
  });

  return (
    <table
      style={{
        borderCollapse: "collapse",
        borderRight: "1px solid #e0e0e0",
        borderBottom: "1px solid #e0e0e0",
        margin: "0 auto",
      }}
    >
      <thead>
        <tr>
          <th style={labelStyle}>
            {adjustedRateFieldId && getFieldName(adjustedRateFieldId)}
          </th>
          {columnIds?.map((column, i) => (
            <th key={i} style={labelStyle}>
              {getFieldName(column)}
            </th>
          ))}
          <th style={labelStyle}>Status</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

export default RateWithColumnsExampleTable;
