import styled from "styled-components";
import breakpoints from "design/subatomics/breakpoints";
import color from "design/subatomics/colors";
import whitespace from "design/subatomics/whitespace";

export const FieldsColumnWelcome = styled.div`
  background: ${color({ color: "gray", shade: 9 })};
  border-bottom: 1px solid ${color({ color: "border" })};
  position: relative;

  .close {
    position: absolute;
    top: ${whitespace("less")};
    right: ${whitespace("less")};
  }

  h3 {
    margin: ${whitespace()} 0;
  }

  .molecule-select {
    margin-bottom ${whitespace()};
  }
}

  ${breakpoints.mobile} {
    padding: ${whitespace("less")};
    padding-top: 0;

    .typography-paragraph {
      margin-bottom: ${whitespace()};
    }
    
    .loan-pricing-component-product-results-summary {
      margin: 0 0 ${whitespace("least")};      

      .updating {
        display: block;
        padding-bottom: ${whitespace("less")};
      }
    }  
  
    .review-filters {
      display: none;
    }
  }

  ${breakpoints.desktop} {
    padding-top: 1px;
    padding-bottom: 1px;
    padding-right: ${whitespace()};
    padding-left: ${whitespace()};

    .loan-pricing-component-product-results-summary {
      margin: 0 0 ${whitespace("less")};      
    }  

    .view-products {
      display: none;
    }

    .typography-paragraph {
      margin-bottom: ${whitespace()};
    }

    .view-products,
    .loan-pricing-component-product-results-summary {
      display: none;
    }
  }
  
`;
