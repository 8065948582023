import * as React from "react";
import { LoanComparison, DetailsWrapper } from "./styles";
import ProductDetails from "../loan-pricing/_components/product-details";
import ProductDetailActionPanel from "../loan-pricing/_components/product-detail-action-panel";

export default React.memo(() => {
  return (
    <LoanComparison className="page-loan-comparison">
      <ProductDetailActionPanel compareMode={true} />

      <DetailsWrapper>
        <ProductDetails
          fieldValueMappings={null}
          showControlButtons={true}
          showActionPanel={false}
        />
        <ProductDetails
          fieldValueMappings={null}
          showControlButtons={true}
          showActionPanel={false}
        />
        <ProductDetails
          fieldValueMappings={null}
          showControlButtons={true}
          showActionPanel={false}
        />
        <ProductDetails
          fieldValueMappings={null}
          showControlButtons={true}
          showActionPanel={false}
        />
      </DetailsWrapper>
    </LoanComparison>
  );
});
