import React, { useCallback, useState, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { PanelTitle } from "design/atoms/typography";
import Button from "design/atoms/button";
import Icon from "design/atoms/icon";
import { FieldsColumnWelcome } from "./styles";
import ProductResultsSummary from "../product-results-summary";
import ActionButtons from "../action-buttons";
import {
  faEllipsisV,
  faFloppyDisk,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import Popover from "design/layout/popover";
import { LinkList } from "design/molecules/link-list/styles";
import IconLink from "design/atoms/icon-link";
import TextInput from "design/atoms/text-input";
import Scenarios from "../scenarios";
import {
  loansSelector,
  saveScenario,
  mostRequiredFieldsSelector,
} from "features/loans";
import * as T from "types/engine-types";
import { localAccessId } from "features/access-id";

export default React.memo(
  ({
    resetFields,
    fieldValueMappings,
    highlightNextField,
    loading,
  }: {
    resetFields: () => void;
    fieldValueMappings: T.FieldValueMapping[] | null;
    highlightNextField: () => void;
    loading: boolean;
  }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const accessId = localAccessId();
    const loansState = useSelector(loansSelector);

    const [view, setView] = useState<
      "save-scenario" | "load-scenario" | "default"
    >("default");
    const [scenarioName, setScenarioName] = useState("");

    const mostRequiredFields = useSelector(mostRequiredFieldsSelector);

    const toggleSaveScenario = useCallback(() => {
      setView("save-scenario");
    }, []);

    const toggleLoadScenario = useCallback(() => {
      setView("load-scenario");
    }, []);

    const cancel = useCallback(() => {
      setView("default");
    }, []);

    return (
      <FieldsColumnWelcome
        className={`page-component-fields-column-welcome ${view}`}
      >
        {<ProductResultsSummary loading={loading} />}

        {view === "load-scenario" && (
          <Scenarios close={() => setView("default")} />
        )}

        {view === "save-scenario" && (
          <>
            <PanelTitle>Save Inputs to Scenario</PanelTitle>
            <TextInput
              inputProps={{
                value: scenarioName,
                onChange: (e: ChangeEvent<HTMLInputElement>) =>
                  setScenarioName(e.target.value),
              }}
              label="Name the new scenario"
            />
          </>
        )}

        {view === "load-scenario" && (
          <ActionButtons>
            <Button onClick={cancel}>Cancel</Button>
          </ActionButtons>
        )}

        {view === "save-scenario" && (
          <ActionButtons>
            <Button onClick={cancel}>Cancel</Button>

            <Button
              isPrimary={true}
              onClick={() => {
                cancel();
                dispatch(
                  saveScenario({
                    scope: "user",
                    displayName: scenarioName,
                    fields: fieldValueMappings || [],
                  }),
                );
              }}
            >
              Save Scenario
            </Button>
          </ActionButtons>
        )}

        {view === "default" && (
          <div>
            <ActionButtons>
              <Button
                isPrimary={!loansState.summary?.totals?.approved}
                grow={false}
                disabled={loading || !mostRequiredFields.length}
                onClick={() => {
                  highlightNextField();
                }}
                className="next-field"
              >
                Next Field
              </Button>

              {(!!loansState.scenarios?.userScoped?.length ||
                !!loansState.scenarios?.clientScoped?.length) && (
                <Button
                  isPrimary={!loansState.summary?.totals?.approved}
                  grow={false}
                  onClick={toggleLoadScenario}
                >
                  Load Scenario
                </Button>
              )}

              <Button
                isPrimary={true}
                grow={false}
                onClick={() => {
                  const filteredSearch = location.search.replace("?", "");
                  const searchArray = filteredSearch.split("&");

                  const prunedSearchArray = searchArray.map((a, i) => {
                    return a.includes("activeView")
                      ? i === 0
                        ? "activeView=products"
                        : "&activeView=products"
                      : a;
                  });

                  const rejoinedSearchArray = "?" + prunedSearchArray.join("&");
                  history.push(
                    `${location.pathname}${
                      location.search
                        ? rejoinedSearchArray
                        : "?activeView=products"
                    }${location.hash}`,
                  );
                }}
                className="view-products"
              >
                View Products
              </Button>

              <Popover
                trigger={
                  <Button>
                    <Icon icon={faEllipsisV} />
                  </Button>
                }
                content={
                  <LinkList>
                    <IconLink
                      inline={true}
                      icon={<Icon icon={faFloppyDisk} />}
                      onClick={toggleSaveScenario}
                    >
                      Save Inputs to Scenario
                    </IconLink>

                    {!!fieldValueMappings?.length && (
                      <IconLink
                        inline={true}
                        to={`/c/${accessId}/v2/loan-pricing${location.search}`}
                        icon={<Icon icon={faRotateLeft} />}
                        onClick={resetFields}
                      >
                        Clear Inputs
                      </IconLink>
                    )}
                  </LinkList>
                }
              />
            </ActionButtons>
          </div>
        )}
      </FieldsColumnWelcome>
    );
  },
);
