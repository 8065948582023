import React, { useState } from "react";
import whitespace from "design/subatomics/whitespace";
import { styles } from "design/atoms/typography";
import colors from "design/subatomics/colors";
import {
  Input,
  Label,
  TextWrapper,
  Prefix,
  InputWrapper,
  Suffix,
  LabelWrapper,
} from "./styles";

function StringInput({
  label,
  hint,
  error,
  inputProps,
  isRequired = false,
  adornment,
  adornmentText,
  customClassname,
  placeholderText,
}: {
  label?: string;
  hint?: string;
  error?: string;
  inputProps?: unknown;
  isRequired?: boolean;
  adornment?: "prefix" | "suffix" | null;
  adornmentText?: string | null;
  customClassname?: string;
  placeholderText?: string;
}) {
  const [adornmentState, setAdornment] = useState(false);

  return (
    <LabelWrapper>
      {label && (
        <Label className="label">
          {label}
          {isRequired && (
            <strong
              title="This field is required"
              style={{
                display: "inline-block",
                marginLeft: whitespace("least"),
                color: colors({ color: "white" }),
              }}
            >
              *
            </strong>
          )}
        </Label>
      )}
      <TextWrapper>
        {adornment === "prefix" && (
          <Prefix
            className={adornmentState ? "prefix-span focused" : "prefix-span"}
          >
            {adornmentText}
          </Prefix>
        )}
        <InputWrapper>
          <Input
            autoComplete="off"
            className={`string-input ${
              error ? "with-error" : "without-error"
            } ${adornment ? adornment : ""}`}
            {...inputProps}
            type="search"
            placeholder={placeholderText ? placeholderText : ""}
            onFocus={() => setAdornment(true)}
            onBlur={() => setAdornment(false)}
          />
        </InputWrapper>
        {adornment === "suffix" && (
          <Suffix
            className={adornmentState ? "suffix-span focused" : "suffix-span"}
          >
            {adornmentText}
          </Suffix>
        )}
      </TextWrapper>
      {hint && !error && (
        <div
          className="hint"
          style={{
            ...styles.smallText,
            color: colors({ color: "gray", shade: 6 }),
            marginTop: whitespace("least"),
            marginLeft: whitespace("less"),
          }}
        >
          {hint}
        </div>
      )}
      {error && (
        <div
          className="error"
          style={{
            ...styles.smallText,
            color: colors({ color: "red" }),
            marginTop: whitespace("least"),
            marginLeft: whitespace("less"),
          }}
        >
          {error}
        </div>
      )}
    </LabelWrapper>
  );
}

export default StringInput;
