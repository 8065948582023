import styled from "styled-components";

export const DurationWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const NoTextWrapper = styled.div`
  width: 100%;
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const DurationEnumWrapper = styled.div`
  min-width: 140px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-left: 2px;
`;
