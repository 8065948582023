import styled from "styled-components";
// import { Link as RRLink } from "react-router-dom"
// import opacities from 'design/subatomics/opacities';
import whitespace from "design/subatomics/whitespace";
import fontSizes from "design/subatomics/font-sizes";
import shadows from "design/subatomics/shadows";
// import transitions from 'design/subatomics/transitions';
import color from "design/subatomics/colors";
import { joinClasses } from "features/utils";

export const fontWeights = {
  lightest: "200",
  default: "400",
  bold: "700",
  boldest: "900",
};

export const styles = {
  primaryTitle: {
    margin: 0,
    padding: 0,
    lineHeight: "1.15em",
    fontSize: fontSizes.xxl,
    fontWeight: fontWeights.lightest,
    textShadow: shadows.titles,
    color: color({ color: "gray", shade: 3 }),
  },
  primaryTitleSub: {
    margin: 0,
    padding: 0,
    lineHeight: "1.35em",
    fontSize: fontSizes.m,
    fontWeight: fontWeights.default,
    color: color({ color: "gray", shade: 5 }),
  },
  secondaryTitle: {
    margin: 0,
    padding: 0,
    lineHeight: "1.15em",
    fontSize: fontSizes.xl,
    fontWeight: fontWeights.default,
    textShadow: shadows.titles,
    color: color({ color: "gray", shade: 3 }),
  },
  secondaryTitleSub: {
    margin: 0,
    padding: 0,
    lineHeight: "1.35em",
    fontSize: fontSizes.m,
    fontWeight: fontWeights.default,
    color: color({ color: "gray", shade: 5 }),
  },
  panelTitle: {
    margin: 0,
    padding: 0,
    lineHeight: "1.15em",
    fontSize: fontSizes.l,
    textShadow: shadows.titles,
    color: color({ color: "gray", shade: 3 }),
  },
  panelTitleSub: {
    margin: 0,
    padding: 0,
    lineHeight: "1.35em",
    fontSize: fontSizes.m,
    fontWeight: fontWeights.default,
    color: color({ color: "gray", shade: 5 }),
  },
  microTitle: {
    margin: 0,
    padding: 0,
    lineHeight: "1.15em",
    fontSize: fontSizes.xs,
    fontWeight: fontWeights.bold,
    color: color({ color: "gray", shade: 6 }),
  },
  paragraph: {
    margin: 0,
    padding: 0,
    lineHeight: "1.35em",
    fontSize: fontSizes.m,
    fontWeight: fontWeights.default,
    color: color({ color: "gray", shade: 5 }),
  },
  link: {
    margin: 0,
    padding: 0,
    fontWeight: fontWeights.default,
    color: color({ color: "blue", shade: 4 }),
    textDecoration: "none",
  },
  linkHover: {
    cursor: "pointer",
    color: color({ color: "blue", shade: 5 }),
  },
  linkActive: {
    outline: `1px solid ${color({ color: "blue", shade: 5 })} !important`,

    color: color({ color: "blue", shade: 5 }),
  },
  text: {
    lineHeight: fontSizes.m,
    fontSize: fontSizes.m,
  },
  errorText: {
    margin: 0,
    padding: whitespace(),
    color: color({ color: "red", shade: 5 }),
  },
  smallText: {
    lineHeight: fontSizes.m,
    fontSize: fontSizes.xs,
  },
  input: {
    margin: 0,
    padding: 10,
    lineHeight: fontSizes.m,
    fontSize: fontSizes.m,
    fontFamily: '"Helvetica Neue", sans-serif',
    color: color({ color: "gray", shade: 2 }),
    letterSpacing: "1px",
  },
  inputSmall: {
    margin: 0,
    padding: 0,
    lineHeight: fontSizes.xs,
    fontSize: fontSizes.xs,
    fontFamily: '"Helvetica Neue", sans-serif',
    color: color({ color: "gray", shade: 2 }),
    letterSpacing: ".5px",
  },
};

export const PrimaryTitle = styled.h1.attrs((props) => ({
  className: joinClasses(props.className, "typography-primary-title"),
}))`
  ${styles.primaryTitle};

  strong {
    font-weight: ${fontWeights.default};
  }
`;

export const PrimaryTitleSub = styled.div.attrs((props) => ({
  className: joinClasses(props.className, "typography-primary-title-sub"),
}))`
  ${styles.primaryTitleSub}
`;

export const SecondaryTitle = styled.h2.attrs((props) => ({
  className: joinClasses(props.className, "typography-secondary-title"),
}))`
  ${styles.secondaryTitle};

  strong {
    font-weight: ${fontWeights.default};
  }
`;

export const SecondaryTitleSub = styled.div.attrs((props) => ({
  className: joinClasses(props.className, "typography-secondary-title-sub"),
}))`
  ${styles.secondaryTitleSub}
`;

export const PanelTitle = styled.h3.attrs((props) => ({
  className: joinClasses(props.className, "typography-panel-title"),
}))`
  ${styles.panelTitle}
`;

export const PanelTitleSub = styled.div.attrs((props) => ({
  className: joinClasses(props.className, "typography-panel-title-sub"),
}))`
  ${styles.panelTitleSub}
`;

export const MicroTitle = styled.h4.attrs((props) => ({
  className: joinClasses(props.className, "typography-micro-title"),
}))`
  ${styles.microTitle}
`;

export const Paragraph = styled.div.attrs((props) => ({
  className: joinClasses(props.className, "typography-paragraph"),
}))`
  ${styles.paragraph};

  .atom-icon {
    padding: 0 ${whitespace("less")};
  }
`;

export const Text = styled.span.attrs((props) => ({
  className: joinClasses(props.className, "typography-text"),
}))`
  ${styles.text}
`;

export const ErrorText = styled.div.attrs((props) => ({
  className: joinClasses(props.className, "typography-error-text"),
}))`
  ${styles.errorText};
  text-align: center;
`;

export const SmallText = styled.span.attrs<{}>((props) => ({
  className: joinClasses(props.className, "typography-small-text"),
}))`
  ${styles.smallText}
`;

export const TooltipText = styled.span.attrs<{}>((props) => ({
  className: joinClasses(props.className, "typography-tooltip-text"),
}))`
  ${styles.smallText};
  display: inline-block;
  padding: ${whitespace()};
`;

export const Input = styled.div.attrs<{}>((props) => ({
  className: joinClasses(props.className, "typography-input"),
}))`
  ${styles.input}
`;

export const InputSmall = styled.div.attrs<{}>((props) => ({
  className: joinClasses(props.className, "typography-input-small"),
}))`
  ${styles.inputSmall}
`;

export const Link = styled.a.attrs<{}>((props) => ({
  className: joinClasses(props.className, "typography-link"),
}))`
  ${styles.link}

  &:hover {
    ${styles.linkHover};
  }

  &:active,
  &:focus {
    ${styles.linkActive};
  }
`;

export const AsLink = styled.span.attrs<{}>((props) => ({
  className: joinClasses(props.className, "typography-as-link"),
}))`
  ${styles.link}

  &:hover {
    ${styles.linkHover};
  }

  &:active {
    ${styles.linkActive};
  }
`;
