import { Map as IMap } from "immutable";
import React from "react";
import ClearIcon from "@material-ui/icons/Clear";
import CommentIcon from "@material-ui/icons/Comment";
import DescriptionIcon from "@material-ui/icons/Description";
import WarningIcon from "@material-ui/icons/Warning";
import EnglishList from "design/atoms/english-list";
import { Configuration } from "config";
import * as T from "types/engine-types";
import { getMissingFieldsOnPriceScenario } from "features/execution";
import CauseLink from "pages/loans/_components/cause-link";
import MessageSection from "pages/loans-v2/loan-pricing/_components/message-section";
import colors from "design/subatomics/colors";
import {
  getRejectionReasonInfo,
  getMissingFieldInfo,
  convertExecutionErrorToString,
} from "features/loans";
import Icon from "design/atoms/icon";

import {
  faExclamationCircle,
  faCheckCircle,
  faCircleXmark,
  faCircleQuestion,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { Paragraph, PrimaryTitle } from "design/atoms/typography";
import whitespace from "design/subatomics/whitespace";
import { localAccessId } from "features/access-id";

const PriceScenarioSummary = React.memo(
  ({
    scenario,
    config,
    rulesById,
    color,
  }: {
    scenario: T.PriceScenarioResult;
    config: Configuration;
    rulesById: IMap<T.RuleId, T.DecoratedRuleHeader>;
    color: string;
  }) => {
    let title: string;
    let titleIcon: JSX.Element | null;
    let description: JSX.Element | null;
    const accessId = localAccessId();

    switch (scenario.status) {
      case "missing-configuration":
        throw new Error(
          "we shouldn't be displaying a PriceScenarioSummary if missing configuration",
        );
      case "approved":
        title = "Approved";
        titleIcon = <Icon icon={faCheckCircle} />;
        description = null;
        break;
      case "review-required": {
        const reviewRequirementReasons = getRejectionReasonInfo(
          config,
          rulesById,
          scenario.reviewRequirements,
        );

        title = "Review Required";
        titleIcon = <Icon icon={faCircleQuestion} />;
        description = (
          <>
            <Paragraph>
              Given the information provided, this loan requires further review
              before it can be approved. Pricing may change upon review. The
              following{" "}
              {scenario.reviewRequirements.length === 1
                ? `reason was`
                : `${scenario.reviewRequirements.length} reasons were`}{" "}
              given for this requirement:
            </Paragraph>

            {reviewRequirementReasons.map((reason, reasonIndex) => {
              return (
                <MessageSection key={reasonIndex} icon={<CommentIcon />}>
                  {reason.title}
                  {reason.cause && (
                    <CauseLink
                      title={reason.cause.name}
                      link={reason.cause.link}
                    />
                  )}
                </MessageSection>
              );
            })}
          </>
        );
        break;
      }
      case "rejected": {
        const rejectionReasons = getRejectionReasonInfo(
          config,
          rulesById,
          scenario.rejections,
        );

        const reviewRequirementReasons = getRejectionReasonInfo(
          config,
          rulesById,
          scenario.reviewRequirements,
        );

        title = "Rejected";
        titleIcon = <Icon icon={faCircleXmark} />;
        description = (
          <>
            <Paragraph>
              Given the information provided, this loan would not meet this
              product's eligibility guidelines.
              {rejectionReasons.length === 1
                ? " The following reason was "
                : ` The following ${rejectionReasons.length} reasons were `}
              given for the rejection:
            </Paragraph>
            {rejectionReasons.map((reason, reasonIndex) => (
              <MessageSection key={reasonIndex} icon={<ClearIcon />}>
                {reason.title}
                {reason.cause && (
                  <>
                    {" "}
                    (
                    <CauseLink
                      title={reason.cause.name}
                      link={reason.cause.link}
                    />
                    )
                  </>
                )}
              </MessageSection>
            ))}
            {reviewRequirementReasons.length > 0 && (
              <>
                <Paragraph>
                  Furthermore, if the above rejection reasons were not present,
                  this loan would still be subject to further review for the
                  following{" "}
                  {reviewRequirementReasons.length === 1
                    ? "reason"
                    : `${reviewRequirementReasons.length} reasons`}{" "}
                  before it could be approved:
                </Paragraph>
                {reviewRequirementReasons.map((reason, reasonIndex) => (
                  <MessageSection key={reasonIndex} icon={<CommentIcon />}>
                    {reason.title}
                    {reason.cause && (
                      <>
                        {" "}
                        (
                        <CauseLink
                          title={reason.cause.name}
                          link={reason.cause.link}
                        />
                        )
                      </>
                    )}
                  </MessageSection>
                ))}
              </>
            )}
          </>
        );

        break;
      }
      case "error":
        const isMissingData = scenario.errors.every(
          (err) => err.type === "blank-field",
        );

        if (isMissingData) {
          const missingFieldInfo = getMissingFieldInfo(
            config,
            rulesById,
            getMissingFieldsOnPriceScenario(scenario),
            accessId,
          );
          title = "Information needed";
          titleIcon = <Icon icon={faExclamationCircle} />;

          description = (
            <>
              <Paragraph>
                More information is needed to produce an Approved or Rejected
                response at this price point. Fill out the following{" "}
                {missingFieldInfo.length === 1 ? "field" : "fields"} to
                continue:
              </Paragraph>
              {missingFieldInfo.map((missingField) => (
                <MessageSection
                  key={missingField.fieldId}
                  icon={<DescriptionIcon />}
                >
                  <strong>{missingField.fieldName}</strong> is needed in{" "}
                  <EnglishList
                    items={missingField.causes.map((cause) => {
                      if (cause.link) {
                        return (
                          <CauseLink
                            key={cause.link}
                            title={cause.title}
                            link={cause.link}
                          />
                        );
                      } else {
                        return cause.title;
                      }
                    })}
                  />
                  .
                </MessageSection>
              ))}
            </>
          );
        } else {
          const errorMessages = scenario.errors.map((err) =>
            convertExecutionErrorToString(err, config.allFieldsById),
          );
          title = "Error";
          titleIcon = <Icon icon={faCircleExclamation} />;
          description = (
            <>
              <Paragraph>
                {errorMessages.length === 1 ? "An error " : "Multiple errors "}
                occurred while evaluating this product.
              </Paragraph>
              {errorMessages.map((msg, msgIndex) => (
                <MessageSection key={msgIndex} icon={<WarningIcon />}>
                  {msg}
                </MessageSection>
              ))}
            </>
          );
        }
    }

    return (
      // specify container to make print positioning a bit easier
      <div style={{ padding: whitespace() }}>
        <PrimaryTitle>
          <span style={{ color: colors({ color, shade: 5 }) }}>
            {titleIcon}
          </span>{" "}
          {title}
        </PrimaryTitle>
        {description}
      </div>
    );
  },
);

export default PriceScenarioSummary;
