import React, { useState } from "react";
import Select from "design/atoms/select";
import { CreditApplicationFieldDefinition } from "types/generated-types";
import { BaseFieldDefinition } from "types/engine-types";
import { useSelector } from "react-redux";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import { EnumWrapper } from "./styles";

export default React.memo(
  ({
    showLabel = true,
    field,
  }: {
    showLabel?: boolean;
    field: CreditApplicationFieldDefinition | BaseFieldDefinition;
  }) => {
    const applicationState = useSelector(
      nonNullApplicationInitializationSelector,
    );
    const enumVariants = applicationState.config.enumTypes
      .find((variants) => {
        return "enumTypeId" in field.valueType
          ? variants.name === field.name ||
              variants.name === field.id ||
              variants.id === field.valueType.enumTypeId
          : null;
      })
      ?.variants.map((variant) => {
        return { name: variant.name, id: variant.name };
      });

    const [selectedEnum, setSelectedEnum] = useState<{
      id: string;
      name: string;
    }>();

    return (
      <>
        {enumVariants && (
          <EnumWrapper>
            <Select
              label={showLabel ? field.name : ""}
              options={enumVariants}
              value={selectedEnum!}
              getOptionLabel={(value: { id: string; name: string }) =>
                value.name
              }
              getOptionValue={(value: { id: string; name: string }) => value.id}
              onChange={(e) => setSelectedEnum(e!)}
            />
          </EnumWrapper>
        )}
      </>
    );
  },
);
