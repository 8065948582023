import { Map as IMap, Set as ISet } from "immutable";
import React, { useCallback, useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { Configuration } from "config";
import * as T from "types/engine-types";
import { useLazyMemo } from "features/utils";
import PriceScenarioDialog from "../price-scenario-dialog";
import SuspendedPricingWarning from "../suspended-price-warning";
import { ObjectDetails } from "features/objects";

import { RateWithLockPeriodPriceScenarioTable } from "./rate-with-lock-period";
import { RateWithColumnsPriceScenarioTable } from "./rate-with-columns";

const PriceScenarioTable = React.memo(
  ({
    product,
    scenarios,
    rulesById,
    config,
    objectDetails,
    isInvestorPricingEnabled,
    result,
  }: {
    product: T.ProductHeader;
    isInvestorPricingEnabled?: Boolean;
    scenarios: ISet<T.PriceScenarioResult>;
    rulesById: IMap<T.RuleId, T.DecoratedRuleHeader>;
    config: Configuration;
    objectDetails: ObjectDetails;
    result: T.ProductExecutionResult;
  }) => {
    const [openPriceScenarioId, setOpenPriceScenarioId] =
      useState<T.PriceScenarioResultId | null>(null);

    const openPriceScenario = useMemo(
      () =>
        openPriceScenarioId === null
          ? null
          : scenarios.find((s) => s.id === openPriceScenarioId) || null,
      [scenarios, openPriceScenarioId],
    );

    const openPriceScenarioById = useLazyMemo(
      (id: T.PriceScenarioResultId) => () => setOpenPriceScenarioId(id),
      [],
    );
    const closePriceScenario = useCallback(
      () => setOpenPriceScenarioId(null),
      [],
    );

    const { priceScenarioTableFieldInfo } = config;

    if (
      !priceScenarioTableFieldInfo ||
      scenarios.some((s) => s.status === "missing-configuration")
    ) {
      return (
        <Box>
          Pricing is unavailable due to a configuration problem. Check Settings
          on the Rate Sheets page.
        </Box>
      );
    }

    if (scenarios.size === 0) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="400px"
          height="200px"
          border="1px solid #e0e0e0"
        >
          <Box color="text.secondary" fontSize="24px">
            No pricing available.
          </Box>
        </Box>
      );
    }

    return (
      <>
        {!isInvestorPricingEnabled && <SuspendedPricingWarning />}
        {priceScenarioTableFieldInfo.type === "rate-with-lock-period" && (
          <RateWithLockPeriodPriceScenarioTable
            fieldInfo={priceScenarioTableFieldInfo}
            config={config}
            scenarios={scenarios}
            objectDetails={objectDetails}
            openPriceScenarioById={openPriceScenarioById}
          />
        )}
        {priceScenarioTableFieldInfo.type === "rate-with-columns" && (
          <RateWithColumnsPriceScenarioTable
            fieldInfo={priceScenarioTableFieldInfo}
            config={config}
            scenarios={scenarios}
            objectDetails={objectDetails}
            openPriceScenarioById={openPriceScenarioById}
          />
        )}
        <p
          className="hide-from-print"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <WarningIcon style={{ marginRight: "8px" }} /> Click{" "}
          <strong style={{ margin: "0 4px" }}> ANY PRICING CELL</strong> above
          for additional details.
        </p>

        {openPriceScenario !== null && (
          <PriceScenarioDialog
            result={result}
            product={product}
            scenario={openPriceScenario}
            config={config}
            rulesById={rulesById}
            close={closePriceScenario}
          />
        )}
      </>
    );
  },
);

export default PriceScenarioTable;
