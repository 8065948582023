import whitespace from "design/subatomics/whitespace";
import { styles } from "design/atoms/typography";
import colors from "design/subatomics/colors";
import radius from "design/subatomics/corners";
import styled from "styled-components";
import shadows from "design/subatomics/shadows";
import layers from "design/subatomics/layers";

export const Input = styled.input.attrs<{}>((props) => ({
  className: `${props.className || ""} atom-input`,
}))`
  ${styles.input};
  border-radius: ${radius.small};
  box-sizing: border-box;
  padding: ${whitespace("less")};
  border: 1px solid ${colors({ color: "lightBorder" })};
  width: 100%;
  background-color: ${colors({ color: "white" })};

  &:focus {
    border-color: ${colors({ color: "green", shade: 5 })};
    outline: none;
  }

  &::placeholder {
    color: ${colors({ color: "gray", shade: 5 })};
  }

  &:focus: &.suffix-span {
    border-color: ${colors({ color: "green", shade: 5 })};
    outline: none;
  }

  &.prefix {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.suffix {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const Label = styled.span`
  ${styles.microTitle};
  text-transform: uppercase;
  position: relative;
  top: ${whitespace("less")};
  left: ${whitespace("least")};
  background: ${colors({ color: "white" })};
  padding: 0 ${whitespace("least")};
  z-index: ${layers({ layer: "controls" })};
`;

export const LabelWrapper = styled.label`
  margin-top: ${whitespace("least")};
  width: 100%;
  display: block;
`;

export const TextWrapper = styled.span`
  display: flex;
  box-shadow: ${shadows.controls};
  border-radius: ${radius.small};
  // margin-top: ${whitespace("less")};
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const Prefix = styled.span`
  ${styles.input};
  background-color: ${colors({ color: "white" })};
  color: ${colors({ color: "gray", shade: 5 })};
  padding: 0px ${whitespace("least")} 0px ${whitespace("base")};
  border-top-left-radius: ${radius.small};
  border-bottom-left-radius: ${radius.small};
  border: 1px solid ${colors({ color: "lightBorder" })};
  border-right: none;
  display: inline-flex;
  align-items: center;

  &.focused {
    border-color: ${colors({ color: "green", shade: 5 })};
    outline: none;
  }
`;

export const Suffix = styled.span`
  ${styles.input};
  background-color: ${colors({ color: "white" })};
  color: ${colors({ color: "gray", shade: 5 })};
  padding: 0px ${whitespace("base")} 0px ${whitespace("least")};
  border-top-right-radius: ${radius.small};
  border-bottom-right-radius: ${radius.small};
  border: 1px solid ${colors({ color: "lightBorder" })};
  border-left: none;
  display: inline-flex;
  align-items: center;

  &.focused {
    border-color: ${colors({ color: "green", shade: 5 })};
    outline: none;
  }
`;
