import { Map as IMap, Set as ISet } from "immutable";
import React from "react";
import { Configuration } from "config";
import * as T from "types/engine-types";
import * as Stages from "features/stages";
import { usePermissions } from "features/roles";
import AdjustmentsSection from "pages/loans-v2/loan-pricing/_components/adjustments-section";
import CalculationsSection from "pages/loans-v2/loan-pricing/_components/calculations-section";
import StipulationsSection from "pages/loans-v2/loan-pricing/_components/stipulations-section";

const PriceScenarioFields = React.memo(
  ({
    scenario,
    config,
    rulesById,
    color,
  }: {
    scenario: T.PriceScenarioResult;
    config: Configuration;
    rulesById: IMap<T.RuleId, T.DecoratedRuleHeader>;
    color: string;
  }) => {
    const hasPermission = usePermissions();
    const hasProfitViewPerm = hasPermission(
      "pricing-profit-margin-adjustments-view",
    );
    const calcStagesByScope = Stages.getCalcStagesByScope(config);
    const priceScenarioCalculatedFieldValuesById = IMap(
      [...scenario.calculatedFields].map((mapping) => [
        mapping.fieldId,
        mapping.value,
      ]),
    );
    let content: JSX.Element | null;

    switch (scenario.status) {
      case "missing-configuration":
        throw new Error(
          "we shouldn't be displaying a PriceScenarioFields if missing configuration",
        );
      case "approved":
        content = (
          <>
            {calcStagesByScope.priceScenario.map((stage) => (
              <CalculationsSection
                color={color}
                key={stage.id}
                config={config}
                stage={stage}
                fieldValuesById={priceScenarioCalculatedFieldValuesById}
              />
            ))}
            <AdjustmentsSection
              color={color}
              adjustments={ISet(scenario.rateAdjustments)}
              adjustmentKindName="Rate"
              rulesById={rulesById}
            />
            <AdjustmentsSection
              color={color}
              adjustments={ISet(scenario.priceAdjustments)}
              adjustmentKindName="Price"
              rulesById={rulesById}
            />
            {scenario.marginAdjustments && hasProfitViewPerm && (
              <AdjustmentsSection
                color={color}
                adjustments={ISet(scenario.marginAdjustments)}
                adjustmentKindName="Profit Margin Price"
                rulesById={rulesById}
              />
            )}
            <StipulationsSection
              color={color}
              stipulations={ISet(scenario.stipulations)}
              rulesById={rulesById}
            />
          </>
        );
        break;
      case "review-required": {
        content = (
          <>
            {calcStagesByScope.priceScenario.map((stage) => (
              <CalculationsSection
                color={color}
                key={stage.id}
                config={config}
                stage={stage}
                fieldValuesById={priceScenarioCalculatedFieldValuesById}
              />
            ))}
            <AdjustmentsSection
              color={color}
              adjustments={ISet(scenario.rateAdjustments)}
              adjustmentKindName="Rate"
              rulesById={rulesById}
            />
            <AdjustmentsSection
              color={color}
              adjustments={ISet(scenario.priceAdjustments)}
              adjustmentKindName="Price"
              rulesById={rulesById}
            />
            {scenario.marginAdjustments && hasProfitViewPerm && (
              <AdjustmentsSection
                color={color}
                adjustments={ISet(scenario.marginAdjustments)}
                adjustmentKindName="Profit Margin Price"
                rulesById={rulesById}
              />
            )}
            <StipulationsSection
              color={color}
              stipulations={ISet(scenario.stipulations)}
              rulesById={rulesById}
            />
          </>
        );
        break;
      }
      case "rejected": {
        content = (
          <>
            {calcStagesByScope.priceScenario.map((stage) => (
              <CalculationsSection
                color={color}
                key={stage.id}
                config={config}
                stage={stage}
                fieldValuesById={priceScenarioCalculatedFieldValuesById}
              />
            ))}
            <AdjustmentsSection
              color={color}
              adjustments={ISet(scenario.rateAdjustments)}
              adjustmentKindName="Rate"
              rulesById={rulesById}
            />
            <AdjustmentsSection
              color={color}
              adjustments={ISet(scenario.priceAdjustments)}
              adjustmentKindName="Price"
              rulesById={rulesById}
            />
            {scenario.marginAdjustments && hasProfitViewPerm && (
              <AdjustmentsSection
                color={color}
                adjustments={ISet(scenario.marginAdjustments)}
                adjustmentKindName="Profit Margin Price"
                rulesById={rulesById}
              />
            )}
            <StipulationsSection
              color={color}
              stipulations={ISet(scenario.stipulations)}
              rulesById={rulesById}
            />
          </>
        );
        break;
      }
      case "error":
        const isMissingData = scenario.errors.every(
          (err) => err.type === "blank-field",
        );

        if (isMissingData) {
          content = (
            <>
              {calcStagesByScope.priceScenario.map((stage) => (
                <CalculationsSection
                  color={color}
                  key={stage.id}
                  config={config}
                  stage={stage}
                  fieldValuesById={priceScenarioCalculatedFieldValuesById}
                />
              ))}
            </>
          );
        } else {
          content = (
            <>
              {calcStagesByScope.priceScenario.map((stage) => (
                <CalculationsSection
                  color={color}
                  key={stage.id}
                  config={config}
                  stage={stage}
                  fieldValuesById={priceScenarioCalculatedFieldValuesById}
                />
              ))}
            </>
          );
        }
    }

    return (
      // specify container to make print positioning a bit easier
      <>{content}</>
    );
  },
);

export default PriceScenarioFields;
