import {
  createSlice,
  PayloadAction,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import _ from "lodash";
import * as Api from "api";
import * as T from "types/engine-types";
import { AppThunk } from "features/store";
import moment from "moment";
import {
  FieldValueState,
  convertStateToFieldValue,
  convertFieldValueToState,
  newFieldValueState,
} from "design/organisms/field-value-editor";
import { Map as IMap, Set as ISet } from "immutable";
import { Configuration } from "config";
import {
  UiValidationError,
  toMapById,
  toMapByCustomId,
  unreachable,
  getErrorMessage,
} from "features/utils";
import { fieldValuesEqual } from "features/fields";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import { filteredSummaryProductsSelector } from "features/pricing-summaries";
import { localAccessId } from "features/access-id";

export type LoansState = {
  summary: T.ExecutionSummary | null;
  priceLockingConfig: PriceLockingConfig;
  scenarios: {
    userScoped: T.ApplicationScenario[] | null;
    clientScoped: T.ApplicationScenario[] | null;
    error: string | null;
  };
  scenarioToLoad: T.FieldValueMapping[] | null | undefined;
  currentFormValues: T.FieldValueMapping[] | null | undefined;
  openProduct: T.ProductId | null;
  exportedLoan: exportedLoan | null;
};

export type exportedLoan = {
  scenario: T.PriceScenarioResult | null;
  product: T.ProductHeader | null;
  result: T.ProductExecutionResult | null;
};

export type PriceLockingConfig =
  | { enabled: false }
  | { enabled: true; lockRequestLabel: string };

interface EnablePriceLocking {
  lockRequestLabel?: string;
}

const initialState: LoansState = {
  summary: null,
  priceLockingConfig: {
    enabled: false,
  },
  scenarios: {
    userScoped: null,
    clientScoped: null,
    error: null,
  },
  scenarioToLoad: null,
  currentFormValues: undefined,
  openProduct: null,
  exportedLoan: {
    scenario: null,
    product: null,
    result: null,
  },
};

const loansSlice = createSlice({
  name: "Loans",
  initialState,
  reducers: {
    setSummary: (
      state,
      { payload }: PayloadAction<T.ExecutionSummary | null>,
    ) => {
      state.summary = payload;
    },
    enablePriceLocking: (
      state,
      { payload }: PayloadAction<EnablePriceLocking>,
    ) => {
      state.priceLockingConfig = {
        enabled: true,
        lockRequestLabel: payload.lockRequestLabel ?? "Lock Request",
      };
    },
    exportLoan: (state, { payload }) => {
      state.exportedLoan = {
        // This comment was generated when upgrading react-scripts and eslint
        // TODO: fix the lint rule and remove this eslint-disable comment
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        scenario: payload.scenario,
        // This comment was generated when upgrading react-scripts and eslint
        // TODO: fix the lint rule and remove this eslint-disable comment
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        product: payload.product,
        // This comment was generated when upgrading react-scripts and eslint
        // TODO: fix the lint rule and remove this eslint-disable comment
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        result: payload.result,
      };
    },
    loadScenario: (
      state,
      { payload }: PayloadAction<T.FieldValueMapping[] | null | undefined>,
    ) => {
      const newScenarioToLoad = payload || null;

      if (!_.isEqual(state.scenarioToLoad, newScenarioToLoad)) {
        state.scenarioToLoad = newScenarioToLoad;
      }
    },
    setOpenProduct: (state, { payload }: PayloadAction<T.ProductId | null>) => {
      state.openProduct = payload;
    },
    persistCurrentFormValues: (state, { payload }) => {
      // This comment was generated when upgrading react-scripts and eslint
      // TODO: fix the lint rule and remove this eslint-disable comment
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      state.currentFormValues = payload;
    },
    setScenarios: (
      state,
      { payload }: PayloadAction<T.ApplicationScenario[]>,
    ) => {
      const clientScoped = payload.filter((s) => s.scope === "client");
      const userScoped = payload.filter((s) => s.scope === "user");

      clientScoped.sort(sortScenarios);
      userScoped.sort(sortScenarios);

      state.scenarios.clientScoped = clientScoped;
      state.scenarios.userScoped = userScoped;
    },
    setScenarioErrors: (state, { payload }: PayloadAction<unknown>) => {
      state.scenarios.error =
        "An error occurred while loading or saving scenarios.";
    },
    clearScenarioErrors: (state) => {
      state.scenarios.error = null;
    },
  },
});

export const {
  loadScenario,
  enablePriceLocking,
  persistCurrentFormValues,
  exportLoan,
  setOpenProduct,
  setSummary,
} = loansSlice.actions;

export default loansSlice.reducer;

export const loansSelector = (state: { loans: LoansState }) => state.loans;

export const fetchSummary = createAsyncThunk<
  [Promise<T.ExecutionSummary>, () => void],
  {
    currentTime: string;
    pricingProfileId: T.PricingProfileId | null;
    creditApplicationFields: T.FieldValueMapping[];
    extraFields: T.FieldId[];
  }
>(
  "fetchSummary/fetchSummary",
  async (
    { currentTime, pricingProfileId, creditApplicationFields, extraFields },
    thunkAPI,
  ) => {
    const response = Api.executeSummary({
      currentTime,
      pricingProfileId,
      creditApplicationFields,
      outputFieldsFilter: { type: "only", fieldIds: extraFields },
    });

    response[0].then((summary) => {
      thunkAPI.dispatch(loansSlice.actions.setSummary(summary));
    });

    response[0].catch((error) => {
      thunkAPI.dispatch(loansSlice.actions.setScenarioErrors(error));
    });

    return response;
  },
);

export const mostRequiredFieldsSelector = createSelector(
  [
    (state: { loans: LoansState }) => state.loans?.summary?.products,
    nonNullApplicationInitializationSelector,
  ],

  (products) => {
    const log = new Map<T.FieldId, number>();

    products?.forEach((product) => {
      if (product.status === "available") {
        product.requiredFieldIds.forEach((id) => {
          log.set(id, (log.get(id) || 0) + 1);
        });
      }
    });

    const vals = Array.from(log.values());
    const entries = Array.from(log.entries());
    const max = Math.max(...vals);
    const filteredEntries = entries.filter((e) => e[1] === max);

    return filteredEntries.map((e) => e[0]);
  },
);

export const productGroupsSelector = createSelector(
  [filteredSummaryProductsSelector],

  (products) => {
    const approved: T.ExecutionProductSummary[] = [];
    const reviewRequired: T.ExecutionProductSummary[] = [];
    const available: T.ExecutionProductSummary[] = [];
    const rejected: T.ExecutionProductSummary[] = [];
    const error: T.ExecutionProductSummary[] = [];
    const missingConfiguration: T.ExecutionProductSummary[] = [];
    const noPricing: T.ExecutionProductSummary[] = [];

    products?.forEach((product) => {
      switch (product.status) {
        case "approved":
          approved.push(product);
          break;
        case "review-required":
          reviewRequired.push(product);
          break;
        case "available":
          available.push(product);
          break;
        case "rejected":
          rejected.push(product);
          break;
        case "error":
          error.push(product);
          break;
        case "missing-configuration":
          missingConfiguration.push(product);
          break;
        case "no-pricing":
          noPricing.push(product);
          break;
      }
    });

    return {
      approved,
      reviewRequired,
      available,
      rejected,
      error,
      missingConfiguration,
      noPricing,
    };
  },
);

export const loadScenarios = (): AppThunk => {
  return async (dispatch) => {
    dispatch(loansSlice.actions.clearScenarioErrors());
    try {
      const scenarios = await Api.getApplicationScenarios();
      dispatch(loansSlice.actions.setScenarios(scenarios));
    } catch (error) {
      newrelic.noticeError(getErrorMessage(error));
      dispatch(loansSlice.actions.setScenarioErrors(error));
      dispatch(loansSlice.actions.setScenarios([]));
    }
  };
};

export const deleteScenario = (scenario: T.ApplicationScenario): AppThunk => {
  return async (dispatch) => {
    try {
      await Api.deleteApplicationScenario(scenario.id);
      dispatch(loadScenarios());
    } catch (error) {
      newrelic.noticeError(getErrorMessage(error));
      dispatch(loansSlice.actions.setScenarioErrors(error));
    }
  };
};

export const promoteScenarioToClientScope = (
  scenario: T.ApplicationScenario,
): AppThunk => {
  return async (dispatch) => {
    try {
      await Api.promoteScenarioToClientScope(scenario);
      dispatch(loadScenarios());
    } catch (error) {
      newrelic.noticeError(getErrorMessage(error));
      dispatch(loansSlice.actions.setScenarioErrors(error));
    }
  };
};

export const demoteScenarioToUserScope = (
  scenario: T.ApplicationScenario,
): AppThunk => {
  return async (dispatch) => {
    try {
      await Api.demoteScenarioToUserScope(scenario);
      dispatch(loadScenarios());
    } catch (error) {
      newrelic.noticeError(getErrorMessage(error));
      dispatch(loansSlice.actions.setScenarioErrors(error));
    }
  };
};

export const saveScenario = (scenario: T.NewApplicationScenario): AppThunk => {
  return async (dispatch) => {
    try {
      await Api.createApplicationScenario(scenario);
      dispatch(loadScenarios());
    } catch (error) {
      newrelic.noticeError(getErrorMessage(error));
      dispatch(loansSlice.actions.setScenarioErrors(error));
    }
  };
};

const sortScenarios = (
  a: T.ApplicationScenario,
  b: T.ApplicationScenario,
): number => {
  // Scenarios in the same scope are sorted alphabetically
  if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
    return -1;
  } else if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
    return 1;
  } else {
    // Scenarios with the same name are sorted by most recent first
    const aCreatedAt = moment(a.createdAt);
    const bCreatedAt = moment(b.createdAt);
    if (aCreatedAt < bCreatedAt) {
      return 1;
    } else if (aCreatedAt > bCreatedAt) {
      return -1;
    } else {
      return 0;
    }
  }
};

/// Resolve field values with the same effective values used for engine
/// execution. This applies rules related to both default values and field
/// conditions.
///
/// Currently, this is used for evaluating the values sent to the iframe API
/// for price lock messages, as well as resolving the field values to show
/// in exported PDFs.
export function resolveEffectiveFieldValues(
  applicationFields: readonly T.BaseFieldDefinition[],
  currentFormValues: T.FieldValueMapping[],
  defaultFieldValues: T.DefaultFieldValue[],
): T.FieldValueMapping[] {
  const currentFormValuesById = toMapByCustomId("fieldId", currentFormValues);
  const defaultFieldValuesById = toMapByCustomId("fieldId", defaultFieldValues);
  const applicationFieldsById = toMapById(applicationFields);

  const fieldValueStatesById = IMap(
    applicationFields.map((field) => {
      const currentValue = currentFormValuesById.get(field.id);
      const state = currentValue
        ? convertFieldValueToState(currentValue.value)
        : newFieldValueState(field.valueType);
      return [field.id, state];
    }),
  );

  const fieldValues = applicationFields.map((field) => {
    const currentValue = currentFormValuesById.get(field.id);
    const defaultValue = defaultFieldValuesById.get(field.id);
    const condition = evaluateFieldCondition(
      field.id,
      applicationFieldsById,
      fieldValueStatesById,
      defaultFieldValuesById,
    );
    return {
      field,
      currentValue,
      defaultValue,
      condition,
    };
  });

  return fieldValues.flatMap<T.FieldValueMapping>((fieldValue) => {
    if (!fieldValue.condition) {
      // If the field's condition did not pass, do not include it
      return [];
    } else if (fieldValue.defaultValue?.hidden === true) {
      // If the field has a default that is marked hidden, hide it
      return [];
    } else if (fieldValue.currentValue != null) {
      // If the field has a value that the user entered, use it
      return [
        {
          fieldId: fieldValue.field.id,
          value: fieldValue.currentValue.value,
        },
      ];
    } else if (fieldValue.defaultValue != null) {
      // If the field has a default value that the user didn't override, use it
      return [
        {
          fieldId: fieldValue.field.id,
          value: fieldValue.defaultValue.value,
        },
      ];
    } else {
      // Otherwise, don't include the field
      return [];
    }
  });
}

// Get the "parent state" value for a given field, based on the field's
// conditions. This is used primarily for geogrphic location lookup, where
// the "state" field affects the options shown in the "county" field.
export function getParentState(
  conditions: T.FieldCondition[] | null,
  fieldsById: IMap<T.FieldId, T.BaseFieldDefinition>,
  fieldStatesById: IMap<T.FieldId, FieldValueState>,
  defaultFieldValuesByFieldId: IMap<T.FieldId, T.DefaultFieldValue>,
): FieldValueState | null {
  if (!conditions || !conditions.length) {
    return null;
  }

  // Check if parent field is disabled
  // Note: this may recurse infinitely if config is set up incorrectly
  const parentsEnabled = conditions.every((condition) => {
    return evaluateFieldCondition(
      condition.parentFieldId,
      fieldsById,
      fieldStatesById,
      defaultFieldValuesByFieldId,
    );
  });

  if (!parentsEnabled) {
    return null;
  }

  const parentFieldId = conditions[0].parentFieldId;
  const parentField = fieldsById.get(parentFieldId);
  const currentParentFieldState = fieldStatesById.get(parentFieldId);
  const defaultParentField = defaultFieldValuesByFieldId.get(parentFieldId);
  if (currentParentFieldState == null || parentField == null) {
    return null;
  }

  return resolveStateWithDefault(
    parentField,
    currentParentFieldState,
    defaultParentField ?? null,
  );
}

export function evaluateFieldCondition(
  fieldId: T.FieldId,
  fieldsById: IMap<T.FieldId, T.BaseFieldDefinition>,
  fieldStatesById: IMap<T.FieldId, FieldValueState>,
  defaultFieldValuesByFieldId: IMap<T.FieldId, T.DefaultFieldValue>,
): boolean {
  const resolvedFieldStates = resolveStatesWithDefaults(
    fieldsById,
    fieldStatesById,
    defaultFieldValuesByFieldId,
  );
  return evaluateResolvedFieldCondition(
    fieldId,
    fieldsById,
    resolvedFieldStates,
  );
}

// Apply the default field values onto the current list of field values. This
// essentially applies the same logic the engine uses to determine the "final"
// value to use for a given field (choosing between hidden fields, default
// fields, and the field values from the request).
//
// For the canonical implementation, see the Rust function
// `engine::field_transforms::apply_default_field_values`.
function resolveStatesWithDefaults(
  fieldsById: IMap<T.FieldId, T.BaseFieldDefinition>,
  fieldStatesById: IMap<T.FieldId, FieldValueState>,
  defaultFieldValuesByFieldId: IMap<T.FieldId, T.DefaultFieldValue>,
): IMap<T.FieldId, FieldValueState> {
  const resolvedFieldStates = fieldsById.flatMap((field, fieldId) => {
    const currentFieldState = fieldStatesById.get(fieldId);
    const defaultField = defaultFieldValuesByFieldId.get(fieldId);

    if (currentFieldState == null) {
      return [];
    }

    const resolvedState = resolveStateWithDefault(
      field,
      currentFieldState,
      defaultField ?? null,
    );

    return [[fieldId, resolvedState]] as [T.FieldId, FieldValueState][];
  });

  return resolvedFieldStates;
}

function resolveStateWithDefault(
  field: T.BaseFieldDefinition,
  currentFieldState: FieldValueState,
  defaultField: T.DefaultFieldValue | null,
): FieldValueState {
  if (defaultField == null) {
    return currentFieldState;
  }

  const currentValue = convertStateToFieldValue(
    field.valueType,
    currentFieldState,
  );
  const defaultFieldState = convertFieldValueToState(defaultField.value);

  if (currentValue == null) {
    // If there is no current value, use the default field value
    return defaultFieldState;
  } else if (defaultField.hidden) {
    // If the default value is marked as hidden, use the default field
    // value(since the user should not be able to override the value)
    return defaultFieldState;
  } else {
    // Otherwise, use the current value
    return currentFieldState;
  }
}

function evaluateResolvedFieldCondition(
  fieldId: T.FieldId,
  fieldsById: IMap<T.FieldId, T.BaseFieldDefinition>,
  fieldStatesById: IMap<T.FieldId, FieldValueState>,
): boolean {
  const field = fieldsById.get(fieldId);
  const conditions = field?.conditions;

  if (!conditions || !conditions.length) {
    return true;
  }

  return conditions?.every((condition) => {
    switch (condition.type) {
      case "parent-field-is-blank": {
        // Check if parent field is disabled
        // Note: this may recurse infinitely if config is set up incorrectly
        if (
          !evaluateResolvedFieldCondition(
            condition.parentFieldId,
            fieldsById,
            fieldStatesById,
          )
        ) {
          // Disable field if parent is disabled
          return false;
        }

        const parentState = fieldStatesById.get(condition.parentFieldId);
        if (!parentState) {
          return false;
        }

        const parentValueType = fieldsById.get(
          condition.parentFieldId,
        )!.valueType;

        try {
          const parentValue = convertStateToFieldValue(
            parentValueType,
            parentState,
          );
          return !parentValue;
        } catch (err) {
          if (err instanceof UiValidationError) {
            newrelic.noticeError(err);
            return false;
          }

          throw err;
        }
      }
      case "parent-field-is-not-blank": {
        // Check if parent field is disabled
        // Note: this may recurse infinitely if config is set up incorrectly
        if (
          !evaluateResolvedFieldCondition(
            condition.parentFieldId,
            fieldsById,
            fieldStatesById,
          )
        ) {
          // Disable field if parent is disabled
          return false;
        }

        const parentState = fieldStatesById.get(condition.parentFieldId);
        if (!parentState) {
          return false;
        }

        const parentValueType = fieldsById.get(
          condition.parentFieldId,
        )!.valueType;

        try {
          const parentValue = convertStateToFieldValue(
            parentValueType,
            parentState,
          );
          return !!parentValue;
        } catch (err) {
          if (err instanceof UiValidationError) {
            newrelic.noticeError(err);
            return false;
          }

          throw err;
        }
      }
      case "parent-field-has-value": {
        // Check if parent field is disabled
        // Note: this may recurse infinitely if config is set up incorrectly
        if (
          !evaluateResolvedFieldCondition(
            condition.parentFieldId,
            fieldsById,
            fieldStatesById,
          )
        ) {
          // Disable field if parent is disabled
          return false;
        }

        const parentState = fieldStatesById.get(condition.parentFieldId);
        if (!parentState) {
          return false;
        }

        const parentValueType = fieldsById.get(
          condition.parentFieldId,
        )!.valueType;

        try {
          const parentValue = convertStateToFieldValue(
            parentValueType,
            parentState,
          );
          if (!parentValue) {
            return false;
          }

          return fieldValuesEqual(parentValue, condition.value);
        } catch (err) {
          if (err instanceof UiValidationError) {
            newrelic.noticeError(err);
            return false;
          }

          throw err;
        }
      }
      case "parent-field-has-not-value": {
        // Check if parent field is disabled
        // Note: this may recurse infinitely if config is set up incorrectly
        if (
          !evaluateResolvedFieldCondition(
            condition.parentFieldId,
            fieldsById,
            fieldStatesById,
          )
        ) {
          // Disable field if parent is disabled
          return false;
        }

        const parentState = fieldStatesById.get(condition.parentFieldId);
        if (!parentState) {
          return false;
        }

        const parentValueType = fieldsById.get(
          condition.parentFieldId,
        )!.valueType;

        try {
          const parentValue = convertStateToFieldValue(
            parentValueType,
            parentState,
          );
          if (!parentValue) {
            return false;
          }

          return !fieldValuesEqual(parentValue, condition.value);
        } catch (err) {
          if (err instanceof UiValidationError) {
            newrelic.noticeError(err);
            return false;
          }

          throw err;
        }
      }
      case "parent-field-is-one-of": {
        // // Check if parent field is disabled
        // // Note: this may recurse infinitely if config is set up incorrectly
        if (
          !evaluateResolvedFieldCondition(
            condition.parentFieldId,
            fieldsById,
            fieldStatesById,
          )
        ) {
          // Disable field if parent is disabled
          return false;
        }

        const parentState = fieldStatesById.get(condition.parentFieldId);
        if (!parentState) {
          return false;
        }

        const parentValueType = fieldsById.get(
          condition.parentFieldId,
        )!.valueType;

        try {
          const parentValue = convertStateToFieldValue(
            parentValueType,
            parentState,
          );
          if (!parentValue) {
            return false;
          }

          return (
            parentValue.type === "enum" &&
            parentValue.variantId &&
            condition.values.filter(
              (v) => v.type === "enum" && parentValue.variantId === v.variantId,
            ).length
          );
        } catch (err) {
          if (err instanceof UiValidationError) {
            newrelic.noticeError(err);
            return false;
          }

          throw err;
        }
      }
      case "parent-field-is-not-one-of": {
        // Check if parent field is disabled
        // Note: this may recurse infinitely if config is set up incorrectly
        if (
          !evaluateResolvedFieldCondition(
            condition.parentFieldId,
            fieldsById,
            fieldStatesById,
          )
        ) {
          // Disable field if parent is disabled
          return false;
        }

        const parentState = fieldStatesById.get(condition.parentFieldId);
        if (!parentState) {
          return false;
        }

        const parentValueType = fieldsById.get(
          condition.parentFieldId,
        )!.valueType;

        try {
          const parentValue = convertStateToFieldValue(
            parentValueType,
            parentState,
          );
          if (!parentValue) {
            return false;
          }

          return (
            parentValue.type === "enum" &&
            parentValue.variantId &&
            !condition.values.filter(
              (v) => v.type === "enum" && parentValue.variantId === v.variantId,
            ).length
          );
        } catch (err) {
          if (err instanceof UiValidationError) {
            newrelic.noticeError(err);
            return false;
          }

          throw err;
        }
      }
      default:
        throw new Error(`Unsupported field condition type`);
    }
  });
}

export type RejectionReasonInfo = {
  title: string;
  cause: { name: string; link: string } | null;
};

export type MissingFieldInfo = {
  fieldId: T.FieldId;
  fieldName: string;
  causes: MissingFieldInfoCause[];
};

export type MissingFieldInfoCause = {
  title: string;
  link: string | null;
};

export function loanStatusToString(
  status: T.ProductSummaryStatus["status"],
): string {
  switch (status) {
    case "approved":
      return "Approved";
    case "error":
      return "Error";
    case "rejected":
      return "Rejected";
    case "review-required":
      return "Review Required";
    case "missing-configuration":
      return "Missing Configuration";
    case "no-pricing":
      return "No Rate Sheet";
    case "available":
      return "Data Required";
    default:
      unreachable(status);
  }
}

export function convertExecutionErrorToString(
  err: T.ExecutionError,
  allFieldsById: IMap<T.FieldId, T.BaseFieldDefinition>,
): string {
  switch (err.type) {
    case "blank-field":
      return (
        "Missing field: " +
        (allFieldsById.get(err.fieldId)?.name || "<unknown field>")
      );
    case "internal":
      return "Internal error: " + err.message;
  }
}

export function getMissingFieldInfo(
  config: Configuration,
  rulesById: IMap<T.RuleId, T.DecoratedRuleHeader>,
  sourcesByFieldId: IMap<T.FieldId, ISet<T.ExecutionErrorSource>>,
  accessId: string,
): MissingFieldInfo[] {
  function causeToInfo(cause: T.ExecutionErrorSource): MissingFieldInfoCause {
    switch (cause.type) {
      case "filter":
        return {
          title: "a product requirement",
          link: null,
        };
      case "rule":
        return {
          title: rulesById.get(cause.ruleId)?.name || "<unknown rule>",
          link: `/c/${accessId}/rules/${cause.ruleId}`,
        };
      case "pricing-calculation":
        return {
          title: "a pricing calculation",
          link: null,
        };
      case "calculated-field":
        // This should never happen?
        throw new Error(
          "cannot handle blank field error from calculated field",
        );
      case "data-table-lookup":
        // TODO: I think this can happen if a lookup fails to find a matching row
        throw new Error(
          "cannot handle blank field errors from data table lookups yet",
        );
    }
  }

  return _.sortBy(
    sourcesByFieldId.toArray().map(([fieldId, causes]) => {
      const field = config.allFieldsById.get(fieldId);
      const fieldName = field?.name || "<unknown field>";

      return {
        fieldId,
        fieldName,
        causes: _.sortBy(causes.toArray().map(causeToInfo), "title"),
      };
    }),
    "fieldName",
  );
}

export function getRejectionReasonInfo(
  config: Configuration,
  rulesById: IMap<T.RuleId, T.DecoratedRuleHeader>,
  rejections: T.Rejection[] | T.ReviewRequirement[],
): RejectionReasonInfo[] {
  function toInfo(rejection: T.Rejection): RejectionReasonInfo {
    const accessId = localAccessId();
    switch (rejection.source.type) {
      case "filter":
        // TODO remove when removed from engine-types
        throw new Error("filter rejections are unimplemented");
      case "rule":
        const rule = rulesById.get(rejection.source.ruleId);
        const ruleName = rule?.name || "<unknown rule>";

        return {
          title: rejection.message,
          cause: {
            name: ruleName,
            link: `/c/${accessId}/rules/${rejection.source.ruleId}`,
          },
        };
    }
  }

  return _.sortBy(rejections.map(toInfo), "title");
}
