import _ from "lodash";
import { Map as IMap } from "immutable";

import {
  ALL_US_STATES,
  UsState,
  findUsStateByTwoDigitCode,
} from "features/us-states";

export type UsCounty = {
  name: string;
  fiveDigitCode: string;
  usState: UsState;
};

export const ALL_US_COUNTIES: readonly UsCounty[] = makeCounties([
  ["Abbeville County", "45001"],
  ["Acadia Parish", "22001"],
  ["Accomack County", "51001"],
  ["Ada County", "16001"],
  ["Adair County", "19001"],
  ["Adair County", "21001"],
  ["Adair County", "29001"],
  ["Adair County", "40001"],
  ["Adams County", "08001"],
  ["Adams County", "19003"],
  ["Adams County", "16003"],
  ["Adams County", "17001"],
  ["Adams County", "18001"],
  ["Adams County", "28001"],
  ["Adams County", "38001"],
  ["Adams County", "31001"],
  ["Adams County", "39001"],
  ["Adams County", "42001"],
  ["Adams County", "53001"],
  ["Adams County", "55001"],
  ["Addison County", "50001"],
  ["Adjuntas Municipio", "72001"],
  ["Aguada Municipio", "72003"],
  ["Aguadilla Municipio", "72005"],
  ["Aguas Buenas Municipio", "72007"],
  ["Aibonito Municipio", "72009"],
  ["Aiken County", "45003"],
  ["Aitkin County", "27001"],
  ["Alachua County", "12001"],
  ["Alamance County", "37001"],
  ["Alameda County", "06001"],
  ["Alamosa County", "08003"],
  ["Albany County", "36001"],
  ["Albany County", "56001"],
  ["Albemarle County", "51003"],
  ["Alcona County", "26001"],
  ["Alcorn County", "28003"],
  ["Aleutians East Borough", "02013"],
  ["Aleutians West Census Area", "02016"],
  ["Alexander County", "17003"],
  ["Alexander County", "37003"],
  ["Alexandria City", "51510"],
  ["Alfalfa County", "40003"],
  ["Alger County", "26003"],
  ["Allamakee County", "19005"],
  ["Allegan County", "26005"],
  ["Allegany County", "24001"],
  ["Allegany County", "36003"],
  ["Alleghany County", "37005"],
  ["Alleghany County", "51005"],
  ["Allegheny County", "42003"],
  ["Allen County", "18003"],
  ["Allen County", "20001"],
  ["Allen County", "21003"],
  ["Allen County", "39003"],
  ["Allen Parish", "22003"],
  ["Allendale County", "45005"],
  ["Alpena County", "26007"],
  ["Alpine County", "06003"],
  ["Amador County", "06005"],
  ["Amelia County", "51007"],
  ["Amherst County", "51009"],
  ["Amite County", "28005"],
  ["Anasco Municipio", "72011"],
  ["Anchorage Municipality", "02020"],
  ["Anderson County", "20003"],
  ["Anderson County", "21005"],
  ["Anderson County", "45007"],
  ["Anderson County", "47001"],
  ["Anderson County", "48001"],
  ["Andrew County", "29003"],
  ["Andrews County", "48003"],
  ["Androscoggin County", "23001"],
  ["Angelina County", "48005"],
  ["Anne Arundel County", "24003"],
  ["Anoka County", "27003"],
  ["Anson County", "37007"],
  ["Antelope County", "31003"],
  ["Antrim County", "26009"],
  ["Apache County", "04001"],
  ["Appanoose County", "19007"],
  ["Appling County", "13001"],
  ["Appomattox County", "51011"],
  ["Aransas County", "48007"],
  ["Arapahoe County", "08005"],
  ["Archer County", "48009"],
  ["Archuleta County", "08007"],
  ["Arecibo Municipio", "72013"],
  ["Arenac County", "26011"],
  ["Arkansas County", "05001"],
  ["Arlington County", "51013"],
  ["Armstrong County", "42005"],
  ["Armstrong County", "48011"],
  ["Aroostook County", "23003"],
  ["Arroyo Municipio", "72015"],
  ["Arthur County", "31005"],
  ["Ascension Parish", "22005"],
  ["Ashe County", "37009"],
  ["Ashland County", "39005"],
  ["Ashland County", "55003"],
  ["Ashley County", "05003"],
  ["Ashtabula County", "39007"],
  ["Asotin County", "53003"],
  ["Assumption Parish", "22007"],
  ["Atascosa County", "48013"],
  ["Atchison County", "20005"],
  ["Atchison County", "29005"],
  ["Athens County", "39009"],
  ["Atkinson County", "13003"],
  ["Atlantic County", "34001"],
  ["Atoka County", "40005"],
  ["Attala County", "28007"],
  ["Audrain County", "29007"],
  ["Audubon County", "19009"],
  ["Auglaize County", "39011"],
  ["Augusta County", "51015"],
  ["Aurora County", "46003"],
  ["Austin County", "48015"],
  ["Autauga County", "01001"],
  ["Avery County", "37011"],
  ["Avoyelles Parish", "22009"],
  ["Baca County", "08009"],
  ["Bacon County", "13005"],
  ["Bailey County", "48017"],
  ["Baker County", "12003"],
  ["Baker County", "13007"],
  ["Baker County", "41001"],
  ["Baldwin County", "01003"],
  ["Baldwin County", "13009"],
  ["Ballard County", "21007"],
  ["Baltimore City", "24510"],
  ["Baltimore County", "24005"],
  ["Bamberg County", "45009"],
  ["Bandera County", "48019"],
  ["Banks County", "13011"],
  ["Banner County", "31007"],
  ["Bannock County", "16005"],
  ["Baraga County", "26013"],
  ["Barber County", "20007"],
  ["Barbour County", "01005"],
  ["Barbour County", "54001"],
  ["Barceloneta Municipio", "72017"],
  ["Barnes County", "38003"],
  ["Barnstable County", "25001"],
  ["Barnwell County", "45011"],
  ["Barranquitas Municipio", "72019"],
  ["Barren County", "21009"],
  ["Barron County", "55005"],
  ["Barrow County", "13013"],
  ["Barry County", "26015"],
  ["Barry County", "29009"],
  ["Bartholomew County", "18005"],
  ["Barton County", "20009"],
  ["Barton County", "29011"],
  ["Bartow County", "13015"],
  ["Bastrop County", "48021"],
  ["Bates County", "29013"],
  ["Bath County", "21011"],
  ["Bath County", "51017"],
  ["Baxter County", "05005"],
  ["Bay County", "12005"],
  ["Bay County", "26017"],
  ["Bayamon Municipio", "72021"],
  ["Bayfield County", "55007"],
  ["Baylor County", "48023"],
  ["Beadle County", "46005"],
  ["Bear Lake County", "16007"],
  ["Beaufort County", "37013"],
  ["Beaufort County", "45013"],
  ["Beauregard Parish", "22011"],
  ["Beaver County", "40007"],
  ["Beaver County", "42007"],
  ["Beaver County", "49001"],
  ["Beaverhead County", "30001"],
  ["Becker County", "27005"],
  ["Beckham County", "40009"],
  ["Bedford County", "42009"],
  ["Bedford County", "47003"],
  ["Bedford County", "51019"],
  ["Bee County", "48025"],
  ["Belknap County", "33001"],
  ["Bell County", "21013"],
  ["Bell County", "48027"],
  ["Belmont County", "39013"],
  ["Beltrami County", "27007"],
  ["Ben Hill County", "13017"],
  ["Benewah County", "16009"],
  ["Bennett County", "46007"],
  ["Bennington County", "50003"],
  ["Benson County", "38005"],
  ["Bent County", "08011"],
  ["Benton County", "05007"],
  ["Benton County", "19011"],
  ["Benton County", "18007"],
  ["Benton County", "27009"],
  ["Benton County", "29015"],
  ["Benton County", "28009"],
  ["Benton County", "41003"],
  ["Benton County", "47005"],
  ["Benton County", "53005"],
  ["Benzie County", "26019"],
  ["Bergen County", "34003"],
  ["Berkeley County", "45015"],
  ["Berkeley County", "54003"],
  ["Berks County", "42011"],
  ["Berkshire County", "25003"],
  ["Bernalillo County", "35001"],
  ["Berrien County", "13019"],
  ["Berrien County", "26021"],
  ["Bertie County", "37015"],
  ["Bethel Census Area", "02050"],
  ["Bexar County", "48029"],
  ["Bibb County", "01007"],
  ["Bibb County", "13021"],
  ["Bienville Parish", "22013"],
  ["Big Horn County", "30003"],
  ["Big Horn County", "56003"],
  ["Big Stone County", "27011"],
  ["Billings County", "38007"],
  ["Bingham County", "16011"],
  ["Black Hawk County", "19013"],
  ["Blackford County", "18009"],
  ["Bladen County", "37017"],
  ["Blaine County", "16013"],
  ["Blaine County", "30005"],
  ["Blaine County", "31009"],
  ["Blaine County", "40011"],
  ["Blair County", "42013"],
  ["Blanco County", "48031"],
  ["Bland County", "51021"],
  ["Bleckley County", "13023"],
  ["Bledsoe County", "47007"],
  ["Blount County", "01009"],
  ["Blount County", "47009"],
  ["Blue Earth County", "27013"],
  ["Boise County", "16015"],
  ["Bolivar County", "28011"],
  ["Bollinger County", "29017"],
  ["Bon Homme County", "46009"],
  ["Bond County", "17005"],
  ["Bonner County", "16017"],
  ["Bonneville County", "16019"],
  ["Boone County", "05009"],
  ["Boone County", "19015"],
  ["Boone County", "17007"],
  ["Boone County", "18011"],
  ["Boone County", "21015"],
  ["Boone County", "29019"],
  ["Boone County", "31011"],
  ["Boone County", "54005"],
  ["Borden County", "48033"],
  ["Bosque County", "48035"],
  ["Bossier Parish", "22015"],
  ["Botetourt County", "51023"],
  ["Bottineau County", "38009"],
  ["Boulder County", "08013"],
  ["Boundary County", "16021"],
  ["Bourbon County", "20011"],
  ["Bourbon County", "21017"],
  ["Bowie County", "48037"],
  ["Bowman County", "38011"],
  ["Box Butte County", "31013"],
  ["Box Elder County", "49003"],
  ["Boyd County", "21019"],
  ["Boyd County", "31015"],
  ["Boyle County", "21021"],
  ["Bracken County", "21023"],
  ["Bradford County", "12007"],
  ["Bradford County", "42015"],
  ["Bradley County", "05011"],
  ["Bradley County", "47011"],
  ["Branch County", "26023"],
  ["Brantley County", "13025"],
  ["Braxton County", "54007"],
  ["Brazoria County", "48039"],
  ["Brazos County", "48041"],
  ["Breathitt County", "21025"],
  ["Breckinridge County", "21027"],
  ["Bremer County", "19017"],
  ["Brevard County", "12009"],
  ["Brewster County", "48043"],
  ["Briscoe County", "48045"],
  ["Bristol Bay Borough", "02060"],
  ["Bristol City", "51520"],
  ["Bristol County", "25005"],
  ["Bristol County", "44001"],
  ["Broadwater County", "30007"],
  ["Bronx County", "36005"],
  ["Brooke County", "54009"],
  ["Brookings County", "46011"],
  ["Brooks County", "13027"],
  ["Brooks County", "48047"],
  ["Broome County", "36007"],
  ["Broomfield County", "08014"],
  ["Broward County", "12011"],
  ["Brown County", "17009"],
  ["Brown County", "18013"],
  ["Brown County", "20013"],
  ["Brown County", "27015"],
  ["Brown County", "31017"],
  ["Brown County", "39015"],
  ["Brown County", "46013"],
  ["Brown County", "48049"],
  ["Brown County", "55009"],
  ["Brule County", "46015"],
  ["Brunswick County", "37019"],
  ["Brunswick County", "51025"],
  ["Bryan County", "13029"],
  ["Bryan County", "40013"],
  ["Buchanan County", "19019"],
  ["Buchanan County", "29021"],
  ["Buchanan County", "51027"],
  ["Buckingham County", "51029"],
  ["Bucks County", "42017"],
  ["Buena Vista City", "51530"],
  ["Buena Vista County", "19021"],
  ["Buffalo County", "31019"],
  ["Buffalo County", "46017"],
  ["Buffalo County", "55011"],
  ["Bullitt County", "21029"],
  ["Bulloch County", "13031"],
  ["Bullock County", "01011"],
  ["Buncombe County", "37021"],
  ["Bureau County", "17011"],
  ["Burke County", "13033"],
  ["Burke County", "37023"],
  ["Burke County", "38013"],
  ["Burleigh County", "38015"],
  ["Burleson County", "48051"],
  ["Burlington County", "34005"],
  ["Burnet County", "48053"],
  ["Burnett County", "55013"],
  ["Burt County", "31021"],
  ["Butler County", "01013"],
  ["Butler County", "19023"],
  ["Butler County", "20015"],
  ["Butler County", "21031"],
  ["Butler County", "29023"],
  ["Butler County", "31023"],
  ["Butler County", "39017"],
  ["Butler County", "42019"],
  ["Butte County", "06007"],
  ["Butte County", "16023"],
  ["Butte County", "46019"],
  ["Butts County", "13035"],
  ["Cabarrus County", "37025"],
  ["Cabell County", "54011"],
  ["Cabo Rojo Municipio", "72023"],
  ["Cache County", "49005"],
  ["Caddo County", "40015"],
  ["Caddo Parish", "22017"],
  ["Caguas Municipio", "72025"],
  ["Calaveras County", "06009"],
  ["Calcasieu Parish", "22019"],
  ["Caldwell County", "21033"],
  ["Caldwell County", "29025"],
  ["Caldwell County", "37027"],
  ["Caldwell County", "48055"],
  ["Caldwell Parish", "22021"],
  ["Caledonia County", "50005"],
  ["Calhoun County", "01015"],
  ["Calhoun County", "05013"],
  ["Calhoun County", "12013"],
  ["Calhoun County", "13037"],
  ["Calhoun County", "19025"],
  ["Calhoun County", "17013"],
  ["Calhoun County", "26025"],
  ["Calhoun County", "28013"],
  ["Calhoun County", "45017"],
  ["Calhoun County", "48057"],
  ["Calhoun County", "54013"],
  ["Callahan County", "48059"],
  ["Callaway County", "29027"],
  ["Calloway County", "21035"],
  ["Calumet County", "55015"],
  ["Calvert County", "24009"],
  ["Camas County", "16025"],
  ["Cambria County", "42021"],
  ["Camden County", "13039"],
  ["Camden County", "29029"],
  ["Camden County", "37029"],
  ["Camden County", "34007"],
  ["Cameron County", "42023"],
  ["Cameron County", "48061"],
  ["Cameron Parish", "22023"],
  ["Camp County", "48063"],
  ["Campbell County", "21037"],
  ["Campbell County", "46021"],
  ["Campbell County", "47013"],
  ["Campbell County", "51031"],
  ["Campbell County", "56005"],
  ["Camuy Municipio", "72027"],
  ["Canadian County", "40017"],
  ["Candler County", "13043"],
  ["Cannon County", "47015"],
  ["Canovanas Municipio", "72029"],
  ["Canyon County", "16027"],
  ["Cape Girardeau County", "29031"],
  ["Cape May County", "34009"],
  ["Carbon County", "30009"],
  ["Carbon County", "42025"],
  ["Carbon County", "49007"],
  ["Carbon County", "56007"],
  ["Caribou County", "16029"],
  ["Carlisle County", "21039"],
  ["Carlton County", "27017"],
  ["Carolina Municipio", "72031"],
  ["Caroline County", "24011"],
  ["Caroline County", "51033"],
  ["Carroll County", "05015"],
  ["Carroll County", "13045"],
  ["Carroll County", "19027"],
  ["Carroll County", "17015"],
  ["Carroll County", "18015"],
  ["Carroll County", "21041"],
  ["Carroll County", "24013"],
  ["Carroll County", "29033"],
  ["Carroll County", "28015"],
  ["Carroll County", "33003"],
  ["Carroll County", "39019"],
  ["Carroll County", "47017"],
  ["Carroll County", "51035"],
  ["Carson City", "32510"],
  ["Carson County", "48065"],
  ["Carter County", "21043"],
  ["Carter County", "29035"],
  ["Carter County", "30011"],
  ["Carter County", "40019"],
  ["Carter County", "47019"],
  ["Carteret County", "37031"],
  ["Carver County", "27019"],
  ["Cascade County", "30013"],
  ["Casey County", "21045"],
  ["Cass County", "19029"],
  ["Cass County", "17017"],
  ["Cass County", "18017"],
  ["Cass County", "26027"],
  ["Cass County", "27021"],
  ["Cass County", "29037"],
  ["Cass County", "38017"],
  ["Cass County", "31025"],
  ["Cass County", "48067"],
  ["Cassia County", "16031"],
  ["Castro County", "48069"],
  ["Caswell County", "37033"],
  ["Catahoula Parish", "22025"],
  ["Catano Municipio", "72033"],
  ["Catawba County", "37035"],
  ["Catoosa County", "13047"],
  ["Catron County", "35003"],
  ["Cattaraugus County", "36009"],
  ["Cavalier County", "38019"],
  ["Cayey Municipio", "72035"],
  ["Cayuga County", "36011"],
  ["Cecil County", "24015"],
  ["Cedar County", "19031"],
  ["Cedar County", "29039"],
  ["Cedar County", "31027"],
  ["Ceiba Municipio", "72037"],
  ["Centre County", "42027"],
  ["Cerro Gordo County", "19033"],
  ["Chaffee County", "08015"],
  ["Chambers County", "01017"],
  ["Chambers County", "48071"],
  ["Champaign County", "17019"],
  ["Champaign County", "39021"],
  ["Chariton County", "29041"],
  ["Charles City County", "51036"],
  ["Charles County", "24017"],
  ["Charles Mix County", "46023"],
  ["Charleston County", "45019"],
  ["Charlevoix County", "26029"],
  ["Charlotte County", "12015"],
  ["Charlotte County", "51037"],
  ["Charlottesville City", "51540"],
  ["Charlton County", "13049"],
  ["Chase County", "20017"],
  ["Chase County", "31029"],
  ["Chatham County", "13051"],
  ["Chatham County", "37037"],
  ["Chattahoochee County", "13053"],
  ["Chattooga County", "13055"],
  ["Chautauqua County", "20019"],
  ["Chautauqua County", "36013"],
  ["Chaves County", "35005"],
  ["Cheatham County", "47021"],
  ["Cheboygan County", "26031"],
  ["Chelan County", "53007"],
  ["Chemung County", "36015"],
  ["Chenango County", "36017"],
  ["Cherokee County", "01019"],
  ["Cherokee County", "13057"],
  ["Cherokee County", "19035"],
  ["Cherokee County", "20021"],
  ["Cherokee County", "37039"],
  ["Cherokee County", "40021"],
  ["Cherokee County", "45021"],
  ["Cherokee County", "48073"],
  ["Cherry County", "31031"],
  ["Chesapeake City", "51550"],
  ["Cheshire County", "33005"],
  ["Chester County", "42029"],
  ["Chester County", "45023"],
  ["Chester County", "47023"],
  ["Chesterfield County", "45025"],
  ["Chesterfield County", "51041"],
  ["Cheyenne County", "08017"],
  ["Cheyenne County", "20023"],
  ["Cheyenne County", "31033"],
  ["Chickasaw County", "19037"],
  ["Chickasaw County", "28017"],
  ["Chicot County", "05017"],
  ["Childress County", "48075"],
  ["Chilton County", "01021"],
  ["Chippewa County", "26033"],
  ["Chippewa County", "27023"],
  ["Chippewa County", "55017"],
  ["Chisago County", "27025"],
  ["Chittenden County", "50007"],
  ["Choctaw County", "01023"],
  ["Choctaw County", "28019"],
  ["Choctaw County", "40023"],
  ["Chouteau County", "30015"],
  ["Chowan County", "37041"],
  ["Christian County", "17021"],
  ["Christian County", "21047"],
  ["Christian County", "29043"],
  ["Churchill County", "32001"],
  ["Ciales Municipio", "72039"],
  ["Cibola County", "35006"],
  ["Cidra Municipio", "72041"],
  ["Cimarron County", "40025"],
  ["Citrus County", "12017"],
  ["Clackamas County", "41005"],
  ["Claiborne County", "28021"],
  ["Claiborne County", "47025"],
  ["Claiborne Parish", "22027"],
  ["Clallam County", "53009"],
  ["Clare County", "26035"],
  ["Clarendon County", "45027"],
  ["Clarion County", "42031"],
  ["Clark County", "05019"],
  ["Clark County", "16033"],
  ["Clark County", "17023"],
  ["Clark County", "18019"],
  ["Clark County", "20025"],
  ["Clark County", "21049"],
  ["Clark County", "29045"],
  ["Clark County", "32003"],
  ["Clark County", "39023"],
  ["Clark County", "46025"],
  ["Clark County", "53011"],
  ["Clark County", "55019"],
  ["Clarke County", "01025"],
  ["Clarke County", "13059"],
  ["Clarke County", "19039"],
  ["Clarke County", "28023"],
  ["Clarke County", "51043"],
  ["Clatsop County", "41007"],
  ["Clay County", "01027"],
  ["Clay County", "05021"],
  ["Clay County", "12019"],
  ["Clay County", "13061"],
  ["Clay County", "19041"],
  ["Clay County", "17025"],
  ["Clay County", "18021"],
  ["Clay County", "20027"],
  ["Clay County", "21051"],
  ["Clay County", "27027"],
  ["Clay County", "29047"],
  ["Clay County", "28025"],
  ["Clay County", "37043"],
  ["Clay County", "31035"],
  ["Clay County", "46027"],
  ["Clay County", "47027"],
  ["Clay County", "48077"],
  ["Clay County", "54015"],
  ["Clayton County", "13063"],
  ["Clayton County", "19043"],
  ["Clear Creek County", "08019"],
  ["Clearfield County", "42033"],
  ["Clearwater County", "16035"],
  ["Clearwater County", "27029"],
  ["Cleburne County", "01029"],
  ["Cleburne County", "05023"],
  ["Clermont County", "39025"],
  ["Cleveland County", "05025"],
  ["Cleveland County", "37045"],
  ["Cleveland County", "40027"],
  ["Clinch County", "13065"],
  ["Clinton County", "19045"],
  ["Clinton County", "17027"],
  ["Clinton County", "18023"],
  ["Clinton County", "21053"],
  ["Clinton County", "26037"],
  ["Clinton County", "29049"],
  ["Clinton County", "36019"],
  ["Clinton County", "39027"],
  ["Clinton County", "42035"],
  ["Cloud County", "20029"],
  ["Coahoma County", "28027"],
  ["Coal County", "40029"],
  ["Coamo Municipio", "72043"],
  ["Cobb County", "13067"],
  ["Cochise County", "04003"],
  ["Cochran County", "48079"],
  ["Cocke County", "47029"],
  ["Coconino County", "04005"],
  ["Codington County", "46029"],
  ["Coffee County", "01031"],
  ["Coffee County", "13069"],
  ["Coffee County", "47031"],
  ["Coffey County", "20031"],
  ["Coke County", "48081"],
  ["Colbert County", "01033"],
  ["Cole County", "29051"],
  ["Coleman County", "48083"],
  ["Coles County", "17029"],
  ["Colfax County", "31037"],
  ["Colfax County", "35007"],
  ["Colleton County", "45029"],
  ["Collier County", "12021"],
  ["Collin County", "48085"],
  ["Collingsworth County", "48087"],
  ["Colonial Heights City", "51570"],
  ["Colorado County", "48089"],
  ["Colquitt County", "13071"],
  ["Columbia County", "05027"],
  ["Columbia County", "12023"],
  ["Columbia County", "13073"],
  ["Columbia County", "36021"],
  ["Columbia County", "41009"],
  ["Columbia County", "42037"],
  ["Columbia County", "53013"],
  ["Columbia County", "55021"],
  ["Columbiana County", "39029"],
  ["Columbus County", "37047"],
  ["Colusa County", "06011"],
  ["Comal County", "48091"],
  ["Comanche County", "20033"],
  ["Comanche County", "40031"],
  ["Comanche County", "48093"],
  ["Comerio Municipio", "72045"],
  ["Concho County", "48095"],
  ["Concordia Parish", "22029"],
  ["Conecuh County", "01035"],
  ["Conejos County", "08021"],
  ["Contra Costa County", "06013"],
  ["Converse County", "56009"],
  ["Conway County", "05029"],
  ["Cook County", "13075"],
  ["Cook County", "17031"],
  ["Cook County", "27031"],
  ["Cooke County", "48097"],
  ["Cooper County", "29053"],
  ["Coos County", "33007"],
  ["Coos County", "41011"],
  ["Coosa County", "01037"],
  ["Copiah County", "28029"],
  ["Corozal Municipio", "72047"],
  ["Corson County", "46031"],
  ["Cortland County", "36023"],
  ["Coryell County", "48099"],
  ["Coshocton County", "39031"],
  ["Costilla County", "08023"],
  ["Cottle County", "48101"],
  ["Cotton County", "40033"],
  ["Cottonwood County", "27033"],
  ["Covington City", "51580"],
  ["Covington County", "01039"],
  ["Covington County", "28031"],
  ["Coweta County", "13077"],
  ["Cowley County", "20035"],
  ["Cowlitz County", "53015"],
  ["Craig County", "40035"],
  ["Craig County", "51045"],
  ["Craighead County", "05031"],
  ["Crane County", "48103"],
  ["Craven County", "37049"],
  ["Crawford County", "05033"],
  ["Crawford County", "13079"],
  ["Crawford County", "19047"],
  ["Crawford County", "17033"],
  ["Crawford County", "18025"],
  ["Crawford County", "20037"],
  ["Crawford County", "26039"],
  ["Crawford County", "29055"],
  ["Crawford County", "39033"],
  ["Crawford County", "42039"],
  ["Crawford County", "55023"],
  ["Creek County", "40037"],
  ["Crenshaw County", "01041"],
  ["Crisp County", "13081"],
  ["Crittenden County", "05035"],
  ["Crittenden County", "21055"],
  ["Crockett County", "47033"],
  ["Crockett County", "48105"],
  ["Crook County", "41013"],
  ["Crook County", "56011"],
  ["Crosby County", "48107"],
  ["Cross County", "05037"],
  ["Crow Wing County", "27035"],
  ["Crowley County", "08025"],
  ["Culberson County", "48109"],
  ["Culebra Municipio", "72049"],
  ["Cullman County", "01043"],
  ["Culpeper County", "51047"],
  ["Cumberland County", "17035"],
  ["Cumberland County", "21057"],
  ["Cumberland County", "23005"],
  ["Cumberland County", "37051"],
  ["Cumberland County", "34011"],
  ["Cumberland County", "42041"],
  ["Cumberland County", "47035"],
  ["Cumberland County", "51049"],
  ["Cuming County", "31039"],
  ["Currituck County", "37053"],
  ["Curry County", "35009"],
  ["Curry County", "41015"],
  ["Custer County", "08027"],
  ["Custer County", "16037"],
  ["Custer County", "30017"],
  ["Custer County", "31041"],
  ["Custer County", "40039"],
  ["Custer County", "46033"],
  ["Cuyahoga County", "39035"],
  ["Dade County", "13083"],
  ["Dade County", "29057"],
  ["Daggett County", "49009"],
  ["Dakota County", "27037"],
  ["Dakota County", "31043"],
  ["Dale County", "01045"],
  ["Dallam County", "48111"],
  ["Dallas County", "01047"],
  ["Dallas County", "05039"],
  ["Dallas County", "19049"],
  ["Dallas County", "29059"],
  ["Dallas County", "48113"],
  ["Dane County", "55025"],
  ["Daniels County", "30019"],
  ["Danville City", "51590"],
  ["Dare County", "37055"],
  ["Darke County", "39037"],
  ["Darlington County", "45031"],
  ["Dauphin County", "42043"],
  ["Davidson County", "37057"],
  ["Davidson County", "47037"],
  ["Davie County", "37059"],
  ["Daviess County", "18027"],
  ["Daviess County", "21059"],
  ["Daviess County", "29061"],
  ["Davis County", "19051"],
  ["Davis County", "49011"],
  ["Davison County", "46035"],
  ["Dawes County", "31045"],
  ["Dawson County", "13085"],
  ["Dawson County", "30021"],
  ["Dawson County", "31047"],
  ["Dawson County", "48115"],
  ["Day County", "46037"],
  ["De Baca County", "35011"],
  ["De Soto Parish", "22031"],
  ["De Witt County", "17039"],
  ["Deaf Smith County", "48117"],
  ["Dearborn County", "18029"],
  ["Decatur County", "13087"],
  ["Decatur County", "19053"],
  ["Decatur County", "18031"],
  ["Decatur County", "20039"],
  ["Decatur County", "47039"],
  ["Deer Lodge County", "30023"],
  ["Defiance County", "39039"],
  ["Dekalb County", "01049"],
  ["Dekalb County", "13089"],
  ["Dekalb County", "17037"],
  ["Dekalb County", "18033"],
  ["Dekalb County", "29063"],
  ["Dekalb County", "47041"],
  ["Del Norte County", "06015"],
  ["Delaware County", "19055"],
  ["Delaware County", "18035"],
  ["Delaware County", "36025"],
  ["Delaware County", "39041"],
  ["Delaware County", "40041"],
  ["Delaware County", "42045"],
  ["Delta County", "08029"],
  ["Delta County", "26041"],
  ["Delta County", "48119"],
  ["Denali Borough", "02068"],
  ["Dent County", "29065"],
  ["Denton County", "48121"],
  ["Denver County", "08031"],
  ["Des Moines County", "19057"],
  ["Deschutes County", "41017"],
  ["Desha County", "05041"],
  ["Desoto County", "12027"],
  ["Desoto County", "28033"],
  ["Deuel County", "31049"],
  ["Deuel County", "46039"],
  ["Dewey County", "40043"],
  ["Dewey County", "46041"],
  ["Dewitt County", "48123"],
  ["Dickens County", "48125"],
  ["Dickenson County", "51051"],
  ["Dickey County", "38021"],
  ["Dickinson County", "19059"],
  ["Dickinson County", "20041"],
  ["Dickinson County", "26043"],
  ["Dickson County", "47043"],
  ["Dillingham Census Area", "02070"],
  ["Dillon County", "45033"],
  ["Dimmit County", "48127"],
  ["Dinwiddie County", "51053"],
  ["District Of Columbia", "11001"],
  ["Divide County", "38023"],
  ["Dixie County", "12029"],
  ["Dixon County", "31051"],
  ["Doddridge County", "54017"],
  ["Dodge County", "13091"],
  ["Dodge County", "27039"],
  ["Dodge County", "31053"],
  ["Dodge County", "55027"],
  ["Dolores County", "08033"],
  ["Dona Ana County", "35013"],
  ["Doniphan County", "20043"],
  ["Donley County", "48129"],
  ["Dooly County", "13093"],
  ["Door County", "55029"],
  ["Dorado Municipio", "72051"],
  ["Dorchester County", "24019"],
  ["Dorchester County", "45035"],
  ["Dougherty County", "13095"],
  ["Douglas County", "08035"],
  ["Douglas County", "13097"],
  ["Douglas County", "17041"],
  ["Douglas County", "20045"],
  ["Douglas County", "27041"],
  ["Douglas County", "29067"],
  ["Douglas County", "31055"],
  ["Douglas County", "32005"],
  ["Douglas County", "41019"],
  ["Douglas County", "46043"],
  ["Douglas County", "53017"],
  ["Douglas County", "55031"],
  ["Drew County", "05043"],
  ["Dubois County", "18037"],
  ["Dubuque County", "19061"],
  ["Duchesne County", "49013"],
  ["Dukes County", "25007"],
  ["Dundy County", "31057"],
  ["Dunklin County", "29069"],
  ["Dunn County", "38025"],
  ["Dunn County", "55033"],
  ["Dupage County", "17043"],
  ["Duplin County", "37061"],
  ["Durham County", "37063"],
  ["Dutchess County", "36027"],
  ["Duval County", "12031"],
  ["Duval County", "48131"],
  ["Dyer County", "47045"],
  ["Eagle County", "08037"],
  ["Early County", "13099"],
  ["East Baton Rouge Parish", "22033"],
  ["East Carroll Parish", "22035"],
  ["East Feliciana Parish", "22037"],
  ["Eastern District", "60010"],
  ["Eastland County", "48133"],
  ["Eaton County", "26045"],
  ["Eau Claire County", "55035"],
  ["Echols County", "13101"],
  ["Ector County", "48135"],
  ["Eddy County", "38027"],
  ["Eddy County", "35015"],
  ["Edgar County", "17045"],
  ["Edgecombe County", "37065"],
  ["Edgefield County", "45037"],
  ["Edmonson County", "21061"],
  ["Edmunds County", "46045"],
  ["Edwards County", "17047"],
  ["Edwards County", "20047"],
  ["Edwards County", "48137"],
  ["Effingham County", "13103"],
  ["Effingham County", "17049"],
  ["El Dorado County", "06017"],
  ["El Paso County", "08041"],
  ["El Paso County", "48141"],
  ["Elbert County", "08039"],
  ["Elbert County", "13105"],
  ["Elk County", "20049"],
  ["Elk County", "42047"],
  ["Elkhart County", "18039"],
  ["Elko County", "32007"],
  ["Elliott County", "21063"],
  ["Ellis County", "20051"],
  ["Ellis County", "40045"],
  ["Ellis County", "48139"],
  ["Ellsworth County", "20053"],
  ["Elmore County", "01051"],
  ["Elmore County", "16039"],
  ["Emanuel County", "13107"],
  ["Emery County", "49015"],
  ["Emmet County", "19063"],
  ["Emmet County", "26047"],
  ["Emmons County", "38029"],
  ["Emporia City", "51595"],
  ["Erath County", "48143"],
  ["Erie County", "36029"],
  ["Erie County", "39043"],
  ["Erie County", "42049"],
  ["Escambia County", "01053"],
  ["Escambia County", "12033"],
  ["Esmeralda County", "32009"],
  ["Essex County", "25009"],
  ["Essex County", "34013"],
  ["Essex County", "36031"],
  ["Essex County", "51057"],
  ["Essex County", "50009"],
  ["Estill County", "21065"],
  ["Etowah County", "01055"],
  ["Eureka County", "32011"],
  ["Evangeline Parish", "22039"],
  ["Evans County", "13109"],
  ["Fairbanks North Star Borough", "02090"],
  ["Fairfax City", "51600"],
  ["Fairfax County", "51059"],
  ["Fairfield County", "09001"],
  ["Fairfield County", "39045"],
  ["Fairfield County", "45039"],
  ["Fajardo Municipio", "72053"],
  ["Fall River County", "46047"],
  ["Fallon County", "30025"],
  ["Falls Church City", "51610"],
  ["Falls County", "48145"],
  ["Fannin County", "13111"],
  ["Fannin County", "48147"],
  ["Faribault County", "27043"],
  ["Faulk County", "46049"],
  ["Faulkner County", "05045"],
  ["Fauquier County", "51061"],
  ["Fayette County", "01057"],
  ["Fayette County", "13113"],
  ["Fayette County", "19065"],
  ["Fayette County", "17051"],
  ["Fayette County", "18041"],
  ["Fayette County", "21067"],
  ["Fayette County", "39047"],
  ["Fayette County", "42051"],
  ["Fayette County", "47047"],
  ["Fayette County", "48149"],
  ["Fayette County", "54019"],
  ["Fentress County", "47049"],
  ["Fergus County", "30027"],
  ["Ferry County", "53019"],
  ["Fillmore County", "27045"],
  ["Fillmore County", "31059"],
  ["Finney County", "20055"],
  ["Fisher County", "48151"],
  ["Flagler County", "12035"],
  ["Flathead County", "30029"],
  ["Fleming County", "21069"],
  ["Florence County", "45041"],
  ["Florence County", "55037"],
  ["Florida Municipio", "72054"],
  ["Floyd County", "13115"],
  ["Floyd County", "19067"],
  ["Floyd County", "18043"],
  ["Floyd County", "21071"],
  ["Floyd County", "48153"],
  ["Floyd County", "51063"],
  ["Fluvanna County", "51065"],
  ["Foard County", "48155"],
  ["Fond Du Lac County", "55039"],
  ["Ford County", "17053"],
  ["Ford County", "20057"],
  ["Forest County", "42053"],
  ["Forest County", "55041"],
  ["Forrest County", "28035"],
  ["Forsyth County", "13117"],
  ["Forsyth County", "37067"],
  ["Fort Bend County", "48157"],
  ["Foster County", "38031"],
  ["Fountain County", "18045"],
  ["Franklin City", "51620"],
  ["Franklin County", "01059"],
  ["Franklin County", "05047"],
  ["Franklin County", "12037"],
  ["Franklin County", "13119"],
  ["Franklin County", "19069"],
  ["Franklin County", "16041"],
  ["Franklin County", "17055"],
  ["Franklin County", "18047"],
  ["Franklin County", "20059"],
  ["Franklin County", "21073"],
  ["Franklin County", "25011"],
  ["Franklin County", "23007"],
  ["Franklin County", "29071"],
  ["Franklin County", "28037"],
  ["Franklin County", "37069"],
  ["Franklin County", "31061"],
  ["Franklin County", "36033"],
  ["Franklin County", "39049"],
  ["Franklin County", "42055"],
  ["Franklin County", "47051"],
  ["Franklin County", "48159"],
  ["Franklin County", "51067"],
  ["Franklin County", "50011"],
  ["Franklin County", "53021"],
  ["Franklin Parish", "22041"],
  ["Frederick County", "24021"],
  ["Frederick County", "51069"],
  ["Fredericksburg City", "51630"],
  ["Freeborn County", "27047"],
  ["Freestone County", "48161"],
  ["Fremont County", "08043"],
  ["Fremont County", "19071"],
  ["Fremont County", "16043"],
  ["Fremont County", "56013"],
  ["Fresno County", "06019"],
  ["Frio County", "48163"],
  ["Frontier County", "31063"],
  ["Fulton County", "05049"],
  ["Fulton County", "13121"],
  ["Fulton County", "17057"],
  ["Fulton County", "18049"],
  ["Fulton County", "21075"],
  ["Fulton County", "36035"],
  ["Fulton County", "39051"],
  ["Fulton County", "42057"],
  ["Furnas County", "31065"],
  ["Gadsden County", "12039"],
  ["Gage County", "31067"],
  ["Gaines County", "48165"],
  ["Galax City", "51640"],
  ["Gallatin County", "17059"],
  ["Gallatin County", "21077"],
  ["Gallatin County", "30031"],
  ["Gallia County", "39053"],
  ["Galveston County", "48167"],
  ["Garden County", "31069"],
  ["Garfield County", "08045"],
  ["Garfield County", "30033"],
  ["Garfield County", "31071"],
  ["Garfield County", "40047"],
  ["Garfield County", "49017"],
  ["Garfield County", "53023"],
  ["Garland County", "05051"],
  ["Garrard County", "21079"],
  ["Garrett County", "24023"],
  ["Garvin County", "40049"],
  ["Garza County", "48169"],
  ["Gasconade County", "29073"],
  ["Gaston County", "37071"],
  ["Gates County", "37073"],
  ["Geary County", "20061"],
  ["Geauga County", "39055"],
  ["Gem County", "16045"],
  ["Genesee County", "26049"],
  ["Genesee County", "36037"],
  ["Geneva County", "01061"],
  ["Gentry County", "29075"],
  ["George County", "28039"],
  ["Georgetown County", "45043"],
  ["Gibson County", "18051"],
  ["Gibson County", "47053"],
  ["Gila County", "04007"],
  ["Gilchrist County", "12041"],
  ["Giles County", "47055"],
  ["Giles County", "51071"],
  ["Gillespie County", "48171"],
  ["Gilliam County", "41021"],
  ["Gilmer County", "13123"],
  ["Gilmer County", "54021"],
  ["Gilpin County", "08047"],
  ["Glacier County", "30035"],
  ["Glades County", "12043"],
  ["Gladwin County", "26051"],
  ["Glascock County", "13125"],
  ["Glasscock County", "48173"],
  ["Glenn County", "06021"],
  ["Gloucester County", "34015"],
  ["Gloucester County", "51073"],
  ["Glynn County", "13127"],
  ["Gogebic County", "26053"],
  ["Golden Valley County", "30037"],
  ["Golden Valley County", "38033"],
  ["Goliad County", "48175"],
  ["Gonzales County", "48177"],
  ["Goochland County", "51075"],
  ["Goodhue County", "27049"],
  ["Gooding County", "16047"],
  ["Gordon County", "13129"],
  ["Goshen County", "56015"],
  ["Gosper County", "31073"],
  ["Gove County", "20063"],
  ["Grady County", "13131"],
  ["Grady County", "40051"],
  ["Grafton County", "33009"],
  ["Graham County", "04009"],
  ["Graham County", "20065"],
  ["Graham County", "37075"],
  ["Grainger County", "47057"],
  ["Grand County", "08049"],
  ["Grand County", "49019"],
  ["Grand Forks County", "38035"],
  ["Grand Isle County", "50013"],
  ["Grand Traverse County", "26055"],
  ["Granite County", "30039"],
  ["Grant County", "05053"],
  ["Grant County", "18053"],
  ["Grant County", "20067"],
  ["Grant County", "21081"],
  ["Grant County", "27051"],
  ["Grant County", "38037"],
  ["Grant County", "31075"],
  ["Grant County", "35017"],
  ["Grant County", "40053"],
  ["Grant County", "41023"],
  ["Grant County", "46051"],
  ["Grant County", "53025"],
  ["Grant County", "55043"],
  ["Grant County", "54023"],
  ["Grant Parish", "22043"],
  ["Granville County", "37077"],
  ["Gratiot County", "26057"],
  ["Graves County", "21083"],
  ["Gray County", "20069"],
  ["Gray County", "48179"],
  ["Grays Harbor County", "53027"],
  ["Grayson County", "21085"],
  ["Grayson County", "48181"],
  ["Grayson County", "51077"],
  ["Greeley County", "20071"],
  ["Greeley County", "31077"],
  ["Green County", "21087"],
  ["Green County", "55045"],
  ["Green Lake County", "55047"],
  ["Greenbrier County", "54025"],
  ["Greene County", "01063"],
  ["Greene County", "05055"],
  ["Greene County", "13133"],
  ["Greene County", "19073"],
  ["Greene County", "17061"],
  ["Greene County", "18055"],
  ["Greene County", "29077"],
  ["Greene County", "28041"],
  ["Greene County", "37079"],
  ["Greene County", "36039"],
  ["Greene County", "39057"],
  ["Greene County", "42059"],
  ["Greene County", "47059"],
  ["Greene County", "51079"],
  ["Greenlee County", "04011"],
  ["Greensville County", "51081"],
  ["Greenup County", "21089"],
  ["Greenville County", "45045"],
  ["Greenwood County", "20073"],
  ["Greenwood County", "45047"],
  ["Greer County", "40055"],
  ["Gregg County", "48183"],
  ["Gregory County", "46053"],
  ["Grenada County", "28043"],
  ["Griggs County", "38039"],
  ["Grimes County", "48185"],
  ["Grundy County", "19075"],
  ["Grundy County", "17063"],
  ["Grundy County", "29079"],
  ["Grundy County", "47061"],
  ["Guadalupe County", "35019"],
  ["Guadalupe County", "48187"],
  ["Guam", "66010"],
  ["Guanica Municipio", "72055"],
  ["Guayama Municipio", "72057"],
  ["Guayanilla Municipio", "72059"],
  ["Guaynabo Municipio", "72061"],
  ["Guernsey County", "39059"],
  ["Guilford County", "37081"],
  ["Gulf County", "12045"],
  ["Gunnison County", "08051"],
  ["Gurabo Municipio", "72063"],
  ["Guthrie County", "19077"],
  ["Gwinnett County", "13135"],
  ["Haakon County", "46055"],
  ["Habersham County", "13137"],
  ["Haines Borough", "02100"],
  ["Hale County", "01065"],
  ["Hale County", "48189"],
  ["Halifax County", "37083"],
  ["Halifax County", "51083"],
  ["Hall County", "13139"],
  ["Hall County", "31079"],
  ["Hall County", "48191"],
  ["Hamblen County", "47063"],
  ["Hamilton County", "12047"],
  ["Hamilton County", "19079"],
  ["Hamilton County", "17065"],
  ["Hamilton County", "18057"],
  ["Hamilton County", "20075"],
  ["Hamilton County", "31081"],
  ["Hamilton County", "36041"],
  ["Hamilton County", "39061"],
  ["Hamilton County", "47065"],
  ["Hamilton County", "48193"],
  ["Hamlin County", "46057"],
  ["Hampden County", "25013"],
  ["Hampshire County", "25015"],
  ["Hampshire County", "54027"],
  ["Hampton City", "51650"],
  ["Hampton County", "45049"],
  ["Hancock County", "13141"],
  ["Hancock County", "19081"],
  ["Hancock County", "17067"],
  ["Hancock County", "18059"],
  ["Hancock County", "21091"],
  ["Hancock County", "23009"],
  ["Hancock County", "28045"],
  ["Hancock County", "39063"],
  ["Hancock County", "47067"],
  ["Hancock County", "54029"],
  ["Hand County", "46059"],
  ["Hanover County", "51085"],
  ["Hansford County", "48195"],
  ["Hanson County", "46061"],
  ["Haralson County", "13143"],
  ["Hardee County", "12049"],
  ["Hardeman County", "47069"],
  ["Hardeman County", "48197"],
  ["Hardin County", "19083"],
  ["Hardin County", "17069"],
  ["Hardin County", "21093"],
  ["Hardin County", "39065"],
  ["Hardin County", "47071"],
  ["Hardin County", "48199"],
  ["Harding County", "35021"],
  ["Harding County", "46063"],
  ["Hardy County", "54031"],
  ["Harford County", "24025"],
  ["Harlan County", "21095"],
  ["Harlan County", "31083"],
  ["Harmon County", "40057"],
  ["Harnett County", "37085"],
  ["Harney County", "41025"],
  ["Harper County", "20077"],
  ["Harper County", "40059"],
  ["Harris County", "13145"],
  ["Harris County", "48201"],
  ["Harrison County", "19085"],
  ["Harrison County", "18061"],
  ["Harrison County", "21097"],
  ["Harrison County", "29081"],
  ["Harrison County", "28047"],
  ["Harrison County", "39067"],
  ["Harrison County", "48203"],
  ["Harrison County", "54033"],
  ["Harrisonburg City", "51660"],
  ["Hart County", "13147"],
  ["Hart County", "21099"],
  ["Hartford County", "09003"],
  ["Hartley County", "48205"],
  ["Harvey County", "20079"],
  ["Haskell County", "20081"],
  ["Haskell County", "40061"],
  ["Haskell County", "48207"],
  ["Hatillo Municipio", "72065"],
  ["Hawaii County", "15001"],
  ["Hawkins County", "47073"],
  ["Hayes County", "31085"],
  ["Hays County", "48209"],
  ["Haywood County", "37087"],
  ["Haywood County", "47075"],
  ["Heard County", "13149"],
  ["Hemphill County", "48211"],
  ["Hempstead County", "05057"],
  ["Henderson County", "17071"],
  ["Henderson County", "21101"],
  ["Henderson County", "37089"],
  ["Henderson County", "47077"],
  ["Henderson County", "48213"],
  ["Hendricks County", "18063"],
  ["Hendry County", "12051"],
  ["Hennepin County", "27053"],
  ["Henrico County", "51087"],
  ["Henry County", "01067"],
  ["Henry County", "13151"],
  ["Henry County", "19087"],
  ["Henry County", "17073"],
  ["Henry County", "18065"],
  ["Henry County", "21103"],
  ["Henry County", "29083"],
  ["Henry County", "39069"],
  ["Henry County", "47079"],
  ["Henry County", "51089"],
  ["Herkimer County", "36043"],
  ["Hernando County", "12053"],
  ["Hertford County", "37091"],
  ["Hettinger County", "38041"],
  ["Hickman County", "21105"],
  ["Hickman County", "47081"],
  ["Hickory County", "29085"],
  ["Hidalgo County", "35023"],
  ["Hidalgo County", "48215"],
  ["Highland County", "39071"],
  ["Highland County", "51091"],
  ["Highlands County", "12055"],
  ["Hill County", "30041"],
  ["Hill County", "48217"],
  ["Hillsborough County", "12057"],
  ["Hillsborough County", "33011"],
  ["Hillsdale County", "26059"],
  ["Hinds County", "28049"],
  ["Hinsdale County", "08053"],
  ["Hitchcock County", "31087"],
  ["Hocking County", "39073"],
  ["Hockley County", "48219"],
  ["Hodgeman County", "20083"],
  ["Hoke County", "37093"],
  ["Holmes County", "12059"],
  ["Holmes County", "28051"],
  ["Holmes County", "39075"],
  ["Holt County", "29087"],
  ["Holt County", "31089"],
  ["Honolulu County", "15003"],
  ["Hood County", "48221"],
  ["Hood River County", "41027"],
  ["Hooker County", "31091"],
  ["Hoonah-Angoon Census Area", "02105"],
  ["Hopewell City", "51670"],
  ["Hopkins County", "21107"],
  ["Hopkins County", "48223"],
  ["Hormigueros Municipio", "72067"],
  ["Horry County", "45051"],
  ["Hot Spring County", "05059"],
  ["Hot Springs County", "56017"],
  ["Houghton County", "26061"],
  ["Houston County", "01069"],
  ["Houston County", "13153"],
  ["Houston County", "27055"],
  ["Houston County", "47083"],
  ["Houston County", "48225"],
  ["Howard County", "05061"],
  ["Howard County", "19089"],
  ["Howard County", "18067"],
  ["Howard County", "24027"],
  ["Howard County", "29089"],
  ["Howard County", "31093"],
  ["Howard County", "48227"],
  ["Howell County", "29091"],
  ["Hubbard County", "27057"],
  ["Hudson County", "34017"],
  ["Hudspeth County", "48229"],
  ["Huerfano County", "08055"],
  ["Hughes County", "40063"],
  ["Hughes County", "46065"],
  ["Humacao Municipio", "72069"],
  ["Humboldt County", "06023"],
  ["Humboldt County", "19091"],
  ["Humboldt County", "32013"],
  ["Humphreys County", "28053"],
  ["Humphreys County", "47085"],
  ["Hunt County", "48231"],
  ["Hunterdon County", "34019"],
  ["Huntingdon County", "42061"],
  ["Huntington County", "18069"],
  ["Huron County", "26063"],
  ["Huron County", "39077"],
  ["Hutchinson County", "46067"],
  ["Hutchinson County", "48233"],
  ["Hyde County", "37095"],
  ["Hyde County", "46069"],
  ["Iberia Parish", "22045"],
  ["Iberville Parish", "22047"],
  ["Ida County", "19093"],
  ["Idaho County", "16049"],
  ["Imperial County", "06025"],
  ["Independence County", "05063"],
  ["Indian River County", "12061"],
  ["Indiana County", "42063"],
  ["Ingham County", "26065"],
  ["Inyo County", "06027"],
  ["Ionia County", "26067"],
  ["Iosco County", "26069"],
  ["Iowa County", "19095"],
  ["Iowa County", "55049"],
  ["Iredell County", "37097"],
  ["Irion County", "48235"],
  ["Iron County", "26071"],
  ["Iron County", "29093"],
  ["Iron County", "49021"],
  ["Iron County", "55051"],
  ["Iroquois County", "17075"],
  ["Irwin County", "13155"],
  ["Isabela Municipio", "72071"],
  ["Isabella County", "26073"],
  ["Isanti County", "27059"],
  ["Island County", "53029"],
  ["Isle Of Wight County", "51093"],
  ["Issaquena County", "28055"],
  ["Itasca County", "27061"],
  ["Itawamba County", "28057"],
  ["Izard County", "05065"],
  ["Jack County", "48237"],
  ["Jackson County", "01071"],
  ["Jackson County", "05067"],
  ["Jackson County", "08057"],
  ["Jackson County", "12063"],
  ["Jackson County", "13157"],
  ["Jackson County", "19097"],
  ["Jackson County", "17077"],
  ["Jackson County", "18071"],
  ["Jackson County", "20085"],
  ["Jackson County", "21109"],
  ["Jackson County", "26075"],
  ["Jackson County", "27063"],
  ["Jackson County", "29095"],
  ["Jackson County", "28059"],
  ["Jackson County", "37099"],
  ["Jackson County", "39079"],
  ["Jackson County", "40065"],
  ["Jackson County", "41029"],
  ["Jackson County", "46071"],
  ["Jackson County", "47087"],
  ["Jackson County", "48239"],
  ["Jackson County", "55053"],
  ["Jackson County", "54035"],
  ["Jackson Parish", "22049"],
  ["James City County", "51095"],
  ["Jasper County", "13159"],
  ["Jasper County", "19099"],
  ["Jasper County", "17079"],
  ["Jasper County", "18073"],
  ["Jasper County", "29097"],
  ["Jasper County", "28061"],
  ["Jasper County", "45053"],
  ["Jasper County", "48241"],
  ["Jay County", "18075"],
  ["Jayuya Municipio", "72073"],
  ["Jeff Davis County", "13161"],
  ["Jeff Davis County", "48243"],
  ["Jefferson County", "01073"],
  ["Jefferson County", "05069"],
  ["Jefferson County", "08059"],
  ["Jefferson County", "12065"],
  ["Jefferson County", "13163"],
  ["Jefferson County", "19101"],
  ["Jefferson County", "16051"],
  ["Jefferson County", "17081"],
  ["Jefferson County", "18077"],
  ["Jefferson County", "20087"],
  ["Jefferson County", "21111"],
  ["Jefferson County", "29099"],
  ["Jefferson County", "28063"],
  ["Jefferson County", "30043"],
  ["Jefferson County", "31095"],
  ["Jefferson County", "36045"],
  ["Jefferson County", "39081"],
  ["Jefferson County", "40067"],
  ["Jefferson County", "41031"],
  ["Jefferson County", "42065"],
  ["Jefferson County", "47089"],
  ["Jefferson County", "48245"],
  ["Jefferson County", "53031"],
  ["Jefferson County", "55055"],
  ["Jefferson County", "54037"],
  ["Jefferson Davis County", "28065"],
  ["Jefferson Davis Parish", "22053"],
  ["Jefferson Parish", "22051"],
  ["Jenkins County", "13165"],
  ["Jennings County", "18079"],
  ["Jerauld County", "46073"],
  ["Jerome County", "16053"],
  ["Jersey County", "17083"],
  ["Jessamine County", "21113"],
  ["Jewell County", "20089"],
  ["Jim Hogg County", "48247"],
  ["Jim Wells County", "48249"],
  ["Jo Daviess County", "17085"],
  ["Johnson County", "05071"],
  ["Johnson County", "13167"],
  ["Johnson County", "19103"],
  ["Johnson County", "17087"],
  ["Johnson County", "18081"],
  ["Johnson County", "20091"],
  ["Johnson County", "21115"],
  ["Johnson County", "29101"],
  ["Johnson County", "31097"],
  ["Johnson County", "47091"],
  ["Johnson County", "48251"],
  ["Johnson County", "56019"],
  ["Johnston County", "37101"],
  ["Johnston County", "40069"],
  ["Jones County", "13169"],
  ["Jones County", "19105"],
  ["Jones County", "28067"],
  ["Jones County", "37103"],
  ["Jones County", "46075"],
  ["Jones County", "48253"],
  ["Josephine County", "41033"],
  ["Juab County", "49023"],
  ["Juana Diaz Municipio", "72075"],
  ["Judith Basin County", "30045"],
  ["Juncos Municipio", "72077"],
  ["Juneau City And Borough", "02110"],
  ["Juneau County", "55057"],
  ["Juniata County", "42067"],
  ["Kalamazoo County", "26077"],
  ["Kalawao County", "15005"],
  ["Kalkaska County", "26079"],
  ["Kanabec County", "27065"],
  ["Kanawha County", "54039"],
  ["Kandiyohi County", "27067"],
  ["Kane County", "17089"],
  ["Kane County", "49025"],
  ["Kankakee County", "17091"],
  ["Karnes County", "48255"],
  ["Kauai County", "15007"],
  ["Kaufman County", "48257"],
  ["Kay County", "40071"],
  ["Kearney County", "31099"],
  ["Kearny County", "20093"],
  ["Keith County", "31101"],
  ["Kemper County", "28069"],
  ["Kenai Peninsula Borough", "02122"],
  ["Kendall County", "17093"],
  ["Kendall County", "48259"],
  ["Kenedy County", "48261"],
  ["Kennebec County", "23011"],
  ["Kenosha County", "55059"],
  ["Kent County", "10001"],
  ["Kent County", "24029"],
  ["Kent County", "26081"],
  ["Kent County", "44003"],
  ["Kent County", "48263"],
  ["Kenton County", "21117"],
  ["Keokuk County", "19107"],
  ["Kern County", "06029"],
  ["Kerr County", "48265"],
  ["Kershaw County", "45055"],
  ["Ketchikan Gateway Borough", "02130"],
  ["Kewaunee County", "55061"],
  ["Keweenaw County", "26083"],
  ["Keya Paha County", "31103"],
  ["Kidder County", "38043"],
  ["Kimball County", "31105"],
  ["Kimble County", "48267"],
  ["King And Queen County", "51097"],
  ["King County", "48269"],
  ["King County", "53033"],
  ["King George County", "51099"],
  ["King William County", "51101"],
  ["Kingfisher County", "40073"],
  ["Kingman County", "20095"],
  ["Kings County", "06031"],
  ["Kings County", "36047"],
  ["Kingsbury County", "46077"],
  ["Kinney County", "48271"],
  ["Kiowa County", "08061"],
  ["Kiowa County", "20097"],
  ["Kiowa County", "40075"],
  ["Kit Carson County", "08063"],
  ["Kitsap County", "53035"],
  ["Kittitas County", "53037"],
  ["Kittson County", "27069"],
  ["Klamath County", "41035"],
  ["Kleberg County", "48273"],
  ["Klickitat County", "53039"],
  ["Knott County", "21119"],
  ["Knox County", "17095"],
  ["Knox County", "18083"],
  ["Knox County", "21121"],
  ["Knox County", "23013"],
  ["Knox County", "29103"],
  ["Knox County", "31107"],
  ["Knox County", "39083"],
  ["Knox County", "47093"],
  ["Knox County", "48275"],
  ["Kodiak Island Borough", "02150"],
  ["Koochiching County", "27071"],
  ["Kootenai County", "16055"],
  ["Kosciusko County", "18085"],
  ["Kossuth County", "19109"],
  ["Kusilvak Census Area", "02158"],
  ["La Crosse County", "55063"],
  ["La Paz County", "04012"],
  ["La Plata County", "08067"],
  ["La Salle County", "48283"],
  ["La Salle Parish", "22059"],
  ["Labette County", "20099"],
  ["Lac Qui Parle County", "27073"],
  ["Lackawanna County", "42069"],
  ["Laclede County", "29105"],
  ["Lafayette County", "05073"],
  ["Lafayette County", "12067"],
  ["Lafayette County", "29107"],
  ["Lafayette County", "28071"],
  ["Lafayette County", "55065"],
  ["Lafayette Parish", "22055"],
  ["Lafourche Parish", "22057"],
  ["Lagrange County", "18087"],
  ["Lajas Municipio", "72079"],
  ["Lake And Peninsula Borough", "02164"],
  ["Lake County", "06033"],
  ["Lake County", "08065"],
  ["Lake County", "12069"],
  ["Lake County", "17097"],
  ["Lake County", "18089"],
  ["Lake County", "26085"],
  ["Lake County", "27075"],
  ["Lake County", "30047"],
  ["Lake County", "39085"],
  ["Lake County", "41037"],
  ["Lake County", "46079"],
  ["Lake County", "47095"],
  ["Lake Of The Woods County", "27077"],
  ["Lamar County", "01075"],
  ["Lamar County", "13171"],
  ["Lamar County", "28073"],
  ["Lamar County", "48277"],
  ["Lamb County", "48279"],
  ["Lamoille County", "50015"],
  ["Lamoure County", "38045"],
  ["Lampasas County", "48281"],
  ["Lancaster County", "31109"],
  ["Lancaster County", "42071"],
  ["Lancaster County", "45057"],
  ["Lancaster County", "51103"],
  ["Lander County", "32015"],
  ["Lane County", "20101"],
  ["Lane County", "41039"],
  ["Langlade County", "55067"],
  ["Lanier County", "13173"],
  ["Lapeer County", "26087"],
  ["Laporte County", "18091"],
  ["Laramie County", "56021"],
  ["Lares Municipio", "72081"],
  ["Larimer County", "08069"],
  ["Larue County", "21123"],
  ["Las Animas County", "08071"],
  ["Las Marias Municipio", "72083"],
  ["Las Piedras Municipio", "72085"],
  ["Lasalle County", "17099"],
  ["Lassen County", "06035"],
  ["Latah County", "16057"],
  ["Latimer County", "40077"],
  ["Lauderdale County", "01077"],
  ["Lauderdale County", "28075"],
  ["Lauderdale County", "47097"],
  ["Laurel County", "21125"],
  ["Laurens County", "13175"],
  ["Laurens County", "45059"],
  ["Lavaca County", "48285"],
  ["Lawrence County", "01079"],
  ["Lawrence County", "05075"],
  ["Lawrence County", "17101"],
  ["Lawrence County", "18093"],
  ["Lawrence County", "21127"],
  ["Lawrence County", "29109"],
  ["Lawrence County", "28077"],
  ["Lawrence County", "39087"],
  ["Lawrence County", "42073"],
  ["Lawrence County", "46081"],
  ["Lawrence County", "47099"],
  ["Le Flore County", "40079"],
  ["Le Sueur County", "27079"],
  ["Lea County", "35025"],
  ["Leake County", "28079"],
  ["Leavenworth County", "20103"],
  ["Lebanon County", "42075"],
  ["Lee County", "01081"],
  ["Lee County", "05077"],
  ["Lee County", "12071"],
  ["Lee County", "13177"],
  ["Lee County", "19111"],
  ["Lee County", "17103"],
  ["Lee County", "21129"],
  ["Lee County", "28081"],
  ["Lee County", "37105"],
  ["Lee County", "45061"],
  ["Lee County", "48287"],
  ["Lee County", "51105"],
  ["Leelanau County", "26089"],
  ["Leflore County", "28083"],
  ["Lehigh County", "42077"],
  ["Lemhi County", "16059"],
  ["Lenawee County", "26091"],
  ["Lenoir County", "37107"],
  ["Leon County", "12073"],
  ["Leon County", "48289"],
  ["Leslie County", "21131"],
  ["Letcher County", "21133"],
  ["Levy County", "12075"],
  ["Lewis And Clark County", "30049"],
  ["Lewis County", "16061"],
  ["Lewis County", "21135"],
  ["Lewis County", "29111"],
  ["Lewis County", "36049"],
  ["Lewis County", "47101"],
  ["Lewis County", "53041"],
  ["Lewis County", "54041"],
  ["Lexington City", "51678"],
  ["Lexington County", "45063"],
  ["Liberty County", "12077"],
  ["Liberty County", "13179"],
  ["Liberty County", "30051"],
  ["Liberty County", "48291"],
  ["Licking County", "39089"],
  ["Limestone County", "01083"],
  ["Limestone County", "48293"],
  ["Lincoln County", "05079"],
  ["Lincoln County", "08073"],
  ["Lincoln County", "13181"],
  ["Lincoln County", "16063"],
  ["Lincoln County", "20105"],
  ["Lincoln County", "21137"],
  ["Lincoln County", "23015"],
  ["Lincoln County", "27081"],
  ["Lincoln County", "29113"],
  ["Lincoln County", "28085"],
  ["Lincoln County", "30053"],
  ["Lincoln County", "37109"],
  ["Lincoln County", "31111"],
  ["Lincoln County", "35027"],
  ["Lincoln County", "32017"],
  ["Lincoln County", "40081"],
  ["Lincoln County", "41041"],
  ["Lincoln County", "46083"],
  ["Lincoln County", "47103"],
  ["Lincoln County", "53043"],
  ["Lincoln County", "55069"],
  ["Lincoln County", "54043"],
  ["Lincoln County", "56023"],
  ["Lincoln Parish", "22061"],
  ["Linn County", "19113"],
  ["Linn County", "20107"],
  ["Linn County", "29115"],
  ["Linn County", "41043"],
  ["Lipscomb County", "48295"],
  ["Litchfield County", "09005"],
  ["Little River County", "05081"],
  ["Live Oak County", "48297"],
  ["Livingston County", "17105"],
  ["Livingston County", "21139"],
  ["Livingston County", "26093"],
  ["Livingston County", "29117"],
  ["Livingston County", "36051"],
  ["Livingston Parish", "22063"],
  ["Llano County", "48299"],
  ["Logan County", "05083"],
  ["Logan County", "08075"],
  ["Logan County", "17107"],
  ["Logan County", "20109"],
  ["Logan County", "21141"],
  ["Logan County", "38047"],
  ["Logan County", "31113"],
  ["Logan County", "39091"],
  ["Logan County", "40083"],
  ["Logan County", "54045"],
  ["Loiza Municipio", "72087"],
  ["Long County", "13183"],
  ["Lonoke County", "05085"],
  ["Lorain County", "39093"],
  ["Los Alamos County", "35028"],
  ["Los Angeles County", "06037"],
  ["Loudon County", "47105"],
  ["Loudoun County", "51107"],
  ["Louisa County", "19115"],
  ["Louisa County", "51109"],
  ["Loup County", "31115"],
  ["Love County", "40085"],
  ["Loving County", "48301"],
  ["Lowndes County", "01085"],
  ["Lowndes County", "13185"],
  ["Lowndes County", "28087"],
  ["Lubbock County", "48303"],
  ["Lucas County", "19117"],
  ["Lucas County", "39095"],
  ["Luce County", "26095"],
  ["Lumpkin County", "13187"],
  ["Luna County", "35029"],
  ["Lunenburg County", "51111"],
  ["Luquillo Municipio", "72089"],
  ["Luzerne County", "42079"],
  ["Lycoming County", "42081"],
  ["Lyman County", "46085"],
  ["Lynchburg City", "51680"],
  ["Lynn County", "48305"],
  ["Lyon County", "19119"],
  ["Lyon County", "20111"],
  ["Lyon County", "21143"],
  ["Lyon County", "27083"],
  ["Lyon County", "32019"],
  ["Mackinac County", "26097"],
  ["Macomb County", "26099"],
  ["Macon County", "01087"],
  ["Macon County", "13193"],
  ["Macon County", "17115"],
  ["Macon County", "29121"],
  ["Macon County", "37113"],
  ["Macon County", "47111"],
  ["Macoupin County", "17117"],
  ["Madera County", "06039"],
  ["Madison County", "01089"],
  ["Madison County", "05087"],
  ["Madison County", "12079"],
  ["Madison County", "13195"],
  ["Madison County", "19121"],
  ["Madison County", "16065"],
  ["Madison County", "17119"],
  ["Madison County", "18095"],
  ["Madison County", "21151"],
  ["Madison County", "29123"],
  ["Madison County", "28089"],
  ["Madison County", "30057"],
  ["Madison County", "37115"],
  ["Madison County", "31119"],
  ["Madison County", "36053"],
  ["Madison County", "39097"],
  ["Madison County", "47113"],
  ["Madison County", "48313"],
  ["Madison County", "51113"],
  ["Madison Parish", "22065"],
  ["Magoffin County", "21153"],
  ["Mahaska County", "19123"],
  ["Mahnomen County", "27087"],
  ["Mahoning County", "39099"],
  ["Major County", "40093"],
  ["Malheur County", "41045"],
  ["Manassas City", "51683"],
  ["Manassas Park City", "51685"],
  ["Manatee County", "12081"],
  ["Manati Municipio", "72091"],
  ["Manistee County", "26101"],
  ["Manitowoc County", "55071"],
  ["Manu'A District", "60020"],
  ["Marathon County", "55073"],
  ["Marengo County", "01091"],
  ["Maricao Municipio", "72093"],
  ["Maricopa County", "04013"],
  ["Maries County", "29125"],
  ["Marin County", "06041"],
  ["Marinette County", "55075"],
  ["Marion County", "01093"],
  ["Marion County", "05089"],
  ["Marion County", "12083"],
  ["Marion County", "13197"],
  ["Marion County", "19125"],
  ["Marion County", "17121"],
  ["Marion County", "18097"],
  ["Marion County", "20115"],
  ["Marion County", "21155"],
  ["Marion County", "29127"],
  ["Marion County", "28091"],
  ["Marion County", "39101"],
  ["Marion County", "41047"],
  ["Marion County", "45067"],
  ["Marion County", "47115"],
  ["Marion County", "48315"],
  ["Marion County", "54049"],
  ["Mariposa County", "06043"],
  ["Marlboro County", "45069"],
  ["Marquette County", "26103"],
  ["Marquette County", "55077"],
  ["Marshall County", "01095"],
  ["Marshall County", "19127"],
  ["Marshall County", "17123"],
  ["Marshall County", "18099"],
  ["Marshall County", "20117"],
  ["Marshall County", "21157"],
  ["Marshall County", "27089"],
  ["Marshall County", "28093"],
  ["Marshall County", "40095"],
  ["Marshall County", "46091"],
  ["Marshall County", "47117"],
  ["Marshall County", "54051"],
  ["Martin County", "12085"],
  ["Martin County", "18101"],
  ["Martin County", "21159"],
  ["Martin County", "27091"],
  ["Martin County", "37117"],
  ["Martin County", "48317"],
  ["Martinsville City", "51690"],
  ["Mason County", "17125"],
  ["Mason County", "21161"],
  ["Mason County", "26105"],
  ["Mason County", "48319"],
  ["Mason County", "53045"],
  ["Mason County", "54053"],
  ["Massac County", "17127"],
  ["Matagorda County", "48321"],
  ["Matanuska-Susitna Borough", "02170"],
  ["Mathews County", "51115"],
  ["Maui County", "15009"],
  ["Maunabo Municipio", "72095"],
  ["Maury County", "47119"],
  ["Maverick County", "48323"],
  ["Mayaguez Municipio", "72097"],
  ["Mayes County", "40097"],
  ["Mcclain County", "40087"],
  ["Mccone County", "30055"],
  ["Mccook County", "46087"],
  ["Mccormick County", "45065"],
  ["Mccracken County", "21145"],
  ["Mccreary County", "21147"],
  ["Mcculloch County", "48307"],
  ["Mccurtain County", "40089"],
  ["Mcdonald County", "29119"],
  ["Mcdonough County", "17109"],
  ["Mcdowell County", "37111"],
  ["Mcdowell County", "54047"],
  ["Mcduffie County", "13189"],
  ["Mchenry County", "17111"],
  ["Mchenry County", "38049"],
  ["Mcintosh County", "13191"],
  ["Mcintosh County", "38051"],
  ["Mcintosh County", "40091"],
  ["Mckean County", "42083"],
  ["Mckenzie County", "38053"],
  ["Mckinley County", "35031"],
  ["Mclean County", "17113"],
  ["Mclean County", "21149"],
  ["Mclean County", "38055"],
  ["Mclennan County", "48309"],
  ["Mcleod County", "27085"],
  ["Mcminn County", "47107"],
  ["Mcmullen County", "48311"],
  ["Mcnairy County", "47109"],
  ["Mcpherson County", "20113"],
  ["Mcpherson County", "31117"],
  ["Mcpherson County", "46089"],
  ["Meade County", "20119"],
  ["Meade County", "21163"],
  ["Meade County", "46093"],
  ["Meagher County", "30059"],
  ["Mecklenburg County", "37119"],
  ["Mecklenburg County", "51117"],
  ["Mecosta County", "26107"],
  ["Medina County", "39103"],
  ["Medina County", "48325"],
  ["Meeker County", "27093"],
  ["Meigs County", "39105"],
  ["Meigs County", "47121"],
  ["Mellette County", "46095"],
  ["Menard County", "17129"],
  ["Menard County", "48327"],
  ["Mendocino County", "06045"],
  ["Menifee County", "21165"],
  ["Menominee County", "26109"],
  ["Menominee County", "55078"],
  ["Merced County", "06047"],
  ["Mercer County", "17131"],
  ["Mercer County", "21167"],
  ["Mercer County", "29129"],
  ["Mercer County", "38057"],
  ["Mercer County", "34021"],
  ["Mercer County", "39107"],
  ["Mercer County", "42085"],
  ["Mercer County", "54055"],
  ["Meriwether County", "13199"],
  ["Merrick County", "31121"],
  ["Merrimack County", "33013"],
  ["Mesa County", "08077"],
  ["Metcalfe County", "21169"],
  ["Miami County", "18103"],
  ["Miami County", "20121"],
  ["Miami County", "39109"],
  ["Miami-Dade County", "12086"],
  ["Middlesex County", "09007"],
  ["Middlesex County", "25017"],
  ["Middlesex County", "34023"],
  ["Middlesex County", "51119"],
  ["Midland County", "26111"],
  ["Midland County", "48329"],
  ["Mifflin County", "42087"],
  ["Milam County", "48331"],
  ["Millard County", "49027"],
  ["Mille Lacs County", "27095"],
  ["Miller County", "05091"],
  ["Miller County", "13201"],
  ["Miller County", "29131"],
  ["Mills County", "19129"],
  ["Mills County", "48333"],
  ["Milwaukee County", "55079"],
  ["Miner County", "46097"],
  ["Mineral County", "08079"],
  ["Mineral County", "30061"],
  ["Mineral County", "32021"],
  ["Mineral County", "54057"],
  ["Mingo County", "54059"],
  ["Minidoka County", "16067"],
  ["Minnehaha County", "46099"],
  ["Missaukee County", "26113"],
  ["Mississippi County", "05093"],
  ["Mississippi County", "29133"],
  ["Missoula County", "30063"],
  ["Mitchell County", "13205"],
  ["Mitchell County", "19131"],
  ["Mitchell County", "20123"],
  ["Mitchell County", "37121"],
  ["Mitchell County", "48335"],
  ["Mobile County", "01097"],
  ["Moca Municipio", "72099"],
  ["Modoc County", "06049"],
  ["Moffat County", "08081"],
  ["Mohave County", "04015"],
  ["Moniteau County", "29135"],
  ["Monmouth County", "34025"],
  ["Mono County", "06051"],
  ["Monona County", "19133"],
  ["Monongalia County", "54061"],
  ["Monroe County", "01099"],
  ["Monroe County", "05095"],
  ["Monroe County", "12087"],
  ["Monroe County", "13207"],
  ["Monroe County", "19135"],
  ["Monroe County", "17133"],
  ["Monroe County", "18105"],
  ["Monroe County", "21171"],
  ["Monroe County", "26115"],
  ["Monroe County", "29137"],
  ["Monroe County", "28095"],
  ["Monroe County", "36055"],
  ["Monroe County", "39111"],
  ["Monroe County", "42089"],
  ["Monroe County", "47123"],
  ["Monroe County", "55081"],
  ["Monroe County", "54063"],
  ["Montague County", "48337"],
  ["Montcalm County", "26117"],
  ["Monterey County", "06053"],
  ["Montezuma County", "08083"],
  ["Montgomery County", "01101"],
  ["Montgomery County", "05097"],
  ["Montgomery County", "13209"],
  ["Montgomery County", "19137"],
  ["Montgomery County", "17135"],
  ["Montgomery County", "18107"],
  ["Montgomery County", "20125"],
  ["Montgomery County", "21173"],
  ["Montgomery County", "24031"],
  ["Montgomery County", "29139"],
  ["Montgomery County", "28097"],
  ["Montgomery County", "37123"],
  ["Montgomery County", "36057"],
  ["Montgomery County", "39113"],
  ["Montgomery County", "42091"],
  ["Montgomery County", "47125"],
  ["Montgomery County", "48339"],
  ["Montgomery County", "51121"],
  ["Montmorency County", "26119"],
  ["Montour County", "42093"],
  ["Montrose County", "08085"],
  ["Moody County", "46101"],
  ["Moore County", "37125"],
  ["Moore County", "47127"],
  ["Moore County", "48341"],
  ["Mora County", "35033"],
  ["Morehouse Parish", "22067"],
  ["Morgan County", "01103"],
  ["Morgan County", "08087"],
  ["Morgan County", "13211"],
  ["Morgan County", "17137"],
  ["Morgan County", "18109"],
  ["Morgan County", "21175"],
  ["Morgan County", "29141"],
  ["Morgan County", "39115"],
  ["Morgan County", "47129"],
  ["Morgan County", "49029"],
  ["Morgan County", "54065"],
  ["Morovis Municipio", "72101"],
  ["Morrill County", "31123"],
  ["Morris County", "20127"],
  ["Morris County", "34027"],
  ["Morris County", "48343"],
  ["Morrison County", "27097"],
  ["Morrow County", "39117"],
  ["Morrow County", "41049"],
  ["Morton County", "20129"],
  ["Morton County", "38059"],
  ["Motley County", "48345"],
  ["Moultrie County", "17139"],
  ["Mountrail County", "38061"],
  ["Mower County", "27099"],
  ["Muhlenberg County", "21177"],
  ["Multnomah County", "41051"],
  ["Murray County", "13213"],
  ["Murray County", "27101"],
  ["Murray County", "40099"],
  ["Muscatine County", "19139"],
  ["Muscogee County", "13215"],
  ["Muskegon County", "26121"],
  ["Muskingum County", "39119"],
  ["Muskogee County", "40101"],
  ["Musselshell County", "30065"],
  ["Nacogdoches County", "48347"],
  ["Naguabo Municipio", "72103"],
  ["Nance County", "31125"],
  ["Nantucket County", "25019"],
  ["Napa County", "06055"],
  ["Naranjito Municipio", "72105"],
  ["Nash County", "37127"],
  ["Nassau County", "12089"],
  ["Nassau County", "36059"],
  ["Natchitoches Parish", "22069"],
  ["Natrona County", "56025"],
  ["Navajo County", "04017"],
  ["Navarro County", "48349"],
  ["Nelson County", "21179"],
  ["Nelson County", "38063"],
  ["Nelson County", "51125"],
  ["Nemaha County", "20131"],
  ["Nemaha County", "31127"],
  ["Neosho County", "20133"],
  ["Neshoba County", "28099"],
  ["Ness County", "20135"],
  ["Nevada County", "05099"],
  ["Nevada County", "06057"],
  ["New Castle County", "10003"],
  ["New Hanover County", "37129"],
  ["New Haven County", "09009"],
  ["New Kent County", "51127"],
  ["New London County", "09011"],
  ["New Madrid County", "29143"],
  ["New York County", "36061"],
  ["Newaygo County", "26123"],
  ["Newberry County", "45071"],
  ["Newport County", "44005"],
  ["Newport News City", "51700"],
  ["Newton County", "05101"],
  ["Newton County", "13217"],
  ["Newton County", "18111"],
  ["Newton County", "29145"],
  ["Newton County", "28101"],
  ["Newton County", "48351"],
  ["Nez Perce County", "16069"],
  ["Niagara County", "36063"],
  ["Nicholas County", "21181"],
  ["Nicholas County", "54067"],
  ["Nicollet County", "27103"],
  ["Niobrara County", "56027"],
  ["Noble County", "18113"],
  ["Noble County", "39121"],
  ["Noble County", "40103"],
  ["Nobles County", "27105"],
  ["Nodaway County", "29147"],
  ["Nolan County", "48353"],
  ["Nome Census Area", "02180"],
  ["Norfolk City", "51710"],
  ["Norfolk County", "25021"],
  ["Norman County", "27107"],
  ["North Slope Borough", "02185"],
  ["Northampton County", "37131"],
  ["Northampton County", "42095"],
  ["Northampton County", "51131"],
  ["Northern Islands Municipality", "69085"],
  ["Northumberland County", "42097"],
  ["Northumberland County", "51133"],
  ["Northwest Arctic Borough", "02188"],
  ["Norton City", "51720"],
  ["Norton County", "20137"],
  ["Nottoway County", "51135"],
  ["Nowata County", "40105"],
  ["Noxubee County", "28103"],
  ["Nuckolls County", "31129"],
  ["Nueces County", "48355"],
  ["Nye County", "32023"],
  ["O'Brien County", "19141"],
  ["Oakland County", "26125"],
  ["Obion County", "47131"],
  ["Ocean County", "34029"],
  ["Oceana County", "26127"],
  ["Ochiltree County", "48357"],
  ["Oconee County", "13219"],
  ["Oconee County", "45073"],
  ["Oconto County", "55083"],
  ["Ogemaw County", "26129"],
  ["Oglala Lakota County", "46102"],
  ["Ogle County", "17141"],
  ["Oglethorpe County", "13221"],
  ["Ohio County", "18115"],
  ["Ohio County", "21183"],
  ["Ohio County", "54069"],
  ["Okaloosa County", "12091"],
  ["Okanogan County", "53047"],
  ["Okeechobee County", "12093"],
  ["Okfuskee County", "40107"],
  ["Oklahoma County", "40109"],
  ["Okmulgee County", "40111"],
  ["Oktibbeha County", "28105"],
  ["Oldham County", "21185"],
  ["Oldham County", "48359"],
  ["Oliver County", "38065"],
  ["Olmsted County", "27109"],
  ["Oneida County", "16071"],
  ["Oneida County", "36065"],
  ["Oneida County", "55085"],
  ["Onondaga County", "36067"],
  ["Onslow County", "37133"],
  ["Ontario County", "36069"],
  ["Ontonagon County", "26131"],
  ["Orange County", "06059"],
  ["Orange County", "12095"],
  ["Orange County", "18117"],
  ["Orange County", "37135"],
  ["Orange County", "36071"],
  ["Orange County", "48361"],
  ["Orange County", "51137"],
  ["Orange County", "50017"],
  ["Orangeburg County", "45075"],
  ["Oregon County", "29149"],
  ["Orleans County", "36073"],
  ["Orleans County", "50019"],
  ["Orleans Parish", "22071"],
  ["Orocovis Municipio", "72107"],
  ["Osage County", "20139"],
  ["Osage County", "29151"],
  ["Osage County", "40113"],
  ["Osborne County", "20141"],
  ["Osceola County", "12097"],
  ["Osceola County", "19143"],
  ["Osceola County", "26133"],
  ["Oscoda County", "26135"],
  ["Oswego County", "36075"],
  ["Otero County", "08089"],
  ["Otero County", "35035"],
  ["Otoe County", "31131"],
  ["Otsego County", "26137"],
  ["Otsego County", "36077"],
  ["Ottawa County", "20143"],
  ["Ottawa County", "26139"],
  ["Ottawa County", "39123"],
  ["Ottawa County", "40115"],
  ["Otter Tail County", "27111"],
  ["Ouachita County", "05103"],
  ["Ouachita Parish", "22073"],
  ["Ouray County", "08091"],
  ["Outagamie County", "55087"],
  ["Overton County", "47133"],
  ["Owen County", "18119"],
  ["Owen County", "21187"],
  ["Owsley County", "21189"],
  ["Owyhee County", "16073"],
  ["Oxford County", "23017"],
  ["Ozark County", "29153"],
  ["Ozaukee County", "55089"],
  ["Pacific County", "53049"],
  ["Page County", "19145"],
  ["Page County", "51139"],
  ["Palm Beach County", "12099"],
  ["Palo Alto County", "19147"],
  ["Palo Pinto County", "48363"],
  ["Pamlico County", "37137"],
  ["Panola County", "28107"],
  ["Panola County", "48365"],
  ["Park County", "08093"],
  ["Park County", "30067"],
  ["Park County", "56029"],
  ["Parke County", "18121"],
  ["Parker County", "48367"],
  ["Parmer County", "48369"],
  ["Pasco County", "12101"],
  ["Pasquotank County", "37139"],
  ["Passaic County", "34031"],
  ["Patillas Municipio", "72109"],
  ["Patrick County", "51141"],
  ["Paulding County", "13223"],
  ["Paulding County", "39125"],
  ["Pawnee County", "20145"],
  ["Pawnee County", "31133"],
  ["Pawnee County", "40117"],
  ["Payette County", "16075"],
  ["Payne County", "40119"],
  ["Peach County", "13225"],
  ["Pearl River County", "28109"],
  ["Pecos County", "48371"],
  ["Pembina County", "38067"],
  ["Pemiscot County", "29155"],
  ["Pend Oreille County", "53051"],
  ["Pender County", "37141"],
  ["Pendleton County", "21191"],
  ["Pendleton County", "54071"],
  ["Pennington County", "27113"],
  ["Pennington County", "46103"],
  ["Penobscot County", "23019"],
  ["Penuelas Municipio", "72111"],
  ["Peoria County", "17143"],
  ["Pepin County", "55091"],
  ["Perkins County", "31135"],
  ["Perkins County", "46105"],
  ["Perquimans County", "37143"],
  ["Perry County", "01105"],
  ["Perry County", "05105"],
  ["Perry County", "17145"],
  ["Perry County", "18123"],
  ["Perry County", "21193"],
  ["Perry County", "29157"],
  ["Perry County", "28111"],
  ["Perry County", "39127"],
  ["Perry County", "42099"],
  ["Perry County", "47135"],
  ["Pershing County", "32027"],
  ["Person County", "37145"],
  ["Petersburg Census Area", "02195"],
  ["Petersburg City", "51730"],
  ["Petroleum County", "30069"],
  ["Pettis County", "29159"],
  ["Phelps County", "29161"],
  ["Phelps County", "31137"],
  ["Philadelphia County", "42101"],
  ["Phillips County", "05107"],
  ["Phillips County", "08095"],
  ["Phillips County", "20147"],
  ["Phillips County", "30071"],
  ["Piatt County", "17147"],
  ["Pickaway County", "39129"],
  ["Pickens County", "01107"],
  ["Pickens County", "13227"],
  ["Pickens County", "45077"],
  ["Pickett County", "47137"],
  ["Pierce County", "13229"],
  ["Pierce County", "38069"],
  ["Pierce County", "31139"],
  ["Pierce County", "53053"],
  ["Pierce County", "55093"],
  ["Pike County", "01109"],
  ["Pike County", "05109"],
  ["Pike County", "13231"],
  ["Pike County", "17149"],
  ["Pike County", "18125"],
  ["Pike County", "21195"],
  ["Pike County", "29163"],
  ["Pike County", "28113"],
  ["Pike County", "39131"],
  ["Pike County", "42103"],
  ["Pima County", "04019"],
  ["Pinal County", "04021"],
  ["Pine County", "27115"],
  ["Pinellas County", "12103"],
  ["Pipestone County", "27117"],
  ["Piscataquis County", "23021"],
  ["Pitkin County", "08097"],
  ["Pitt County", "37147"],
  ["Pittsburg County", "40121"],
  ["Pittsylvania County", "51143"],
  ["Piute County", "49031"],
  ["Placer County", "06061"],
  ["Plaquemines Parish", "22075"],
  ["Platte County", "29165"],
  ["Platte County", "31141"],
  ["Platte County", "56031"],
  ["Pleasants County", "54073"],
  ["Plumas County", "06063"],
  ["Plymouth County", "19149"],
  ["Plymouth County", "25023"],
  ["Pocahontas County", "19151"],
  ["Pocahontas County", "54075"],
  ["Poinsett County", "05111"],
  ["Pointe Coupee Parish", "22077"],
  ["Polk County", "05113"],
  ["Polk County", "12105"],
  ["Polk County", "13233"],
  ["Polk County", "19153"],
  ["Polk County", "27119"],
  ["Polk County", "29167"],
  ["Polk County", "37149"],
  ["Polk County", "31143"],
  ["Polk County", "41053"],
  ["Polk County", "47139"],
  ["Polk County", "48373"],
  ["Polk County", "55095"],
  ["Ponce Municipio", "72113"],
  ["Pondera County", "30073"],
  ["Pontotoc County", "28115"],
  ["Pontotoc County", "40123"],
  ["Pope County", "05115"],
  ["Pope County", "17151"],
  ["Pope County", "27121"],
  ["Poquoson City", "51735"],
  ["Portage County", "39133"],
  ["Portage County", "55097"],
  ["Porter County", "18127"],
  ["Portsmouth City", "51740"],
  ["Posey County", "18129"],
  ["Pottawatomie County", "20149"],
  ["Pottawatomie County", "40125"],
  ["Pottawattamie County", "19155"],
  ["Potter County", "42105"],
  ["Potter County", "46107"],
  ["Potter County", "48375"],
  ["Powder River County", "30075"],
  ["Powell County", "21197"],
  ["Powell County", "30077"],
  ["Power County", "16077"],
  ["Poweshiek County", "19157"],
  ["Powhatan County", "51145"],
  ["Prairie County", "05117"],
  ["Prairie County", "30079"],
  ["Pratt County", "20151"],
  ["Preble County", "39135"],
  ["Prentiss County", "28117"],
  ["Presidio County", "48377"],
  ["Presque Isle County", "26141"],
  ["Preston County", "54077"],
  ["Price County", "55099"],
  ["Prince Edward County", "51147"],
  ["Prince George County", "51149"],
  ["Prince George'S County", "24033"],
  ["Prince Of Wales-Hyder Census Area", "02198"],
  ["Prince William County", "51153"],
  ["Providence County", "44007"],
  ["Prowers County", "08099"],
  ["Pueblo County", "08101"],
  ["Pulaski County", "05119"],
  ["Pulaski County", "13235"],
  ["Pulaski County", "17153"],
  ["Pulaski County", "18131"],
  ["Pulaski County", "21199"],
  ["Pulaski County", "29169"],
  ["Pulaski County", "51155"],
  ["Pushmataha County", "40127"],
  ["Putnam County", "12107"],
  ["Putnam County", "13237"],
  ["Putnam County", "17155"],
  ["Putnam County", "18133"],
  ["Putnam County", "29171"],
  ["Putnam County", "36079"],
  ["Putnam County", "39137"],
  ["Putnam County", "47141"],
  ["Putnam County", "54079"],
  ["Quay County", "35037"],
  ["Quebradillas Municipio", "72115"],
  ["Queen Anne'S County", "24035"],
  ["Queens County", "36081"],
  ["Quitman County", "13239"],
  ["Quitman County", "28119"],
  ["Rabun County", "13241"],
  ["Racine County", "55101"],
  ["Radford City", "51750"],
  ["Rains County", "48379"],
  ["Raleigh County", "54081"],
  ["Ralls County", "29173"],
  ["Ramsey County", "27123"],
  ["Ramsey County", "38071"],
  ["Randall County", "48381"],
  ["Randolph County", "01111"],
  ["Randolph County", "05121"],
  ["Randolph County", "13243"],
  ["Randolph County", "17157"],
  ["Randolph County", "18135"],
  ["Randolph County", "29175"],
  ["Randolph County", "37151"],
  ["Randolph County", "54083"],
  ["Rankin County", "28121"],
  ["Ransom County", "38073"],
  ["Rapides Parish", "22079"],
  ["Rappahannock County", "51157"],
  ["Ravalli County", "30081"],
  ["Rawlins County", "20153"],
  ["Ray County", "29177"],
  ["Reagan County", "48383"],
  ["Real County", "48385"],
  ["Red Lake County", "27125"],
  ["Red River County", "48387"],
  ["Red River Parish", "22081"],
  ["Red Willow County", "31145"],
  ["Redwood County", "27127"],
  ["Reeves County", "48389"],
  ["Refugio County", "48391"],
  ["Reno County", "20155"],
  ["Rensselaer County", "36083"],
  ["Renville County", "27129"],
  ["Renville County", "38075"],
  ["Republic County", "20157"],
  ["Reynolds County", "29179"],
  ["Rhea County", "47143"],
  ["Rice County", "20159"],
  ["Rice County", "27131"],
  ["Rich County", "49033"],
  ["Richardson County", "31147"],
  ["Richland County", "17159"],
  ["Richland County", "30083"],
  ["Richland County", "38077"],
  ["Richland County", "39139"],
  ["Richland County", "45079"],
  ["Richland County", "55103"],
  ["Richland Parish", "22083"],
  ["Richmond City", "51760"],
  ["Richmond County", "13245"],
  ["Richmond County", "37153"],
  ["Richmond County", "36085"],
  ["Richmond County", "51159"],
  ["Riley County", "20161"],
  ["Rincon Municipio", "72117"],
  ["Ringgold County", "19159"],
  ["Rio Arriba County", "35039"],
  ["Rio Blanco County", "08103"],
  ["Rio Grande County", "08105"],
  ["Rio Grande Municipio", "72119"],
  ["Ripley County", "18137"],
  ["Ripley County", "29181"],
  ["Ritchie County", "54085"],
  ["Riverside County", "06065"],
  ["Roane County", "47145"],
  ["Roane County", "54087"],
  ["Roanoke City", "51770"],
  ["Roanoke County", "51161"],
  ["Roberts County", "46109"],
  ["Roberts County", "48393"],
  ["Robertson County", "21201"],
  ["Robertson County", "47147"],
  ["Robertson County", "48395"],
  ["Robeson County", "37155"],
  ["Rock County", "27133"],
  ["Rock County", "31149"],
  ["Rock County", "55105"],
  ["Rock Island County", "17161"],
  ["Rockbridge County", "51163"],
  ["Rockcastle County", "21203"],
  ["Rockdale County", "13247"],
  ["Rockingham County", "37157"],
  ["Rockingham County", "33015"],
  ["Rockingham County", "51165"],
  ["Rockland County", "36087"],
  ["Rockwall County", "48397"],
  ["Roger Mills County", "40129"],
  ["Rogers County", "40131"],
  ["Rolette County", "38079"],
  ["Rooks County", "20163"],
  ["Roosevelt County", "30085"],
  ["Roosevelt County", "35041"],
  ["Roscommon County", "26143"],
  ["Rose Island", "60030"],
  ["Roseau County", "27135"],
  ["Rosebud County", "30087"],
  ["Ross County", "39141"],
  ["Rota Municipality", "69100"],
  ["Routt County", "08107"],
  ["Rowan County", "21205"],
  ["Rowan County", "37159"],
  ["Runnels County", "48399"],
  ["Rush County", "18139"],
  ["Rush County", "20165"],
  ["Rusk County", "48401"],
  ["Rusk County", "55107"],
  ["Russell County", "01113"],
  ["Russell County", "20167"],
  ["Russell County", "21207"],
  ["Russell County", "51167"],
  ["Rutherford County", "37161"],
  ["Rutherford County", "47149"],
  ["Rutland County", "50021"],
  ["Sabana Grande Municipio", "72121"],
  ["Sabine County", "48403"],
  ["Sabine Parish", "22085"],
  ["Sac County", "19161"],
  ["Sacramento County", "06067"],
  ["Sagadahoc County", "23023"],
  ["Saginaw County", "26145"],
  ["Saguache County", "08109"],
  ["Saipan Municipality", "69110"],
  ["Salem City", "51775"],
  ["Salem County", "34033"],
  ["Salinas Municipio", "72123"],
  ["Saline County", "05125"],
  ["Saline County", "17165"],
  ["Saline County", "20169"],
  ["Saline County", "29195"],
  ["Saline County", "31151"],
  ["Salt Lake County", "49035"],
  ["Saluda County", "45081"],
  ["Sampson County", "37163"],
  ["San Augustine County", "48405"],
  ["San Benito County", "06069"],
  ["San Bernardino County", "06071"],
  ["San Diego County", "06073"],
  ["San Francisco County", "06075"],
  ["San German Municipio", "72125"],
  ["San Jacinto County", "48407"],
  ["San Joaquin County", "06077"],
  ["San Juan County", "08111"],
  ["San Juan County", "35045"],
  ["San Juan County", "49037"],
  ["San Juan County", "53055"],
  ["San Juan Municipio", "72127"],
  ["San Lorenzo Municipio", "72129"],
  ["San Luis Obispo County", "06079"],
  ["San Mateo County", "06081"],
  ["San Miguel County", "08113"],
  ["San Miguel County", "35047"],
  ["San Patricio County", "48409"],
  ["San Saba County", "48411"],
  ["San Sebastian Municipio", "72131"],
  ["Sanborn County", "46111"],
  ["Sanders County", "30089"],
  ["Sandoval County", "35043"],
  ["Sandusky County", "39143"],
  ["Sangamon County", "17167"],
  ["Sanilac County", "26151"],
  ["Sanpete County", "49039"],
  ["Santa Barbara County", "06083"],
  ["Santa Clara County", "06085"],
  ["Santa Cruz County", "04023"],
  ["Santa Cruz County", "06087"],
  ["Santa Fe County", "35049"],
  ["Santa Isabel Municipio", "72133"],
  ["Santa Rosa County", "12113"],
  ["Sarasota County", "12115"],
  ["Saratoga County", "36091"],
  ["Sargent County", "38081"],
  ["Sarpy County", "31153"],
  ["Sauk County", "55111"],
  ["Saunders County", "31155"],
  ["Sawyer County", "55113"],
  ["Schenectady County", "36093"],
  ["Schleicher County", "48413"],
  ["Schley County", "13249"],
  ["Schoharie County", "36095"],
  ["Schoolcraft County", "26153"],
  ["Schuyler County", "17169"],
  ["Schuyler County", "29197"],
  ["Schuyler County", "36097"],
  ["Schuylkill County", "42107"],
  ["Scioto County", "39145"],
  ["Scotland County", "29199"],
  ["Scotland County", "37165"],
  ["Scott County", "05127"],
  ["Scott County", "19163"],
  ["Scott County", "17171"],
  ["Scott County", "18143"],
  ["Scott County", "20171"],
  ["Scott County", "21209"],
  ["Scott County", "27139"],
  ["Scott County", "29201"],
  ["Scott County", "28123"],
  ["Scott County", "47151"],
  ["Scott County", "51169"],
  ["Scotts Bluff County", "31157"],
  ["Screven County", "13251"],
  ["Scurry County", "48415"],
  ["Searcy County", "05129"],
  ["Sebastian County", "05131"],
  ["Sedgwick County", "08115"],
  ["Sedgwick County", "20173"],
  ["Seminole County", "12117"],
  ["Seminole County", "13253"],
  ["Seminole County", "40133"],
  ["Seneca County", "36099"],
  ["Seneca County", "39147"],
  ["Sequatchie County", "47153"],
  ["Sequoyah County", "40135"],
  ["Sevier County", "05133"],
  ["Sevier County", "47155"],
  ["Sevier County", "49041"],
  ["Seward County", "20175"],
  ["Seward County", "31159"],
  ["Shackelford County", "48417"],
  ["Shannon County", "29203"],
  ["Sharkey County", "28125"],
  ["Sharp County", "05135"],
  ["Shasta County", "06089"],
  ["Shawano County", "55115"],
  ["Shawnee County", "20177"],
  ["Sheboygan County", "55117"],
  ["Shelby County", "01117"],
  ["Shelby County", "19165"],
  ["Shelby County", "17173"],
  ["Shelby County", "18145"],
  ["Shelby County", "21211"],
  ["Shelby County", "29205"],
  ["Shelby County", "39149"],
  ["Shelby County", "47157"],
  ["Shelby County", "48419"],
  ["Shenandoah County", "51171"],
  ["Sherburne County", "27141"],
  ["Sheridan County", "20179"],
  ["Sheridan County", "30091"],
  ["Sheridan County", "38083"],
  ["Sheridan County", "31161"],
  ["Sheridan County", "56033"],
  ["Sherman County", "20181"],
  ["Sherman County", "31163"],
  ["Sherman County", "41055"],
  ["Sherman County", "48421"],
  ["Shiawassee County", "26155"],
  ["Shoshone County", "16079"],
  ["Sibley County", "27143"],
  ["Sierra County", "06091"],
  ["Sierra County", "35051"],
  ["Silver Bow County", "30093"],
  ["Simpson County", "21213"],
  ["Simpson County", "28127"],
  ["Sioux County", "19167"],
  ["Sioux County", "38085"],
  ["Sioux County", "31165"],
  ["Siskiyou County", "06093"],
  ["Sitka City And Borough", "02220"],
  ["Skagit County", "53057"],
  ["Skagway Municipality", "02230"],
  ["Skamania County", "53059"],
  ["Slope County", "38087"],
  ["Smith County", "20183"],
  ["Smith County", "28129"],
  ["Smith County", "47159"],
  ["Smith County", "48423"],
  ["Smyth County", "51173"],
  ["Snohomish County", "53061"],
  ["Snyder County", "42109"],
  ["Socorro County", "35053"],
  ["Solano County", "06095"],
  ["Somerset County", "24039"],
  ["Somerset County", "23025"],
  ["Somerset County", "34035"],
  ["Somerset County", "42111"],
  ["Somervell County", "48425"],
  ["Sonoma County", "06097"],
  ["Southampton County", "51175"],
  ["Southeast Fairbanks Census Area", "02240"],
  ["Spalding County", "13255"],
  ["Spartanburg County", "45083"],
  ["Spencer County", "18147"],
  ["Spencer County", "21215"],
  ["Spink County", "46115"],
  ["Spokane County", "53063"],
  ["Spotsylvania County", "51177"],
  ["St. Bernard Parish", "22087"],
  ["St. Charles County", "29183"],
  ["St. Charles Parish", "22089"],
  ["St. Clair County", "01115"],
  ["St. Clair County", "17163"],
  ["St. Clair County", "26147"],
  ["St. Clair County", "29185"],
  ["St. Croix County", "55109"],
  ["St. Croix Island", "78010"],
  ["St. Francis County", "05123"],
  ["St. Francois County", "29187"],
  ["St. Helena Parish", "22091"],
  ["St. James Parish", "22093"],
  ["St. John Island", "78020"],
  ["St. John The Baptist Parish", "22095"],
  ["St. Johns County", "12109"],
  ["St. Joseph County", "18141"],
  ["St. Joseph County", "26149"],
  ["St. Landry Parish", "22097"],
  ["St. Lawrence County", "36089"],
  ["St. Louis City", "29510"],
  ["St. Louis County", "27137"],
  ["St. Louis County", "29189"],
  ["St. Lucie County", "12111"],
  ["St. Martin Parish", "22099"],
  ["St. Mary Parish", "22101"],
  ["St. Mary'S County", "24037"],
  ["St. Tammany Parish", "22103"],
  ["St. Thomas Island", "78030"],
  ["Stafford County", "20185"],
  ["Stafford County", "51179"],
  ["Stanislaus County", "06099"],
  ["Stanley County", "46117"],
  ["Stanly County", "37167"],
  ["Stanton County", "20187"],
  ["Stanton County", "31167"],
  ["Stark County", "17175"],
  ["Stark County", "38089"],
  ["Stark County", "39151"],
  ["Starke County", "18149"],
  ["Starr County", "48427"],
  ["Staunton City", "51790"],
  ["Ste. Genevieve County", "29186"],
  ["Stearns County", "27145"],
  ["Steele County", "27147"],
  ["Steele County", "38091"],
  ["Stephens County", "13257"],
  ["Stephens County", "40137"],
  ["Stephens County", "48429"],
  ["Stephenson County", "17177"],
  ["Sterling County", "48431"],
  ["Steuben County", "18151"],
  ["Steuben County", "36101"],
  ["Stevens County", "20189"],
  ["Stevens County", "27149"],
  ["Stevens County", "53065"],
  ["Stewart County", "13259"],
  ["Stewart County", "47161"],
  ["Stillwater County", "30095"],
  ["Stoddard County", "29207"],
  ["Stokes County", "37169"],
  ["Stone County", "05137"],
  ["Stone County", "29209"],
  ["Stone County", "28131"],
  ["Stonewall County", "48433"],
  ["Storey County", "32029"],
  ["Story County", "19169"],
  ["Strafford County", "33017"],
  ["Stutsman County", "38093"],
  ["Sublette County", "56035"],
  ["Suffolk City", "51800"],
  ["Suffolk County", "25025"],
  ["Suffolk County", "36103"],
  ["Sullivan County", "18153"],
  ["Sullivan County", "29211"],
  ["Sullivan County", "33019"],
  ["Sullivan County", "36105"],
  ["Sullivan County", "42113"],
  ["Sullivan County", "47163"],
  ["Sully County", "46119"],
  ["Summers County", "54089"],
  ["Summit County", "08117"],
  ["Summit County", "39153"],
  ["Summit County", "49043"],
  ["Sumner County", "20191"],
  ["Sumner County", "47165"],
  ["Sumter County", "01119"],
  ["Sumter County", "12119"],
  ["Sumter County", "13261"],
  ["Sumter County", "45085"],
  ["Sunflower County", "28133"],
  ["Surry County", "37171"],
  ["Surry County", "51181"],
  ["Susquehanna County", "42115"],
  ["Sussex County", "10005"],
  ["Sussex County", "34037"],
  ["Sussex County", "51183"],
  ["Sutter County", "06101"],
  ["Sutton County", "48435"],
  ["Suwannee County", "12121"],
  ["Swain County", "37173"],
  ["Swains Island", "60040"],
  ["Sweet Grass County", "30097"],
  ["Sweetwater County", "56037"],
  ["Swift County", "27151"],
  ["Swisher County", "48437"],
  ["Switzerland County", "18155"],
  ["Talbot County", "13263"],
  ["Talbot County", "24041"],
  ["Taliaferro County", "13265"],
  ["Talladega County", "01121"],
  ["Tallahatchie County", "28135"],
  ["Tallapoosa County", "01123"],
  ["Tama County", "19171"],
  ["Taney County", "29213"],
  ["Tangipahoa Parish", "22105"],
  ["Taos County", "35055"],
  ["Tarrant County", "48439"],
  ["Tate County", "28137"],
  ["Tattnall County", "13267"],
  ["Taylor County", "12123"],
  ["Taylor County", "13269"],
  ["Taylor County", "19173"],
  ["Taylor County", "21217"],
  ["Taylor County", "48441"],
  ["Taylor County", "55119"],
  ["Taylor County", "54091"],
  ["Tazewell County", "17179"],
  ["Tazewell County", "51185"],
  ["Tehama County", "06103"],
  ["Telfair County", "13271"],
  ["Teller County", "08119"],
  ["Tensas Parish", "22107"],
  ["Terrebonne Parish", "22109"],
  ["Terrell County", "13273"],
  ["Terrell County", "48443"],
  ["Terry County", "48445"],
  ["Teton County", "16081"],
  ["Teton County", "30099"],
  ["Teton County", "56039"],
  ["Texas County", "29215"],
  ["Texas County", "40139"],
  ["Thayer County", "31169"],
  ["Thomas County", "13275"],
  ["Thomas County", "20193"],
  ["Thomas County", "31171"],
  ["Throckmorton County", "48447"],
  ["Thurston County", "31173"],
  ["Thurston County", "53067"],
  ["Tift County", "13277"],
  ["Tillamook County", "41057"],
  ["Tillman County", "40141"],
  ["Tinian Municipality", "69120"],
  ["Tioga County", "36107"],
  ["Tioga County", "42117"],
  ["Tippah County", "28139"],
  ["Tippecanoe County", "18157"],
  ["Tipton County", "18159"],
  ["Tipton County", "47167"],
  ["Tishomingo County", "28141"],
  ["Titus County", "48449"],
  ["Toa Alta Municipio", "72135"],
  ["Toa Baja Municipio", "72137"],
  ["Todd County", "21219"],
  ["Todd County", "27153"],
  ["Todd County", "46121"],
  ["Tolland County", "09013"],
  ["Tom Green County", "48451"],
  ["Tompkins County", "36109"],
  ["Tooele County", "49045"],
  ["Toole County", "30101"],
  ["Toombs County", "13279"],
  ["Torrance County", "35057"],
  ["Towner County", "38095"],
  ["Towns County", "13281"],
  ["Traill County", "38097"],
  ["Transylvania County", "37175"],
  ["Traverse County", "27155"],
  ["Travis County", "48453"],
  ["Treasure County", "30103"],
  ["Trego County", "20195"],
  ["Trempealeau County", "55121"],
  ["Treutlen County", "13283"],
  ["Trigg County", "21221"],
  ["Trimble County", "21223"],
  ["Trinity County", "06105"],
  ["Trinity County", "48455"],
  ["Tripp County", "46123"],
  ["Troup County", "13285"],
  ["Trousdale County", "47169"],
  ["Trujillo Alto Municipio", "72139"],
  ["Trumbull County", "39155"],
  ["Tucker County", "54093"],
  ["Tulare County", "06107"],
  ["Tulsa County", "40143"],
  ["Tunica County", "28143"],
  ["Tuolumne County", "06109"],
  ["Turner County", "13287"],
  ["Turner County", "46125"],
  ["Tuscaloosa County", "01125"],
  ["Tuscarawas County", "39157"],
  ["Tuscola County", "26157"],
  ["Twiggs County", "13289"],
  ["Twin Falls County", "16083"],
  ["Tyler County", "48457"],
  ["Tyler County", "54095"],
  ["Tyrrell County", "37177"],
  ["Uinta County", "56041"],
  ["Uintah County", "49047"],
  ["Ulster County", "36111"],
  ["Umatilla County", "41059"],
  ["Unicoi County", "47171"],
  ["Union County", "05139"],
  ["Union County", "12125"],
  ["Union County", "13291"],
  ["Union County", "19175"],
  ["Union County", "17181"],
  ["Union County", "18161"],
  ["Union County", "21225"],
  ["Union County", "28145"],
  ["Union County", "37179"],
  ["Union County", "34039"],
  ["Union County", "35059"],
  ["Union County", "39159"],
  ["Union County", "41061"],
  ["Union County", "42119"],
  ["Union County", "45087"],
  ["Union County", "46127"],
  ["Union County", "47173"],
  ["Union Parish", "22111"],
  ["Upshur County", "48459"],
  ["Upshur County", "54097"],
  ["Upson County", "13293"],
  ["Upton County", "48461"],
  ["Utah County", "49049"],
  ["Utuado Municipio", "72141"],
  ["Uvalde County", "48463"],
  ["Val Verde County", "48465"],
  ["Valdez-Cordova Census Area", "02261"],
  ["Valencia County", "35061"],
  ["Valley County", "16085"],
  ["Valley County", "30105"],
  ["Valley County", "31175"],
  ["Van Buren County", "05141"],
  ["Van Buren County", "19177"],
  ["Van Buren County", "26159"],
  ["Van Buren County", "47175"],
  ["Van Wert County", "39161"],
  ["Van Zandt County", "48467"],
  ["Vance County", "37181"],
  ["Vanderburgh County", "18163"],
  ["Vega Alta Municipio", "72143"],
  ["Vega Baja Municipio", "72145"],
  ["Venango County", "42121"],
  ["Ventura County", "06111"],
  ["Vermilion County", "17183"],
  ["Vermilion Parish", "22113"],
  ["Vermillion County", "18165"],
  ["Vernon County", "29217"],
  ["Vernon County", "55123"],
  ["Vernon Parish", "22115"],
  ["Victoria County", "48469"],
  ["Vieques Municipio", "72147"],
  ["Vigo County", "18167"],
  ["Vilas County", "55125"],
  ["Villalba Municipio", "72149"],
  ["Vinton County", "39163"],
  ["Virginia Beach City", "51810"],
  ["Volusia County", "12127"],
  ["Wabash County", "17185"],
  ["Wabash County", "18169"],
  ["Wabasha County", "27157"],
  ["Wabaunsee County", "20197"],
  ["Wadena County", "27159"],
  ["Wagoner County", "40145"],
  ["Wahkiakum County", "53069"],
  ["Wake County", "37183"],
  ["Wakulla County", "12129"],
  ["Waldo County", "23027"],
  ["Walker County", "01127"],
  ["Walker County", "13295"],
  ["Walker County", "48471"],
  ["Walla Walla County", "53071"],
  ["Wallace County", "20199"],
  ["Waller County", "48473"],
  ["Wallowa County", "41063"],
  ["Walsh County", "38099"],
  ["Walthall County", "28147"],
  ["Walton County", "12131"],
  ["Walton County", "13297"],
  ["Walworth County", "46129"],
  ["Walworth County", "55127"],
  ["Wapello County", "19179"],
  ["Ward County", "38101"],
  ["Ward County", "48475"],
  ["Ware County", "13299"],
  ["Warren County", "13301"],
  ["Warren County", "19181"],
  ["Warren County", "17187"],
  ["Warren County", "18171"],
  ["Warren County", "21227"],
  ["Warren County", "29219"],
  ["Warren County", "28149"],
  ["Warren County", "37185"],
  ["Warren County", "34041"],
  ["Warren County", "36113"],
  ["Warren County", "39165"],
  ["Warren County", "42123"],
  ["Warren County", "47177"],
  ["Warren County", "51187"],
  ["Warrick County", "18173"],
  ["Wasatch County", "49051"],
  ["Wasco County", "41065"],
  ["Waseca County", "27161"],
  ["Washakie County", "56043"],
  ["Washburn County", "55129"],
  ["Washington County", "01129"],
  ["Washington County", "05143"],
  ["Washington County", "08121"],
  ["Washington County", "12133"],
  ["Washington County", "13303"],
  ["Washington County", "19183"],
  ["Washington County", "16087"],
  ["Washington County", "17189"],
  ["Washington County", "18175"],
  ["Washington County", "20201"],
  ["Washington County", "21229"],
  ["Washington County", "24043"],
  ["Washington County", "23029"],
  ["Washington County", "27163"],
  ["Washington County", "29221"],
  ["Washington County", "28151"],
  ["Washington County", "37187"],
  ["Washington County", "31177"],
  ["Washington County", "36115"],
  ["Washington County", "39167"],
  ["Washington County", "40147"],
  ["Washington County", "41067"],
  ["Washington County", "42125"],
  ["Washington County", "44009"],
  ["Washington County", "47179"],
  ["Washington County", "48477"],
  ["Washington County", "49053"],
  ["Washington County", "51191"],
  ["Washington County", "50023"],
  ["Washington County", "55131"],
  ["Washington Parish", "22117"],
  ["Washita County", "40149"],
  ["Washoe County", "32031"],
  ["Washtenaw County", "26161"],
  ["Watauga County", "37189"],
  ["Watonwan County", "27165"],
  ["Waukesha County", "55133"],
  ["Waupaca County", "55135"],
  ["Waushara County", "55137"],
  ["Wayne County", "13305"],
  ["Wayne County", "19185"],
  ["Wayne County", "17191"],
  ["Wayne County", "18177"],
  ["Wayne County", "21231"],
  ["Wayne County", "26163"],
  ["Wayne County", "29223"],
  ["Wayne County", "28153"],
  ["Wayne County", "37191"],
  ["Wayne County", "31179"],
  ["Wayne County", "36117"],
  ["Wayne County", "39169"],
  ["Wayne County", "42127"],
  ["Wayne County", "47181"],
  ["Wayne County", "49055"],
  ["Wayne County", "54099"],
  ["Waynesboro City", "51820"],
  ["Weakley County", "47183"],
  ["Webb County", "48479"],
  ["Weber County", "49057"],
  ["Webster County", "13307"],
  ["Webster County", "19187"],
  ["Webster County", "21233"],
  ["Webster County", "29225"],
  ["Webster County", "28155"],
  ["Webster County", "31181"],
  ["Webster County", "54101"],
  ["Webster Parish", "22119"],
  ["Weld County", "08123"],
  ["Wells County", "18179"],
  ["Wells County", "38103"],
  ["West Baton Rouge Parish", "22121"],
  ["West Carroll Parish", "22123"],
  ["West Feliciana Parish", "22125"],
  ["Westchester County", "36119"],
  ["Western District", "60050"],
  ["Westmoreland County", "42129"],
  ["Westmoreland County", "51193"],
  ["Weston County", "56045"],
  ["Wetzel County", "54103"],
  ["Wexford County", "26165"],
  ["Wharton County", "48481"],
  ["Whatcom County", "53073"],
  ["Wheatland County", "30107"],
  ["Wheeler County", "13309"],
  ["Wheeler County", "31183"],
  ["Wheeler County", "41069"],
  ["Wheeler County", "48483"],
  ["White County", "05145"],
  ["White County", "13311"],
  ["White County", "17193"],
  ["White County", "18181"],
  ["White County", "47185"],
  ["White Pine County", "32033"],
  ["Whiteside County", "17195"],
  ["Whitfield County", "13313"],
  ["Whitley County", "18183"],
  ["Whitley County", "21235"],
  ["Whitman County", "53075"],
  ["Wibaux County", "30109"],
  ["Wichita County", "20203"],
  ["Wichita County", "48485"],
  ["Wicomico County", "24045"],
  ["Wilbarger County", "48487"],
  ["Wilcox County", "01131"],
  ["Wilcox County", "13315"],
  ["Wilkes County", "13317"],
  ["Wilkes County", "37193"],
  ["Wilkin County", "27167"],
  ["Wilkinson County", "13319"],
  ["Wilkinson County", "28157"],
  ["Will County", "17197"],
  ["Willacy County", "48489"],
  ["Williams County", "38105"],
  ["Williams County", "39171"],
  ["Williamsburg City", "51830"],
  ["Williamsburg County", "45089"],
  ["Williamson County", "17199"],
  ["Williamson County", "47187"],
  ["Williamson County", "48491"],
  ["Wilson County", "20205"],
  ["Wilson County", "37195"],
  ["Wilson County", "47189"],
  ["Wilson County", "48493"],
  ["Winchester City", "51840"],
  ["Windham County", "09015"],
  ["Windham County", "50025"],
  ["Windsor County", "50027"],
  ["Winkler County", "48495"],
  ["Winn Parish", "22127"],
  ["Winnebago County", "19189"],
  ["Winnebago County", "17201"],
  ["Winnebago County", "55139"],
  ["Winneshiek County", "19191"],
  ["Winona County", "27169"],
  ["Winston County", "01133"],
  ["Winston County", "28159"],
  ["Wirt County", "54105"],
  ["Wise County", "48497"],
  ["Wise County", "51195"],
  ["Wolfe County", "21237"],
  ["Wood County", "39173"],
  ["Wood County", "48499"],
  ["Wood County", "55141"],
  ["Wood County", "54107"],
  ["Woodbury County", "19193"],
  ["Woodford County", "17203"],
  ["Woodford County", "21239"],
  ["Woodruff County", "05147"],
  ["Woods County", "40151"],
  ["Woodson County", "20207"],
  ["Woodward County", "40153"],
  ["Worcester County", "25027"],
  ["Worcester County", "24047"],
  ["Worth County", "13321"],
  ["Worth County", "19195"],
  ["Worth County", "29227"],
  ["Wrangell City And Borough", "02275"],
  ["Wright County", "19197"],
  ["Wright County", "27171"],
  ["Wright County", "29229"],
  ["Wyandot County", "39175"],
  ["Wyandotte County", "20209"],
  ["Wyoming County", "36121"],
  ["Wyoming County", "42131"],
  ["Wyoming County", "54109"],
  ["Wythe County", "51197"],
  ["Yabucoa Municipio", "72151"],
  ["Yadkin County", "37197"],
  ["Yakima County", "53077"],
  ["Yakutat City And Borough", "02282"],
  ["Yalobusha County", "28161"],
  ["Yamhill County", "41071"],
  ["Yancey County", "37199"],
  ["Yankton County", "46135"],
  ["Yates County", "36123"],
  ["Yauco Municipio", "72153"],
  ["Yavapai County", "04025"],
  ["Yazoo County", "28163"],
  ["Yell County", "05149"],
  ["Yellow Medicine County", "27173"],
  ["Yellowstone County", "30111"],
  ["Yoakum County", "48501"],
  ["Yolo County", "06113"],
  ["York County", "23031"],
  ["York County", "31185"],
  ["York County", "42133"],
  ["York County", "45091"],
  ["York County", "51199"],
  ["Young County", "48503"],
  ["Yuba County", "06115"],
  ["Yukon-Koyukuk Census Area", "02290"],
  ["Yuma County", "04027"],
  ["Yuma County", "08125"],
  ["Zapata County", "48505"],
  ["Zavala County", "48507"],
  ["Ziebach County", "46137"],
]);
export const ALL_US_COUNTIES_SORT_BY_STATE_CODE: readonly UsCounty[] = _.sortBy(
  ALL_US_COUNTIES,
  [(c) => c.usState.twoLetterCode, (c) => c.name],
);

function makeCounties(data: [string, string][]): UsCounty[] {
  return data.map(([name, fiveDigitCode]) => {
    if (fiveDigitCode.length !== 5 || !isStringDigits(fiveDigitCode)) {
      throw new Error("US County 5-digit code must be five digits");
    }

    const usState = findUsStateByTwoDigitCode(fiveDigitCode.substring(0, 2));

    if (!usState) {
      throw new Error(
        "First two digits of US County 5-digit code must correspond to a state",
      );
    }

    return { name, fiveDigitCode, usState };
  });
}

const US_COUNTIES_BY_FIVE_DIGIT_COUNTY_CODE: IMap<string, UsCounty> = IMap(
  ALL_US_COUNTIES.map((county) => [county.fiveDigitCode, county]),
);

export function findUsCountyByFiveDigitCode(code: string): UsCounty | null {
  return US_COUNTIES_BY_FIVE_DIGIT_COUNTY_CODE.get(code) || null;
}

const US_COUNTIES_BY_TWO_DIGIT_STATE_CODE: IMap<string, UsCounty[]> = IMap(
  ALL_US_STATES.map((state) => [
    state.twoDigitCode,
    ALL_US_COUNTIES.filter((county) =>
      county.fiveDigitCode.startsWith(state.twoDigitCode),
    ),
  ]),
);

export function findUsCountiesInUsState(state: UsState): UsCounty[] {
  return US_COUNTIES_BY_TWO_DIGIT_STATE_CODE.get(state.twoDigitCode) || [];
}

function isStringDigits(s: string): boolean {
  for (let i = 0; i < s.length; i++) {
    if (!isCharDigit(s.charAt(i))) {
      return false;
    }
  }

  return true;
}

function isCharDigit(c: string): boolean {
  return (
    c.charCodeAt(0) >= "0".charCodeAt(0) && c.charCodeAt(0) <= "9".charCodeAt(0)
  );
}
