import { Map as IMap } from "immutable";
import React, { useCallback, useEffect } from "react";
import Field from "design/molecules/field";
import * as T from "types/engine-types";
import {
  FieldValueState,
  newFieldValueState,
} from "design/organisms/field-value-editor";
import { Setter, useById, useByCustomId, useIMapSetter } from "features/utils";
import { evaluateFieldCondition, getParentState } from "features/loans";
import { expandedFieldsArray } from "features/fields";
import { NoGroupWrapper } from "design/workflows/fields/styles";
import FieldGroup from "./_components/field-group";

const FieldsColumn = React.memo(
  ({
    applicationFields,
    applicationFieldValueStatesById,
    setApplicationFieldValueStatesById,
    defaultFieldValues,
    fieldToHighlight,
    setFieldToHighlight,
  }: {
    applicationFields: readonly T.BaseFieldDefinition[];
    applicationFieldValueStatesById: IMap<T.FieldId, FieldValueState>;
    setApplicationFieldValueStatesById: Setter<
      IMap<T.FieldId, FieldValueState>
    >;
    defaultFieldValues: T.DefaultFieldValue[];
    fieldToHighlight: T.CreditApplicationFieldDefinition | undefined;
    setFieldToHighlight: React.Dispatch<
      React.SetStateAction<T.CreditApplicationFieldDefinition | undefined>
    >;
  }) => {
    const applicationFieldsById = useById(applicationFields);
    const applicationFieldValueSetter = useIMapSetter(
      setApplicationFieldValueStatesById,
    );
    const defaultFieldValuesByFieldId = useByCustomId(
      "fieldId",
      defaultFieldValues,
    );

    useEffect(() => {
      if (fieldToHighlight?.id) {
        const el = document.querySelector(
          `[data-field-id="${fieldToHighlight.id}"]`,
        );
        const scrollable = document.querySelector(".scrollable-fields-column");

        setTimeout(() => {
          if (el) {
            const accordionWrapper = el?.closest(".molecule-accordion");

            if (
              el instanceof HTMLElement &&
              accordionWrapper instanceof HTMLElement
            ) {
              scrollable?.scrollTo(
                0,
                el.offsetTop + accordionWrapper.offsetTop,
              );
            } else if (el instanceof HTMLElement) {
              scrollable?.scrollTo(0, el.offsetTop + 300);
            }

            const input = el.querySelector("input");
            const select = el.querySelector("select");

            if (input) {
              input.focus();
            } else if (select) {
              select.focus();
            }

            setFieldToHighlight(undefined);
          }
        }, 1);
      }
    }, [fieldToHighlight, setFieldToHighlight]);

    const applicationFieldsToDisplay = applicationFields
      .filter((f) => {
        // Filter out any fields that shouldn't be displayed because they
        // have a default value and have been marked as hidden.

        const defaultFieldValue = defaultFieldValuesByFieldId.get(f.id);
        if (defaultFieldValue != null) {
          return !defaultFieldValue.hidden;
        } else {
          return true;
        }
      })
      .filter((f) =>
        // Filter out any fields that shouldn't be displayed because their
        // conditions or parent's conditions aren't met.
        evaluateFieldCondition(
          f.id,
          applicationFieldsById,
          applicationFieldValueStatesById,
          defaultFieldValuesByFieldId,
        ),
      )
      .filter((f, i, all) => {
        // Filter out any headers that don't currently have fields displayed under them

        // Anything that isn't a header should be left alone
        if (f.valueType.type !== "header") return true;

        // If this is the last item and it's a header, filter it out
        if (i === all.length - 1) return false;

        // If this item and the next one are both headers, filter this one out
        if (all[i + 1].valueType.type === "header") return false;

        // Everything else should be displayed
        return true;
      });

    const applicationFieldsByGroup = useCallback(() => {
      const mappedFields: expandedFieldsArray = [
        { header: "no group", fields: [] },
      ];

      applicationFieldsToDisplay?.map((field) => {
        field.valueType.type === "header"
          ? mappedFields.push({
              header: field.name,
              fields: [],
            })
          : mappedFields[mappedFields.length - 1].fields.push(field);
      });

      return mappedFields;
    }, [applicationFieldsToDisplay]);

    return (
      <div
        className="scrollable-fields-column"
        style={{ overflow: "hidden auto" }}
      >
        {applicationFieldsByGroup().map((group, i) => {
          if (group.header === "no group" && group.fields.length > 0) {
            return (
              <NoGroupWrapper key={i}>
                {group.fields.map((field) => (
                  <Field
                    key={field.id}
                    required={false}
                    showErrors={true}
                    margin="dense"
                    field={field}
                    fieldState={
                      field
                        ? applicationFieldValueStatesById.get(field.id) ||
                          newFieldValueState(field.valueType)
                        : null
                    }
                    defaultValue={defaultFieldValuesByFieldId.get(
                      field.id,
                      null,
                    )}
                    parentState={getParentState(
                      field.conditions || [],
                      applicationFieldsById,
                      applicationFieldValueStatesById,
                      defaultFieldValuesByFieldId,
                    )}
                    setState={(e) => {
                      return applicationFieldValueSetter.withKey(field.id)(e);
                    }}
                  />
                ))}
              </NoGroupWrapper>
            );
          }

          if (group.header !== "no group" && group.fields.length > 0) {
            return (
              <FieldGroup
                key={group.header}
                fields={group.fields}
                header={group.header}
                applicationFieldsById={applicationFieldsById}
                applicationFieldValueStatesById={
                  applicationFieldValueStatesById
                }
                applicationFieldValueSetter={applicationFieldValueSetter}
                defaultFieldValuesByFieldId={defaultFieldValuesByFieldId}
              />
            );
          }
        })}
      </div>
    );
  },
);

export default FieldsColumn;
