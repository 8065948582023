import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductResultsSummary from "../product-results-summary";
import { ProductsActionPanel, HeaderWrapper } from "./styles";
import Button from "design/atoms/button";
import ActionButtons from "../action-buttons";
import { useHistory, useLocation } from "react-router-dom";
import { ActiveFiltersSelector } from "features/pricing-summaries";
import ManageFilters from "../manage-filters";
import { setSearchTerm } from "features/pricing-summaries";
import FilterInput from "design/molecules/filter-input";
import { resetFiltersToNone } from "features/pricing-summaries";

export default React.memo(
  ({
    loading,
    setForceCollapseResults,
  }: {
    loading: boolean;
    setForceCollapseResults: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const history = useHistory();
    const location = useLocation();
    const reviewFilters = () =>
      history.push(
        `${location.pathname}?activeView=inputs${
          location.hash ? `${location.hash}` : ""
        }`,
      );

    const activeFilters = useSelector(ActiveFiltersSelector);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(document.location.search);

    useEffect(() => {
      if (!!activeFilters) {
        setOpen(true);
      }
    }, [activeFilters]);

    return (
      <ProductsActionPanel
        className={"page-loan-pricing-products-action-panel"}
      >
        <HeaderWrapper>
          <FilterInput
            className="hide-for-mobile"
            setValue={setSearchTerm}
            label="Filter by Text"
          />

          <ProductResultsSummary
            activeFilters={activeFilters}
            open={open}
            setOpen={setOpen}
            loading={loading}
          />
        </HeaderWrapper>

        {open && <ManageFilters />}

        <ActionButtons>
          <Button grow={true} onClick={reviewFilters}>
            Review Inputs
          </Button>

          <Button
            grow={true}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <>Close Filters</> : <>Update Filters</>}
          </Button>

          {!!activeFilters && (
            <Button
              onClick={() => {
                queryParams.delete("searchTerm");
                queryParams.delete("filters");
                queryParams.delete("dynamicFilters");
                history.push(
                  `${location.pathname}?${queryParams.toString()}#${
                    location.hash ? "#" + location.hash : ""
                  }`,
                );

                dispatch(resetFiltersToNone());
              }}
            >
              Clear Filters
            </Button>
          )}
        </ActionButtons>
      </ProductsActionPanel>
    );
  },
);
