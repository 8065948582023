import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Api from "api";
import { pathIsNextGen } from "features/application-initialization";
import * as DataTransfer from "features/data-transfer";
import Header from "design/organisms/header";
import HeaderV2 from "design/application/header";
import ContentV2 from "design/application/content";
import GuardedRoute from "design/atoms/guarded-route";
import { HomePage } from "pages/home";
import CalculationsPage from "pages/calculations";
import ProductsPage from "pages/products/list";
import RulesPage from "pages/rules/list";
import TestsPage from "pages/loans";
import ExportLoanPage from "pages/loans/export";
import LoansV2Page from "pages/loans-v2";
import LoanComparisonV2Page from "pages/loans-v2/loan-comparison";
import LoanPricingV2Page from "pages/loans-v2/loan-pricing";
import { PublishPage } from "pages/publish";
import LoginPage from "pages/login";
import ForgotPasswordPage from "pages/forgot-password";
import ResetPasswordPage from "pages/reset-password";
import CreateProductPage from "pages/products/create";
import EditProductPage from "pages/products/edit";
import ViewProductPage from "pages/products/view";
import CreateRulePage from "pages/rules/create";
import EditRulePage from "pages/rules/edit";
import ViewRulePage from "pages/rules/view";
import CreateDataTablePage from "pages/data-tables/create";
import DataTablesPage from "pages/data-tables/list";
import ViewDataTablePage from "pages/data-tables/view";
import EditDataTablePage from "pages/data-tables/edit";
import { CreateRateSheetFormatPage } from "pages/rate-sheet-formats/create";
import { EditRateSheetFormatPage } from "pages/rate-sheet-formats/edit";
import { RateSheetFormatsPage } from "pages/rate-sheet-formats/list";
import { FieldsPage } from "pages/fields";
import CreateUserPage from "pages/users/create";
import EditUserPage from "pages/users/edit";
import ListUsersPage from "pages/users/list";
import ViewUserPage from "pages/users/view";
import ViewPricingProfilePage from "pages/pricing-profiles/view";
import ListPricingProfilePage from "pages/pricing-profiles/list";
import CreatePricingProfilePage from "pages/pricing-profiles/create";
import ViewRolePage from "pages/roles/view";
import ListRolesPage from "pages/roles/list";
import CreateRolePage from "pages/roles/create";
import DebugPage from "pages/debug";
import InvestorsPage from "pages/investors/list";
import ViewInvestorPage from "pages/investors/view";
import CreateInvestorPage from "pages/investors/create";
import EditInvestorPage from "pages/investors/edit";
import RateSheetsPage from "pages/rate-sheets/list";
import { PatternPage } from "pages/object-patterns/create";
import { PatternInvestorsPage } from "pages/object-patterns/investors";
import { ViewRateSheetPage } from "pages/rate-sheets/view";
import { ClientsPage } from "pages/clients/list";
import { CreateClientPage } from "pages/clients/create";
import { ExportPage } from "pages/export";
import { ImportPage } from "pages/import";
import Heartbeat from "design/application/heartbeat";
import AuthenticatedInitializer from "design/application/authenticated-initializer";
import store from "./features/store";

// TODO: verify in the below are actually needed
// assign api module to a global variable, to help with testing
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(window as any).API = Api;
// also export data-transfer module, so we can copy data between environments
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(window as any).DataTransferModule = DataTransfer;

// expose store when run in Cypress
//@ts-expect-error
if (window.Cypress) {
  //@ts-expect-error
  window.store = store;
}

// TODO: remove these styles and replace with Design Language integration
const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",

    width: "100%",
    height: "100%",

    fontFamily: "roboto",
  },
  header: {
    display: "flex",
  },
  body: {
    display: "flex",
    flex: 1,
    overflowY: "hidden",
  },
  sidebar: {},
  drawer: {
    width: 250,
    height: "100%",
    flexShrink: 0,
  },
  drawerPaper: {
    position: "static",
    width: 250,
    height: "100%",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowY: "hidden",
  },
  breadcrumbContainer: {},
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "auto",
  },
});

export function App() {
  const classes = useStyles();

  return (
    <Router>
      <Switch>
        <Route exact path="/frame-redirect/:clientAccessId">
          <Redirect to="/loan-pricing" />
        </Route>
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <Route exact path="/login/:clientAccessId">
          <LoginPage />
        </Route>
        <Route exact path="/forgot-password">
          <ForgotPasswordPage />
        </Route>
        <Route exact path="/forgot-password/:clientAccessId">
          <ForgotPasswordPage />
        </Route>
        <Route exact path="/reset-password">
          <ResetPasswordPage />
        </Route>
        <Route
          render={({ location }) => {
            // Redirect to include client access id if it is not already included.
            // If it's not available, point to login but keep url for redirect after login.
            if (!location.pathname.startsWith("/c/")) {
              const url = `${location.pathname}${location.search}${location.hash}`;
              const clientAccessId =
                localStorage.getItem("clientAccessId") || "";
              if (clientAccessId) {
                const suffix = url.startsWith("/") ? url : "/" + url;
                return <Redirect to={`/c/${clientAccessId}${suffix}`} />;
              } else {
                const encodedPathname = encodeURIComponent(url);
                return <Redirect to={`/login?redirect=${encodedPathname}`} />;
              }
            }

            return pathIsNextGen(location.pathname) ? (
              <AuthenticatedInitializer>
                <Heartbeat />
                <HeaderV2 />
                <ContentV2>
                  <LoansV2Page>
                    <Switch>
                      <GuardedRoute
                        exact
                        path="/c/:clientAccessId/v2/loan-pricing"
                        requiredPermission="price-a-loan-v2-view"
                        component={LoanPricingV2Page}
                      />
                      <GuardedRoute
                        exact
                        path="/c/:clientAccessId/v2/loan-pricing/compare/*"
                        requiredPermission="price-a-loan-v2-view"
                        component={LoanComparisonV2Page}
                      />
                      <GuardedRoute
                        exact
                        path="/c/:clientAccessId/v2/loan-pricing/products/:productId/"
                        requiredPermission="price-a-loan-v2-view"
                        component={LoanPricingV2Page}
                      />
                      <GuardedRoute
                        exact
                        path="/c/:clientAccessId/v2/loan-pricing/products/:productId/:pricingScenarioId"
                        requiredPermission="price-a-loan-v2-view"
                        component={LoanPricingV2Page}
                      />

                      <GuardedRoute
                        exact
                        path="/c/:clientAccessId/v2/loan-pricing/products/:productId/:pricingScenarioRate/:pricingScenarioLock"
                        requiredPermission="price-a-loan-v2-view"
                        component={LoanPricingV2Page}
                      />

                      <GuardedRoute
                        exact
                        path="/c/:clientAccessId/v2/loan-pricing/products/:productId/:pricingScenarioRate"
                        requiredPermission="price-a-loan-v2-view"
                        component={LoanPricingV2Page}
                      />
                    </Switch>
                  </LoansV2Page>
                </ContentV2>
              </AuthenticatedInitializer>
            ) : (
              <AuthenticatedInitializer>
                <DndProvider backend={HTML5Backend}>
                  <Heartbeat />
                  <CssBaseline />
                  <div className={classes.container}>
                    <div className={classes.header}>
                      <Header />
                    </div>
                    <div className={classes.body}>
                      <div className={classes.mainContainer}>
                        <div
                          id="pageContainer"
                          className={classes.pageContainer}
                        >
                          <Switch>
                            <Route
                              exact
                              path="/c/:clientAccessId/"
                              component={HomePage}
                            />
                            <Route
                              path={[
                                "/c/:clientAccessId/loan-pricing/export",
                                "/c/:clientAccessId/loan-pricing",
                              ]}
                            >
                              <div
                                style={{
                                  display: location.pathname.endsWith(
                                    "/loan-pricing",
                                  )
                                    ? "block"
                                    : "none",
                                  height: "100%",
                                }}
                              >
                                <GuardedRoute
                                  path="/c/:clientAccessId/loan-pricing"
                                  component={TestsPage}
                                  requiredPermission="price-a-loan-v1-view"
                                />
                              </div>

                              <GuardedRoute
                                exact
                                path="/c/:clientAccessId/loan-pricing/export"
                                component={ExportLoanPage}
                                requiredPermission="price-a-loan-v1-view"
                              />
                            </Route>
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/calculations"
                              requiredPermission="calculations-menu-access"
                              component={CalculationsPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/investors"
                              requiredPermission="investors-menu-access"
                              component={InvestorsPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/create-investor"
                              requiredPermission="investors-create"
                              component={CreateInvestorPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/investors/:id"
                              requiredPermission="investors-menu-access"
                              component={ViewInvestorPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/investors/:id/edit"
                              requiredPermission="investors-edit"
                              component={EditInvestorPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/products"
                              requiredPermission="products-menu-access"
                              component={ProductsPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/create-product"
                              requiredPermission="products-create"
                              component={CreateProductPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/products/:id"
                              requiredPermission="products-menu-access"
                              component={ViewProductPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/products/:id/edit"
                              requiredPermission="products-edit"
                              component={EditProductPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/fields"
                              requiredPermission="field-enum-library-menu-access"
                              component={FieldsPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/rules"
                              requiredPermission="rules-menu-access"
                              component={RulesPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/create-rule"
                              requiredPermission="rules-create"
                              component={CreateRulePage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/rules/:id"
                              requiredPermission="rules-menu-access"
                              component={ViewRulePage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/rules/:id/edit"
                              requiredPermission="rules-edit"
                              component={EditRulePage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/data-tables"
                              requiredPermission="data-tables-menu-access"
                              component={DataTablesPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/create-data-table"
                              requiredPermission="data-tables-create"
                              component={CreateDataTablePage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/data-tables/:id"
                              requiredPermission="data-tables-menu-access"
                              component={ViewDataTablePage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/data-tables/:id/edit"
                              requiredPermission="data-tables-edit"
                              component={EditDataTablePage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/rate-sheets"
                              requiredPermission="rate-sheets-menu-access"
                              component={RateSheetsPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/rate-sheets/:id"
                              requiredPermission="rate-sheets-menu-access"
                              component={ViewRateSheetPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/users"
                              requiredPermission="users-menu-access"
                              component={ListUsersPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/users/create"
                              requiredPermission="users-create"
                              component={CreateUserPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/users/:id/edit"
                              requiredPermission="users-edit"
                              component={EditUserPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/users/:id"
                              requiredPermission="users-menu-access"
                              component={ViewUserPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/pricing-profiles"
                              requiredPermission="pricing-profiles-menu-access"
                              component={ListPricingProfilePage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/pricing-profiles/create"
                              requiredPermission="pricing-profiles-menu-access"
                              component={CreatePricingProfilePage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/pricing-profiles/:id"
                              requiredPermission="pricing-profiles-menu-access"
                              component={ViewPricingProfilePage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/roles"
                              requiredPermission="roles-menu-access"
                              component={ListRolesPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/roles/create"
                              requiredPermission="roles-menu-access"
                              component={CreateRolePage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/roles/:id"
                              requiredPermission="roles-menu-access"
                              component={ViewRolePage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/debug"
                              component={DebugPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/export"
                              component={ExportPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/import"
                              component={ImportPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/publish"
                              component={PublishPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/__admin/clients"
                              component={ClientsPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/__admin/create-client"
                              component={CreateClientPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/__admin/rate-sheet-formats"
                              component={RateSheetFormatsPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/__admin/object-patterns"
                              component={PatternPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/__admin/investor-automations"
                              component={PatternInvestorsPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/__admin/create-rate-sheet-format"
                              component={CreateRateSheetFormatPage}
                            />
                            <GuardedRoute
                              exact
                              path="/c/:clientAccessId/__admin/rate-sheet-formats/:id"
                              component={EditRateSheetFormatPage}
                            />
                          </Switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </DndProvider>
              </AuthenticatedInitializer>
            );
          }}
        />
      </Switch>
    </Router>
  );
}
