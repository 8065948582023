import React, { useEffect } from "react";
import { List, ListItem, Typography, Checkbox } from "@material-ui/core";
import * as T from "types/engine-types";
import { useSelector, useDispatch } from "react-redux";
import Link from "design/atoms/link";
import { rolesSelector, getRoles } from "features/roles";
import _ from "lodash";
import { expandedConfigSelector } from "features/application-initialization";
import { localAccessId } from "features/access-id";

export const AssociatedRolesViewer = React.memo(
  ({
    linkRoles,
    objectId,
    rolesToAdd,
    setRolesToAdd,
  }: {
    linkRoles: boolean;
    objectId: T.ObjectId;
    setRolesToAdd?: React.Dispatch<
      React.SetStateAction<{
        [key: string]: T.RoleId[];
      }>
    >;
    // object that tracks changes in referenced roles per field
    // keyed with fieldId + __ADD__ or __REMOVE__
    // indicates whether field is intended to be added or removed from role
    rolesToAdd?: {
      [key: string]: T.RoleId[];
    };
  }) => {
    const rolesState = useSelector(rolesSelector);
    const { roles } = rolesState;
    const dispatch = useDispatch();
    const config = useSelector(expandedConfigSelector);
    const sortedRoles = _.sortBy(roles, (r) => r.name);

    useEffect(() => {
      if (roles.length === 0) dispatch(getRoles());
    }, [dispatch, roles.length]);

    const fieldId: T.FieldId | false =
      objectId.type === "field" && objectId.fieldId;

    return (
      <>
        {linkRoles && (
          <div style={{ margin: "0 64px 0 0", flex: "1 1 200px" }}>
            <Typography variant="h5">Roles</Typography>
            <List
              style={{
                height: "400px",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              {sortedRoles.map((r) => {
                /* eslint-disable @typescript-eslint/restrict-template-expressions */
                const handleChange = (
                  e: React.ChangeEvent,
                  checked: boolean,
                ) => {
                  const newRolesToAdd = { ...rolesToAdd };
                  if (fieldId && setRolesToAdd && rolesToAdd) {
                    if (checked) {
                      if (
                        newRolesToAdd[`${fieldId}__REMOVE__`] &&
                        rolesToAdd[`${fieldId}__REMOVE__`].includes(r.id)
                      ) {
                        newRolesToAdd[`${fieldId}__REMOVE__`] = _.without(
                          newRolesToAdd[`${fieldId}__REMOVE__`],
                          r.id,
                        );
                      }
                      newRolesToAdd[`${fieldId}__ADD__`] = _.uniq([
                        ...(newRolesToAdd[`${fieldId}__ADD__`] || []),
                        r.id,
                      ]);
                    } else {
                      if (
                        newRolesToAdd[`${fieldId}__ADD__`] &&
                        newRolesToAdd[`${fieldId}__ADD__`].includes(r.id)
                      ) {
                        newRolesToAdd[`${fieldId}__ADD__`] = _.without(
                          newRolesToAdd[`${fieldId}__ADD__`],
                          r.id,
                        );
                      }
                      newRolesToAdd[`${fieldId}__REMOVE__`] = _.uniq([
                        ...(newRolesToAdd[`${fieldId}__REMOVE__`] || []),
                        r.id,
                      ]);
                    }

                    setRolesToAdd(newRolesToAdd);
                  }
                };
                const roleIsAdmin = r.name.toLowerCase() === "admin";
                const isNewField = !config.allFieldsById.find(
                  (f) => f.id === fieldId,
                );
                const roleWasAdded =
                  rolesToAdd &&
                  fieldId &&
                  rolesToAdd[`${fieldId}__ADD__`] &&
                  rolesToAdd[`${fieldId}__ADD__`].includes(r.id);
                const roleIsAssociated =
                  fieldId && r.pricingVisibleFieldIds.includes(fieldId);
                const roleWasRemoved =
                  rolesToAdd &&
                  rolesToAdd[`${fieldId}__REMOVE__`] &&
                  rolesToAdd[`${fieldId}__REMOVE__`].includes(r.id);
                const roleNotRemoved =
                  rolesToAdd &&
                  rolesToAdd[`${fieldId}__REMOVE__`] &&
                  !rolesToAdd[`${fieldId}__REMOVE__`].includes(r.id);
                const nothingChanged =
                  rolesToAdd &&
                  !rolesToAdd[`${fieldId}__ADD__`] &&
                  !rolesToAdd[`${fieldId}__REMOVE__`];

                const checked = !!(
                  roleWasAdded ||
                  roleIsAdmin ||
                  (isNewField && (roleNotRemoved || nothingChanged)) ||
                  (roleIsAssociated && !roleWasRemoved)
                );
                const accessId = localAccessId();
                return (
                  <ListItem style={{ padding: "0" }} key={r.id}>
                    <Checkbox
                      disabled={r.name.toLowerCase() === "admin" || !fieldId}
                      value={r.id}
                      checked={checked}
                      onChange={handleChange}
                    />

                    <Link to={`/c/${accessId}/roles/${r.id}`}> {r.name}</Link>
                  </ListItem>
                );
                /* eslint-enable @typescript-eslint/restrict-template-expressions */
              })}
            </List>
          </div>
        )}
      </>
    );
  },
);
