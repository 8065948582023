import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import * as T from "types/engine-types";
import * as Fields from "features/fields";
import { unreachable } from "features/utils";
import {
  expandedConfigSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import { DataTableBody } from "./DataTableEditor";

const useRevisionStyles = makeStyles((t) =>
  createStyles({
    container: {
      position: "relative",
      overflow: "auto",
    },
    table: {
      borderSpacing: 0,
      width: "100%",
    },
    th: {
      position: "sticky",
      top: 0,

      borderTop: "1px solid #ccc",
      borderRight: "1px solid #ccc",
      borderBottom: "1px solid #888",

      height: 40,

      background: "white",
      padding: "8px 12px",

      "&:first-child": {
        borderLeft: "1px solid #ccc",
      },
    },
    td: {
      borderRight: "1px solid #ccc",
      borderBottom: "1px solid #ccc",

      height: 40,

      padding: "0 10px",

      "&:first-child": {
        borderLeft: "1px solid #ccc",
      },

      "&.rightAlign": {
        textAlign: "right",
      },
    },
  }),
);

export default React.memo(function DataTableBodyViewer({
  columnDefs,
  body,
}: {
  columnDefs: T.DataTableColumn[];
  body: DataTableBody;
}) {
  const C = useRevisionStyles();
  const config = useSelector(expandedConfigSelector);

  const objectDetails = useSelector(objectDetailsMapSelector);

  return (
    <div className={C.container}>
      <table className={C.table}>
        <thead>
          <tr>
            {columnDefs.map((column) => (
              <th key={column.id} className={C.th}>
                {column.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {body.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columnDefs.map((column, columnIndex) => (
                <td
                  key={column.id}
                  className={
                    C.td +
                    (isRightAligned(column.valueType) ? " rightAlign" : "")
                  }
                >
                  {Fields.fieldValueToString(
                    config,
                    objectDetails,
                    column.valueType,
                    row[columnIndex],
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

function isRightAligned(valueType: T.FieldValueType): boolean {
  switch (valueType.type) {
    case "enum":
    case "object-ref":
    case "string":
    case "header":
    case "duration":
    case "date":
      return false;
    case "number":
      return true;
    default:
      return unreachable(valueType);
  }
}
