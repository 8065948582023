import { unreachable } from "features/utils";
import { Set as ISet } from "immutable";
import * as T from "types/engine-types";
import { Snapshot } from "..";
import { exportEnumTypeId, exportEnumVariantId } from "../enums/export";
import { exportObjectRef } from "../rules/export";

export function exportProductFields(
  snapshot: Snapshot,
  fieldIds: ISet<T.FieldId>,
): T.RawProductFieldDefinition[] {
  return snapshot.config.rawProductFields
    .filter((f) => fieldIds.has(f.id))
    .map((f) => {
      if (f.disposition.kind === "native") {
        return {
          oldId: null,
          id: f.id,
          disposition: {
            ...exportNativeFieldDisposition(snapshot, f.disposition),
            kind: "native",
          },
        };
      } else {
        return {
          oldId: null,
          id: f.id,
          disposition: {
            ...exportInheritedFieldDisposition(snapshot, f.disposition),
            kind: "inherited",
          },
        };
      }
    });
}

export function exportPipelineFields(
  snapshot: Snapshot,
  fieldIds: ISet<T.FieldId>,
): T.RawPipelineFieldDefinition[] {
  return snapshot.config.rawPipelineOnlyFields
    .filter((f) => fieldIds.has(f.id))
    .map((f) => {
      if (f.disposition.kind === "native") {
        return {
          oldId: null,
          id: f.id,
          disposition: {
            ...exportNativeFieldDisposition(snapshot, f.disposition),
            kind: "native",
          },
        };
      } else {
        return {
          oldId: null,
          id: f.id,
          disposition: {
            ...exportInheritedFieldDisposition(snapshot, f.disposition),
            kind: "inherited",
          },
        };
      }
    });
}

export function exportApplicationFields(
  snapshot: Snapshot,
  fieldIds: ISet<T.FieldId>,
): T.RawCreditApplicationFieldDefinition[] {
  return snapshot.config.rawCreditApplicationFields
    .filter((f) => fieldIds.has(f.id))
    .map((f) => {
      if (f.disposition.kind === "native") {
        return {
          oldId: null,
          id: f.id,
          disposition: {
            ...exportNativeFieldDisposition(snapshot, f.disposition),
            kind: "native",
          },
        };
      } else {
        return {
          oldId: null,
          id: f.id,
          disposition: {
            ...exportInheritedFieldDisposition(snapshot, f.disposition),
            kind: "inherited",
          },
        };
      }
    });
}

function exportNativeFieldDisposition(
  snapshot: Snapshot,
  disposition: T.NativeFieldDisposition,
): T.NativeFieldDisposition {
  return {
    name: disposition.name,
    description: disposition.description,
    valueType: exportFieldValueType(snapshot, disposition.valueType),
    conditions:
      disposition.conditions &&
      exportFieldCondition(snapshot, disposition.conditions),
  };
}

function exportInheritedFieldDisposition(
  snapshot: Snapshot,
  disposition: T.InheritedFieldDisposition,
): T.InheritedFieldDisposition {
  return {
    nameAlias: disposition.nameAlias,
    descriptionAlias: disposition.descriptionAlias,
    includeConditions: disposition.includeConditions.map((id) => id),
    additionalConditions:
      disposition.additionalConditions &&
      exportFieldCondition(snapshot, disposition.additionalConditions),
  };
}

export function exportFieldValueType(
  snapshot: Snapshot,
  valueType: T.FieldValueType,
): T.FieldValueType {
  switch (valueType.type) {
    case "enum":
      return {
        type: "enum" as const,
        enumTypeId: exportEnumTypeId(snapshot, valueType.enumTypeId),
      };
    case "object-ref":
    case "string":
    case "header":
    case "number":
    case "duration":
    case "date":
      return valueType;
    default:
      return unreachable(valueType);
  }
}

export function exportFieldCondition(
  snapshot: Snapshot,
  conditions: T.FieldCondition[],
): T.FieldCondition[] {
  return conditions.map((condition) => {
    let toReturn;
    switch (condition.type) {
      case "parent-field-is-blank":
        toReturn = {
          type: "parent-field-is-blank" as const,
          id: condition.id,
          parentFieldId: exportFieldId(snapshot, condition.parentFieldId),
        };
        break;
      case "parent-field-is-not-blank":
        toReturn = {
          type: "parent-field-is-not-blank" as const,
          id: condition.id,
          parentFieldId: exportFieldId(snapshot, condition.parentFieldId),
        };
        break;
      case "parent-field-has-not-value":
        toReturn = {
          type: "parent-field-has-not-value" as const,
          id: condition.id,
          parentFieldId: exportFieldId(snapshot, condition.parentFieldId),
          value: exportFieldValue(snapshot, condition.value),
        };
        break;
      case "parent-field-has-value":
        toReturn = {
          type: "parent-field-has-value" as const,
          id: condition.id,
          parentFieldId: exportFieldId(snapshot, condition.parentFieldId),
          value: exportFieldValue(snapshot, condition.value),
        };
        break;
      case "parent-field-is-one-of":
        toReturn = {
          type: "parent-field-is-one-of" as const,
          id: condition.id,
          parentFieldId: exportFieldId(snapshot, condition.parentFieldId),
          values: condition.values.map((v) => exportFieldValue(snapshot, v)),
        };
        break;
      case "parent-field-is-not-one-of":
        toReturn = {
          type: "parent-field-is-not-one-of" as const,
          id: condition.id,
          parentFieldId: exportFieldId(snapshot, condition.parentFieldId),
          values: condition.values.map((v) => exportFieldValue(snapshot, v)),
        };
        break;
    }
    return toReturn;
  });
}

export function exportFieldValue(
  snapshot: Snapshot,
  value: T.FieldValue,
): T.FieldValue {
  switch (value.type) {
    case "enum":
      return {
        type: "enum" as const,
        enumTypeId: exportEnumTypeId(snapshot, value.enumTypeId),
        variantId: exportEnumVariantId(
          snapshot,
          value.enumTypeId,
          value.variantId,
        ),
      };
    case "object-ref":
      return {
        type: "object-ref",
        objectRef: exportObjectRef(snapshot, value.objectRef),
      };
    case "string":
    case "number":
    case "duration":
    case "date":
      return value;
    default:
      return unreachable(value);
  }
}

export function exportFieldId(
  snapshot: Snapshot,
  fieldId: T.FieldId,
): T.FieldId {
  const field = snapshot.config.allFieldsById.get(fieldId);

  if (!field) {
    throw new Error("Field not found: ID = " + JSON.stringify(fieldId));
  }
  return field.id;
}
