import styled from "styled-components";
import breakpoints from "design/subatomics/breakpoints";
import whitespace from "design/subatomics/whitespace";

export const FieldValueEditorV2 = styled.label`
  display: block;
  flex-grow: 1;
  select,
  input {
    box-sizing: border-box;
  }
  padding: ${whitespace()} 0;

  ${breakpoints.mobile} {
    display: flex;

    > span {
      flex: 1 1 30%;
    }

    select,
    input {
      flex: 1 1 70%;
    }
  }

  ${breakpoints.desktop} {
    > span {
      display: block;
      width: 100%;
    }

    select,
    input {
      display: block;
      width: 100%;
    }
  }
`;
