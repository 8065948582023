import React from "react";
import { KeyValue, KeyValueGroup } from "./styles";

export { KeyValueGroup };

export default React.memo(
  ({ label, value }: { label: string; value: string }) => {
    return (
      <KeyValue>
        <span title={value} className="value">
          {value}
        </span>
        <span title={label} className="key">
          {label}
        </span>
      </KeyValue>
    );
  },
);
