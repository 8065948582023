import React, { useCallback, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DoneIcon from "@material-ui/icons/Done";
import * as Api from "api";
import { LoadingOverlay } from "design/atoms/loading-overlay";
import * as T from "types/engine-types";
import {
  UiValidationError,
  usePropertySetter,
  generateSafeId,
  unreachable,
  getErrorMessage,
} from "features/utils";
import { DetailedInvalidRequest } from "api";
import { ClientValidation } from "types/engine-types";
import Loader from "react-loader";
import { Dropdown, SearchableDropdown } from "design/molecules/dropdown";
import { pollJobStatus } from "features/jobs";
import { useSelector } from "react-redux";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

type ClientState = {
  name: string;
  accessId: string;
  cloneFrom: T.Client | null;
  firstUserEmailAddress: string;
  firstUserDisplayName: string;
  firstUserPassword: string;
  displayNewInheritedEnumVariants: boolean;
  timezone: T.ClientTimezone;
};

type SelectableTimezone = {
  key: string;
  name: string;
};

export const SELECTABLE_TIMEZONES: SelectableTimezone[] = [
  // Eastern timezones
  { key: "eastern", name: "Eastern (New York City, EST/EDT)" },

  // Central timezones
  { key: "central", name: "Central (Chicago, CST/CDT)" },

  // Mountain timezones
  { key: "mountain", name: "Mountain (Denver, MST/MDT)" },
  { key: "arizona", name: "Mountain Standard (Phoenix, MST)" },

  // Pacific timezones
  { key: "pacific", name: "Pacific (Los Angeles, PST/PDT)" },

  // Going west from Pacific timezones
  { key: "alaska", name: "Alaska (Anchorage, AKST/AKDT)" },
  { key: "aleutian", name: "Alaska (Aleutian Islands, HST/HDT)" },
  { key: "hawaii", name: "Hawaii (Honolulu, HST)" },
  { key: "samoa", name: "American Samoa (Pago Pago, SST)" },
];

export const CreateClientPage = React.memo(() => {
  const history = useHistory();

  const [state, setState] = useState(newClientState());
  const [errorState, setErrorState] = useState<ClientValidation | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loadingText, setLoadingText] = useState<string | null>(null);

  const setName = usePropertySetter(setState, "name");
  const setAccessId = usePropertySetter(setState, "accessId");
  const setDisplayNewInheritedEnumVariants = usePropertySetter(
    setState,
    "displayNewInheritedEnumVariants",
  );
  const setCloneFrom = usePropertySetter(setState, "cloneFrom");
  const setTimezone = usePropertySetter(setState, "timezone");
  const setFirstUserEmailAddress = usePropertySetter(
    setState,
    "firstUserEmailAddress",
  );
  const setFirstUserDisplayName = usePropertySetter(
    setState,
    "firstUserDisplayName",
  );
  const setFirstUserPassword = usePropertySetter(setState, "firstUserPassword");
  const {
    client: { accessId },
  } = useSelector(nonNullApplicationInitializationSelector);
  const submit = useCallback(() => {
    async function run() {
      let req;
      try {
        req = convertStateToCreateClientRequest(state);
      } catch (err) {
        if (err instanceof UiValidationError) {
          newrelic.noticeError(err);
          alert(err.message);
          return;
        }

        throw err;
      }

      try {
        setLoadingText("Creating client... (this might take a while)");
        const jobId = await Api.Admin.createClient(req);
        const jobStatus = await pollJobStatus(jobId);

        setLoadingText(null);
        switch (jobStatus.status) {
          case "pending":
          case "running":
            console.warn(jobStatus);
            setErrorMessage("Client creation job hasn't finished yet!");
            return;
          case "failed":
            console.warn(jobStatus);
            setErrorMessage(`Client creation failed: ${jobStatus.error}`);
            return;
          case "complete":
            history.push(`/c/${accessId}/__admin/clients`);
            break;
          default:
            return unreachable(jobStatus);
        }
      } catch (err) {
        newrelic.noticeError(getErrorMessage(err));
        if (
          err instanceof DetailedInvalidRequest &&
          err.errors.type === "client"
        ) {
          setErrorState(err.errors.value);
        } else {
          alert(err);
        }

        setLoadingText(null);
        return;
      }
    }

    run();
  }, [accessId, history, state]);

  const [clientsLoad] = Api.Admin.useClients();
  const {
    client: { accessId: currentAccessId },
  } = useSelector(nonNullApplicationInitializationSelector);
  if (clientsLoad.status === "error") {
    return <>"Error"</>;
  }

  if (clientsLoad.status === "loading") {
    return <Loader loaded={false} />;
  } else {
    const clients: T.Client[] = clientsLoad.value;
    const cloneFrom: T.Client | null =
      clientsLoad.value.find(
        (c: T.Client) => c.accessId === state.cloneFrom?.accessId,
      ) || null;

    return (
      <Box>
        <LoadingOverlay when={!!loadingText} text={loadingText} />

        <Box px={2} my={2} display="flex">
          <Button
            component={Link}
            to={`/c/${currentAccessId}/__admin/clients`}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back to Client List
          </Button>
          <Box flex="1" />
          <Button variant="outlined" startIcon={<DoneIcon />} onClick={submit}>
            Create
          </Button>
        </Box>
        <Box px={2} my={2}>
          <Box my={2}>
            <TextField
              style={{ width: "400px" }}
              label="Client Display Name"
              variant="outlined"
              value={state.name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          <Box my={2}>
            <TextField
              style={{ width: "400px" }}
              label="Client ID (used at login)"
              variant="outlined"
              value={state.accessId}
              onChange={(e) => setAccessId(e.target.value)}
            />
            {errorMessage && <Box color="error.main">{errorMessage}</Box>}
            {errorState &&
              errorState.accessId?.map((e, i) => (
                <Box key={i} color="error.main">
                  {e.message}
                </Box>
              ))}
          </Box>
          <Box my={2} style={{ width: "400px" }}>
            <Dropdown<string>
              label="Time Zone"
              options={SELECTABLE_TIMEZONES.map((tz) => tz.key)}
              getOptionLabel={(o) =>
                SELECTABLE_TIMEZONES.find((tz) => tz.key === o)?.name || o
              }
              value={state.timezone}
              setValue={(v) =>
                setTimezone((v || "central") as T.ClientTimezone)
              }
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={state.displayNewInheritedEnumVariants}
                  onChange={(e, checked) =>
                    setDisplayNewInheritedEnumVariants(checked)
                  }
                  color="primary"
                />
              }
              label={
                state.displayNewInheritedEnumVariants
                  ? "Display new inherited enum variants"
                  : "Do not display new inherited enum variants"
              }
            />
          </Box>
          <Box my={2} fontSize="24px">
            First admin user account for new client
          </Box>
          <Box my={2}>
            <TextField
              style={{ width: "400px" }}
              label="Email Address"
              type="email"
              variant="outlined"
              value={state.firstUserEmailAddress}
              onChange={(e) => setFirstUserEmailAddress(e.target.value)}
            />
          </Box>
          <Box my={2}>
            <TextField
              style={{ width: "400px" }}
              label="Full Name"
              variant="outlined"
              value={state.firstUserDisplayName}
              onChange={(e) => setFirstUserDisplayName(e.target.value)}
            />
          </Box>
          <Box my={2}>
            <TextField
              style={{ width: "400px" }}
              label="Password"
              variant="outlined"
              value={state.firstUserPassword}
              onChange={(e) => setFirstUserPassword(e.target.value)}
            />
          </Box>

          <Box my={2} style={{ width: "400px" }}>
            <SearchableDropdown<T.Client>
              label="Clone From"
              options={clients}
              getOptionLabel={(o) => o.name}
              value={cloneFrom}
              setValue={setCloneFrom}
            />
          </Box>
        </Box>
      </Box>
    );
  }
});

function newClientState(): ClientState {
  return {
    name: "",
    accessId: "",
    cloneFrom: null,
    firstUserEmailAddress: "",
    firstUserDisplayName: "",
    firstUserPassword: String(generateSafeId())
      .replace(/[_-]/g, "")
      .substr(0, 12),
    displayNewInheritedEnumVariants: false,
    timezone: "central",
  };
}

// todo: make validation abstraction to avoid this boilerplate
function convertStateToCreateClientRequest(
  state: ClientState,
): T.CreateClientRequest {
  if (!state.name.trim()) {
    throw new UiValidationError("Client display name is required");
  }

  if (!state.accessId.trim()) {
    throw new UiValidationError("Client access ID is required");
  }

  if (!state.firstUserEmailAddress.trim()) {
    throw new UiValidationError("User email address is required");
  }

  if (!state.firstUserDisplayName.trim()) {
    throw new UiValidationError("User full name is required");
  }

  if (!state.firstUserPassword.trim()) {
    throw new UiValidationError("User password is required");
  }

  return {
    clientName: state.name.trim(),
    clientAccessId: state.accessId.trim(),
    cloneFromAccessId: state.cloneFrom?.accessId.trim() || null,
    firstUserEmailAddress: state.firstUserEmailAddress.trim(),
    firstUserDisplayName: state.firstUserDisplayName.trim(),
    firstUserPassword: state.firstUserPassword,
    displayNewInheritedEnumVariants: state.displayNewInheritedEnumVariants,
    timezone: state.timezone,
  };
}
