import styled from "styled-components";
import { styles } from "design/atoms/typography";
import whitespace from "design/subatomics/whitespace";

export const Wrapper = styled.div`
  ${styles.text};
  margin: ${whitespace("least")} ${whitespace("less")};
  flex: 1 1 auto;

  label {
    max-width: 360px;
  }
`;
