import * as T from "../../types/engine-types";
import { createBlob } from "../../api";

export interface UploadOptions {
  contentType?: string;
}

/**
 * Create a new blob in the app's blob store, then upload a file to the
 * the S3 pre-signed URL returned from the API.
 */
export async function uploadBlob(
  data: Blob | string,
  options: UploadOptions = {},
): Promise<T.BlobId> {
  const preparedBlob = await createBlob();
  const uploadResponse = await fetch(preparedBlob.presignedUploadUrl, {
    method: "PUT",
    body: data,
    headers: {
      "Content-Type": options.contentType ?? "application/octet-stream",
    },
  });

  if (uploadResponse.ok) {
    return preparedBlob.blobId;
  } else {
    throw new Error(`Upload for blob ${preparedBlob.blobId} failed`);
  }
}

export async function uploadJsonBlob(value: unknown): Promise<T.BlobId> {
  return await uploadBlob(JSON.stringify(value), {
    contentType: "application/json",
  });
}
