import * as T from "types/engine-types";

export function patternMatchesKey(
  pattern: T.ObjectPattern,
  key: string,
): boolean {
  let match = true;
  if (pattern.keyContains) {
    match = match && key.includes(pattern.keyContains);
  }
  if (pattern.keyContainsOptional) {
    match = match && key.includes(pattern.keyContainsOptional);
  }
  return match;
}

export function filterJoins(
  joins: T.ObjectPatternInvestorJoin[],
  state: { clientId: string | null; investorId: string | null },
): T.ObjectPatternInvestorJoin[] {
  // Copy and clean the patterns
  let copy = joins.slice();
  if (state.clientId) {
    copy = copy.filter((join) => join.clientId === state.clientId);
  }
  if (state.investorId) {
    copy = copy.filter((join) => join.investorId === state.investorId);
  }

  // Sort from largest id to smallest id
  copy.sort(
    (a, b) => parseInt(b.objectPatternId) - parseInt(a.objectPatternId),
  );
  return copy;
}

export function massagedPatterns(
  patterns: T.ObjectPattern[],
): T.ObjectPattern[] {
  // Copy and clean the patterns
  const copy = patterns.map((pattern) => ({
    ...pattern,
    keyContains: pattern.keyContains || "",
    keyContainsOptional: pattern.keyContainsOptional || "",
  }));

  // Sort from largest id to smallest id
  copy.sort((a, b) => parseInt(b.id) - parseInt(a.id));
  return copy;
}
