import * as DateFns from "date-fns";
import { Set as ISet } from "immutable";
import _ from "lodash";
import React, { useCallback, useState, useMemo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import {
  expandedConfigSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import { useSelector } from "react-redux";
import {
  DataTableLookupEditor,
  TableLookupState,
  newTableLookupState,
  convertRuleTableLookupToState,
  convertStateToRuleTableLookup,
} from "design/organisms/data-table-lookup-editor";
import { SearchableDropdown } from "design/molecules/dropdown";
import {
  RuleConditionEditor,
  RuleConditionState,
  newRuleConditionState,
  convertRuleConditionToState,
  convertStateToRuleCondition,
} from "../rule-condition-editor";
import {
  RuleActionEditor,
  RuleActionState,
  convertRuleActionToState,
  convertStateToRuleAction,
  newRuleActionState,
} from "../rule-action-editor";
import SearchableMultiSelectList from "design/organisms/searchable-multi-select-list";
import { Configuration } from "config";
import * as T from "types/engine-types";
import * as Execution from "features/execution";
import { getInvestorNameById } from "pages/products/list";
import {
  Setter,
  UiValidationError,
  getValidationError,
  useNonNullSetter,
  usePropertySetter,
} from "features/utils";

export type RuleState = {
  name: string;
  stageId: T.ExecutionStageId | null;
  activationTimestamp: Date | null;
  deactivationTimestamp: Date | null;
  productIds: T.ProductId[];
  precondition: RuleConditionState | null;
  lookup: TableLookupState | null;
  condition: RuleConditionState;
  action: RuleActionState;
};

const { newrelic } = window;

export function newRuleState(config: Configuration): RuleState {
  return {
    name: "",
    stageId: null,
    activationTimestamp: new Date(), // TODO temporary
    deactivationTimestamp: null,
    productIds: [],
    precondition: null,
    lookup: null,
    condition: newRuleConditionState(),
    action: newRuleActionState(),
  };
}

export function convertRuleToState(
  rule: T.RuleChangeset,
  config: Configuration,
): RuleState {
  const environment = Execution.predictExecutionEnvironment(
    config,
    "before",
    rule.stageId,
  );

  const localFields = rule.body?.lookup?.fields || [];

  return {
    name: rule.name,
    stageId: rule.stageId,
    activationTimestamp: rule.activationTimestamp
      ? DateFns.parseISO(rule.activationTimestamp)
      : null,
    deactivationTimestamp: rule.deactivationTimestamp
      ? DateFns.parseISO(rule.deactivationTimestamp)
      : null,
    productIds: rule.productIds,
    precondition: rule.body?.precondition
      ? convertRuleConditionToState(rule.body.precondition)
      : null,
    lookup: rule.body?.lookup
      ? convertRuleTableLookupToState(rule.body.lookup)
      : null,
    condition: rule.body
      ? convertRuleConditionToState(rule.body.condition)
      : newRuleConditionState(),
    action: rule.body
      ? convertRuleActionToState(
          rule.body.action,
          config,
          environment,
          localFields,
        )
      : newRuleActionState(),
  };
}

export function convertStateToRule(
  config: Configuration,
  existingNames: ISet<string>,
  state: RuleState,
): T.RuleChangeset {
  if (!state.name.trim()) {
    throw new UiValidationError("Rule name is required");
  }

  if (existingNames.has(state.name)) {
    throw new UiValidationError("Rule name must be unique");
  }

  if (!state.stageId) {
    throw new UiValidationError("Select a rule category");
  }

  const stage = config.rulesStagesById.get(state.stageId);

  if (!stage) {
    throw new UiValidationError("Invalid rule category");
  }

  const allowedActionTypes = stage.actionTypes;

  const environment = Execution.predictExecutionEnvironment(
    config,
    "before",
    stage.id,
  );

  const lookup =
    state.lookup &&
    convertStateToRuleTableLookup(state.lookup, environment, config);

  const localFields = lookup?.fields || [];

  return {
    name: state.name,
    stageId: state.stageId,
    activationTimestamp: state.activationTimestamp
      ? DateFns.formatISO(state.activationTimestamp)
      : null,
    deactivationTimestamp: state.deactivationTimestamp
      ? DateFns.formatISO(state.deactivationTimestamp)
      : null,
    body: {
      precondition:
        state.precondition && convertStateToRuleCondition(state.precondition),
      lookup,
      condition: convertStateToRuleCondition(state.condition),
      action: convertStateToRuleAction(
        allowedActionTypes,
        state.action,
        config,
        environment,
        localFields,
      ),
    },
    productIds: state.productIds,
  };
}

export function validateRuleState(
  config: Configuration,
  existingNames: ISet<string>,
  state: RuleState,
): UiValidationError | null {
  return getValidationError(() => {
    convertStateToRule(config, existingNames, state);
  });
}

const useRuleEditorStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      margin: theme.spacing(2),
      padding: theme.spacing(3, 0),
    },
    title: {
      margin: theme.spacing(0, 3, 4),
    },
    nameField: {
      margin: theme.spacing(0, 2, 3, 3),
      width: "50%",
      minWidth: "600px",
    },
    categoryField: {
      margin: theme.spacing(0, 2, 0, 3),
      width: "50%",
      minWidth: "600px",
    },
    sectionHeading: {
      margin: theme.spacing(3, 3),
      fontSize: 24,
    },
    ruleConditionContainer: {
      display: "flex",
      flexWrap: "wrap",
      margin: theme.spacing(1, 3),
    },
    productSelectorContainer: {
      margin: theme.spacing(0, 3),
    },
    productSelector: {
      marginRight: theme.spacing(3),
    },
  }),
);

export function RuleEditor({
  title,
  products,
  existingNames,
  state,
  showValidationErrors,
  setState,
  investors,
}: {
  title: string;
  products: T.DecoratedProductHeader[];
  existingNames: ISet<string>;
  state: RuleState;
  showValidationErrors: boolean;
  setState: Setter<RuleState>;
  investors: T.DecoratedInvestorHeader[];
}) {
  const C = useRuleEditorStyles();
  const config = useSelector(expandedConfigSelector);
  const objectDetails = useSelector(objectDetailsMapSelector);
  const setName = usePropertySetter(setState, "name");
  const setStageId = usePropertySetter(setState, "stageId");
  const setPrecondition = usePropertySetter(setState, "precondition");
  const setPreconditionNonNull = useNonNullSetter(setPrecondition);
  const setLookup = usePropertySetter(setState, "lookup");
  const setLookupNonNull = useNonNullSetter(setLookup);
  const setCondition = usePropertySetter(setState, "condition");
  const setAction = usePropertySetter(setState, "action");
  const setProductIds = usePropertySetter(setState, "productIds");

  const environment = useMemo(
    () =>
      state.stageId === null
        ? null
        : Execution.predictExecutionEnvironment(
            config,
            "before",
            state.stageId,
          ),
    [config, state.stageId],
  );

  const productsById = useMemo(
    () => new Map(products.map((p) => [p.id, p])),
    [products],
  );
  const sortedProductIds = useMemo(
    () =>
      _.sortBy(products, [
        (p) => getInvestorNameById(investors, p.investorId).toLowerCase(),
        (p) => p.name.toLowerCase(),
      ]).map((p) => p.id),
    [products, investors],
  );
  const selectedProductIds = useMemo(
    () => ISet(state.productIds),
    [state.productIds],
  );

  const selectedStage = useMemo(
    () => (state.stageId && config.rulesStagesById.get(state.stageId)) || null,
    [state.stageId, config],
  );

  function changeStage(newStageId: T.ExecutionStageId | null) {
    const newStage = newStageId && config.rulesStagesById.get(newStageId);

    setPrecondition(null);
    setLookup(null);
    setCondition(newRuleConditionState());

    setStageId(newStageId);

    if (newStageId) {
      if (!newStage) {
        throw new Error(
          "stage not found with ID " + JSON.stringify(newStageId),
        );
      }

      // Set action to appropriate type if possible
      const action = newRuleActionState();
      if (newStage.actionTypes.length === 1) {
        action.type = newStage.actionTypes[0];
      }
      setAction(action);
    }
  }

  const addDataTableLookup = useCallback(() => {
    setLookup(newTableLookupState());
  }, [setLookup]);

  const removeDataTableLookup = useCallback(() => {
    setPrecondition(null);
    setLookup(null);
  }, [setPrecondition, setLookup]);

  const addPrecondition = useCallback(() => {
    setPrecondition(newRuleConditionState());
  }, [setPrecondition]);

  const removePrecondition = useCallback(() => {
    setPrecondition(null);
  }, [setPrecondition]);

  const localFields = useMemo(() => {
    if (!state.lookup || !environment) {
      return [];
    }

    try {
      const lookup = convertStateToRuleTableLookup(
        state.lookup,
        environment,
        config,
      );
      return lookup.fields;
    } catch (err) {
      if (err instanceof UiValidationError) {
        newrelic.noticeError(err);
        return [];
      }

      throw err;
    }
  }, [state.lookup, environment, config]);

  const onProductsListChange = useCallback(
    (newSelectedProductIds: ISet<T.ProductId>) => {
      setProductIds(newSelectedProductIds.toArray());
    },
    [setProductIds],
  );

  const getProductLabel = useCallback(
    (productId: T.ProductId) => {
      const product = productsById.get(productId);
      if (product) {
        return `${getInvestorNameById(investors, product.investorId)} - ${
          product.name
        }`;
      } else {
        return "<Undefined>"; // should never happen
      }
    },
    [investors, productsById],
  );

  const allowedActionTypes = useMemo(
    () =>
      config.rulesStages.find((s) => s.id === selectedStage?.id)?.actionTypes ||
      [],
    [config.rulesStages, selectedStage],
  );

  return (
    <Paper className={C.contentContainer}>
      <Typography className={C.title} variant="h4">
        {title}
      </Typography>
      <Box>
        <Box className={C.nameField}>
          <TextField
            style={{ width: "100%" }}
            label="Rule Name"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            defaultValue={state.name}
            error={
              showValidationErrors &&
              (!state.name.trim() || existingNames.has(state.name))
            }
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
        <Box className={C.categoryField}>
          <StageSelector
            state={state.stageId}
            config={config}
            showErrors={showValidationErrors}
            onChange={changeStage}
          />
        </Box>
      </Box>

      {state.stageId !== null &&
        selectedStage !== null &&
        environment !== null && (
          <>
            {state.precondition !== null && (
              <>
                <Box className={C.sectionHeading} display="flex">
                  <Box mr={2}>Rule precondition</Box>
                  <Box>
                    <Button color="secondary" onClick={removePrecondition}>
                      Remove
                    </Button>
                  </Box>
                </Box>
                <div className={C.ruleConditionContainer}>
                  <RuleConditionEditor
                    state={state.precondition}
                    setState={setPreconditionNonNull}
                    environment={environment}
                    localFields={localFields}
                    config={config}
                    objectDetails={objectDetails}
                  />
                </div>
              </>
            )}
            {state.lookup !== null && (
              <>
                <Box className={C.sectionHeading} display="flex">
                  <Box mr={2}>Data table lookup</Box>
                  <Box>
                    <Button color="secondary" onClick={removeDataTableLookup}>
                      Remove
                    </Button>
                  </Box>
                  {state.precondition === null && (
                    <Box>
                      <Button color="primary" onClick={addPrecondition}>
                        Add Precondition
                      </Button>
                    </Box>
                  )}
                </Box>
                <div className={C.ruleConditionContainer}>
                  <DataTableLookupEditor
                    state={state.lookup}
                    setState={setLookupNonNull}
                    environment={environment}
                    config={config}
                  />
                </div>
              </>
            )}
            <Box className={C.sectionHeading} display="flex">
              <Box mr={2}>Rule condition</Box>
              {state.lookup === null && (
                <Box>
                  <Button color="primary" onClick={addDataTableLookup}>
                    Use Data Table
                  </Button>
                </Box>
              )}
            </Box>
            <div className={C.ruleConditionContainer}>
              <RuleConditionEditor
                state={state.condition}
                setState={setCondition}
                environment={environment}
                localFields={localFields}
                config={config}
                objectDetails={objectDetails}
              />
            </div>
            <Typography className={C.sectionHeading} variant="h5">
              Rule action
            </Typography>
            <Box my={2}>
              <RuleActionEditor
                allowedActionTypes={allowedActionTypes}
                state={state.action}
                showErrors={showValidationErrors}
                environment={environment}
                localFields={localFields}
                setState={setAction}
              />
            </Box>
          </>
        )}

      <Typography className={C.sectionHeading} variant="h5">
        Associated Products
      </Typography>
      <Box className={C.productSelectorContainer}>
        <SearchableMultiSelectList
          label={"Products"}
          className={C.productSelector}
          noItemsMessage="No products available."
          noResultsMessage="No products found with that name."
          width="600px"
          options={sortedProductIds}
          selected={selectedProductIds}
          getOptionLabel={getProductLabel}
          onChange={onProductsListChange}
        />
      </Box>
    </Paper>
  );
}

const StageSelector = React.memo(
  ({
    state,
    config,
    showErrors,
    onChange,
  }: {
    state: T.ExecutionStageId | null;
    config: Configuration;
    showErrors: boolean;
    onChange: (newId: T.ExecutionStageId | null) => void;
  }) => {
    const [showDialog, setShowDialog] = useState(false);

    const rulesStageIds = useMemo(
      () => config.rulesStages.map((s) => s.id),
      [config],
    );
    const getStageName = useCallback(
      (id: T.ExecutionStageId) =>
        config.stagesById.get(id)?.name || "<Unknown>",
      [config],
    );

    const [requestedStageId, setRequestedStageId] =
      useState<T.ExecutionStageId | null>(null);

    const requestStageChange = useCallback(
      (id: T.ExecutionStageId | null) => {
        if (state === null) {
          onChange(id);
        } else {
          setRequestedStageId(id);
          setShowDialog(true);
        }
      },
      [state, onChange],
    );

    return (
      <>
        <SearchableDropdown
          label="Rule Category"
          options={rulesStageIds}
          getOptionLabel={getStageName}
          value={state}
          error={showErrors && state === null}
          setValue={requestStageChange}
        />

        <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
          <DialogTitle>Change rule category?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Changing category will clear your rule logic (conditions) and rule
              action.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDialog(false)}>Cancel</Button>
            <Button
              color="secondary"
              onClick={() => {
                setShowDialog(false);
                onChange(requestedStageId);
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  },
);
