import styled from "styled-components";
import breakpoints from "design/subatomics/breakpoints";
import color from "design/subatomics/colors";
import whitespace from "design/subatomics/whitespace";
import radiusOptions from "design/subatomics/corners";
import transitions from "design/subatomics/transitions";
import { fontWeights } from "design/atoms/typography";
import shadowOptions from "design/subatomics/shadows";

export const Accordion = styled.div`
  position: relative;

  > h3 {
    font-weight: ${fontWeights.default};
    position: relative;
    padding-top: ${whitespace("least")};
    padding-bottom: ${whitespace("least")};
    padding-left: ${whitespace("less")};
    border: 1px solid ${color({ color: "lightBorder" })};
    border-radius: ${radiusOptions.small};
    transition: background-color ${transitions.default},
      border-color ${transitions.default}, color ${transitions.default};
    background-color: ${color({ color: "gray", shade: 9 })};
    color: ${color({ color: "gray", shade: 5 })};

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 32px;

    .close {
      position: absolute;
      top: calc(${whitespace("least")} + 1px);
      right: ${whitespace("less")};
      transition: color ${transitions.default};
    }

    svg {
      text-shadow: ${shadowOptions.titles};
      height: 16px;
      width: 16px;
    }

    &:hover {
      border: 1px solid ${color({ color: "border" })};
      cursor: pointer;
    }
  }

  &.open {
    > div {
      padding: 0 ${whitespace("more")};
    }

    h3 {
      font-weight: ${fontWeights.default};

      &:hover {
        cursor: pointer;

        .close {
          color: ${color({ color: "gray", shade: 4 })};
        }
      }
    }
  }

  &.closed {
    > div {
      overflow: hidden;
      height: 0;
      padding: 0;
    }
  }

  &.green {
    h3 {
      color: ${color({ color: "gray", shade: 4 })};
      background-color: ${color({ color: "green", shade: 9 })};
      border-color: transparent;

      svg {
        color: ${color({ color: "green", shade: 5 })};
      }

      &:hover {
        background-color: ${color({ color: "green", shade: 6 })};
        border-color: ${color({ color: "green", shade: 6 })};
        color: ${color({ color: "white" })};

        svg,
        .close {
          color: ${color({ color: "white" })};
        }
      }
    }
  }

  &.blue {
    h3 {
      color: ${color({ color: "gray", shade: 4 })};
      background-color: ${color({ color: "blue", shade: 9 })};
      border-color: transparent;

      svg {
        color: ${color({ color: "blue", shade: 5 })};
      }

      &:hover {
        background-color: ${color({ color: "blue", shade: 6 })};
        border-color: ${color({ color: "blue", shade: 6 })};
        color: ${color({ color: "white" })};

        svg,
        .close {
          color: ${color({ color: "white" })};
        }
      }
    }
  }

  &.gray {
    h3 {
      color: ${color({ color: "gray", shade: 6 })};
      background-color: ${color({ color: "gray", shade: 7 })};
      border-color: transparent;

      &:hover {
        background-color: ${color({ color: "gray", shade: 7 })};
        border-color: ${color({ color: "gray", shade: 7 })};
        color: ${color({ color: "gray", shade: 5 })};

        svg,
        .close {
          color: ${color({ color: "gray", shade: 5 })};
        }
      }
    }
  }

  &.red {
    h3 {
      color: ${color({ color: "gray", shade: 4 })};
      background-color: ${color({ color: "red", shade: 9 })};
      border-color: transparent;

      svg {
        color: ${color({ color: "red", shade: 5 })};
      }

      &:hover {
        background-color: ${color({ color: "red", shade: 6 })};
        border-color: ${color({ color: "red", shade: 6 })};
        color: ${color({ color: "white" })};

        svg,
        .close {
          color: ${color({ color: "white" })};
        }
      }
    }
  }

  &.orange {
    h3 {
      color: ${color({ color: "gray", shade: 4 })};
      background-color: ${color({ color: "orange", shade: 9 })};
      border-color: transparent;

      svg {
        color: ${color({ color: "orange", shade: 5 })};
      }

      &:hover {
        background-color: ${color({ color: "orange", shade: 6 })};
        border-color: ${color({ color: "orange", shade: 6 })};
        color: ${color({ color: "white" })};

        svg,
        .close {
          color: ${color({ color: "white" })};
        }
      }
    }
  }

  &.yellow {
    h3 {
      color: ${color({ color: "gray", shade: 4 })};
      background-color: ${color({ color: "yellow", shade: 9 })};
      border-color: transparent;

      svg {
        color: ${color({ color: "yellow", shade: 5 })};
      }

      &:hover {
        background-color: ${color({ color: "yellow", shade: 6 })};
        border-color: ${color({ color: "yellow", shade: 6 })};

        svg,
        .close {
          color: ${color({ color: "white" })};
        }
      }
    }
  }

  ${breakpoints.mobile} {
    margin-bottom: ${whitespace()};

    > h3 {
      margin: ${whitespace("less")};
    }

    > div {
      margin-top: 0;
    }
  }

  ${breakpoints.desktop} {
    margin-bottom: ${whitespace()};

    > h3 {
      margin: ${whitespace("less")};
    }

    > div {
      margin-top: 0;
    }
  }
`;
