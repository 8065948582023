import React, { ReactNode } from "react";
import { ApplicationContent } from "./styles";

export default React.memo(({ children }: { children: ReactNode }) => {
  return (
    <ApplicationContent className="application-content">
      {children}
    </ApplicationContent>
  );
});
