import styled from "styled-components";
import breakpoints from "design/subatomics/breakpoints";

export const ApplicationContent = styled.main`
  height: calc(100vh - 48px);

  ${breakpoints.mobile} {
    padding-bottom: 148px;

    .hide-for-mobile {
      display: none !important;
    }
  }

  ${breakpoints.desktop} {
    .hide-for-desktop {
      display: none !important;
    }
  }
`;
