import React from "react";
import { CreditApplicationFieldDefinition } from "types/generated-types";
import { BaseFieldDefinition } from "types/engine-types";
import TextInput from "design/atoms/text-input";
import Label from "design/atoms/label/index";
import { FilterWrapper } from "./styles";
import {
  setMinRate,
  setMaxRate,
  setMinPrice,
  setMaxPrice,
} from "features/pricing-summaries";
import FilterInput from "../filter-input";

export default React.memo(
  ({
    field,
    style,
    filterType,
    showLabel = true,
  }: {
    field: CreditApplicationFieldDefinition | BaseFieldDefinition;
    style: string;
    filterType?: string;
    showLabel?: boolean;
  }) => {
    let prefix = null;
    let suffix = null;
    switch (style) {
      case "plain":
        break;
      case "percent":
        suffix = "%";
        break;
      case "dollar":
        prefix = "$";
        break;
    }
    let adornment: "suffix" | "prefix" | null = null;
    let adornmentText: string | null = null;
    if (suffix) {
      adornment = "suffix";
      adornmentText = suffix;
    }
    if (prefix) {
      adornment = "prefix";
      adornmentText = prefix;
    }
    const minSetter = () => {
      switch (filterType!) {
        case "rateField": {
          return setMinRate;
        }
        case "priceField": {
          return setMinPrice;
        }
        default: {
          return filterType!;
        }
      }
    };

    const maxSetter = () => {
      switch (filterType!) {
        case "rateField": {
          return setMaxRate;
        }
        case "priceField": {
          return setMaxPrice;
        }
        default: {
          return filterType!;
        }
      }
    };

    switch (!!field.conditions) {
      case false: {
        return (
          <Label
            className="flex1"
            children={
              <>
                <Label children={showLabel ? field.name : ""} />
                <FilterWrapper>
                  <FilterInput
                    adornment={adornment}
                    adornmentText={adornmentText}
                    label={" Minimum"}
                    setValue={minSetter()}
                    fieldId={field.id}
                  />
                  <FilterInput
                    adornment={adornment}
                    adornmentText={adornmentText}
                    label={" Maximum"}
                    setValue={maxSetter()}
                    fieldId={field.id}
                  />
                </FilterWrapper>
              </>
            }
          />
        );
      }
      case true: {
        return (
          <Label
            children={
              <TextInput
                adornment={adornment}
                adornmentText={adornmentText}
                label={field.name}
              />
            }
          />
        );
      }
    }
  },
);
