import { Map as IMap } from "immutable";

import * as T from "types/engine-types";
import * as FormulasUtil from "./features/formulas-util";
import { toMapById, unreachable } from "./features/utils";
import { ClientTimezone } from "types/engine-types";

export type FieldBucket = {
  name: string;
  fieldDefinitions: T.BaseFieldDefinition;
};

export type Configuration = Readonly<{
  // Original properties from T.EngineConfiguration.
  // Note though that we don't guarantee compatibility with T.EngineConfiguration.
  clientTimezone: ClientTimezone;
  rawProductFields: readonly T.RawProductFieldDefinition[];
  rawCreditApplicationFields: readonly T.RawCreditApplicationFieldDefinition[];
  rawPipelineOnlyFields: readonly T.RawPipelineFieldDefinition[];
  productFields: readonly T.ProductFieldDefinition[];
  creditApplicationFields: readonly T.CreditApplicationFieldDefinition[];
  pipelineOnlyFields: readonly T.PipelineFieldDefinition[];
  systemProductFields: readonly T.ProductFieldDefinition[];
  systemCreditApplicationFields: readonly T.CreditApplicationFieldDefinition[];
  systemPipelineOnlyFields: readonly T.PipelineFieldDefinition[];
  stages: readonly T.ExecutionStage[];
  settings: T.ClientSettings;
  roles: T.RoleHeader[];
  pricingProfiles: T.PricingProfileHeader[];

  rawEnumTypes: readonly T.RawEnumType[];
  enumTypes: readonly T.EnumType[];
  enumTypesById: IMap<T.EnumTypeId, T.EnumType>;
  systemEnumTypes: readonly T.EnumType[];
  systemEnumTypesById: IMap<T.EnumTypeId, T.EnumType>;
  productFieldsById: IMap<T.FieldId, T.ProductFieldDefinition>;
  creditApplicationFieldsById: IMap<
    T.FieldId,
    T.CreditApplicationFieldDefinition
  >;
  allFieldsById: IMap<T.FieldId, T.BaseFieldDefinition>;
  allSystemFieldsById: IMap<T.FieldId, T.BaseFieldDefinition>;

  priceScenarioTableFieldInfo: PriceScenarioTableFieldInfo | null;

  stagesById: IMap<T.ExecutionStageId, T.ExecutionStage>;
  rulesStages: readonly T.ExecutionStage.RunRules[];
  rulesStagesById: IMap<T.ExecutionStageId, T.ExecutionStage.RunRules>;
  calcStages: readonly T.ExecutionStage.Calculations[];

  original: T.EngineConfiguration;

  getEnumTypeByIdentifier(identifier: string): T.EnumType | null;
  getRawEnumTypeByIdentifier(identifier: string): T.RawEnumType | null;
  getFieldByIdentifier(identifier: string): T.BaseFieldDefinition | null;
  getPricingProfileByIdentifier(
    identifier: string,
  ): T.PricingProfileHeader | null;
}>;

export function expandConfig(config: T.EngineConfiguration): Configuration {
  const rawEnumTypes = config.rawEnumerations;
  const enumTypes = config.enumerations;
  const enumTypesById = toMapById(enumTypes);

  const systemEnumTypes = config.systemEnumerations;
  const systemEnumTypesById = toMapById(systemEnumTypes);

  const productFieldsById = toMapById(config.productFields);
  const creditApplicationFieldsById = toMapById(config.creditApplicationFields);

  const allFieldsById = getAllFieldsById(config);
  const allSystemFieldsById = getAllSystemFieldsById(config);

  const stagesById = toMapById(config.stages);
  const rulesStages = config.stages.filter(
    (s): s is T.ExecutionStage.RunRules => s.kind === "run-rules",
  );
  const rulesStagesById = toMapById(rulesStages);
  const calcStages = config.stages.filter(
    (s): s is T.ExecutionStage.Calculations => s.kind === "calculations",
  );

  const priceScenarioTableFieldInfo =
    config.settings.priceScenarioTable &&
    expandPriceScenarioTable(config.settings.priceScenarioTable, allFieldsById);

  const enumTypesByLowerCaseIdentifier = IMap(
    enumTypes.map((enumType) => [
      FormulasUtil.getEnumTypeIdentifier(enumType).toLowerCase(),
      enumType,
    ]),
  );
  const rawEnumTypesByLowerCaseIdentifier = IMap(
    rawEnumTypes.map((enumType) => [
      FormulasUtil.getRawEnumTypeIdentifier(
        enumType,
        systemEnumTypesById,
      ).toLowerCase(),
      enumType,
    ]),
  );
  const allFieldsByLowerCaseIdentifier = IMap(
    allFieldsById
      .valueSeq()
      .map((field) => [
        FormulasUtil.getFieldIdentifier(field).toLowerCase(),
        field,
      ]),
  );

  const pricingProfilesByLowerCaseIdentifier = IMap(
    config.pricingProfiles.map((profile) => [
      FormulasUtil.getPricingProfileIdentifier(profile).toLowerCase(),
      profile,
    ]),
  );

  return {
    ...config,

    rawEnumTypes,
    enumTypes,
    enumTypesById,
    systemEnumTypes,
    systemEnumTypesById,
    productFieldsById,
    creditApplicationFieldsById,
    allFieldsById,
    allSystemFieldsById,
    stagesById,
    rulesStages,
    rulesStagesById,
    calcStages,
    priceScenarioTableFieldInfo,
    original: config,

    getEnumTypeByIdentifier(identifier: string): T.EnumType | null {
      return (
        enumTypesByLowerCaseIdentifier.get(identifier.toLowerCase()) || null
      );
    },
    getRawEnumTypeByIdentifier(identifier: string): T.RawEnumType | null {
      return (
        rawEnumTypesByLowerCaseIdentifier.get(identifier.toLowerCase()) || null
      );
    },
    getFieldByIdentifier(identifier: string): T.BaseFieldDefinition | null {
      return (
        allFieldsByLowerCaseIdentifier.get(identifier.toLowerCase()) || null
      );
    },
    getPricingProfileByIdentifier(
      identifier: string,
    ): T.PricingProfileHeader | null {
      return (
        pricingProfilesByLowerCaseIdentifier.get(identifier.toLowerCase()) ||
        null
      );
    },
  };
}

function getAllFieldsById(
  config: T.EngineConfiguration,
): IMap<T.FieldId, T.BaseFieldDefinition> {
  const allFields = [
    ...config.creditApplicationFields,
    ...config.stages.flatMap((stage) => getFieldsFromStage(config, stage)),
  ];

  return toMapById(allFields);
}

function getAllSystemFieldsById(
  config: T.EngineConfiguration,
): IMap<T.FieldId, T.BaseFieldDefinition> {
  const allFields = [
    ...config.systemCreditApplicationFields,
    ...config.systemProductFields,
  ];

  return toMapById(allFields);
}

export function getFieldsFromStage(
  config: T.EngineConfiguration,
  stage: T.ExecutionStage,
): T.BaseFieldDefinition[] {
  switch (stage.kind) {
    case "run-rules":
      return [];
    case "calculations":
      return stage.calculations.flatMap((calc): T.BaseFieldDefinition[] => {
        switch (calc.type) {
          case "field":
            return [
              {
                ...calc.field,
                description: null,
              },
            ];
          case "data-table-lookup":
            return calc.lookup.fields.map((f) => ({
              ...f,
              description: null,
            }));
        }
      });
    case "split-on-product":
      return [
        stage.investorCodeField,
        stage.investorNameField,
        stage.productCodeField,
        stage.productNameField,
        ...config.productFields,
      ];
    case "split-on-rate-sheet":
      return [stage.baseRateField, stage.lockPeriodField, stage.basePriceField];
    case "insert-pricing-profile":
      return [stage.pricingProfileNameField, stage.pricingProfileRefField];
    case "load-current-date":
    case "calculate-total-rate-adjustment":
    case "calculate-total-price-adjustment":
    case "calculate-total-margin-adjustment":
      return [stage.fieldDef];
    default:
      unreachable(stage);
  }
}

export function fieldBuckets(
  config: Configuration,
): [string, T.BaseFieldDefinition[]][] {
  const productFields = config.productFields.slice();
  const calculationBuckets: [string, T.BaseFieldDefinition[]][] = config.stages
    .filter((s) => s.kind === "calculations")
    .map((stage) => [stage.name, getFieldsFromStage(config.original, stage)]);
  return [
    ["Product Specifications - Configurable", productFields],
    ...calculationBuckets,
  ];
}

export type PriceScenarioTableFieldInfo =
  | PriceScenarioTableFieldInfo.RateWithLockPeriod
  | PriceScenarioTableFieldInfo.RateWithColumns;

export declare namespace PriceScenarioTableFieldInfo {
  export type RateWithLockPeriod = {
    type: "rate-with-lock-period";
    adjustedRateField: T.BaseFieldDefinition;
    adjustedRateLockPeriodField: T.BaseFieldDefinition;
    adjustedPriceField: T.BaseFieldDefinition;
    extraColumnFields: T.BaseFieldDefinition[];
  };

  export type RateWithColumns = {
    type: "rate-with-columns";
    adjustedRateField: T.BaseFieldDefinition;
    columnFields: T.BaseFieldDefinition[];
  };
}

function expandPriceScenarioTable(
  tableSettings: T.PriceScenarioTable,
  allFieldsById: IMap<T.FieldId, T.BaseFieldDefinition>,
): PriceScenarioTableFieldInfo | null {
  const getFieldDef = (fieldId: T.FieldId) => {
    const fieldDef = allFieldsById.get(fieldId);
    if (!fieldDef) {
      console.warn(
        `could not find definition for pricing table field ${fieldId}`,
      );
      throw new Error("missing-field");
    }
    return fieldDef;
  };

  try {
    switch (tableSettings.type) {
      case "rate-with-columns": {
        const { type, adjustedRateFieldId, columns } = tableSettings;
        return {
          type,
          adjustedRateField: getFieldDef(adjustedRateFieldId),
          columnFields: columns.map(getFieldDef),
        };
      }
      case "rate-with-lock-period": {
        const {
          type,
          adjustedRateFieldId,
          adjustedRateLockPeriodFieldId,
          adjustedPriceFieldId,
          extraColumns,
        } = tableSettings;
        return {
          type,
          adjustedRateField: getFieldDef(adjustedRateFieldId),
          adjustedRateLockPeriodField: getFieldDef(
            adjustedRateLockPeriodFieldId,
          ),
          adjustedPriceField: getFieldDef(adjustedPriceFieldId),
          extraColumnFields: extraColumns.map(getFieldDef),
        };
      }
    }
  } catch (err) {
    if (err === "missing-field") {
      return null;
    } else {
      throw err;
    }
  }
}
