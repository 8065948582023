import { Set as ISet, Map as IMap } from "immutable";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Tabs,
  Tab,
  TextField,
  IconButton,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { getMessageNode } from "features/server-validation";
import * as Api from "api";
import {
  FieldValueTypeEditor,
  FieldValueTypeState,
  newFieldValueTypeState,
  convertFieldValueTypeToState,
  convertStateToFieldValueType,
  FieldType,
  FieldValueTypeDisplay,
} from "design/organisms/field-value-type-editor";
import {
  ObjectListEditor,
  ObjectListState,
  newObjectListState,
} from "design/organisms/object-list-editor";
import {
  FieldConditionEditor,
  FieldConditionState,
  convertFieldConditionToState,
  convertStateToFieldCondition,
  newFieldConditionState,
  convertIncludedFieldConditionToState,
  convertStateToIncludedFieldCondition,
} from "./_components/field-condition-editor";
import UnloadPrompt from "design/atoms/unload-prompt";
import { AssociatedRolesViewer } from "design/organisms/associated-role-viewer";
import { RefSourcesViewer } from "design/atoms/ref-sources-viewer";
import { Configuration } from "config";
import * as T from "types/engine-types";
import {
  enumTypeNameToIdentifier,
  enumVariantNameToIdentifier,
  fieldNameToIdentifier,
  getEnumTypeIdentifier,
  getEnumVariantIdentifier,
  getRawFieldStateName,
} from "features/formulas-util";
import {
  Setter,
  UiValidationError,
  getValidationError,
  usePropertySetter,
  useArraySetter,
  createSlugId,
  unPrefixId,
  resolveEnum,
  usePropertySetter2,
  getErrorMessage,
} from "features/utils";
import { getRoles, usePermissions } from "features/roles";
import { DetailedInvalidRequest } from "api";
import { useDispatch, useSelector } from "react-redux";
import { SearchableDropdown } from "design/molecules/dropdown";
import {
  LoggedInInfo,
  loadAppInit,
  nonNullApplicationInitializationSelector,
  expandedConfigSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import ErrorBoundary from "features/utils/error-boundary";

export type FieldDefinitionState = {
  oldId: T.FieldId | null;
  id: T.FieldId;
  disposition: FieldDispositionState;
  domainValidationErrors: {
    hasErrors: boolean;
    id: T.ServerValidationError[];
  };
};

type FieldDispositionState =
  | NativeFieldDispositionState
  | InheritedFieldDispositionState;

export type IncludedFieldConditionState = {
  id: T.FieldConditionId;
  domainValidationErrors: { hasErrors: boolean; id: T.ServerValidationError[] };
};

type NativeFieldDefinitionState = FieldDefinitionState & {
  disposition: { kind: "native" };
};

type InheritedFieldDefinitionState = FieldDefinitionState & {
  disposition: { kind: "inherited" };
};

type NativeFieldDispositionState = {
  kind: "native";
  name: string;
  description: string;
  valueType: FieldValueTypeState;
  conditions: readonly FieldConditionState[];
  domainValidationErrors: {
    hasErrors: boolean;
    name: T.ServerValidationError[];
    description: T.ServerValidationError[];
  };
};

type InheritedFieldDispositionState = {
  kind: "inherited";
  nameAlias: string | null;
  descriptionAlias: string;
  includeConditions: ObjectListState<IncludedFieldConditionState>;
  additionalConditions: readonly FieldConditionState[];
  domainValidationErrors: {
    hasErrors: boolean;
    nameAlias: T.ServerValidationError[];
    descriptionAlias: T.ServerValidationError[];
  };
};

const { newrelic } = window;

function mapFieldDefinitionsValidation(
  states: readonly FieldDefinitionState[],
  validation: readonly T.RawCommonFieldDefinitionValidation[],
): FieldDefinitionState[] {
  return states.map((s, i) => mapFieldDefinitionValidation(s, validation[i]));
}

function mapFieldDefinitionValidation(
  state: FieldDefinitionState,
  validation: T.RawCommonFieldDefinitionValidation,
): FieldDefinitionState {
  return {
    oldId: state.oldId,
    id: state.id,
    disposition: mapFieldDispositionValidation(
      state.disposition,
      validation.disposition,
    ),
    domainValidationErrors: {
      hasErrors: validation.hasErrors,
      id: validation.id,
    },
  };
}

function mapFieldDispositionValidation(
  state: FieldDispositionState,
  validation: T.FieldDispositionValidation,
): FieldDispositionState {
  if (state.kind === "native" && validation.kind === "native") {
    return {
      kind: state.kind,
      name: state.name,
      description: state.description,
      valueType: state.valueType,
      conditions: state.conditions.map((c, i) =>
        mapFieldConditionValidation(c, validation.conditions[i]),
      ),
      domainValidationErrors: {
        hasErrors: validation.hasErrors,
        name: validation.name,
        description: validation.description,
      },
    };
  } else if (state.kind === "inherited" && validation.kind === "inherited") {
    return {
      ...state,
      includeConditions: {
        ...state.includeConditions,
        objects: state.includeConditions.objects.map((c, i) =>
          mapIncludeConditionValidation(c, validation.includeConditions[i]),
        ),
      },
      domainValidationErrors: {
        hasErrors: validation.hasErrors,
        nameAlias: validation.nameAlias,
        descriptionAlias: validation.descriptionAlias,
      },
    };
  }

  return {
    ...state,
  };
}

function mapFieldConditionValidation(
  state: FieldConditionState,
  validation: T.FieldConditionValidation,
): FieldConditionState {
  return {
    ...state,
    id: state.id,
    parentFieldId: state.parentFieldId,
    domainValidationErrors: {
      hasErrors: validation.hasErrors,
      id: validation.id,
      parentFieldId: validation.parentFieldId,
    },
  };
}

function mapIncludeConditionValidation(
  state: IncludedFieldConditionState,
  validation: T.IncludedFieldConditionValidation,
): IncludedFieldConditionState {
  return {
    id: state.id,
    domainValidationErrors: {
      hasErrors: validation.hasErrors,
      id: validation.id,
    },
  };
}

type EnumTypeState = {
  oldId: T.EnumTypeId | null;
  id: T.EnumTypeId;
  disposition: EnumDispositionState;
  domainValidationErrors: {
    hasErrors: boolean;
    id: T.ServerValidationError[];
  };
};

type EnumDispositionState =
  | NativeEnumDispositionState
  | InheritedEnumDispositionState;

type NativeEnumState = EnumTypeState & {
  disposition: { kind: "native" };
};

type InheritedEnumState = EnumTypeState & {
  disposition: { kind: "inherited" };
};

type NativeEnumDispositionState = {
  kind: "native";
  name: string;
  variants: ObjectListState<EnumVariantState>;
  domainValidationErrors: {
    hasErrors: boolean;
    name: T.ServerValidationError[];
  };
};

type InheritedEnumDispositionState = {
  kind: "inherited";
  nameAlias: string | null;
  includeVariants: ObjectListState<IncludedEnumVariantState>;
  excludeVariants: ObjectListState<ExcludedEnumVariantState>;
  domainValidationErrors: {
    hasErrors: boolean;
    nameAlias: T.ServerValidationError[];
  };
};

type EnumVariantState = {
  oldId: T.EnumVariantId | null;
  id: T.EnumVariantId;
  name: string;
  domainValidationErrors: {
    hasErrors: boolean;
    id: T.ServerValidationError[];
    name: T.ServerValidationError[];
  };
};

type IncludedEnumVariantState = {
  id: T.EnumVariantId;
  nameAlias: string | null;
  domainValidationErrors: {
    hasErrors: boolean;
    id: T.ServerValidationError[];
    nameAlias: T.ServerValidationError[];
  };
};

type ExcludedEnumVariantState = {
  id: T.EnumVariantId;
  domainValidationErrors: {
    hasErrors: boolean;
    id: T.ServerValidationError[];
  };
};

function mapEnumTypesValidation(
  states: readonly EnumTypeState[],
  validations: readonly T.RawEnumTypeValidation[],
): EnumTypeState[] {
  return states.map((s, i) => mapEnumTypeValidation(s, validations[i]));
}

function mapEnumTypeValidation(
  state: EnumTypeState,
  validation: T.RawEnumTypeValidation,
): EnumTypeState {
  return {
    ...state,
    disposition: mapEnumDispositionValidation(
      state.disposition,
      validation.disposition,
    ),
    domainValidationErrors: {
      hasErrors: validation.hasErrors,
      id: validation.id,
    },
  };
}

function mapEnumDispositionValidation(
  state: EnumDispositionState,
  validation: T.EnumDispositionValidation,
): EnumDispositionState {
  if (state.kind === "native" && validation.kind === "native") {
    return {
      kind: "native",
      name: state.name,
      variants: {
        ...state.variants,
        objects: state.variants.objects.map((v, i) =>
          mapEnumVariantValidation(v, validation.variants[i]),
        ),
      },
      domainValidationErrors: {
        hasErrors: validation.hasErrors,
        name: validation.name,
      },
    };
  } else if (state.kind === "inherited" && validation.kind === "inherited") {
    return {
      kind: "inherited",
      nameAlias: state.nameAlias,
      includeVariants: {
        ...state.includeVariants,
        objects: state.includeVariants.objects.map((v, i) =>
          mapIncludedEnumVariantValidation(v, validation.includeVariants[i]),
        ),
      },
      excludeVariants: {
        ...state.excludeVariants,
        objects: state.excludeVariants.objects.map((v, i) =>
          mapExcludedEnumVariantValidation(v, validation.excludeVariants[i]),
        ),
      },
      domainValidationErrors: {
        hasErrors: validation.hasErrors,
        nameAlias: validation.nameAlias,
      },
    };
  }

  return {
    ...state,
  };
}

function mapEnumVariantValidation(
  state: EnumVariantState,
  validation: T.EnumVariantValidation,
): EnumVariantState {
  return {
    oldId: state.oldId,
    id: state.id,
    name: state.name,
    domainValidationErrors: {
      hasErrors: validation.hasErrors,
      id: validation.id,
      name: validation.name,
    },
  };
}

function mapIncludedEnumVariantValidation(
  state: IncludedEnumVariantState,
  validation: T.IncludedEnumVariantValidation,
): IncludedEnumVariantState {
  return {
    id: state.id,
    nameAlias: state.nameAlias,
    domainValidationErrors: {
      hasErrors: validation.hasErrors,
      id: validation.id,
      nameAlias: validation.nameAlias,
    },
  };
}

function mapExcludedEnumVariantValidation(
  state: ExcludedEnumVariantState,
  validation: T.ExcludedEnumVariantValidation,
): ExcludedEnumVariantState {
  return {
    id: state.id,
    domainValidationErrors: {
      hasErrors: validation.hasErrors,
      id: validation.id,
    },
  };
}

const useStyles = makeStyles((t) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      height: "100%",
    },
    paper: {
      flex: "1",
      margin: t.spacing(2),
    },
  }),
);

export const FieldsPage = React.memo(() => {
  const C = useStyles();
  const dispatch = useDispatch();
  const nonNullState = useSelector(nonNullApplicationInitializationSelector);
  const objectDetailsMap = useSelector(objectDetailsMapSelector);
  const expandedConfigMap = useSelector(expandedConfigSelector);

  const loggedInInfo = {
    user: nonNullState.user,
    client: nonNullState.client,
    config: expandedConfigMap,
    objectDetails: objectDetailsMap,
    notifications: nonNullState.notifications,
  };

  const savedConfig = loggedInInfo.config;
  const existingFieldNames = listFieldNamesExcludingFieldLibrary(savedConfig);
  const existingEnumNames = listEnumNamesExcludingFieldLibrary(savedConfig);
  const hasPermission = usePermissions();
  const hasFieldsModifyPerm = hasPermission("field-enum-library-modify");
  const [enumsState, setEnumsState] = useState(
    newObjectListState(savedConfig.rawEnumTypes.map(convertEnumTypeToState)),
  );
  const [productFieldsState, setProductFieldsState] = useState(
    newObjectListState(
      savedConfig.rawProductFields.map(convertFieldDefinitionToState),
    ),
  );
  const [creditApplicationFieldsState, setCreditApplicationFieldsState] =
    useState(
      newObjectListState(
        savedConfig.rawCreditApplicationFields.map(
          convertFieldDefinitionToState,
        ),
      ),
    );
  const [pipelineFieldsState, setPipelineFieldsState] = useState(
    newObjectListState(
      savedConfig.rawPipelineOnlyFields.map(convertFieldDefinitionToState),
    ),
  );

  const fieldNamesWithoutProductFields = existingFieldNames.union(
    listFieldNames(
      [...creditApplicationFieldsState.objects, ...pipelineFieldsState.objects],
      loggedInInfo.config.allSystemFieldsById,
    ),
  );
  const fieldNamesWithoutCreditApplicationFields = existingFieldNames.union(
    listFieldNames(
      [...productFieldsState.objects, ...pipelineFieldsState.objects],
      loggedInInfo.config.allSystemFieldsById,
    ),
  );
  const fieldNamesWithoutPipelineFields = existingFieldNames.union(
    listFieldNames(
      [...creditApplicationFieldsState.objects, ...productFieldsState.objects],
      loggedInInfo.config.allSystemFieldsById,
    ),
  );

  const [enumsError, unsavedEnumTypes] = tryConvertEnumsState(
    existingEnumNames,
    enumsState,
    loggedInInfo,
  );
  const [productFieldsError, unsavedProductFields] = tryConvertFieldsState(
    fieldNamesWithoutProductFields,
    productFieldsState,
    loggedInInfo.config.allSystemFieldsById,
    unsavedEnumTypes,
  );

  const [creditApplicationFieldsError, unsavedCreditApplicationFields] =
    tryConvertFieldsState(
      fieldNamesWithoutCreditApplicationFields,
      creditApplicationFieldsState,
      loggedInInfo.config.allSystemFieldsById,
      unsavedEnumTypes,
    );

  const [pipelineFieldsError, unsavedPipelineFields] = tryConvertFieldsState(
    fieldNamesWithoutPipelineFields,
    pipelineFieldsState,
    loggedInInfo.config.allSystemFieldsById,
    unsavedEnumTypes,
  );

  const anyUnsaved = useMemo(() => {
    return !!(
      enumsError ||
      !_.isEqual(
        _.sortBy(savedConfig.rawEnumTypes, "id"),
        _.sortBy(unsavedEnumTypes, "id"),
      ) ||
      productFieldsError ||
      !_.isEqual(savedConfig.rawProductFields, unsavedProductFields) ||
      creditApplicationFieldsError ||
      !_.isEqual(
        savedConfig.rawCreditApplicationFields,
        unsavedCreditApplicationFields,
      ) ||
      pipelineFieldsError ||
      !_.isEqual(savedConfig.rawPipelineOnlyFields, unsavedPipelineFields)
    );
  }, [
    savedConfig,
    productFieldsError,
    creditApplicationFieldsError,
    pipelineFieldsError,
    enumsError,
    unsavedProductFields,
    unsavedCreditApplicationFields,
    unsavedPipelineFields,
    unsavedEnumTypes,
  ]);

  const anyError = useMemo(
    () => productFieldsError || creditApplicationFieldsError || enumsError,
    [productFieldsError, creditApplicationFieldsError, enumsError],
  );

  const saveChanges = useCallback(() => {
    (async () => {
      if (enumsError) {
        alert("Could not save enumerations. " + enumsError.message);
        return;
      }

      if (productFieldsError) {
        alert(
          "Could not save product specifications. " +
            productFieldsError.message,
        );
        return;
      }

      if (creditApplicationFieldsError) {
        alert(
          "Could not save application form. " +
            creditApplicationFieldsError.message,
        );
        return;
      }

      if (pipelineFieldsError) {
        alert("Could not save pipeline fields. " + pipelineFieldsError.message);
        return;
      }

      let enumsOk = false;
      let fieldsOk = false;

      try {
        await Api.updateEnumerations(unsavedEnumTypes);
        enumsOk = true;
      } catch (e) {
        newrelic.noticeError(getErrorMessage(e));
        if (
          e instanceof DetailedInvalidRequest &&
          e.errors.type === "enum-types"
        ) {
          setEnumsState({
            ...enumsState,
            objects: mapEnumTypesValidation(enumsState.objects, e.errors.value),
          });
        }
      }

      const fieldsRequest = {
        productFields: unsavedProductFields,
        creditApplicationFields: unsavedCreditApplicationFields,
        pipelineFields: unsavedPipelineFields,
      };

      try {
        await Api.updateFieldDefinitions(fieldsRequest);
        fieldsOk = true;

        // Refresh the roles because the new field will have been automatically added to them
        dispatch(getRoles());
      } catch (e) {
        newrelic.noticeError(getErrorMessage(e));
        if (e instanceof DetailedInvalidRequest && e.errors.type === "fields") {
          setCreditApplicationFieldsState({
            ...creditApplicationFieldsState,
            objects: mapFieldDefinitionsValidation(
              creditApplicationFieldsState.objects,
              e.errors.value.creditApplicationFields,
            ),
          });
          setProductFieldsState({
            ...productFieldsState,
            objects: mapFieldDefinitionsValidation(
              productFieldsState.objects,
              e.errors.value.productFields,
            ),
          });
          setPipelineFieldsState({
            ...pipelineFieldsState,
            objects: mapFieldDefinitionsValidation(
              pipelineFieldsState.objects,
              e.errors.value.pipelineFields,
            ),
          });
        } else {
          throw e;
        }
      }

      if (enumsOk && fieldsOk) {
        dispatch(loadAppInit());
      }
    })();
  }, [
    productFieldsError,
    creditApplicationFieldsError,
    pipelineFieldsError,
    creditApplicationFieldsState,
    productFieldsState,
    pipelineFieldsState,
    enumsError,
    enumsState,
    unsavedProductFields,
    unsavedCreditApplicationFields,
    unsavedPipelineFields,
    unsavedEnumTypes,
    dispatch,
  ]);

  const tabs = ["enums", "creditApplication", "product"];
  const [requestedTabIdx, setSelectedTabIdx] = useState(0);
  const selectedTabIdx = enumsError ? tabs.indexOf("enums") : requestedTabIdx;

  const handleTabSwitch = useCallback(
    (e, idx: React.SetStateAction<number>) => setSelectedTabIdx(idx),
    [],
  );

  const hasEnumServerErrors = enumsState.objects.some(
    (o) => o.domainValidationErrors.hasErrors,
  );

  return (
    <ErrorBoundary>
      <UnloadPrompt when={anyUnsaved} />

      <Box className={C.container}>
        <Paper className={C.paper}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box display="flex">
              <Tabs value={selectedTabIdx} onChange={handleTabSwitch}>
                <Tab
                  label={
                    <Box display="flex">
                      {(enumsError || hasEnumServerErrors) && (
                        <Box
                          mr={1}
                          display="flex"
                          alignItems="center"
                          title={
                            !!enumsError
                              ? enumsError.message
                              : "Some enumerations have errors"
                          }
                          color="error.main"
                        >
                          <ErrorOutlineIcon fontSize="small" />
                        </Box>
                      )}
                      <Box flex="1" mr={1}>
                        Enumerations
                      </Box>
                    </Box>
                  }
                />
                <Tab
                  label={
                    <Box display="flex">
                      {(creditApplicationFieldsError ||
                        creditApplicationFieldsState.objects.some(
                          (o) => o.domainValidationErrors.hasErrors,
                        )) && (
                        <Box
                          mr={1}
                          display="flex"
                          alignItems="center"
                          title={
                            !!creditApplicationFieldsError
                              ? creditApplicationFieldsError.message
                              : "Some credit application fields have errors"
                          }
                          color="error.main"
                        >
                          <ErrorOutlineIcon fontSize="small" />
                        </Box>
                      )}
                      <Box flex="1" mr={1}>
                        Application Form
                      </Box>
                    </Box>
                  }
                  disabled={!!enumsError}
                />
                <Tab
                  label={
                    <Box display="flex">
                      {(productFieldsError ||
                        productFieldsState.objects.some(
                          (o) => o.domainValidationErrors.hasErrors,
                        )) && (
                        <Box
                          mr={1}
                          display="flex"
                          alignItems="center"
                          title={
                            !!productFieldsError
                              ? productFieldsError.message
                              : "Some product fields have errors"
                          }
                          color="error.main"
                        >
                          <ErrorOutlineIcon fontSize="small" />
                        </Box>
                      )}
                      <Box flex="1" mr={1}>
                        Product Specifications
                      </Box>
                    </Box>
                  }
                  disabled={!!enumsError}
                />
              </Tabs>
              <Box flex="1" />
              <Box display="flex" alignItems="center" mr={1}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CheckIcon />}
                  disabled={!!anyError || !anyUnsaved || !hasFieldsModifyPerm}
                  onClick={saveChanges}
                >
                  {anyUnsaved ? "Save" : "Saved"}
                </Button>
              </Box>
            </Box>
            <Box
              flex="1"
              display="flex"
              overflow="hidden"
              justifyContent="stretch"
            >
              {tabs[selectedTabIdx] === "creditApplication" && (
                <FieldsEditor
                  parentTab="creditApplication"
                  disableControls={!hasFieldsModifyPerm}
                  fieldsState={creditApplicationFieldsState}
                  setFieldsState={setCreditApplicationFieldsState}
                  enumTypes={unsavedEnumTypes!}
                  systemEnumTypesById={loggedInInfo.config.systemEnumTypesById}
                  existingFieldNames={fieldNamesWithoutCreditApplicationFields}
                  allowHeaders={true}
                />
              )}
              {tabs[selectedTabIdx] === "product" && (
                <FieldsEditor
                  parentTab="product"
                  disableControls={!hasFieldsModifyPerm}
                  fieldsState={productFieldsState}
                  setFieldsState={setProductFieldsState}
                  enumTypes={unsavedEnumTypes!}
                  systemEnumTypesById={loggedInInfo.config.systemEnumTypesById}
                  existingFieldNames={fieldNamesWithoutProductFields}
                  allowHeaders={false}
                />
              )}
              {tabs[selectedTabIdx] === "enums" && (
                <EnumerationsEditor
                  disableControls={!hasFieldsModifyPerm}
                  enumsState={enumsState}
                  setState={setEnumsState}
                  existingEnumNames={existingEnumNames}
                />
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </ErrorBoundary>
  );
});

function listEnumNamesExcludingFieldLibrary(
  config: Configuration,
): ISet<string> {
  return config.enumTypesById
    .deleteAll(config.enumTypes.map((e) => e.id))
    .valueSeq()
    .map((e) => e.name)
    .toSet();
}

function listFieldNamesExcludingFieldLibrary(
  config: Configuration,
): ISet<string> {
  return config.allFieldsById
    .deleteAll(config.creditApplicationFields.map((f) => f.id))
    .deleteAll(config.productFields.map((f) => f.id))
    .valueSeq()
    .map((f) => f.name)
    .toSet();
}

function listFieldNames(
  fieldStates: FieldDefinitionState[],
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): ISet<string> {
  return ISet(
    fieldStates.map((f) => getRawFieldStateName(f, inheritableFields)),
  );
}

function tryConvertFieldsState(
  existingFieldNames: ISet<string>,
  state: ObjectListState<FieldDefinitionState>,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
  enumTypes: T.RawEnumType[] | null,
): [null, T.RawProductFieldDefinition[]] | [UiValidationError, null] {
  try {
    return [
      null,
      state.objects.map((field, fieldIndex) =>
        convertFieldStateListItem(
          existingFieldNames,
          state,
          field,
          fieldIndex,
          inheritableFields,
          enumTypes,
        ),
      ),
    ];
  } catch (err) {
    if (err instanceof UiValidationError) {
      newrelic.noticeError(err);
      return [err, null];
    }

    throw err;
  }
}

function tryConvertEnumsState(
  existingEnumNames: ISet<string>,
  state: ObjectListState<EnumTypeState>,
  loggedInInfo: LoggedInInfo,
): [null, T.RawEnumType[]] | [UiValidationError, null] {
  try {
    return [
      null,
      state.objects.map((enumType, enumIndex) =>
        convertEnumStateListItem(
          existingEnumNames,
          state,
          enumType,
          enumIndex,
          loggedInInfo,
        ),
      ),
    ];
  } catch (err) {
    if (err instanceof UiValidationError) {
      newrelic.noticeError(err);
      return [err, null];
    }

    throw err;
  }
}

function convertFieldStateListItem(
  existingFieldNames: ISet<string>,
  fieldListState: ObjectListState<FieldDefinitionState>,
  field: FieldDefinitionState,
  fieldIndex: number,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
  enumTypes: T.RawEnumType[] | null,
): T.RawProductFieldDefinition | T.RawCreditApplicationFieldDefinition {
  const previousFields = fieldListState.objects.slice(0, fieldIndex);
  const allExistingFieldNames = existingFieldNames.union(
    fieldListState.objects
      .filter((_, idx) => idx !== fieldIndex)
      .map((f) => getRawFieldStateName(f, inheritableFields)),
  );

  return convertStateToFieldDefinition(
    allExistingFieldNames,
    previousFields,
    field,
    inheritableFields,
    enumTypes,
  );
}

function convertEnumStateListItem(
  existingEnumNames: ISet<string>,
  enumListState: ObjectListState<EnumTypeState>,
  enumType: EnumTypeState,
  enumIndex: number,
  loggedInInfo: LoggedInInfo,
): T.RawEnumType {
  const allExistingEnumNames = existingEnumNames.union(
    enumListState.objects
      .filter((f, idx) => idx !== enumIndex)
      .map((f) => {
        return resolveEnum(
          convertStateToEnumType(f),
          loggedInInfo.config.systemEnumTypesById,
          loggedInInfo.client.displayNewInheritedEnumVariants,
        ).name;
      }),
  );

  validateEnumState(allExistingEnumNames, enumType, loggedInInfo);
  return convertStateToEnumType(enumType);
}

const FieldsEditor = React.memo(
  ({
    parentTab,
    fieldsState,
    setFieldsState,
    enumTypes,
    systemEnumTypesById,
    existingFieldNames,
    allowHeaders,
    disableControls = false,
  }: {
    parentTab: "creditApplication" | "product";
    disableControls?: boolean;
    fieldsState: ObjectListState<FieldDefinitionState>;
    setFieldsState: Setter<ObjectListState<FieldDefinitionState>>;
    enumTypes: T.RawEnumType[];
    systemEnumTypesById: IMap<T.EnumTypeId, T.EnumType>;
    existingFieldNames: ISet<string>;
    allowHeaders: boolean;
  }) => {
    const C = useObjectEditorStyles();
    const nonNullState = useSelector(nonNullApplicationInitializationSelector);
    const objectDetailsMap = useSelector(objectDetailsMapSelector);

    const loggedInInfo = {
      user: nonNullState.user,
      client: nonNullState.client,
      config: nonNullState.config,
      objectDetails: objectDetailsMap,
      notifications: nonNullState.notifications,
    };

    const makeFieldEditor = useCallback(
      ({ value, showErrors, index, setValue }) => {
        // This comment was generated when upgrading react-scripts and eslint
        // TODO: fix the lint rule and remove this eslint-disable comment
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const previousFields = fieldsState.objects.slice(0, index);
        return (
          <Box className={C.objectEditor}>
            {/* This comment was generated when upgrading react-scripts and eslint */}
            {/* TODO: fix the lint rule and remove this eslint-disable comment */}
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
            {value.disposition.kind === "native" && (
              <NativeFieldEditor
                isProductField={parentTab === "product"}
                // This comment was generated when upgrading react-scripts and eslint
                // TODO: fix the lint rule and remove this eslint-disable comment
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                state={value}
                // This comment was generated when upgrading react-scripts and eslint
                // TODO: fix the lint rule and remove this eslint-disable comment
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                showErrors={showErrors}
                // This comment was generated when upgrading react-scripts and eslint
                // TODO: fix the lint rule and remove this eslint-disable comment
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                setState={setValue}
                previousFields={previousFields}
                enumTypes={enumTypes}
                systemEnumTypesById={systemEnumTypesById}
                allowHeaders={allowHeaders}
              />
            )}
            {/* This comment was generated when upgrading react-scripts and eslint */}
            {/* TODO: fix the lint rule and remove this eslint-disable comment */}
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
            {value.disposition.kind === "inherited" && (
              <InheritedFieldEditor
                isProductField={parentTab === "product"}
                // This comment was generated when upgrading react-scripts and eslint
                // TODO: fix the lint rule and remove this eslint-disable comment
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                state={value}
                // This comment was generated when upgrading react-scripts and eslint
                // TODO: fix the lint rule and remove this eslint-disable comment
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                showErrors={showErrors}
                // This comment was generated when upgrading react-scripts and eslint
                // TODO: fix the lint rule and remove this eslint-disable comment
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                setState={setValue}
                disableControls={disableControls}
                previousFields={previousFields}
                inheritableFields={loggedInInfo.config.allSystemFieldsById}
                enumTypes={enumTypes}
              />
            )}
          </Box>
        );
      },
      [
        enumTypes,
        systemEnumTypesById,
        fieldsState,
        parentTab,
        allowHeaders,
        disableControls,
        C.objectEditor,
        loggedInInfo.config.allSystemFieldsById,
      ],
    );

    const validateFieldDefinitionState = useCallback(
      (state: FieldDefinitionState, fieldIndex: number) =>
        getValidationError(() => {
          convertFieldStateListItem(
            existingFieldNames,
            fieldsState,
            state,
            fieldIndex,
            loggedInInfo.config.allSystemFieldsById,
            enumTypes,
          );
        }),
      [
        existingFieldNames,
        fieldsState,
        loggedInInfo.config.allSystemFieldsById,
        enumTypes,
      ],
    );

    const newButtons = [
      {
        name: "New",
        icon: <AddIcon />,
        makeNewObject: () =>
          ({
            oldId: null,
            id: "" as T.FieldId,
            disposition: {
              kind: "native",
              name: "",
              description: "",
              valueType: newFieldValueTypeState(),
              conditions: [],
              domainValidationErrors: {
                hasErrors: false,
                name: [],
                description: [],
              },
            },
            domainValidationErrors: { hasErrors: false, oldId: [], id: [] },
          } as FieldDefinitionState),
      },
    ];

    // Super client doesn't have anywhere to inherit enums from, so
    // we don't show the "From System" button.
    if (loggedInInfo.client.accessId !== "super") {
      newButtons.unshift({
        name: "From System",
        icon: <AddIcon />,
        makeNewObject: () =>
          ({
            oldId: null,
            id: "" as T.FieldId,
            disposition: {
              kind: "inherited",
              nameAlias: null,
              descriptionAlias: "",
              includeConditions: newObjectListState([]),
              additionalConditions: [],
              domainValidationErrors: {
                hasErrors: false,
                nameAlias: [],
                descriptionAlias: [],
              },
            },
            domainValidationErrors: { hasErrors: false, oldId: [], id: [] },
          } as FieldDefinitionState),
      });
    }

    return (
      <Box m={3} flex="1">
        <ObjectListEditor
          disableControls={disableControls}
          height="100%"
          state={fieldsState}
          newButtons={newButtons}
          getObjectLabel={(o) =>
            getFieldObjectListLabel(
              o,
              loggedInInfo.config.allSystemFieldsById,
              loggedInInfo.client.accessId === "super",
            )
          }
          validateObject={validateFieldDefinitionState}
          makeObjectEditor={makeFieldEditor}
          setState={setFieldsState}
          emptyText="Click &ldquo;New&rdquo; to insert a new field into this list"
          itemHasAdditionalErrors={(obj) =>
            obj.domainValidationErrors.hasErrors
          }
        />
      </Box>
    );
  },
);

function getListLabel(
  name: string,
  isBold: boolean,
  isCustom: boolean,
): JSX.Element {
  let style = {
    position: "relative",
    float: "left",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  } as {};

  if (isBold) style = { ...style, fontWeight: "bold" };

  if (isCustom)
    style = {
      ...style,
      width: "75%",
    };

  const customFlag = (
    <div
      style={{
        color: "#fff",
        backgroundColor: "#aaa",
        marginTop: "2px",
        fontSize: "0.8em",
        position: "relative",
        float: "right",
        width: "20%",
        textAlign: "center",
        borderRadius: "4px",
      }}
    >
      custom
    </div>
  );

  return (
    <div>
      <div style={style}>{name}</div>
      {isCustom ? customFlag : ""}
    </div>
  );
}

function getFieldObjectListLabel(
  state: FieldDefinitionState,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
  isSuperClient: boolean,
): JSX.Element {
  let fieldType: FieldType | null = null;
  let name: string | null = null;

  if (state.disposition.kind === "native") {
    fieldType = state.disposition.valueType.fieldType;
    name = state.disposition.name;
  } else {
    const inherited = inheritableFields.get(state.id);
    if (inherited) {
      fieldType = inherited.valueType.type;
      name = state.disposition.nameAlias
        ? state.disposition.nameAlias
        : inherited.name;
    }
  }

  return getListLabel(
    name || "<Unknown>",
    fieldType === "header",
    state.disposition.kind === "native" && !isSuperClient,
  );
}

function convertFieldDefinitionToState(
  field: T.RawProductFieldDefinition | T.RawCreditApplicationFieldDefinition,
): FieldDefinitionState {
  if (field.disposition.kind === "native") {
    return {
      oldId: field.id,
      id: field.id,
      disposition: {
        kind: "native",
        name: field.disposition.name,
        description:
          field.disposition.description !== null
            ? field.disposition.description
            : "",
        valueType: convertFieldValueTypeToState(field.disposition.valueType),
        conditions: convertFieldConditionToState(field.disposition.conditions),
        domainValidationErrors: { hasErrors: false, name: [], description: [] },
      },
      domainValidationErrors: { hasErrors: false, id: [] },
    };
  } else {
    return {
      oldId: field.id,
      id: field.id,
      disposition: {
        kind: "inherited",
        nameAlias: field.disposition.nameAlias,
        descriptionAlias:
          field.disposition.descriptionAlias !== null
            ? field.disposition.descriptionAlias
            : "",
        includeConditions: newObjectListState(
          convertIncludedFieldConditionToState(
            field.disposition.includeConditions,
          ),
        ),
        additionalConditions: convertFieldConditionToState(
          field.disposition.additionalConditions,
        ),
        domainValidationErrors: {
          hasErrors: false,
          nameAlias: [],
          descriptionAlias: [],
        },
      },
      domainValidationErrors: { hasErrors: false, id: [] },
    };
  }
}

function convertStateToFieldDefinition(
  existingFieldNames: ISet<string>,
  validParentFields: FieldDefinitionState[],
  state: FieldDefinitionState,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
  enumTypes: T.RawEnumType[] | null,
): T.RawProductFieldDefinition | T.RawCreditApplicationFieldDefinition {
  const name = getRawFieldStateName(state, inheritableFields);

  if (name.trim() === "") {
    throw new UiValidationError("Field name is empty");
  }

  const lowerCaseIdentifier = fieldNameToIdentifier(name).toLowerCase();
  const existingFieldName = existingFieldNames.find(
    (name) => fieldNameToIdentifier(name).toLowerCase() === lowerCaseIdentifier,
  );
  if (existingFieldName) {
    if (existingFieldName.toLowerCase() === name.toLowerCase()) {
      throw new UiValidationError(
        `Duplicate field name "${existingFieldName}"`,
      );
    }

    throw new UiValidationError(
      `Field names "${name}" and "${existingFieldName}" are too similar.`,
    );
  }

  let dataType: T.FieldValueType | undefined;
  if (state.disposition.kind === "native") {
    dataType = convertStateToFieldValueType(state.disposition.valueType);
  } else {
    const inheritedField = inheritableFields.find((f) => f.id === state.id);
    if (!inheritedField) {
      throw new UiValidationError(
        `Attempting to inherit nonexistent field with ID '${state.id}'`,
      );
    }

    dataType = inheritedField.valueType;
  }

  if (dataType.type === "enum") {
    // For some reason this line is needed to make Typescript
    // realize that dataType is an enum type at this point.
    const enumDataType = dataType;
    const referencedEnum = enumTypes?.find((e) => {
      if (e.id !== enumDataType.enumTypeId) return false;
      if (
        state.disposition.kind === "inherited" &&
        e.disposition.kind !== "inherited"
      )
        return false;
      return true;
    });
    if (!referencedEnum) {
      if (state.disposition.kind === "native") {
        throw new UiValidationError(
          `Enumeration with ID '${enumDataType.enumTypeId}' does not exist. You must create or inherit this enumeration.`,
        );
      } else {
        throw new UiValidationError(
          `Inherited enumeration with ID '${enumDataType.enumTypeId}' does not exist. You must inherit this enumeration.`,
        );
      }
    }
  }

  if (state.disposition.kind === "native") {
    return {
      oldId: state.oldId,
      id: state.id,
      disposition: {
        kind: "native",
        name: state.disposition.name,
        description:
          state.disposition.description === ""
            ? null
            : state.disposition.description,
        valueType: convertStateToFieldValueType(state.disposition.valueType),
        conditions: state.disposition.conditions
          ? convertStateToFieldCondition(
              validParentFields,
              state.disposition.conditions,
              inheritableFields,
            )
          : [],
      },
    };
  } else {
    return {
      oldId: state.oldId,
      id: state.id,
      disposition: {
        kind: "inherited",
        nameAlias: state.disposition.nameAlias,
        descriptionAlias:
          state.disposition.descriptionAlias === ""
            ? null
            : state.disposition.descriptionAlias,
        includeConditions: convertStateToIncludedFieldCondition(
          state.disposition.includeConditions.objects,
        ),
        additionalConditions: state.disposition.additionalConditions
          ? convertStateToFieldCondition(
              validParentFields,
              state.disposition.additionalConditions,
              inheritableFields,
            )
          : [],
      },
    };
  }
}

const NativeFieldEditor = React.memo(
  ({
    isProductField,
    state,
    showErrors,
    setState,
    previousFields,
    enumTypes,
    systemEnumTypesById,
    allowHeaders,
  }: {
    isProductField: boolean;
    state: NativeFieldDefinitionState;
    showErrors: boolean;
    setState: Setter<NativeFieldDefinitionState>;
    previousFields: FieldDefinitionState[];
    enumTypes: T.RawEnumType[];
    systemEnumTypesById: IMap<T.EnumTypeId, T.EnumType>;
    allowHeaders: boolean;
  }) => {
    const C = useObjectEditorStyles();
    const config = useSelector(expandedConfigSelector);
    const { client } = useSelector(nonNullApplicationInitializationSelector);

    const setId = usePropertySetter(setState, "id");
    const setName = usePropertySetter2(setState, "disposition", "name");
    const setDescription = usePropertySetter2(
      setState,
      "disposition",
      "description",
    );
    const setValueType = usePropertySetter2(
      setState,
      "disposition",
      "valueType",
    );
    const setConditions = usePropertySetter2(
      setState,
      "disposition",
      "conditions",
    );

    const handleIdChange = useCallback(
      (event: { target: { value: string } }) =>
        setId(("field@" + event.target.value) as T.FieldId),
      [setId],
    );

    const handleNameChange = useCallback(
      (event: { target: { value: string } }) => {
        setName(event.target.value);
        if (!state.oldId) {
          setId(createSlugId(event.target.value, "field@") as T.FieldId);
        }
      },
      [setId, setName, state.oldId],
    );

    const sortEnumTypeToTop = useCallback(
      (enumType: T.RawEnumType) =>
        resolveEnum(
          enumType,
          config.systemEnumTypesById,
          client.displayNewInheritedEnumVariants,
        ).name.toLowerCase() === state.disposition.name.toLowerCase(),
      [state.disposition.name, config, client],
    );

    return (
      <Box display="flex" flexDirection="column">
        <TextField
          className={C.standardField}
          label="Field Name"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={state.disposition.name}
          error={
            (showErrors && state.disposition.name.trim() === "") ||
            !!state.disposition.domainValidationErrors.name.length
          }
          onChange={handleNameChange}
          helperText={getMessageNode(
            state.disposition.domainValidationErrors.name,
          )}
        />
        <TextField
          className={C.standardField}
          label="Field ID"
          InputProps={{ startAdornment: "field@" }}
          fullWidth
          disabled={!!state.oldId}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={unPrefixId(state.id, "field@")}
          error={
            (showErrors && state.id.trim() === "") ||
            !!state.domainValidationErrors.id.length
          }
          onChange={handleIdChange}
          helperText={getMessageNode(state.domainValidationErrors.id)}
        />
        <TextField
          className={C.standardField}
          label="Field Description"
          multiline
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={state.disposition.description}
          error={!!state.disposition.domainValidationErrors.description.length}
          onChange={(e) => setDescription(e.target.value)}
          helperText={getMessageNode(
            state.disposition.domainValidationErrors.description,
          )}
        />
        <Box maxWidth="400px">
          <FieldValueTypeEditor
            state={state.disposition.valueType}
            showErrors={showErrors}
            setState={setValueType}
            enumTypes={enumTypes}
            sortEnumTypeToTop={sortEnumTypeToTop}
            allowHeaders={allowHeaders}
            allowPricingProfiles={false}
          />
        </Box>
        {state.disposition.valueType.fieldType !== "header" && (
          <>
            <OptionalFieldConditionsEditor
              state={state.disposition.conditions}
              showErrors={showErrors}
              setState={setConditions}
              previousFields={previousFields}
              enumTypes={enumTypes}
            />
            <RefSourcesViewer
              objectId={{ type: "field", fieldId: state.id }}
              mt={2}
              style={{ marginBottom: "16px" }}
            />
          </>
        )}
        <AssociatedRolesViewer
          linkRoles={isProductField}
          objectId={{ type: "field", fieldId: state.id }}
        />
      </Box>
    );
  },
);

function getFieldValueSummary(
  value: T.FieldValue,
  loggedInInfo: LoggedInInfo,
  enumTypes: T.RawEnumType[],
): string {
  switch (value.type) {
    case "enum":
      const clientEnum = enumTypes.find((e) => e.id === value.enumTypeId);
      const inheritableEnum = loggedInInfo.config.systemEnumTypesById.get(
        value.enumTypeId,
      );

      if (clientEnum) {
        const resolvedEnum = resolveEnum(
          clientEnum,
          loggedInInfo.config.systemEnumTypesById,
          loggedInInfo.client.displayNewInheritedEnumVariants,
        );
        const typeIdentifier = getEnumTypeIdentifier(resolvedEnum);
        const variant = resolvedEnum.variants.find(
          (v) => v.id === value.variantId,
        );
        const variantIdentifier = variant
          ? getEnumVariantIdentifier(variant)
          : "<unknown variant>";
        return `${typeIdentifier}.${variantIdentifier}`;
      } else if (inheritableEnum) {
        const typeIdentifier = getEnumTypeIdentifier(inheritableEnum);
        const variant = inheritableEnum.variants.find(
          (v) => v.id === value.variantId,
        );
        const variantIdentifier = variant
          ? getEnumVariantIdentifier(variant)
          : "<unknown variant>";
        return `(uninherited enum) ${typeIdentifier}.${variantIdentifier}`;
      }

      return "<unknown enumeration>";
    case "object-ref":
      let objectTypeName: string = value.objectRef.type;
      switch (value.objectRef.type) {
        case "pricing-profile":
          objectTypeName = "Pricing Profile";
          break;
      }
      return `${objectTypeName} with ID ${value.objectRef.id}`;
    case "string":
      return `"${value.value}"`;
    case "number":
      return `${value.value}`;
    case "duration":
      return `${value.count} ${value.unit}`;
    default:
      return "<unknown value>";
  }
}

function getFieldValueSummaries(
  values: T.FieldValue[],
  loggedInInfo: LoggedInInfo,
  enumTypes: T.RawEnumType[],
): string {
  const valueSummaries = values.map((v) =>
    getFieldValueSummary(v, loggedInInfo, enumTypes),
  );
  return `[${valueSummaries.join(", ")}]`;
}

function getInheritedFieldConditionSummary(
  loggedInInfo: LoggedInInfo,
  previousFields: FieldDefinitionState[],
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
  enumTypes: T.RawEnumType[],
  condition?: T.FieldCondition,
): JSX.Element {
  const parentFieldId = condition?.parentFieldId;
  const inheritableField = parentFieldId
    ? inheritableFields.get(parentFieldId)
    : null;
  const previousField = previousFields.find((pf) => pf.id === parentFieldId);

  let parentFieldName = "Parent field";
  if (previousField) {
    parentFieldName = getRawFieldStateName(
      previousField,
      loggedInInfo.config.allSystemFieldsById,
    );
  } else if (inheritableField) {
    parentFieldName = `(uninherited or incorrectly positioned) ${inheritableField.name}`;
  }

  switch (condition?.type) {
    case "parent-field-is-blank":
      return <>{parentFieldName} is blank</>;
    case "parent-field-is-not-blank":
      return <>{parentFieldName} is not blank</>;
    case "parent-field-has-value":
      return (
        <>
          {parentFieldName} has value &nbsp;
          {getFieldValueSummary(condition.value, loggedInInfo, enumTypes)}
        </>
      );
    case "parent-field-has-not-value":
      return (
        <>
          {parentFieldName} does not have value &nbsp;
          {getFieldValueSummary(condition.value, loggedInInfo, enumTypes)}
        </>
      );
    case "parent-field-is-one-of":
      return (
        <>
          {parentFieldName} has one of the values in &nbsp;
          {getFieldValueSummaries(condition.values, loggedInInfo, enumTypes)}
        </>
      );
    case "parent-field-is-not-one-of":
      return (
        <>
          {parentFieldName} has none of the values in &nbsp;
          {getFieldValueSummaries(condition.values, loggedInInfo, enumTypes)}
        </>
      );
    default:
      return <>{"<unknown condition type>"}</>;
  }
}

const InheritedFieldEditor = React.memo(
  ({
    isProductField,
    state,
    showErrors,
    setState,
    disableControls,
    previousFields,
    inheritableFields,
    enumTypes,
  }: {
    isProductField: boolean;
    state: InheritedFieldDefinitionState;
    showErrors: boolean;
    setState: Setter<InheritedFieldDefinitionState>;
    disableControls: boolean;
    previousFields: FieldDefinitionState[];
    inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>;
    enumTypes: T.RawEnumType[];
  }) => {
    const C = useObjectEditorStyles();
    const config = useSelector(expandedConfigSelector);
    const nonNullState = useSelector(nonNullApplicationInitializationSelector);
    const objectDetailsMap = useSelector(objectDetailsMapSelector);
    const loggedInInfo = useMemo(
      () => ({
        user: nonNullState.user,
        client: nonNullState.client,
        config: nonNullState.config,
        objectDetails: objectDetailsMap,
        notifications: nonNullState.notifications,
      }),
      [nonNullState, objectDetailsMap],
    );

    const setNameAlias = usePropertySetter2(
      setState,
      "disposition",
      "nameAlias",
    );
    const setDescriptionAlias = usePropertySetter2(
      setState,
      "disposition",
      "descriptionAlias",
    );
    const setIncludeConditions = usePropertySetter2(
      setState,
      "disposition",
      "includeConditions",
    );
    const setAdditionalConditions = usePropertySetter2(
      setState,
      "disposition",
      "additionalConditions",
    );

    const inheritedField = config.allSystemFieldsById.find(
      (e) => e.id === state.id,
    );

    const availableConditions =
      inheritedField?.conditions?.filter((inheritedCondition) => {
        if (
          !!state.disposition.includeConditions.objects.find(
            (includedCondition) =>
              includedCondition.id === inheritedCondition.id,
          )
        )
          return false;

        return true;
      }) || [];

    const makeIncludedFieldConditionEditor = useCallback(
      (
        { value, showErrors, index, setValue },
        inheritedField?: T.BaseFieldDefinition,
      ) => {
        return (
          <Box display="flex" flexDirection="column" height="100%">
            <Box>
              <TextField
                className={C.standardField}
                label="Condition ID"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                disabled={true}
                // This comment was generated when upgrading react-scripts and eslint
                // TODO: fix the lint rule and remove this eslint-disable comment
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                value={value.id}
                // This comment was generated when upgrading react-scripts and eslint
                // TODO: fix the lint rule and remove this eslint-disable comment
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                error={!!value.domainValidationErrors.id.length}
                // This comment was generated when upgrading react-scripts and eslint
                // TODO: fix the lint rule and remove this eslint-disable comment
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                helperText={getMessageNode(value.domainValidationErrors.id)}
              />
            </Box>
            {getInheritedFieldConditionSummary(
              loggedInInfo,
              previousFields,
              inheritableFields,
              enumTypes,
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              inheritedField?.conditions?.find((c) => c.id === value.id),
            )}
          </Box>
        );
      },
      [
        C.standardField,
        loggedInInfo,
        previousFields,
        enumTypes,
        inheritableFields,
      ],
    );

    const inheritableOptions = isProductField
      ? [...loggedInInfo.config.systemProductFields]
      : [...loggedInInfo.config.systemCreditApplicationFields];

    inheritableOptions.sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();

      if (aName > bName) return 1;
      else if (aName < bName) return -1;
      else return 0;
    });

    return (
      <Box>
        <Box display="flex" flexDirection="row">
          <SearchableDropdown<T.BaseFieldDefinition>
            className={C.standardField}
            label="Inherit from"
            value={inheritedField || null}
            options={inheritableOptions}
            getOptionLabel={(systemField) => systemField.name}
            setValue={(systemField) => {
              if (systemField) {
                setState({
                  ...state,
                  oldId: null,
                  id: systemField.id,
                  disposition: {
                    kind: "inherited",
                    nameAlias: null,
                    descriptionAlias: "",
                    includeConditions: newObjectListState([]),
                    additionalConditions: [],
                    domainValidationErrors: {
                      hasErrors: false,
                      nameAlias: [],
                      descriptionAlias: [],
                    },
                  },
                  domainValidationErrors: {
                    hasErrors: false,
                    id: [],
                  },
                });
              }
            }}
          />
          <TextField
            className={C.standardField}
            style={{ marginLeft: "24px" }}
            label="Field Name Alias"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder={inheritedField?.name}
            value={state.disposition.nameAlias || ""}
            error={!!state.disposition.domainValidationErrors.nameAlias.length}
            onChange={(e) =>
              setNameAlias(
                e.target.value?.trim() === "" ? null : e.target.value,
              )
            }
            helperText={getMessageNode(
              state.disposition.domainValidationErrors.nameAlias,
            )}
          />
        </Box>
        <Box>
          <TextField
            className={C.standardField}
            label="Field ID"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            disabled={true}
            value={state.id}
            error={!!state.domainValidationErrors.id.length}
            helperText={getMessageNode(state.domainValidationErrors.id)}
          />
        </Box>
        <Box>
          <TextField
            className={C.standardField}
            label="Field Description Alias"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder={inheritedField?.description || ""}
            value={state.disposition.descriptionAlias || ""}
            error={
              !!state.disposition.domainValidationErrors.descriptionAlias.length
            }
            onChange={(e) => setDescriptionAlias(e.target.value)}
            helperText={getMessageNode(
              state.disposition.domainValidationErrors.descriptionAlias,
            )}
          />
        </Box>
        {inheritedField && (
          <Box maxWidth="400px">
            <FieldValueTypeDisplay
              state={convertFieldValueTypeToState(inheritedField.valueType)}
              showErrors={showErrors}
              enumTypes={enumTypes}
              allowPricingProfiles={false}
            />
          </Box>
        )}
        {inheritedField?.valueType.type !== "header" && (
          <>
            <Box mb={2} fontSize="24px">
              Conditions
            </Box>
            <OptionalFieldConditionsEditor
              state={state.disposition.additionalConditions}
              showErrors={showErrors}
              setState={setAdditionalConditions}
              previousFields={previousFields}
              enumTypes={enumTypes}
            />
            <RefSourcesViewer
              objectId={{ type: "field", fieldId: state.id }}
              mt={2}
              style={{ marginBottom: "16px" }}
            />
            <Box flex="1" overflow="hidden">
              <h4>Included Conditions</h4>
              <ObjectListEditor
                disableControls={disableControls}
                height="250px"
                state={state.disposition.includeConditions}
                newButtons={[]}
                getObjectLabel={(included) =>
                  getInheritedFieldConditionSummary(
                    loggedInInfo,
                    previousFields,
                    inheritableFields,
                    enumTypes,
                    inheritedField?.conditions?.find(
                      (c) => c.id === included.id,
                    ),
                  )
                }
                showErrors={showErrors}
                makeObjectEditor={(args) =>
                  makeIncludedFieldConditionEditor(args, inheritedField)
                }
                setState={setIncludeConditions}
                emptyText="Select a condition from &ldquo;Inheritable Conditions&rdquo; below to add it to this list"
                // Do not sort, just keep the order in which they were added to the list
                sortBy={(a, b) => 0}
              />

              <h4>Available Conditions</h4>
              <Box className={C.availableItemsList}>
                {availableConditions.map((available, i) => {
                  return (
                    <div key={i} className={C.availableItemsListItem}>
                      {getInheritedFieldConditionSummary(
                        loggedInInfo,
                        previousFields,
                        inheritableFields,
                        enumTypes,
                        inheritedField?.conditions?.find(
                          (c) => c.id === available.id,
                        ),
                      )}
                      <Box className="buttonIcons">
                        <IconButton
                          title="Include this condition"
                          onClick={() =>
                            setIncludeConditions({
                              ...state.disposition.includeConditions,
                              objects: [
                                ...state.disposition.includeConditions.objects,
                                {
                                  id: available.id,
                                  domainValidationErrors: {
                                    hasErrors: false,
                                    id: [],
                                  },
                                },
                              ],
                            })
                          }
                        >
                          <CheckCircleIcon />
                        </IconButton>
                      </Box>
                    </div>
                  );
                })}
              </Box>
            </Box>
          </>
        )}
      </Box>
    );
  },
);

const OptionalFieldConditionsEditor = React.memo(
  ({
    state,
    showErrors,
    setState,
    previousFields,
    enumTypes,
  }: {
    state: readonly FieldConditionState[];
    showErrors: boolean;
    setState: Setter<readonly FieldConditionState[]>;
    previousFields: FieldDefinitionState[];
    enumTypes: T.RawEnumType[];
  }) => {
    const addCondition = useCallback(
      () =>
        setState((oldState) => [...(oldState || []), newFieldConditionState()]),
      [setState],
    );
    const hasPermission = usePermissions();
    const hasFieldsModifyPerm = hasPermission("field-enum-library-modify");
    const conditionsArraySetter = useArraySetter(setState);

    const deleteCondition = useCallback(
      (index: number) =>
        setState((oldState) => {
          const newState = [...(oldState || [])];
          newState.splice(index, 1);
          return newState;
        }),
      [setState],
    );

    return (
      <Box maxWidth={400}>
        {!!state?.length && <Box my={2}>Field is conditional on:</Box>}
        {state &&
          !!state.length &&
          state.map((s, i) => {
            return (
              <div
                key={i}
                style={{
                  borderBottom: "1px solid #ccc",
                  paddingBottom: "16px",
                }}
              >
                <FieldConditionEditor
                  state={s}
                  showErrors={showErrors}
                  setState={conditionsArraySetter.withIndex(i)}
                  previousFields={previousFields}
                  enumTypes={enumTypes}
                />
                <Box my={2}>
                  <Button
                    disabled={!hasFieldsModifyPerm}
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    onClick={() => deleteCondition(i)}
                  >
                    Remove this field condition
                  </Button>
                </Box>
              </div>
            );
          })}
        {state === null ||
          (state.length < 3 && (
            <div style={{ marginTop: "16px" }}>
              <Button
                disabled={!hasFieldsModifyPerm}
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={addCondition}
              >
                {!!state?.length
                  ? "Add another field condition"
                  : "Add a field condition"}
              </Button>
            </div>
          ))}
      </Box>
    );
  },
);

function convertEnumTypeToState(enumType: T.RawEnumType): EnumTypeState {
  if (enumType.disposition.kind === "native") {
    return {
      oldId: enumType.oldId,
      id: enumType.id,
      disposition: {
        kind: "native",
        name: enumType.disposition.name,
        variants: newObjectListState(
          enumType.disposition.variants.map(convertEnumVariantToState),
        ),
        domainValidationErrors: {
          hasErrors: false,
          name: [],
        },
      },
      domainValidationErrors: {
        hasErrors: false,
        id: [],
      },
    };
  } else {
    return {
      oldId: enumType.oldId,
      id: enumType.id,
      disposition: {
        kind: "inherited",
        nameAlias: enumType.disposition.nameAlias,
        includeVariants: newObjectListState(
          enumType.disposition.includeVariants.map(
            convertIncludedEnumVariantToState,
          ),
        ),
        excludeVariants: newObjectListState(
          enumType.disposition.excludeVariants.map(
            convertExcludedEnumVariantToState,
          ),
        ),
        domainValidationErrors: {
          hasErrors: false,
          nameAlias: [],
        },
      },
      domainValidationErrors: {
        hasErrors: false,
        id: [],
      },
    };
  }
}

function convertEnumVariantToState(
  enumVariant: T.EnumVariant,
): EnumVariantState {
  return {
    oldId: enumVariant.id,
    id: enumVariant.id,
    name: enumVariant.name,
    domainValidationErrors: {
      hasErrors: false,
      id: [],
      name: [],
    },
  };
}

function convertIncludedEnumVariantToState(
  enumVariant: T.IncludedEnumVariant,
): IncludedEnumVariantState {
  return {
    id: enumVariant.id,
    nameAlias: enumVariant.nameAlias,
    domainValidationErrors: {
      hasErrors: false,
      id: [],
      nameAlias: [],
    },
  };
}

function convertExcludedEnumVariantToState(
  enumVariant: T.ExcludedEnumVariant,
): ExcludedEnumVariantState {
  return {
    id: enumVariant.id,
    domainValidationErrors: {
      hasErrors: false,
      id: [],
    },
  };
}

function validateEnumState(
  existingEnumTypeNames: ISet<string>,
  state: EnumTypeState,
  loggedInInfo: LoggedInInfo,
): void {
  const resolvedEnum = resolveEnum(
    convertStateToEnumType(state),
    loggedInInfo.config.systemEnumTypesById,
    loggedInInfo.client.displayNewInheritedEnumVariants,
  );

  if (resolvedEnum.name.trim() === "") {
    throw new UiValidationError("Enumeration name is missing");
  }

  const lowerCaseIdentifier = enumTypeNameToIdentifier(
    resolvedEnum.name,
  ).toLowerCase();
  const existingEnumTypeName = existingEnumTypeNames.find(
    (name) =>
      enumTypeNameToIdentifier(name).toLowerCase() === lowerCaseIdentifier,
  );

  if (existingEnumTypeName) {
    if (
      existingEnumTypeName.toLowerCase() === resolvedEnum.name.toLowerCase()
    ) {
      throw new UiValidationError(
        `Duplicate enumeration name "${existingEnumTypeName}"`,
      );
    }

    throw new UiValidationError(
      `Enumeration names "${resolvedEnum.name}" and "${existingEnumTypeName}" are too similar.`,
    );
  }

  if (resolvedEnum.variants.length < 2) {
    throw new UiValidationError("Enumeration must have at least two variants");
  }

  resolvedEnum.variants.forEach((variant, variantIndex) => {
    const previousVariants = resolvedEnum.variants.slice(0, variantIndex);
    const variantErr = validateEnumVariant(previousVariants, variant);

    if (variantErr !== null) {
      throw variantErr;
    }
  });
}

function convertStateToEnumType(state: EnumTypeState): T.RawEnumType {
  if (state.disposition.kind === "native") {
    return {
      oldId: state.oldId,
      id: state.id,
      disposition: {
        kind: "native",
        name: state.disposition.name,
        variants: state.disposition.variants.objects.map(
          convertStateToEnumVariant,
        ),
      },
    };
  } else {
    return {
      oldId: state.oldId,
      id: state.id,
      disposition: {
        kind: "inherited",
        nameAlias: state.disposition.nameAlias,
        includeVariants: state.disposition.includeVariants.objects.map(
          convertStateToIncludedEnumVariant,
        ),
        excludeVariants: state.disposition.excludeVariants.objects.map(
          convertStateToExcludedEnumVariant,
        ),
      },
    };
  }
}

function convertStateToEnumVariant(state: EnumVariantState): T.EnumVariant {
  return {
    oldId: state.oldId,
    id: state.id,
    name: state.name,
  };
}

function convertStateToIncludedEnumVariant(
  state: IncludedEnumVariantState,
): T.IncludedEnumVariant {
  return {
    id: state.id,
    nameAlias: state.nameAlias,
  };
}

function convertStateToExcludedEnumVariant(
  state: ExcludedEnumVariantState,
): T.ExcludedEnumVariant {
  return {
    id: state.id,
  };
}

function getEnumObjectListLabel(
  state: EnumTypeState,
  loggedInInfo: LoggedInInfo,
): JSX.Element {
  return getListLabel(
    resolveEnum(
      convertStateToEnumType(state),
      loggedInInfo.config.systemEnumTypesById,
      loggedInInfo.client.displayNewInheritedEnumVariants,
    ).name,
    false,
    state.disposition.kind === "native" &&
      loggedInInfo.client.accessId !== "super",
  );
}

const EnumerationsEditor = React.memo(
  ({
    enumsState,
    setState,
    existingEnumNames,
    disableControls = false,
  }: {
    disableControls: boolean;
    enumsState: ObjectListState<EnumTypeState>;
    setState: Setter<ObjectListState<EnumTypeState>>;
    existingEnumNames: ISet<string>;
  }) => {
    const C = useObjectEditorStyles();
    const nonNullState = useSelector(nonNullApplicationInitializationSelector);
    const objectDetailsMap = useSelector(objectDetailsMapSelector);
    const loggedInInfo = useMemo(
      () => ({
        user: nonNullState.user,
        client: nonNullState.client,
        config: nonNullState.config,
        objectDetails: objectDetailsMap,
        notifications: nonNullState.notifications,
      }),
      [nonNullState, objectDetailsMap],
    );

    const makeEnumEditor = useCallback(
      ({ value, showErrors, setValue, disableControls }) => (
        <Box className={C.objectEditor}>
          {/* This comment was generated when upgrading react-scripts and eslint */}
          {/* TODO: fix the lint rule and remove this eslint-disable comment */}
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
          {value.disposition.kind === "native" && (
            <NativeEnumerationEditor
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              disableControls={disableControls}
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              state={value}
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              showErrors={showErrors}
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              setState={setValue}
            />
          )}
          {/* This comment was generated when upgrading react-scripts and eslint */}
          {/* TODO: fix the lint rule and remove this eslint-disable comment */}
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
          {value.disposition.kind === "inherited" && (
            <InheritedEnumerationEditor
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              disableControls={disableControls}
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              state={value}
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              showErrors={showErrors}
              // This comment was generated when upgrading react-scripts and eslint
              // TODO: fix the lint rule and remove this eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              setState={setValue}
            />
          )}
        </Box>
      ),
      [C.objectEditor],
    );

    const validateEnumTypeState = useCallback(
      (state: EnumTypeState, enumIndex: number) =>
        getValidationError(() => {
          convertEnumStateListItem(
            existingEnumNames,
            enumsState,
            state,
            enumIndex,
            loggedInInfo,
          );
        }),
      [existingEnumNames, enumsState, loggedInInfo],
    );

    const newButtons = [
      {
        name: "New",
        icon: <AddIcon />,
        makeNewObject: () =>
          ({
            oldId: null,
            id: "" as T.EnumTypeId,
            disposition: {
              kind: "native",
              name: "",
              variants: newObjectListState([]),
              domainValidationErrors: {
                hasErrors: false,
                name: [],
              },
            },
            domainValidationErrors: {
              hasErrors: false,
              id: [],
            },
          } as EnumTypeState),
      },
    ];

    // Super client doesn't have anywhere to inherit enums from, so
    // we don't show the "From System" button.
    if (loggedInInfo.client.accessId !== "super") {
      newButtons.unshift({
        name: "From System",
        icon: <AddIcon />,
        makeNewObject: () =>
          ({
            oldId: null,
            id: "" as T.EnumTypeId,
            disposition: {
              kind: "inherited",
              nameAlias: null,
              includeVariants: {
                objects: [],
                selectedIndex: null,
              },
              excludeVariants: {
                objects: [],
                selectedIndex: null,
              },
              domainValidationErrors: {
                hasErrors: false,
                nameAlias: [],
              },
            },
            domainValidationErrors: {
              hasErrors: false,
              id: [],
            },
          } as EnumTypeState),
      });
    }

    return (
      <Box m={3} flex="1">
        <ObjectListEditor
          disableControls={disableControls}
          height="100%"
          state={enumsState}
          newButtons={newButtons}
          getObjectLabel={(s) => getEnumObjectListLabel(s, loggedInInfo)}
          validateObject={validateEnumTypeState}
          makeObjectEditor={makeEnumEditor}
          setState={setState}
          sortBy={(a, b) => {
            const aResolved = resolveEnum(
              convertStateToEnumType(a),
              loggedInInfo.config.systemEnumTypesById,
              loggedInInfo.client.displayNewInheritedEnumVariants,
            );
            const bResolved = resolveEnum(
              convertStateToEnumType(b),
              loggedInInfo.config.systemEnumTypesById,
              loggedInInfo.client.displayNewInheritedEnumVariants,
            );
            return aResolved.name.localeCompare(bResolved.name);
          }}
          itemHasAdditionalErrors={(obj) =>
            obj.domainValidationErrors.hasErrors
          }
          emptyText="Click &ldquo;New&rdquo; or &ldquo;Inherit&rdquo; to insert a new enumeration into this list"
        />
      </Box>
    );
  },
);

function newEnumVariantState(): EnumVariantState {
  return {
    oldId: null,
    id: "" as T.EnumVariantId,
    name: "",
    domainValidationErrors: {
      hasErrors: false,
      id: [],
      name: [],
    },
  };
}

function getEnumVariantName(variant: EnumVariantState): JSX.Element {
  return <>{variant.name}</>;
}

function getIncludedEnumVariantName(
  includedVariant: IncludedEnumVariantState,
  inheritedEnum: T.EnumType | undefined,
): JSX.Element {
  const inheritedVariant = inheritedEnum?.variants.find(
    (v) => v.id === includedVariant.id,
  );
  return (
    <>
      {includedVariant.nameAlias !== null
        ? includedVariant.nameAlias
        : inheritedVariant?.name}
    </>
  );
}

function getExcludedEnumVariantName(
  excludedVariant: ExcludedEnumVariantState,
  inheritedEnum: T.EnumType | undefined,
): string | undefined {
  const inheritedVariant = inheritedEnum?.variants.find(
    (v) => v.id === excludedVariant.id,
  );
  return inheritedVariant?.name;
}

function validateEnumVariant(
  previousVariants: T.EnumVariant[],
  variant: T.EnumVariant,
): UiValidationError | null {
  if (variant.name.trim() === "") {
    return new UiValidationError("Enumeration variant must have a name");
  }

  const lowerCaseIdentifier = enumVariantNameToIdentifier(
    variant.name,
  ).toLowerCase();
  const existingVariant = previousVariants.find(
    (v) =>
      enumVariantNameToIdentifier(v.name).toLowerCase() === lowerCaseIdentifier,
  );
  if (existingVariant) {
    if (existingVariant.name.toLowerCase() === variant.name.toLowerCase()) {
      return new UiValidationError(
        `Enumeration already has a variant with the name "${existingVariant.name}"`,
      );
    }

    return new UiValidationError(
      `Enumeration variant names "${variant.name}" and "${existingVariant.name}" are too similar`,
    );
  }

  return null;
}

const useObjectEditorStyles = makeStyles((t) =>
  createStyles({
    objectEditor: {
      overflow: "auto",
      scrollbarWidth: "thin",
    },
    standardField: {
      margin: t.spacing(1, 0, 3),
      width: "100%",
      maxWidth: 400,
    },
    availableItemsList: {
      height: "250px",
      overflow: "auto",
      scrollbarWidth: "thin",
      marginRight: t.spacing(2),
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4,
      width: 400,

      "&.error": {
        borderColor: "hsl(4.1, 89.6%, 58.4%)",
      },
    },
    availableItemsListItem: {
      display: "flex",
      alignItems: "center",
      padding: "0 12px",
      cursor: "pointer",

      "& .label": {
        flex: "1",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        padding: "8px 0",
      },

      "&:hover": {
        background: "#eee",
      },

      "& .buttonIcons": {
        display: "flex",
        flex: "auto",
        padding: "4px 0",
        justifyContent: "right",
      },
    },
  }),
);

const InheritedEnumerationEditor = React.memo(
  ({
    state,
    showErrors,
    setState,
    disableControls,
  }: {
    state: InheritedEnumState;
    showErrors: boolean;
    setState: Setter<InheritedEnumState>;
    disableControls: boolean;
  }) => {
    const C = useObjectEditorStyles();
    const nonNullState = useSelector(nonNullApplicationInitializationSelector);

    const loggedInInfo = {
      user: nonNullState.user,
      client: nonNullState.client,
      config: nonNullState.config,
      objectDetails: nonNullState.objectDetails,
      notifications: nonNullState.notifications,
    };

    const setNameAlias = usePropertySetter2(
      setState,
      "disposition",
      "nameAlias",
    );
    const setIncludeVariants = usePropertySetter2(
      setState,
      "disposition",
      "includeVariants",
    );
    const setExcludeVariants = usePropertySetter2(
      setState,
      "disposition",
      "excludeVariants",
    );

    const inheritedEnum = loggedInInfo.config.systemEnumTypes.find(
      (e) => e.id === state.id,
    );

    // These are the variants that are in neither the include nor exclude list
    const availableVariants =
      inheritedEnum?.variants.filter((inheritedVariant) => {
        if (
          !!state.disposition.includeVariants.objects.find(
            (includedVariant) => includedVariant.id === inheritedVariant.id,
          )
        )
          return false;

        if (
          !!state.disposition.excludeVariants.objects.find(
            (excludedVariant) => excludedVariant.id === inheritedVariant.id,
          )
        )
          return false;

        return true;
      }) || [];

    const makeIncludedEnumVariantEditor = useCallback(
      ({
        inheritedEnum,
        value,
        showErrors,
        index,
        setValue,
      }: {
        inheritedEnum?: T.EnumType;
        value: IncludedEnumVariantState;
        showErrors: boolean;
        index: number;
        setValue: Setter<IncludedEnumVariantState>;
      }) => {
        const previousVariants =
          state.disposition.includeVariants.objects.slice(0, index);
        return (
          <IncludedEnumVariantEditor
            state={value}
            inheritedEnum={inheritedEnum}
            showErrors={showErrors}
            setState={setValue}
            previousVariants={previousVariants}
          />
        );
      },
      [state.disposition.includeVariants.objects],
    );

    const makeExcludedEnumVariantEditor = useCallback(({ value }) => {
      return (
        <ExcludedEnumVariantEditor state={value as ExcludedEnumVariantState} />
      );
    }, []);

    const inheritableOptions = [...loggedInInfo.config.systemEnumTypes];

    inheritableOptions.sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();

      if (aName > bName) return 1;
      else if (aName < bName) return -1;
      else return 0;
    });

    return (
      <Box display="flex" flexDirection="column" height="100%">
        <Box margin="12px 0" maxWidth="400px">
          <SearchableDropdown<T.EnumType>
            label="Inherit from"
            value={inheritedEnum || null}
            options={inheritableOptions}
            getOptionLabel={(systemEnum) => systemEnum.name}
            setValue={(systemEnum) => {
              if (systemEnum) {
                setState({
                  oldId: null,
                  id: `${systemEnum.id}` as T.EnumTypeId,
                  disposition: {
                    kind: "inherited",
                    nameAlias: null,
                    includeVariants: newObjectListState([]),
                    excludeVariants: newObjectListState([]),
                    domainValidationErrors: {
                      hasErrors: false,
                      nameAlias: [],
                    },
                  },
                  domainValidationErrors: {
                    hasErrors: false,
                    id: [],
                  },
                });
              }
            }}
          />
        </Box>
        <Box>
          <TextField
            className={C.standardField}
            label="Enumeration ID"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            disabled={true}
            value={state.id}
            error={!!state.domainValidationErrors.id.length}
            helperText={getMessageNode(state.domainValidationErrors.id)}
          />
        </Box>
        <Box>
          <TextField
            className={C.standardField}
            label="Enumeration Name Alias"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder={inheritedEnum?.name}
            value={state.disposition.nameAlias || ""}
            error={!!state.disposition.domainValidationErrors.nameAlias.length}
            onChange={(e) =>
              setNameAlias(
                e.target.value?.trim() === "" ? null : e.target.value,
              )
            }
            helperText={getMessageNode(
              state.disposition.domainValidationErrors.nameAlias,
            )}
          />
        </Box>
        <Box mb={2} fontSize="24px">
          Enumeration Values
        </Box>
        <Box flex="1" overflow="hidden">
          <h4>Included Variants</h4>
          <ObjectListEditor
            disableControls={disableControls}
            height="250px"
            state={state.disposition.includeVariants}
            newButtons={[]}
            getObjectLabel={(v) => getIncludedEnumVariantName(v, inheritedEnum)}
            showErrors={showErrors}
            makeObjectEditor={(args) =>
              makeIncludedEnumVariantEditor({ ...args, inheritedEnum })
            }
            setState={setIncludeVariants}
            emptyText="Select a variant from &ldquo;Available Variants&rdquo; below to add it to this list"
          />

          <h4>Excluded Variants</h4>
          <ObjectListEditor
            disableControls={disableControls}
            height="250px"
            state={state.disposition.excludeVariants}
            newButtons={[]}
            getObjectLabel={(v) => (
              <>{getExcludedEnumVariantName(v, inheritedEnum)}</>
            )}
            showErrors={showErrors}
            makeObjectEditor={makeExcludedEnumVariantEditor}
            setState={setExcludeVariants}
            emptyText="Select a variant from &ldquo;Available Variants&rdquo; below to add it to this list"
            sortBy={(a, b) => {
              const aName = getExcludedEnumVariantName(a, inheritedEnum) || "";
              const bName = getExcludedEnumVariantName(b, inheritedEnum) || "";
              return aName.localeCompare(bName);
            }}
          />

          <h4>Available Variants</h4>
          <Box className={C.availableItemsList}>
            {availableVariants.map((variant, i) => {
              return (
                <div key={i} className={C.availableItemsListItem}>
                  {variant.name}
                  <Box className="buttonIcons">
                    <IconButton
                      title="Explicitly include this variant"
                      onClick={() =>
                        setIncludeVariants({
                          ...state.disposition.includeVariants,
                          objects: [
                            ...state.disposition.includeVariants.objects,
                            {
                              id: variant.id,
                              nameAlias: null,
                              domainValidationErrors: {
                                hasErrors: false,
                                id: [],
                                nameAlias: [],
                              },
                            },
                          ],
                        })
                      }
                    >
                      <CheckCircleIcon />
                    </IconButton>
                    <IconButton
                      title="Explicitly exclude this variant"
                      onClick={() =>
                        setExcludeVariants({
                          ...state.disposition.excludeVariants,
                          objects: [
                            ...state.disposition.excludeVariants.objects,
                            {
                              id: variant.id,
                              domainValidationErrors: {
                                hasErrors: false,
                                id: [],
                              },
                            },
                          ],
                        })
                      }
                    >
                      <CancelIcon />
                    </IconButton>
                  </Box>
                </div>
              );
            })}
          </Box>
          <RefSourcesViewer
            objectId={{
              type: "enum-type",
              enumTypeId: state.id,
            }}
            mt={2}
            style={{ marginBottom: "16px" }}
          />
        </Box>
      </Box>
    );
  },
);

const NativeEnumerationEditor = React.memo(
  ({
    state,
    showErrors,
    setState,
    disableControls,
  }: {
    disableControls: boolean;
    state: NativeEnumState;
    showErrors: boolean;
    setState: Setter<NativeEnumState>;
  }) => {
    const C = useObjectEditorStyles();

    const setId = usePropertySetter(setState, "id");
    const setName = usePropertySetter2(setState, "disposition", "name");
    const setVariants = usePropertySetter2(setState, "disposition", "variants");

    const handleIdChange = useCallback(
      (event: { target: { value: string } }) => {
        const newId = event.target.value as T.EnumTypeId;
        setId(newId);
      },
      [setId],
    );

    const handleNameChange = useCallback(
      (event: { target: { value: string } }) => {
        setName(event.target.value);
        if (!state.oldId) {
          const newId = createSlugId(event.target.value) as T.EnumTypeId;
          setId(newId);
        }
      },
      [setId, setName, state.oldId],
    );

    const makeEnumVariantEditor = useCallback(
      ({
        value,
        showErrors,
        index,
        setValue,
      }: {
        value: EnumVariantState;
        showErrors: boolean;
        index: number;
        setValue: Setter<EnumVariantState>;
      }) => {
        const previousVariants = state.disposition.variants.objects.slice(
          0,

          index,
        );
        return (
          <EnumVariantEditor
            state={value}
            showErrors={showErrors}
            setState={setValue}
            previousVariants={previousVariants}
          />
        );
      },
      [state.disposition.variants.objects],
    );

    const validate = useCallback(
      (variant: EnumVariantState, variantIndex: number) => {
        const previousVariants = state.disposition.variants.objects.slice(
          0,
          variantIndex,
        );
        return validateEnumVariant(previousVariants, variant);
      },
      [state.disposition.variants.objects],
    );

    return (
      <Box display="flex" flexDirection="column" height="100%">
        <Box>
          <TextField
            className={C.standardField}
            label="Enumeration Name"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={state.disposition.name}
            error={
              (showErrors && state.disposition.name.trim() === "") ||
              !!state.disposition.domainValidationErrors.name.length
            }
            onChange={handleNameChange}
            helperText={getMessageNode(
              state.disposition.domainValidationErrors.name,
            )}
          />
        </Box>
        <Box>
          <TextField
            className={C.standardField}
            label="Enumeration ID"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            disabled={!!state.oldId}
            value={state.id}
            error={
              (showErrors && state.id.trim() === "") ||
              !!state.domainValidationErrors.id.length
            }
            onChange={handleIdChange}
            helperText={getMessageNode(state.domainValidationErrors.id)}
          />
        </Box>
        <Box mb={2} fontSize="24px">
          Enumeration Values
        </Box>
        <Box flex="1" overflow="hidden">
          <>
            <ObjectListEditor
              disableControls={disableControls}
              key={state.id}
              height="100%"
              state={state.disposition.variants}
              newButtons={[
                {
                  name: "New",
                  icon: <AddIcon />,
                  makeNewObject: newEnumVariantState,
                },
              ]}
              getObjectLabel={getEnumVariantName}
              showErrors={showErrors}
              validateObject={validate}
              makeObjectEditor={makeEnumVariantEditor}
              setState={setVariants}
              emptyText="Click &ldquo;New&rdquo; to insert a new variant into this list"
            />
            <RefSourcesViewer
              objectId={{
                type: "enum-type",
                enumTypeId: state.id,
              }}
              mt={2}
              style={{ marginBottom: "16px" }}
            />
          </>
        </Box>
      </Box>
    );
  },
);

const EnumVariantEditor = React.memo(
  ({
    state,
    showErrors,
    setState,
    previousVariants,
  }: {
    state: EnumVariantState;
    showErrors: boolean;
    setState: Setter<EnumVariantState>;
    previousVariants: EnumVariantState[];
  }) => {
    const C = useObjectEditorStyles();

    const setName = usePropertySetter(setState, "name");
    const setId = usePropertySetter(setState, "id");

    const handleIdChange = useCallback(
      (event: { target: { value: string } }) => {
        const newId = event.target.value as T.EnumVariantId;
        setId(newId);
      },
      [setId],
    );

    const handleNameChange = useCallback(
      (event: { target: { value: string } }) => {
        setName(event.target.value);
        if (!state.oldId) {
          const newId = createSlugId(event.target.value) as T.EnumVariantId;
          setId(newId);
        }
      },
      [setId, setName, state.oldId],
    );

    const duplicateName = !!previousVariants.find(
      (v) => v.name.toLowerCase().trim() === state.name.toLowerCase().trim(),
    );

    return (
      <Box display="flex" flexDirection="column" height="100%">
        <TextField
          className={C.standardField}
          label="Variant Name"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={state.name}
          error={
            (showErrors && (!state.name.trim() || duplicateName)) ||
            !!state.domainValidationErrors.name.length
          }
          onChange={handleNameChange}
          helperText={getMessageNode(state.domainValidationErrors.name)}
        />
        <TextField
          className={C.standardField}
          label="Variant ID"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          disabled={!!state.oldId}
          value={state.id}
          error={
            (showErrors && !state.id.trim()) ||
            !!state.domainValidationErrors.id.length
          }
          onChange={handleIdChange}
          helperText={getMessageNode(state.domainValidationErrors.id)}
        />
      </Box>
    );
  },
);

const ExcludedEnumVariantEditor = React.memo(
  ({ state }: { state: ExcludedEnumVariantState }) => {
    const C = useObjectEditorStyles();

    return (
      <Box>
        <TextField
          className={C.standardField}
          label="Variant ID"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          disabled={true}
          value={state.id}
          helperText={getMessageNode(state.domainValidationErrors.id)}
        />
      </Box>
    );
  },
);

const IncludedEnumVariantEditor = React.memo(
  ({
    state,
    inheritedEnum,
    showErrors,
    setState,
    previousVariants,
  }: {
    state: IncludedEnumVariantState;
    inheritedEnum?: T.EnumType;
    showErrors: boolean;
    setState: Setter<IncludedEnumVariantState>;
    previousVariants: IncludedEnumVariantState[];
  }) => {
    const C = useObjectEditorStyles();

    const setNameAlias = usePropertySetter(setState, "nameAlias");

    const duplicateName = !!previousVariants.find(
      (v) =>
        getIncludedEnumVariantName(v, inheritedEnum) ===
        getIncludedEnumVariantName(state, inheritedEnum),
    );

    const inheritedVariant = inheritedEnum?.variants.find(
      (v) => v.id === state.id,
    );

    return (
      <Box display="flex" flexDirection="column" height="100%">
        <TextField
          className={C.standardField}
          label="Variant Name Alias"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          placeholder={inheritedVariant?.name}
          value={state.nameAlias || ""}
          error={
            duplicateName || !!state.domainValidationErrors.nameAlias.length
          }
          onChange={(e) =>
            setNameAlias(e.target.value?.trim() === "" ? null : e.target.value)
          }
          helperText={getMessageNode(state.domainValidationErrors.nameAlias)}
        />
        <TextField
          className={C.standardField}
          label="Variant ID"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          disabled={true}
          value={state.id}
          error={
            (showErrors && !state.id.trim()) ||
            !!state.domainValidationErrors.id.length
          }
          helperText={getMessageNode(state.domainValidationErrors.id)}
        />
      </Box>
    );
  },
);
