import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Api from "api";
import * as T from "types/engine-types";
import iFrameMessageTransport from "features/iframe-message-transport";
import { enablePriceLocking, loadScenario } from "features/loans";
import { logout } from "features/application-initialization";
import { setLoggedIn } from "features/login-logout/set-logged-in";

/** log out of this client and go to the login screen for the client indicated by `accessId` */
const IFrameListener: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const listener = iFrameMessageTransport.onMessage(
      (message: T.IFrameMessage) => {
        if (
          typeof message !== "object" ||
          typeof message?.message !== "string"
        ) {
          return;
        }

        switch (message.message) {
          case "listening":
            break;
          case "price-lock":
            break;
          case "log-in":
            (async () => {
              try {
                const res = await Api.login({
                  clientAccessId: message.clientAccessId,
                  emailAddress: message.emailAddress,
                  password: message.password,
                });
                console.info("Successfully logged in from iframe message");

                setLoggedIn({
                  sessionToken: res.sessionToken,
                  accessId: res.client.accessId,
                });

                window.location.reload();
              } catch (e) {
                console.warn("Failed to log in from iframe message", e);
              }
            })();
            break;
          case "log-out":
            logout(message.clientAccessId);
            break;
          case "enable-price-locking":
            dispatch(
              enablePriceLocking({
                lockRequestLabel: message.lockRequestLabel,
              }),
            );
            break;
          case "set-fields":
            dispatch(loadScenario(message.fields));
            break;
        }
      },
    );

    iFrameMessageTransport.postMessage({
      message: "listening",
    });

    return () => {
      listener.unsubscribe();
    };
  }, [dispatch, history]);

  return null;
};

export default IFrameListener;
