import React, { ReactNode } from "react";
import StringInput from "design/molecules/string-input";
import EnumInput from "design/molecules/enum-input";
import DurationInput from "design/molecules/duration-input";
import NumberInput from "design/molecules/number-input";
import { FieldValueEditorV2 } from "./styles";
import { CreditApplicationFieldDefinition } from "types/generated-types";
import { BaseFieldDefinition } from "types/engine-types";

export default React.memo(
  ({
    children,
    field,
    filterType,
    showLabel = false,
  }: {
    children?: ReactNode;
    field?: CreditApplicationFieldDefinition | BaseFieldDefinition;
    filterType?: string;
    showLabel?: boolean;
  }) => {
    switch (field?.valueType.type) {
      case "enum":
        return (
          <FieldValueEditorV2 className="organism-field-value-editor-v2">
            <EnumInput showLabel={showLabel} field={field} />
          </FieldValueEditorV2>
        );
      case "string":
        return (
          <FieldValueEditorV2 className="organism-field-value-editor-v2">
            <StringInput
              showLabel={showLabel}
              field={field}
              format={field.valueType.format}
            />
          </FieldValueEditorV2>
        );
      case "number":
        return (
          <FieldValueEditorV2 className="organism-field-value-editor-v2">
            <NumberInput
              showLabel={showLabel}
              filterType={filterType}
              field={field}
              style={field.valueType.style}
            />
          </FieldValueEditorV2>
        );
      case "duration":
        return (
          <FieldValueEditorV2 className="organism-field-value-editor-v2">
            <DurationInput showLabel={showLabel} field={field} />
          </FieldValueEditorV2>
        );
      case "date":
        return (
          <></>
          // <FieldValueEditorV2 className="organism-field-value-editor-v2">
          //   <DateInput field={field} />
          // </FieldValueEditorV2>
        );
      case "header":
        return (
          <FieldValueEditorV2 className="organism-field-value-editor-v2">
            {children}
          </FieldValueEditorV2>
        );
      case "object-ref":
        return <></>;
      case undefined:
        return (
          <>
            <FieldValueEditorV2 className="organism-field-value-editor-v2">
              {children}
            </FieldValueEditorV2>
          </>
        );
    }
  },
);
