import styled from "styled-components";
import color from "design/subatomics/colors";
import layers from "design/subatomics/layers";
import whitespace from "design/subatomics/whitespace";
import shadowOptions from "design/subatomics/shadows";
import radiusOptions from "design/subatomics/corners";
import breakpoints from "design/subatomics/breakpoints";

export const Container = styled.div`
  box-sizing: border-box;
  z-index: ${layers({ modifier: 0, layer: "above" })};
  > div {
    z-index: ${layers({ modifier: 1, layer: "above" })};
    background-color: ${color({ color: "gray", shade: 10 })};
    border: 1px solid ${color({ color: "lightBorder" })};
    box-shadow: ${shadowOptions.modals};
    border-radius: ${radiusOptions.medium};
    min-width: 240px;

    &:focus {
      display: none;
    }
  }

  &.custom-tooltip div {
    z-index: ${layers({ modifier: 1, layer: "above" })};
    padding: 5px 15px;
  }

  &.sortsFilters {
    width: auto;
  }

  ${breakpoints.mobile} {
    width: 100%;
    padding: ${whitespace("less")};
  }

  ${breakpoints.desktop} {
    padding: ${whitespace("less")};
  }
`;

export const HoverSpan = styled.span``;
