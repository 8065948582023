import React, { ReactNode } from "react";
import { ProductResult, InlineGroup, ResultActions } from "./styles";
import KeyValue, { KeyValueGroup } from "design/atoms/key-value";
import {
  AsLink,
  fontWeights,
  PanelTitle,
  PanelTitleSub,
  SecondaryTitle,
  SmallText,
} from "design/atoms/typography";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as T from "types/engine-types";
import {
  nonNullApplicationInitializationSelector,
  expandedConfigSelector,
  objectDetailsMapSelector,
} from "features/application-initialization";
import { useSelector } from "react-redux";
import { getLowest } from "features/pricing-summaries";
import Icon from "design/atoms/icon";
import { Portal } from "react-portal";
import Draggable from "react-draggable";
import SummaryPriceScenarioTable from "pages/loans-v2/loan-pricing/_components/summary-price-scenario-table";
import { investorsSelector } from "features/investors";
import colors from "design/subatomics/colors";
import {
  faEye,
  faClose,
  faTableCells,
} from "@fortawesome/free-solid-svg-icons";

import radiusOptions from "design/subatomics/corners";
import shadowOptions from "design/subatomics/shadows";
import Button from "design/atoms/button";
import whitespace from "design/subatomics/whitespace";
import { localAccessId } from "features/access-id";

type Params = {
  productId: T.ProductId;
  pricingScenarioId: string;
  pricingScenarioRate: string;
  pricingScenarioLock: string;
};

export default React.memo(
  ({
    hideNextRequiredLink,
    icon,
    color,
    product,
    highlightNextField,
  }: {
    hideNextRequiredLink?: boolean;
    icon: ReactNode;
    color?: string;
    product?: T.ExecutionProductSummary;
    highlightNextField: (field: T.FieldId[] | undefined) => void;
  }) => {
    const history = useHistory();
    const location = useLocation();
    const config = useSelector(expandedConfigSelector);
    const { investors } = useSelector(investorsSelector);
    const params = useParams<Params>();

    const [open, setOpen] = React.useState(false);
    const [offset, setOffset] = React.useState<string>("0px");
    const isInvestorPricingEnabled = investors.find(
      (i) => product?.investorCode === i.code,
    )?.isPricingEnabled;
    const accessId = localAccessId();

    const applicationState = useSelector(
      nonNullApplicationInitializationSelector,
    );
    const objectDetails = useSelector(objectDetailsMapSelector);

    const nextField = applicationState.config.creditApplicationFields.find(
      (f) =>
        product?.status === "available" && f.id === product.requiredFieldIds[0],
    );

    if (!product) return <></>;

    const settings = applicationState.config.settings;
    const settingsType = settings.priceScenarioTable?.type;
    const allFields = applicationState.config.allFieldsById;
    const lowest = getLowest(product);
    const extraFields =
      product.status === "approved" || product.status === "review-required"
        ? [...lowest!.calculatedFields, ...lowest!.priceScenarioFields]
        : [];
    const extraColumns =
      applicationState.config.priceScenarioTableFieldInfo &&
      (applicationState.config.priceScenarioTableFieldInfo.type ===
      "rate-with-lock-period"
        ? applicationState.config.priceScenarioTableFieldInfo.extraColumnFields
        : applicationState.config.priceScenarioTableFieldInfo.columnFields);
    const extraColumnsMap: Map<T.FieldId, T.BaseFieldDefinition> = new Map();
    extraColumns?.map((item) => {
      extraColumnsMap.set(item.id, item);
    });

    const sortedColumns = extraFields?.sort((a, b) => {
      const aItem: T.BaseFieldDefinition = extraColumnsMap.get(a.fieldId)!;
      const bItem: T.BaseFieldDefinition = extraColumnsMap.get(b.fieldId)!;
      return extraColumns!.indexOf(aItem) - extraColumns!.indexOf(bItem);
    });

    const handleClick = () => {
      if (
        product &&
        lowest?.adjustedRate &&
        lowest?.adjustedRateLockPeriod?.count
      ) {
        history.push(
          `/c/${accessId}/v2/loan-pricing/products/${product.productId}/${lowest.adjustedRate}/${lowest.adjustedRateLockPeriod.count}${location.hash}`,
        );
      } else {
        history.push(
          `/c/${accessId}/v2/loan-pricing/products/${product.productId}${location.hash}`,
        );
      }
    };

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const adjustedRateObj =
      settings.priceScenarioTable?.adjustedRateFieldId &&
      applicationState.config.allFieldsById.get(
        settings.priceScenarioTable?.adjustedRateFieldId,
      );
    const adjustedRateValue =
      adjustedRateObj &&
      adjustedRateObj.valueType.type === "number" &&
      parseFloat(getLowest(product)?.adjustedRate || "").toFixed(
        adjustedRateObj.valueType.precision,
      );
    const adjustedPriceObj =
      settingsType === "rate-with-lock-period" &&
      settings.priceScenarioTable?.adjustedPriceFieldId &&
      applicationState.config.allFieldsById.get(
        settings.priceScenarioTable?.adjustedPriceFieldId,
      );
    const adjustedPriceValue =
      settingsType === "rate-with-lock-period" &&
      adjustedPriceObj &&
      adjustedPriceObj.valueType.type === "number" &&
      parseFloat(getLowest(product)?.adjustedPrice || "").toFixed(
        adjustedPriceObj.valueType.precision,
      );

    return (
      <ProductResult
        className={
          color
            ? `page-loan-pricing-component-product-result ${color} ${
                params.productId === product.productId
                  ? "selected"
                  : "not-selected"
              }`
            : `page-loan-pricing-component-product-result ${
                params.productId === product.productId
                  ? "selected"
                  : "not-selected"
              }`
        }
      >
        <SecondaryTitle onClick={handleClick}>
          <AsLink style={{ color: colors({ color: "gray", shade: 4 }) }}>
            {params.productId === product.productId ? (
              <>
                {icon}{" "}
                <strong
                  style={{
                    color: colors({ color: "gray", shade: 1 }),
                  }}
                >
                  {product.productName}
                </strong>{" "}
                <Icon icon={faEye} />
              </>
            ) : (
              <>
                {icon} <strong>{product.productName}</strong>
              </>
            )}
          </AsLink>
        </SecondaryTitle>

        <ResultActions>
          {!hideNextRequiredLink && (
            <>
              {params.productId !== product.productId && (
                <Button
                  className="select-product hide-for-mobile"
                  onClick={handleClick}
                >
                  <Icon icon={faEye} />
                </Button>
              )}

              {params.productId !== product.productId &&
                (product.status === "approved" ||
                  product.status === "review-required") &&
                config.settings.priceScenarioTable?.type ===
                  "rate-with-lock-period" && (
                  <>
                    <Button
                      className="pricing-popover hide-for-mobile"
                      onClick={(e) => {
                        setOffset(
                          `${
                            (e.currentTarget.parentElement?.parentElement
                              ?.offsetTop || 0) + 84
                          }px`,
                        );
                        return open ? handleClose() : handleClickOpen();
                      }}
                    >
                      <Icon icon={faTableCells} />
                    </Button>
                    <Portal>
                      {open && (
                        <Draggable handle={`#product${product.productId}`}>
                          <div
                            style={{
                              boxShadow: shadowOptions.modals,
                              position: "absolute",
                              top: offset,
                              right: whitespace(),
                              padding: whitespace(),
                              minWidth: "500px",
                              zIndex: 9999,
                              background: colors({ color: "white" }),
                              border: `1px solid ${colors({
                                color: "lightBorder",
                              })}`,
                              borderRadius: radiusOptions.medium,
                            }}
                            id={`product${product.productId}`}
                          >
                            <Button
                              data-selector="draggable-close-button"
                              onClick={handleClose}
                              style={{
                                color: colors({ color: "gray", shade: 4 }),
                                cursor: "pointer",
                                position: "absolute",
                                top: whitespace(),
                                right: whitespace(),
                              }}
                            >
                              <Icon icon={faClose} />
                            </Button>
                            <PanelTitle>{product.productName}</PanelTitle>
                            <PanelTitleSub>
                              {product.investorName}
                            </PanelTitleSub>
                            <SummaryPriceScenarioTable
                              isInvestorPricingEnabled={
                                isInvestorPricingEnabled
                              }
                              result={product}
                              config={config}
                              objectDetails={objectDetails}
                            />
                          </div>
                        </Draggable>
                      )}
                    </Portal>
                  </>
                )}
            </>
          )}
        </ResultActions>

        <InlineGroup>
          <SmallText className="investor-name">
            {product.investorName}
          </SmallText>

          {nextField &&
            !hideNextRequiredLink &&
            product.status === "available" && (
              <SmallText className="next-required">
                Next Field:{" "}
                <AsLink
                  style={{
                    fontWeight: fontWeights.bold,
                  }}
                  onClick={() => {
                    highlightNextField([nextField.id]);
                  }}
                >
                  {nextField.name}
                </AsLink>
              </SmallText>
            )}
        </InlineGroup>

        {(product.status === "approved" ||
          product.status === "review-required") && (
          <KeyValueGroup>
            {settingsType === "rate-with-columns" &&
              settings.priceScenarioTable && (
                <>
                  <KeyValue
                    label={
                      allFields.get(
                        settings.priceScenarioTable.adjustedRateFieldId,
                      )?.name || ""
                    }
                    value={adjustedRateValue || ""}
                  />
                </>
              )}

            {settingsType === "rate-with-lock-period" &&
              settings.priceScenarioTable && (
                <>
                  <KeyValue
                    label={
                      allFields.get(
                        settings.priceScenarioTable.adjustedPriceFieldId,
                      )?.name || ""
                    }
                    value={adjustedPriceValue || ""}
                  />

                  <KeyValue
                    label={
                      allFields.get(
                        settings.priceScenarioTable.adjustedRateFieldId,
                      )?.name || ""
                    }
                    value={adjustedRateValue || ""}
                  />

                  <KeyValue
                    label={
                      allFields.get(
                        settings.priceScenarioTable
                          .adjustedRateLockPeriodFieldId,
                      )?.name || ""
                    }
                    value={`${
                      getLowest(product)?.adjustedRateLockPeriod?.count || ""
                    } ${
                      getLowest(product)?.adjustedRateLockPeriod?.unit || ""
                    }`}
                  />
                </>
              )}

            {sortedColumns &&
              sortedColumns.map((field, i) => {
                let value;
                switch (field.value?.type) {
                  case "duration":
                    value = `${field.value.count} + ${field.value.unit}`;
                    break;
                  case "enum":
                    value = applicationState.config.enumTypesById
                      .get(field.value.enumTypeId)
                      ?.variants.find(
                        (v) =>
                          v.id ===
                          (field.value?.type === "enum" &&
                            field.value?.variantId),
                      )?.name;
                    break;
                  case "number":
                    const fieldObj = applicationState.config.allFieldsById.get(
                      field.fieldId,
                    );

                    if (fieldObj?.valueType.type === "number") {
                      value = parseFloat(field.value.value).toFixed(
                        fieldObj.valueType.precision,
                      );
                    } else {
                      value = field.value.value;
                    }

                    if (
                      fieldObj?.valueType.type === "number" &&
                      fieldObj?.valueType.style === "dollar"
                    ) {
                      value = "$" + value;
                    }

                    if (
                      fieldObj?.valueType.type === "number" &&
                      fieldObj?.valueType.style === "percent"
                    ) {
                      value = value + "%";
                    }

                    break;
                  case "object-ref":
                    value = field.value.objectRef.id;
                    break;
                  case "string":
                    value = field.value.value;
                    break;
                  case "date":
                    value = field.value.value;
                    break;
                  default:
                    break;
                }

                return (
                  <KeyValue
                    key={i}
                    label={allFields.get(field.fieldId)?.name || ""}
                    value={value || ""}
                  />
                );
              })}
          </KeyValueGroup>
        )}
      </ProductResult>
    );
  },
);
