import styled from "styled-components";
import breakpoints from "design/subatomics/breakpoints";
import whitespace from "design/subatomics/whitespace";
import color from "design/subatomics/colors";

export const ProductDetailActionPanel = styled.div`
  background: ${color({ color: "gray", shade: 10 })};
  border-bottom: 1px solid ${color({ color: "border" })};
  flex: 0 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: flex-end;

  ${breakpoints.mobile} {
    padding: ${whitespace("least")} ${whitespace("less")};

    .hide-for-mobile {
      display: none;
    }

    .page-component-loan-pricing-action-buttons {
      margin-top: 0;

      button,
      span button {
        margin: 0;
      }
    }
  }

  ${breakpoints.desktop} {
    padding-bottom: 1px;

    .hide-for-desktop {
      display: none;
    }

    .page-component-loan-pricing-action-buttons {
      margin: 0 ${whitespace("less")};
      display: flex;

      button,
      span {
        margin: ${whitespace("least")} 0;
      }
    }
  }
`;
