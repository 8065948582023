import { Map as IMap, Set as ISet } from "immutable";
import _ from "lodash";
import React from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import * as T from "types/generated-types";
import { DecoratedRuleHeader } from "types/engine-types";
import MessageSection from "../message-section";
import CauseLink from "pages/loans/_components/cause-link";
import Accordion from "design/molecules/accordion";
import { Text } from "design/atoms/typography";

const StipulationsSection = React.memo(
  ({
    stipulations,
    rulesById,
    color,
  }: {
    stipulations: ISet<T.StipulationValue>;
    rulesById: IMap<T.RuleId, DecoratedRuleHeader>;
    color: string;
  }) => {
    const sortedStipulations = _.sortBy(stipulations.toArray(), "text");

    return (
      <Accordion color={color} open={true} label="Stipulations">
        {stipulations.size === 0 && <Text>No stipulations.</Text>}
        {sortedStipulations.map((stip) => {
          const rule = rulesById.get(stip.ruleId);
          const ruleName = rule?.name || "<unknown rule>";

          return (
            <MessageSection key={stip.ruleId} icon={<DescriptionIcon />}>
              {stip.text}
              <> &mdash; </>
              <CauseLink title={ruleName} link={`/rules/${stip.ruleId}`} />
            </MessageSection>
          );
        })}
      </Accordion>
    );
  },
);

export default StipulationsSection;
