/// Some notes about this file:
/// * IDs are not included in the exported types, except for fields and enums. These export their
///   IDs because if IDs are different on the importing system, inherited fields and enums may
///   not point to the intended system fields and enums.
//  * Instead, each type has a "key" that is used instead. This is usually the name, unless
//    there's a code available. Hopefully it's always something we have a unique constraint on
//    in the database.
//  * Exported products and rules still have the ruleId and productId fields, but the actual
//    values stored in those arrays are keys, i.e. product codes or rule names. This is a
//    pretty ugly hack that it would be nice to get rid of.
//  * For some reason, maybe to reduce the number of new types that have to be defined, the exported
//    data often uses types that do have an ID property, and the ID property is set to "" and should
//    be disregarded by import code. If that makes the code a bit harder to reason about, well,
//    such is the state in which we've inherited things.
//  * Merging: when importing, a snapshot of the client's existing data is taken, and
//    the data from the export file is merged with it. It gets stored in a bunch of
//    IOrderedMap<string, T>. T is some type that can contain either the type returned by an
//    API call, e.g. Rule, or the type from the export file, which in the case of rules is NewRule.
//    In theory it should be Rule | NewRule, but in reality it is stored as `Rule`, and if the rule
//    is imported from the export file, then it will have "" as the id.
//  * Upsert: once everything is merged, the function upsertObjects is called for each object type,
//    and it either creates a new object, updates an existing object, or deletes and existing
//    object.

import { fetchObjectDetails, ObjectDetails } from "features/objects";
import { Map as IMap, OrderedMap as IOrderedMap, Set as ISet } from "immutable";
import * as Api from "api";
import { Configuration, expandConfig } from "config";
import * as T from "types/engine-types";
import { ObjectWithId, enumerate, resolveEnum } from "features/utils";
import { getRawEnumTypeKey, importEnumType } from "./enums/import";
import { getAllFieldIdsByKey } from "./fields";
import {
  buildObjectDetailsByKey,
  importRule,
  importRuleId,
} from "./rules/import";
import { exportRules } from "./rules/export";
import { getDataTableKey, getRuleKey } from "./rules";
import {
  importApplicationField,
  importPipelineField,
  importProductField,
  reorderImportedApplicationFields,
} from "./fields/import";
import {
  exportApplicationFields,
  exportPipelineFields,
  exportProductFields,
} from "./fields/export";
import { importInvestor } from "./investors/import";
import { getInvestorKey } from "./investors";
import { exportInvestor } from "./investors/export";
import { exportEnumTypes } from "./enums/export";
import { getProductKey } from "./products";
import { exportProducts } from "./products/export";
import { importProduct } from "./products/import";

export type Snapshot = {
  client: T.Client;
  config: Configuration;
  objectDetails: ObjectDetails;
  investors: T.InvestorHeader[];
  products: T.Product[];
  dataTables: T.DataTable[];
  rules: T.Rule[];
};

export type MergeMode =
  | "delete-all-existing"
  | "overwrite-conflicts"
  | "skip-conflicts"
  | "skip-all";

export type ImportSelections = {
  investorMergeMode: MergeMode;
  enumTypeMergeMode: MergeMode;
  applicationFieldMergeMode: MergeMode;
  productFieldMergeMode: MergeMode;
  pipelineFieldMergeMode: MergeMode;
  productMergeMode: MergeMode;
  ruleMergeMode: MergeMode;
};

export type ExportSelections = {
  investorId: T.InvestorId;
  enumTypeIds: ISet<T.EnumTypeId>;
  applicationFieldIds: ISet<T.FieldId>;
  productFieldIds: ISet<T.FieldId>;
  pipelineFieldIds: ISet<T.FieldId>;
  productIds: ISet<T.ProductId>;
  ruleIds: ISet<T.RuleId>;
};

export type ExportFile = {
  investor: T.NewInvestor;
  rawEnumTypes: T.RawEnumType[];
  rawCreditApplicationFields: T.RawCreditApplicationFieldDefinition[];
  rawProductFields: T.RawProductFieldDefinition[];
  rawPipelineOnlyFields: T.RawPipelineFieldDefinition[];
  products: T.NewProduct[];
  rules: T.NewRule[];
};

export async function getSnapshot(
  setStatusText: (t: string) => void,
): Promise<Snapshot> {
  setStatusText("Loading client info...");
  const { client } = await Api.getUserInfo();

  setStatusText("Loading config...");
  const config = await Api.getConfig();

  setStatusText("Loading investor headers...");
  const DecoratedInvestorHeaders = await Api.getInvestors();
  setStatusText("Loading investors...");
  const investors = await Promise.all(
    DecoratedInvestorHeaders.map((h) => Api.getInvestor(h.id)),
  );

  setStatusText("Loading product headers...");
  const DecoratedProductHeaders = await Api.getProducts();
  setStatusText("Loading products...");
  const products = await Promise.all(
    DecoratedProductHeaders.map((h) => Api.getProduct(h.id)),
  );

  setStatusText("Loading data table headers...");
  const dataTableHeaders = await Api.getDataTables();
  setStatusText("Loading data tables...");
  const dataTables = await Promise.all(
    dataTableHeaders.map((h) => Api.getDataTable(h.id)),
  );

  setStatusText("Loading rule headers...");
  const DecoratedRuleHeaders = await Api.getRules();
  setStatusText("Loading rules...");
  const rules = await Promise.all(
    DecoratedRuleHeaders.map((h) => Api.getRule(h.id)),
  );

  const objectDetails = await fetchObjectDetails();

  return {
    client,
    objectDetails,
    config: expandConfig(config),
    investors,
    products,
    dataTables,
    rules,
  };
}

export async function importFile(
  snapshot: Snapshot,
  file: ExportFile,
  selections: ImportSelections,
  setStatusText: (t: string) => void,
): Promise<void> {
  setStatusText("Preparing data for import...");

  if (selections.investorMergeMode === "delete-all-existing") {
    throw new Error(
      "Delete all existing merge mode is not supported for investors",
    );
  }

  const rawEnumTypesByKey = mergeObjectImports(
    snapshot.config.rawEnumTypes,
    file.rawEnumTypes,
    (acc, old, obj) => importEnumType(old, obj),
    (o) => getRawEnumTypeKey(o),
    selections.enumTypeMergeMode,
  );

  const resolvedEnumTypesByKey = rawEnumTypesByKey.map((v, k) =>
    resolveEnum(
      v,
      snapshot.config.systemEnumTypesById,
      snapshot.client.displayNewInheritedEnumVariants,
    ),
  );

  const objectDetails = buildObjectDetailsByKey(snapshot.objectDetails);
  const applicationFieldsByKey = mergeObjectImports(
    snapshot.config.rawCreditApplicationFields,
    file.rawCreditApplicationFields,
    (
      accumulator: IMap<string, T.RawCreditApplicationFieldDefinition>,
      oldObj: T.RawCreditApplicationFieldDefinition | null,
      importObj: T.RawCreditApplicationFieldDefinition,
    ): T.RawCreditApplicationFieldDefinition => {
      return importApplicationField(
        resolvedEnumTypesByKey,
        accumulator,
        objectDetails,
        oldObj,
        importObj,
      );
    },
    (o) => o.id,
    selections.applicationFieldMergeMode,
  );
  const applicationFields = reorderImportedApplicationFields({
    mergedApplicationFields: applicationFieldsByKey,
    snapshotApplicationFields: snapshot.config.rawCreditApplicationFields,
    fileApplicationFields: file.rawCreditApplicationFields,
    inheritableFields: snapshot.config.allSystemFieldsById,
  });
  const rawProductFieldsByKey = mergeObjectImports(
    snapshot.config.rawProductFields,
    file.rawProductFields,
    (
      accumulator: IMap<string, T.RawProductFieldDefinition>,
      oldObj: T.RawProductFieldDefinition | null,
      importObj: T.RawProductFieldDefinition,
    ): T.RawProductFieldDefinition => {
      return importProductField(
        resolvedEnumTypesByKey,
        accumulator,
        objectDetails,
        oldObj,
        importObj,
      );
    },
    (o) => o.id,
    selections.productFieldMergeMode,
  );
  const rawPipelineOnlyFieldsByKey = mergeObjectImports(
    snapshot.config.rawPipelineOnlyFields,
    file.rawPipelineOnlyFields,
    (
      accumulator: IMap<string, T.RawPipelineFieldDefinition>,
      oldObj: T.RawPipelineFieldDefinition | null,
      importObj: T.RawPipelineFieldDefinition,
    ): T.RawPipelineFieldDefinition => {
      return importPipelineField(
        resolvedEnumTypesByKey,
        accumulator,
        objectDetails,
        oldObj,
        importObj,
      );
    },
    (o) => o.id,
    selections.pipelineFieldMergeMode,
  );
  const allFieldIdsByKey = getAllFieldIdsByKey(
    snapshot,
    applicationFieldsByKey,
    rawProductFieldsByKey,
  );
  const dataTablesByKey = IMap(
    snapshot.dataTables.map((t) => [getDataTableKey(t), t]),
  );
  let investorsByKey = mergeObjectImports(
    snapshot.investors,
    [file.investor],
    (acc, old, obj) => importInvestor(old, obj),
    getInvestorKey,
    selections.investorMergeMode,
  );
  let rulesByKey = mergeObjectImports(
    snapshot.rules,
    file.rules,
    (acc, old, obj) =>
      importRule(
        resolvedEnumTypesByKey,
        allFieldIdsByKey,
        dataTablesByKey,
        objectDetails,
        old,
        obj,
      ),
    getRuleKey,
    selections.ruleMergeMode,
  );

  if (selections.enumTypeMergeMode !== "skip-all") {
    setStatusText("Importing enumerations...");
    await Api.updateEnumerations(rawEnumTypesByKey.valueSeq().toArray());
  }

  if (
    selections.applicationFieldMergeMode !== "skip-all" ||
    selections.productFieldMergeMode !== "skip-all"
  ) {
    setStatusText("Importing fields...");
    await Api.updateFieldDefinitions({
      creditApplicationFields: applicationFields,
      productFields: rawProductFieldsByKey.valueSeq().toArray(),
      pipelineFields: rawPipelineOnlyFieldsByKey.valueSeq().toArray(),
    });
  }

  investorsByKey = await upsertObjects(
    "investor",
    investorsByKey,
    snapshot.investors,
    Api.createInvestor,
    Api.saveInvestor,
    Api.deleteInvestor,
    selections.investorMergeMode,
    setStatusText,
  );

  let productsByKey = mergeObjectImports(
    snapshot.products,
    file.products,
    (acc, old, obj) =>
      importProduct(
        resolvedEnumTypesByKey,
        allFieldIdsByKey,

        // mergeObjectImports delayed until here because the next argument must
        // have knowledge of the server-generated investor IDs given to any
        // newly imported investor objects.
        investorsByKey,

        objectDetails,
        old,
        obj,
      ),
    getProductKey,
    selections.productMergeMode,
  );

  productsByKey = await upsertObjects(
    "product",
    productsByKey,
    snapshot.products,
    Api.createProduct,
    Api.saveProduct,
    Api.deleteProduct,
    selections.productMergeMode,
    setStatusText,
  );

  rulesByKey = await upsertObjects(
    "rule",
    rulesByKey,
    snapshot.rules,
    Api.createRule,
    Api.saveRule,
    Api.deleteRule,
    selections.ruleMergeMode,
    setStatusText,
  );

  await linkProductsToRules(
    selections.productMergeMode,
    snapshot,
    file.products,
    productsByKey,
    rulesByKey,
    setStatusText,
  );

  setStatusText("Import complete.");
}

async function upsertObjects<I, T extends ObjectWithId<I>>(
  objTypeName: string,
  objectsByKey: IOrderedMap<string, T>,
  snapshotObjects: T[],
  create: (obj: T) => Promise<T>,
  save: (obj: T) => Promise<T>,
  remove: (objId: I) => Promise<void>,
  mergeMode: MergeMode,
  setStatusText: (text: string) => void,
): Promise<IOrderedMap<string, T>> {
  if (mergeMode === "skip-all") {
    return objectsByKey;
  }

  if (mergeMode === "delete-all-existing") {
    for (const [obj, objIndex] of enumerate(snapshotObjects)) {
      setStatusText(
        `Deleting ${objTypeName} ${objIndex + 1}/${snapshotObjects.length}...`,
      );

      await remove(obj.id);
    }
  }

  let result = objectsByKey;

  const objects = objectsByKey.valueSeq().toArray();
  for (const [obj, objIndex] of enumerate(objects)) {
    setStatusText(
      `Importing ${objTypeName} ${objIndex + 1}/${objectsByKey.count()}...`,
    );

    if (obj.id && mergeMode !== "delete-all-existing") {
      await save(obj);
    } else {
      const createdObj = await create(obj);
      const objKey = objectsByKey.keySeq().get(objIndex);

      if (objKey === undefined) {
        throw new Error("unreachable");
      }

      result = result.set(objKey, { ...obj, id: createdObj.id });
    }
  }

  return result;
}

async function linkProductsToRules(
  mergeMode: MergeMode,
  snapshot: Snapshot,
  products: T.NewProduct[],
  productsByKey: IMap<string, T.Product>,
  rulesByKey: IMap<string, T.Rule>,
  setStatusText: (text: string) => void,
): Promise<void> {
  for (const [product, productIndex] of enumerate(products)) {
    setStatusText(
      `Linking product ${productIndex + 1}/${products.length} to rules...`,
    );

    const objKey = getProductKey(product);
    let doLinking;

    switch (mergeMode) {
      case "skip-all":
        doLinking = false;
        break;
      case "skip-conflicts":
        doLinking = !snapshot.products.some((p) => getProductKey(p) === objKey);
        break;
      case "overwrite-conflicts":
      case "delete-all-existing":
        doLinking = true;
        break;
    }

    if (doLinking) {
      const savedProduct = productsByKey.get(objKey);

      if (!savedProduct) {
        throw new Error("unreachable");
      }

      savedProduct.ruleIds = product.ruleIds.map((id) =>
        importRuleId(rulesByKey, id),
      );
      await Api.saveProduct(savedProduct);
    }
  }
}

function mergeObjectImports<I, T>(
  snapshotObjects: readonly T[],
  objects: readonly I[],
  importFunc: (
    accumulator: IMap<string, T>,
    oldObj: T | null,
    importObj: I,
  ) => T,
  getObjectKey: (o: T | I) => string,
  mergeMode: MergeMode,
): IOrderedMap<string, T> {
  const snapshotObjectsByKey = IOrderedMap(
    snapshotObjects.map((o) => [getObjectKey(o), o]),
  );

  if (mergeMode === "skip-all") {
    return snapshotObjectsByKey;
  }

  let objectsByKey: IOrderedMap<string, T> =
    mergeMode === "delete-all-existing" ? IOrderedMap() : snapshotObjectsByKey;

  for (const obj of objects) {
    const objKey = getObjectKey(obj);

    let action: "new" | "replace" | "skip" = "new";
    if (objectsByKey.has(objKey)) {
      switch (mergeMode) {
        case "delete-all-existing":
          // Unusual case, conflicts within export file itself
          action = "replace";
          break;
        case "overwrite-conflicts":
          action = "replace";
          break;
        case "skip-conflicts":
          action = "skip";
          break;
      }
    }

    switch (action) {
      case "new": {
        objectsByKey = objectsByKey.set(
          objKey,
          importFunc(objectsByKey, null, obj),
        );
        break;
      }
      case "replace": {
        const oldObj = objectsByKey.get(objKey);

        if (oldObj === undefined) {
          throw new Error("unreachable");
        }

        objectsByKey = objectsByKey.set(
          objKey,
          importFunc(objectsByKey, oldObj, obj),
        );
        break;
      }
      case "skip":
        break;
    }
  }

  return objectsByKey;
}

export function exportSelections(
  snapshot: Snapshot,
  selections: ExportSelections,
  inheritableFields: IMap<T.FieldId, T.BaseFieldDefinition>,
): ExportFile {
  // TODO calculations
  return {
    investor: exportInvestor(snapshot, selections.investorId),
    rawEnumTypes: exportEnumTypes(snapshot, selections.enumTypeIds),
    rawCreditApplicationFields: exportApplicationFields(
      snapshot,
      selections.applicationFieldIds,
    ),
    rawProductFields: exportProductFields(snapshot, selections.productFieldIds),
    rawPipelineOnlyFields: exportPipelineFields(
      snapshot,
      selections.pipelineFieldIds,
    ),
    products: exportProducts(snapshot, selections.productIds),
    rules: exportRules(snapshot, selections.ruleIds, inheritableFields),
  };
}
