import React from "react";
import { CheckboxWrapper, CheckboxText, MultiSelect } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  setInvestorNameIncluded,
  setRateLockPeriodIncluded,
  uniqueLockSelector,
  summarySelector,
  uniqueInvestorNamesSelector,
} from "features/pricing-summaries";
import * as T from "types/engine-types";
import _ from "lodash";

import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import Icon from "design/atoms/icon";

export default React.memo(
  ({ label, field }: { label?: string; field?: T.BaseFieldDefinition }) => {
    const dispatch = useDispatch();
    const summaryState = useSelector(summarySelector);
    const { filters } = summaryState;
    const investorNameOptions = useSelector(uniqueInvestorNamesSelector);
    const lockOptions = useSelector(uniqueLockSelector);
    const defaultLockPeriods: T.DurationValue[] = [
      { count: "15", unit: "days" },
      { count: "30", unit: "days" },
      { count: "60", unit: "days" },
      { count: "90", unit: "days" },
    ];
    const lockOptionProps =
      lockOptions.length === 0 ? defaultLockPeriods : lockOptions;
    const filter: T.DurationValue[] | string[] | null = !field
      ? filters.investorNames
      : filters.rateLockPeriods;

    switch (!!field) {
      case true:
        return (
          <MultiSelect>
            {lockOptionProps.map((lock, i) => {
              const checked: boolean =
                !filter || filter.some((rlp) => _.isEqual(lock, rlp));
              return (
                <CheckboxWrapper
                  key={i}
                  onClick={() => {
                    dispatch(
                      setRateLockPeriodIncluded({
                        rateLockPeriod: lock,
                        included: !checked,
                      }),
                    );
                  }}
                >
                  {checked ? (
                    <Icon icon={faSquareCheck} />
                  ) : (
                    <Icon icon={faSquare} />
                  )}
                  <CheckboxText>
                    {lock.count} {lock.unit}
                  </CheckboxText>
                </CheckboxWrapper>
              );
            })}
          </MultiSelect>
        );

      case false:
        return (
          <>
            <MultiSelect>
              {investorNameOptions.map((investor) => {
                const checked: boolean =
                  !filter ||
                  filter?.includes(investor as string & T.DurationValue);

                return (
                  <CheckboxWrapper
                    onClick={() => {
                      dispatch(
                        setInvestorNameIncluded({
                          investorName: investor,
                          included: !checked,
                        }),
                      );
                    }}
                  >
                    {checked ? (
                      <Icon icon={faSquareCheck} />
                    ) : (
                      <Icon icon={faSquare} />
                    )}

                    <CheckboxText>{investor}</CheckboxText>
                  </CheckboxWrapper>
                );
              })}
            </MultiSelect>
          </>
        );
    }
  },
);
