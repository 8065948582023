import { Map as IMap } from "immutable";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import {
  nonNullApplicationInitializationSelector,
  expandedConfigSelector,
} from "features/application-initialization";

import * as Api from "api";
import { LoadingOverlay } from "design/atoms/loading-overlay";
import * as ImportExport from "features/import-export";
import { ExportFile, MergeMode, Snapshot } from "features/import-export";
import {
  Setter,
  genericReactMemo,
  readFileText,
  resolveEnum,
  getErrorMessage,
} from "features/utils";
import { useSelector } from "react-redux";
import { getRawFieldName } from "features/formulas-util";
import { getRawEnumTypeKey } from "features/import-export/enums/import";
import { getRuleKey } from "features/import-export/rules";
import { getInvestorKey } from "features/import-export/investors";
import { getProductKey } from "features/import-export/products";

const { newrelic } = window;

export const ImportPage = React.memo(() => {
  const [file, setFile] = useState<File | null>();

  return (
    <>
      <Box px={3} my={2} fontSize="36px">
        Import account data
      </Box>
      {!file ? (
        <Box px={3} my={2}>
          <input
            type="file"
            onChange={(e) =>
              setFile((e.target.files && e.target.files[0]) || null)
            }
          />
        </Box>
      ) : (
        <Importer file={file} />
      )}
    </>
  );
});

export const Importer = React.memo(({ file }: { file: File }) => {
  const [snapshotLoad, setSnapshotLoad] = useState<Api.LoadState<Snapshot>>({
    status: "loading",
  });
  const [parsedFile, setParsedFile] = useState<ExportFile | null>(null);
  const [statusText, setStatusText] = useState("Starting...");

  useEffect(() => {
    async function load() {
      try {
        setStatusText("Reading file...");
        const fileText = await readFileText(file);
        const parsedFile: unknown = JSON.parse(fileText);
        setParsedFile(
          parsedFile as React.SetStateAction<ImportExport.ExportFile | null>,
        );

        const snapshot: Snapshot = await ImportExport.getSnapshot((text) => {
          setStatusText(text);
        });
        setStatusText("Loaded.");
        setSnapshotLoad({
          status: "loaded",
          value: snapshot,
        });
      } catch (err) {
        console.error(err);
        if (err instanceof Error) {
          newrelic.noticeError(err);
          setSnapshotLoad({ status: "error", error: err });
        }
      }
    }

    load();
  }, [file]);

  if (snapshotLoad.status === "loading" || parsedFile === null) {
    return (
      <Box
        px={3}
        my={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="600px"
        width="100%"
      >
        <Box flex="1" />
        <Box mb={2}>
          <CircularProgress />
        </Box>
        <Box fontSize="18px">{statusText}</Box>
        <Box flex="1" />
      </Box>
    );
  }

  if (snapshotLoad.status === "error") {
    return (
      <Box px={3} my={2}>
        Error loading data
      </Box>
    );
  }

  return <LoadedImporter snapshot={snapshotLoad.value} file={parsedFile} />;
});

const LoadedImporter = React.memo(
  ({ snapshot, file }: { snapshot: Snapshot; file: ExportFile }) => {
    const { client } = useSelector(nonNullApplicationInitializationSelector);
    const config = useSelector(expandedConfigSelector);

    const investors = useMemo(() => [file.investor], [file.investor]);

    const [investorMergeMode, setInvestorMergeMode] = useState<MergeMode>(
      "overwrite-conflicts",
    );
    const [enumTypeMergeMode, setEnumTypeMergeMode] = useState<MergeMode>(
      "overwrite-conflicts",
    );
    const [applicationFieldMergeMode, setApplicationFieldMergeMode] =
      useState<MergeMode>("overwrite-conflicts");
    const [productFieldMergeMode, setProductFieldMergeMode] =
      useState<MergeMode>("overwrite-conflicts");
    const [pipelineFieldMergeMode, setPipelineFieldMergeMode] =
      useState<MergeMode>("overwrite-conflicts");
    const [productMergeMode, setProductMergeMode] = useState<MergeMode>(
      "overwrite-conflicts",
    );
    const [ruleMergeMode, setRuleMergeMode] = useState<MergeMode>(
      "overwrite-conflicts",
    );

    const [loadingText, setLoadingText] = useState<string | null>(null);
    const [finished, setFinished] = useState<boolean>(false);

    const doImport = useCallback(() => {
      async function run() {
        try {
          await ImportExport.importFile(
            snapshot,
            file,
            {
              investorMergeMode,
              enumTypeMergeMode,
              applicationFieldMergeMode,
              productFieldMergeMode,
              pipelineFieldMergeMode,
              productMergeMode,
              ruleMergeMode,
            },
            setLoadingText,
          );
          setLoadingText(null);
          setFinished(true);
        } catch (err) {
          const error = new Error("Import failed: ");
          console.error(err);
          newrelic.noticeError(error);
          newrelic.noticeError(getErrorMessage(err));
          setLoadingText(null);
          setTimeout(() => {
            if (err instanceof Error) {
              alert("Import failed: " + err.message);
              console.error(err);
              newrelic.noticeError(error);
              newrelic.noticeError(err);
            }
          }, 100);
        }
      }

      run();
    }, [
      snapshot,
      file,
      investorMergeMode,
      enumTypeMergeMode,
      applicationFieldMergeMode,
      productFieldMergeMode,
      pipelineFieldMergeMode,
      productMergeMode,
      ruleMergeMode,
    ]);

    return (
      <>
        <LoadingOverlay when={!!loadingText} text={loadingText} />

        {finished ? (
          <Box px={3} my={3} fontSize="18px">
            Import complete. Press F5 to see the changes.
          </Box>
        ) : (
          <>
            <ObjectImportList
              title="Investors (matched by code)"
              objects={investors}
              snapshotObjects={snapshot.investors}
              sortByName
              getObjectKey={getInvestorKey}
              getObjectName={(o) => o.name}
              mergeMode={investorMergeMode}
              setMergeMode={setInvestorMergeMode}
            />
            <ObjectImportList
              title="Enumerations (matched by name, case insensitive)"
              objects={file.rawEnumTypes}
              snapshotObjects={snapshot.config.rawEnumTypes}
              sortByName
              getObjectKey={(o) => getRawEnumTypeKey(o)}
              getObjectName={(o) =>
                resolveEnum(
                  o,
                  config.systemEnumTypesById,
                  client.displayNewInheritedEnumVariants,
                ).name
              }
              mergeMode={enumTypeMergeMode}
              setMergeMode={setEnumTypeMergeMode}
            />
            <ObjectImportList
              title="Application Form Fields (matched by name, case insensitive)"
              objects={file.rawCreditApplicationFields}
              snapshotObjects={snapshot.config.rawCreditApplicationFields}
              getObjectKey={(o) => o.id}
              getObjectName={(o) =>
                getRawFieldName(o, snapshot.config.allSystemFieldsById)
              }
              mergeMode={applicationFieldMergeMode}
              setMergeMode={setApplicationFieldMergeMode}
            />
            <ObjectImportList
              title="Pipeline Fields (matched by name, case insensitive)"
              objects={file.rawPipelineOnlyFields}
              snapshotObjects={snapshot.config.rawPipelineOnlyFields}
              getObjectKey={(o) => o.id}
              getObjectName={(o) =>
                getRawFieldName(o, snapshot.config.allSystemFieldsById)
              }
              mergeMode={pipelineFieldMergeMode}
              setMergeMode={setPipelineFieldMergeMode}
            />
            <ObjectImportList
              title="Product Fields (matched by name, case insensitive)"
              objects={file.rawProductFields}
              snapshotObjects={snapshot.config.rawProductFields}
              getObjectKey={(o) => o.id}
              getObjectName={(o) =>
                getRawFieldName(o, snapshot.config.allSystemFieldsById)
              }
              mergeMode={productFieldMergeMode}
              setMergeMode={setProductFieldMergeMode}
            />
            <ObjectImportList
              title="Products (matched by code)"
              objects={file.products}
              snapshotObjects={snapshot.products}
              sortByName
              getObjectKey={getProductKey}
              getObjectName={(o) => o.name}
              mergeMode={productMergeMode}
              setMergeMode={setProductMergeMode}
            />
            <ObjectImportList
              title="Rules (matched by name, case insensitive)"
              objects={file.rules}
              snapshotObjects={snapshot.rules}
              sortByName
              getObjectKey={getRuleKey}
              getObjectName={(o) => o.name}
              mergeMode={ruleMergeMode}
              setMergeMode={setRuleMergeMode}
            />
            <Box px={3} my={2}>
              <Button
                variant="outlined"
                size="large"
                startIcon={<PublishIcon />}
                onClick={doImport}
              >
                Run Data Import
              </Button>
            </Box>
          </>
        )}
      </>
    );
  },
);

const ObjectImportList = genericReactMemo(
  <T extends object>({
    title,
    objects,
    snapshotObjects,
    sortByName,
    getObjectKey,
    getObjectName,
    mergeMode,
    setMergeMode,
  }: {
    title: string;
    objects: readonly T[];
    snapshotObjects: readonly T[];
    sortByName?: boolean;
    getObjectKey: (o: T) => string;
    getObjectName: (o: T) => string;
    mergeMode: MergeMode;
    setMergeMode: Setter<MergeMode>;
  }) => {
    const snapshotObjectsByKey = useMemo(
      () => IMap(snapshotObjects.map((o) => [getObjectKey(o), o])),
      [snapshotObjects, getObjectKey],
    );

    const conflictCount = useMemo(
      () =>
        objects.filter((o) => snapshotObjectsByKey.has(getObjectKey(o))).length,
      [objects, snapshotObjectsByKey, getObjectKey],
    );

    const sortedObjects = sortByName ? _.sortBy(objects, "name") : objects;

    return (
      <>
        <Box px={3} my={2} fontSize="24px">
          {title}
        </Box>
        <Box px={3} my={2} display="flex" style={{ flex: "0 0 auto" }}>
          <Box
            overflow="auto"
            width="600px"
            height="300px"
            border="1px solid #ccc"
          >
            {sortedObjects.map((obj) => {
              const objKey = getObjectKey(obj);

              return (
                <Box key={objKey} px={2} py={1}>
                  <Box display="flex" alignItems="center">
                    <Box
                      display="flex"
                      mr={1}
                      style={{
                        visibility: snapshotObjectsByKey.has(objKey)
                          ? "visible"
                          : "hidden",
                      }}
                      title="This object conflicts with an object that already exists in this environment."
                    >
                      <MergeTypeIcon />
                    </Box>
                    <Box>{getObjectName(obj)}</Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box ml={2}>
            <Box mb={3} fontSize="16px">
              {conflictCount === 1
                ? "1 object from the selected file conflicts "
                : String(conflictCount) +
                  " objects from the selected file conflict "}
              with objects that already exist in this environment.
            </Box>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Choose a conflict resolution method:
              </FormLabel>
              <RadioGroup
                value={mergeMode}
                onChange={(e) =>
                  setMergeMode(e.target.value as ImportExport.MergeMode)
                }
              >
                <FormControlLabel
                  value="delete-all-existing"
                  control={<Radio />}
                  label="Delete ALL existing objects of this type before importing"
                />
                <FormControlLabel
                  value="overwrite-conflicts"
                  control={<Radio />}
                  label="Overwrite and replace the object already in the system when there is a conflict"
                />
                <FormControlLabel
                  value="skip-conflicts"
                  control={<Radio />}
                  label="Skip over the objects with conflicts"
                />
                <FormControlLabel
                  value="skip-all"
                  control={<Radio />}
                  label="Do not import any objects of this type"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </>
    );
  },
);
