import React, { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import {
  setColumnsValues,
  dynamicFilterSelector,
  summarySelector,
} from "features/pricing-summaries";
import * as T from "types/generated-types";
import TextInput from "design/atoms/text-input";
import { Wrapper } from "./styles";

export default React.memo(
  ({
    label,
    setValue,
    fieldId,
    adornment,
    adornmentText,
    className,
  }: {
    label?: string;
    hint?: string;
    error?: string;
    className?: string;
    isRequired?: boolean;
    adornment?: "prefix" | "suffix" | null;
    adornmentText?: string | null;
    setValue:
      | ActionCreatorWithPayload<string | null, string>
      | ActionCreatorWithPayload<string, string>
      | string;
    fieldId?: T.FieldId | null;
  }) => {
    const dispatch = useDispatch();
    const dynamicFilters = useSelector(dynamicFilterSelector);
    const summaryState = useSelector(summarySelector);
    const extraColumn = dynamicFilters.dynamicColumns.find(
      (filter) => filter.id === setValue,
    );
    let stateValue;

    if (typeof setValue === "string") {
      if (label === " Minimum") {
        stateValue = dynamicFilters.dynamicColumns.find(
          (column) => column.id === setValue,
        )?.columnMin;
      }
      if (label === " Maximum") {
        stateValue = dynamicFilters.dynamicColumns.find(
          (column) => column.id === setValue,
        )?.columnMax;
      }
    } else {
      let values;
      for (const value of Object.values(dynamicFilters.filtersObj)) {
        if (value && typeof value !== "string" && typeof value !== "boolean") {
          if ("id" in value) {
            if (value.id === fieldId) {
              if ("values" in value) {
                values = value.values;
              }
            }
          }
        }
      }
      if (label === " Minimum") {
        stateValue = values?.min;
      }
      if (label === " Maximum") {
        stateValue = values?.max;
      }
      if (label === "Filter by Text") {
        stateValue = summaryState.searchTerm;
      }
    }

    const currentMin =
      typeof setValue === "string" && extraColumn
        ? extraColumn.columnMin
        : null;
    const currentMax =
      typeof setValue === "string" && extraColumn
        ? extraColumn.columnMax
        : null;

    return (
      <Wrapper className={className}>
        <TextInput
          adornment={adornment}
          adornmentText={adornmentText}
          placeholderText={label}
          inputProps={{
            type: "filterInput",
            value: stateValue ? stateValue : "",
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              if (typeof setValue !== "string") {
                dispatch(setValue(e.target.value || ""));
              } else if (typeof setValue === "string") {
                if (label === " Minimum") {
                  const newColumnValues = dynamicFilters.dynamicColumns.map(
                    (filter) => {
                      if (filter.id === setValue) {
                        return {
                          id: setValue,
                          columnMin: e.target.value.length
                            ? e.target.value
                            : "",
                          columnMax: currentMax,
                        };
                      } else {
                        return filter;
                      }
                    },
                  );
                  dispatch(setColumnsValues(newColumnValues));
                } else {
                  const newColumnValues = dynamicFilters.dynamicColumns.map(
                    (filter) => {
                      if (filter.id === setValue) {
                        return {
                          id: setValue,
                          columnMin: currentMin,
                          columnMax: e.target.value.length
                            ? e.target.value
                            : "",
                        };
                      } else {
                        return filter;
                      }
                    },
                  );
                  dispatch(setColumnsValues(newColumnValues));
                }
              }
            },
          }}
        />
      </Wrapper>
    );
  },
);
