import React, { useCallback, useMemo } from "react";
import { InputAdornment, MenuItem, TextField } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Map as IMap } from "immutable";
import DatePicker from "react-date-picker";
import { Configuration } from "config";
import { SearchableDropdown } from "design/molecules/dropdown";
import * as T from "types/generated-types";
import {
  NumberFormatCustom,
  DurationFormatCustom,
} from "../../molecules/field";
import {
  normalizeDurationFieldValue,
  getEquivalentMaximumFromDays,
  getEquivalentMaximumFromMonths,
  getEquivalentMinimumFromDays,
  getEquivalentMinimumFromMonths,
} from "features/fields";
import {
  ALL_US_COUNTIES_SORT_BY_STATE_CODE,
  UsCounty,
  findUsCountyByFiveDigitCode,
} from "features/us-counties";
import {
  ALL_US_STATES,
  UsState,
  findUsStateByTwoLetterCode,
} from "features/us-states";
import {
  getObjectRefLabel,
  getVisibleObjectRefsByObjectType,
  ObjectDetails,
} from "features/objects";
import {
  Setter,
  UiValidationError,
  usePropertySetter,
  useSetter,
  unreachable,
  getErrorMessage,
} from "features/utils";

const { newrelic } = window;

export type EnumFieldValueState = {
  type: "enum";
  enumTypeId: T.EnumTypeId;
  variantId: T.EnumVariantId | null;
};
export type ObjectRefFieldValueState = {
  type: "object-ref";
  objectType: T.ObjectType;
  objectRef: T.ObjectRef | null;
};
export type StringFieldValueState = { type: "string"; value: string };
export type NumberFieldValueState = { type: "number"; value: string };
export type DurationFieldValueState = { type: "duration" } & T.DurationValue;
export type DateFieldValueState = { type: "date"; value: string };
export type HeaderFieldValueState = { type: "header" };

export type FieldValueState =
  | EnumFieldValueState
  | ObjectRefFieldValueState
  | StringFieldValueState
  | NumberFieldValueState
  | DurationFieldValueState
  | DateFieldValueState
  | HeaderFieldValueState;

export function newFieldValueState(
  valueType: T.FieldValueType,
): FieldValueState {
  switch (valueType.type) {
    case "enum":
      return newEnumFieldValueState(valueType);
    case "object-ref":
      return newObjectRefFieldValueState(valueType);
    case "string":
      return newStringFieldValueState(valueType);
    case "number":
      return newNumberFieldValueState(valueType);
    case "duration":
      return newDurationFieldValueState(valueType);
    case "date":
      return newDateFieldValueState(valueType);
    case "header":
      return newHeaderFieldValueState(valueType);
  }
}

export function newEnumFieldValueState(
  valueType: T.FieldValueType.Enum,
): EnumFieldValueState {
  return {
    type: "enum",
    enumTypeId: valueType.enumTypeId,
    variantId: null,
  };
}

export function newObjectRefFieldValueState(
  valueType: T.FieldValueType.ObjectRef,
): ObjectRefFieldValueState {
  return {
    type: "object-ref",
    objectType: valueType.objectType,
    objectRef: null,
  };
}

export function newStringFieldValueState(
  valueType: T.FieldValueType.String,
): StringFieldValueState {
  return {
    type: "string",
    value: "",
  };
}

export function newHeaderFieldValueState(
  valueType: T.FieldValueType.Header,
): HeaderFieldValueState {
  return {
    type: "header",
  };
}

export function newNumberFieldValueState(
  valueType: T.FieldValueType.Number,
): NumberFieldValueState {
  return {
    type: "number",
    value: "",
  };
}

export function newDurationFieldValueState(
  valueType: T.FieldValueType.Duration,
): DurationFieldValueState {
  return {
    type: "duration",
    count: "",
    unit: "months",
  };
}

export function newDateFieldValueState(
  valueType: T.FieldValueType.Date,
): DateFieldValueState {
  return {
    type: "date",
    value: "",
  };
}

export function convertFieldValueToState(
  fieldValue: T.FieldValue,
): FieldValueState {
  switch (fieldValue.type) {
    case "object-ref":
      return {
        type: fieldValue.type,
        objectRef: fieldValue.objectRef,
        objectType: fieldValue.objectRef.type,
      };
    case "duration":
    case "date":
    case "enum":
    case "number":
    case "string":
      return fieldValue;
  }
}

export function convertNumberFieldValueToState(
  fieldValue: T.FieldValue.Number,
): NumberFieldValueState {
  return fieldValue;
}

export function convertDurationFieldValueToState(
  fieldValue: T.FieldValue.Duration,
): DurationFieldValueState {
  return fieldValue;
}

export function convertDateFieldValueToState(
  fieldValue: T.FieldValue.Date,
): DateFieldValueState {
  return fieldValue;
}

export function convertStateToFieldValue(
  valueType: T.FieldValueType,
  state: FieldValueState,
  required?: boolean,
): T.FieldValue | null {
  switch (valueType.type) {
    case "string":
      if (state.type !== valueType.type || !state.value.trim()) {
        if (required) {
          throw new UiValidationError("Field value is required");
        }
        return null;
      }
      return state;
    case "number":
      if (state.type !== valueType.type) {
        if (required) {
          throw new UiValidationError("Field value is required");
        }

        return null;
      }

      return convertStateToNumberFieldValue(valueType, state, required);
    case "duration":
      if (state.type !== valueType.type) {
        if (required) {
          throw new UiValidationError("Field value is required");
        }

        return null;
      }

      return convertStateToDurationFieldValue(valueType, state, required);
    case "date":
      if (state.type !== valueType.type) {
        if (required) {
          throw new UiValidationError("Field value is required");
        }

        return null;
      }

      return convertStateToDateFieldValue(valueType, state, required);
    case "enum":
      if (
        state.type !== valueType.type ||
        state.enumTypeId !== valueType.enumTypeId
      ) {
        throw new UiValidationError("Field type does not match");
      }
      if (!state.variantId) {
        if (required) {
          throw new UiValidationError("Field variant ID is required");
        }
        return null;
      }
      return { ...state, variantId: state.variantId };
    case "object-ref":
      if (state.type !== valueType.type) {
        if (required) {
          throw new UiValidationError("Field type does not match");
        }
        return null;
      }
      if (!state.objectRef) {
        if (required) {
          throw new UiValidationError("Field object ref is required");
        }
        return null;
      }
      if (state.objectRef.type !== valueType.objectType) {
        throw new UiValidationError("Field object type does not match");
      }
      return { ...state, objectRef: state.objectRef };
    case "header":
      return null;
  }
}

export function convertStateToNumberFieldValue(
  valueType: T.FieldValueType.Number,
  state: NumberFieldValueState,
  required?: boolean,
): T.FieldValue.Number | null {
  if (!state.value.trim()) {
    if (required) {
      throw new UiValidationError("Field value is required");
    }

    return null;
  }

  return state;
}

export function convertStateToDurationFieldValue(
  valueType: T.FieldValueType.Duration,
  state: DurationFieldValueState,
  required?: boolean,
): T.FieldValue.Duration | null {
  if (!state.count.trim() || !state.unit) {
    if (required) {
      throw new UiValidationError("Field value is required");
    }

    return null;
  }

  return state;
}

export function convertStateToDateFieldValue(
  valueType: T.FieldValueType.Date,
  state: DateFieldValueState,
  required?: boolean,
): T.FieldValue.Date | null {
  if (!state.value.trim()) {
    if (required) {
      throw new UiValidationError("Field value is required");
    }

    return null;
  }

  return state;
}

function defaultValuePlaceholderString(
  defaultField: T.DefaultFieldValue,
  valueType: T.FieldValueType,
  config: Configuration,
  objectDetails: ObjectDetails,
): string {
  const defaultFieldValue = defaultField.value;

  switch (defaultFieldValue.type) {
    case "string":
      const stringFormat =
        valueType.type === "string" ? valueType.format : "plain";
      const value = defaultFieldValue.value;

      switch (stringFormat) {
        case "plain":
          return value;
        case "us-state-code":
          const state = findUsStateByTwoLetterCode(value);
          return state ? displayUsState(state) : value;
        case "us-county-code":
          const county = findUsCountyByFiveDigitCode(value);
          return county ? displayUsCounty(county) : value;
        default:
          return unreachable(stringFormat);
      }
    case "number":
      return defaultFieldValue.value;
    case "duration":
      return `${defaultFieldValue.count} ${defaultFieldValue.unit}`;
    case "date":
      return defaultFieldValue.value;
    case "enum":
      const enumType = config.enumTypesById.get(defaultFieldValue.enumTypeId);
      if (enumType == null) {
        console.warn(
          "Could not find enum type by ID",
          defaultFieldValue.enumTypeId,
        );
        return "<Unknown>";
      }

      const variant = enumType.variants.find(
        (variant) => variant.id === defaultFieldValue.variantId,
      );
      if (variant == null) {
        console.warn(
          "Could not find variant type by ID",
          defaultFieldValue.variantId,
        );
        return "<Unknown>";
      }

      return variant.name;
    case "object-ref":
      return getObjectRefLabel(objectDetails, defaultFieldValue.objectRef);
    default:
      return unreachable(defaultFieldValue);
  }
}

function displayUsState(state: UsState): string {
  return `${state.name} (${state.twoLetterCode})`;
}

function displayUsCounty(county: UsCounty): string {
  return `${county.usState.twoLetterCode} - ${county.name} - ${county.fiveDigitCode}`;
}

export const FieldValueEditor = React.memo(
  ({
    state,
    defaultValue,
    required,
    showErrors,
    error,
    setState,
    valueType,
    parentState,
    label,
    config,
    objectDetails,
    margin,
    disabled = false,
  }: {
    state: FieldValueState;
    defaultValue?: T.DefaultFieldValue | null;
    required?: boolean;
    showErrors: boolean;
    error?: boolean;
    setState: Setter<FieldValueState>;
    valueType: T.FieldValueType;
    parentState: FieldValueState | null;
    label?: string;
    config: Configuration;
    objectDetails: ObjectDetails;
    margin?: "normal" | "dense";
    disabled?: boolean;
  }) => {
    const isRequired = required === undefined ? true : required; // true is the default value

    const placeholder = useMemo(() => {
      if (defaultValue != null) {
        return defaultValuePlaceholderString(
          defaultValue,
          valueType,
          config,
          objectDetails,
        );
      } else {
        return undefined;
      }
    }, [defaultValue, valueType, config, objectDetails]);

    const setStringState: Setter<StringFieldValueState> = useSetter(
      setState,
      (oldState, transform) => {
        if (valueType.type !== "string") {
          const error = new Error("Wrong type of field setter called");
          console.error(error);
          newrelic.noticeError(error);
          return oldState;
        }

        if (oldState.type !== "string") {
          oldState = newStringFieldValueState(valueType);
        }

        return transform(oldState);
      },
      [valueType],
    );

    const setNumberState: Setter<NumberFieldValueState> = useSetter(
      setState,
      (oldState, transform) => {
        if (valueType.type !== "number") {
          const error = new Error("Wrong type of field setter called");
          console.error(error);
          newrelic.noticeError(error);
          return oldState;
        }

        if (oldState.type !== "number") {
          oldState = newNumberFieldValueState(valueType);
        }

        return transform(oldState);
      },
      [valueType],
    );

    const setDurationState: Setter<DurationFieldValueState> = useSetter(
      setState,
      (oldState, transform) => {
        if (valueType.type !== "duration") {
          const error = new Error("Wrong type of field setter called");
          console.error(error);
          newrelic.noticeError(error);
          return oldState;
        }

        if (oldState.type !== "duration") {
          oldState = newDurationFieldValueState(valueType);
        }

        return transform(oldState);
      },
      [valueType],
    );

    const setDateState: Setter<DateFieldValueState> = useSetter(
      setState,
      (oldState, transform) => {
        if (valueType.type !== "date") {
          const error = new Error("Wrong type of field setter called");
          console.error(error);
          newrelic.noticeError(error);
          return oldState;
        }

        if (oldState.type !== "date") {
          oldState = newDateFieldValueState(valueType);
        }

        return transform(oldState);
      },
      [valueType],
    );

    const setEnumState: Setter<EnumFieldValueState> = useSetter(
      setState,
      (oldState, transform) => {
        if (valueType.type !== "enum") {
          const error = new Error("Wrong type of field setter called");
          console.error(error);
          newrelic.noticeError(error);
          return oldState;
        }

        if (oldState.type !== "enum") {
          oldState = newEnumFieldValueState(valueType);
        }

        return transform(oldState);
      },
      [valueType],
    );

    const setObjectRefState: Setter<ObjectRefFieldValueState> = useSetter(
      setState,
      (oldState, transform) => {
        if (valueType.type !== "object-ref") {
          const error = new Error("Wrong type of field setter called");
          console.error(error);
          newrelic.noticeError(error);
          return oldState;
        }

        if (oldState.type !== "object-ref") {
          oldState = newObjectRefFieldValueState(valueType);
        }

        return transform(oldState);
      },
      [valueType],
    );

    switch (valueType.type) {
      case "string": {
        const emptyState = newStringFieldValueState(valueType);
        const validState = state.type === "string" ? state : emptyState;

        return (
          <StringFieldValueEditor
            disabled={disabled}
            state={validState}
            setState={setStringState}
            label={label}
            defaultValue={
              defaultValue?.value.type === "string"
                ? defaultValue.value.value
                : null
            }
            margin={margin}
            required={isRequired}
            showErrors={showErrors}
            error={error}
            parentState={parentState}
            format={valueType.format}
          />
        );
      }
      case "number": {
        const emptyState = newNumberFieldValueState(valueType);
        const validState = state.type === "number" ? state : emptyState;

        return (
          <NumberFieldValueEditor
            disabled={disabled}
            state={validState}
            setState={setNumberState}
            label={label}
            defaultValue={
              defaultValue?.value.type === "number"
                ? defaultValue.value.value
                : null
            }
            margin={margin}
            required={isRequired}
            showErrors={showErrors}
            error={error}
            precision={valueType.precision}
            style={valueType.style}
          />
        );
      }
      case "duration": {
        const emptyState = newDurationFieldValueState(valueType);
        const validState = state.type === "duration" ? state : emptyState;

        const defaultDuration =
          defaultValue?.value.type === "duration" ? defaultValue.value : null;

        if (defaultDuration && validState.count === "") {
          // If the field is blank and there's a default value, set the duration
          // unit in the actual state to the default unit. This keeps the displayed
          // unit from unexpectedly changing to whatever happens to be in `state`
          // when the user starts to type a non-default count into the duration
          // control.
          validState.unit = defaultDuration.unit;
        }

        return (
          <DurationFieldValueEditor
            disabled={disabled}
            state={validState}
            setState={setDurationState}
            label={label}
            defaultValue={
              defaultValue?.value.type === "duration"
                ? defaultValue.value
                : null
            }
            margin={margin}
            required={isRequired}
            showErrors={showErrors}
            error={error}
            minimumDays={valueType.minimumDays}
            maximumDays={valueType.maximumDays}
            minimumMonths={valueType.minimumMonths}
            maximumMonths={valueType.maximumMonths}
          />
        );
      }
      case "date": {
        const emptyState = newDateFieldValueState(valueType);
        const validState = state.type === "date" ? state : emptyState;

        return (
          <DateFieldValueEditor
            disabled={disabled}
            state={validState}
            setState={setDateState}
            label={label}
            placeholder={placeholder}
            margin={margin}
            required={isRequired}
            showErrors={showErrors}
            error={error}
          />
        );
      }
      case "enum": {
        const emptyState = newEnumFieldValueState(valueType);
        const validState =
          state.type === "enum" && state.enumTypeId === valueType.enumTypeId
            ? state
            : emptyState;

        return (
          <EnumFieldValueEditor
            disabled={disabled}
            state={validState}
            setState={setEnumState}
            label={label}
            defaultValue={
              defaultValue?.value.type === "enum" ? defaultValue.value : null
            }
            margin={margin}
            required={isRequired}
            showErrors={showErrors}
            error={error}
            config={config}
          />
        );
      }
      case "object-ref": {
        const emptyState = newObjectRefFieldValueState(valueType);
        const validState =
          state.type === "object-ref" &&
          state.objectRef?.type === valueType.objectType
            ? state
            : emptyState;

        return (
          <ObjectRefFieldValueEditor
            disabled={disabled}
            state={validState}
            setState={setObjectRefState}
            label={label}
            defaultValue={
              defaultValue?.value.type === "object-ref"
                ? defaultValue.value.objectRef
                : null
            }
            margin={margin}
            required={isRequired}
            showErrors={showErrors}
            error={error}
            config={config}
            objectDetails={objectDetails}
          />
        );
      }
      case "header": {
        return <></>;
      }
    }
  },
);

/*
  String Editor
*/

const useStringEditorStyles = makeStyles((t) =>
  createStyles({
    field: {
      display: "flex", // Override MUI's inline-flex
    },
  }),
);

export const StringFieldValueEditor = React.memo(
  ({
    state,
    setState,
    label,
    defaultValue,
    margin,
    required,
    showErrors,
    error,
    parentState,
    format,
    disabled = false,
  }: {
    state: StringFieldValueState;
    setState: Setter<StringFieldValueState>;
    label?: string;
    defaultValue?: string | null;
    margin?: "normal" | "dense";
    required?: boolean;
    showErrors: boolean;
    error?: boolean;
    parentState: FieldValueState | null;
    format: T.StringFieldFormat;
    disabled?: boolean;
  }) => {
    const setValue = usePropertySetter(setState, "value");

    let placeholder = "";
    if (defaultValue) {
      switch (format) {
        case "plain":
          placeholder = defaultValue;
          break;
        case "us-state-code":
          const usState = findUsStateByTwoLetterCode(defaultValue);
          placeholder = usState ? displayUsState(usState) : defaultValue;
          break;
        case "us-county-code":
          const county = findUsCountyByFiveDigitCode(defaultValue);
          placeholder = county ? displayUsCounty(county) : defaultValue;
          break;
        default:
          unreachable(format);
      }
    }

    switch (format) {
      case "plain":
        return (
          <PlainStringFieldValueEditor
            disabled={disabled}
            value={state.value}
            setValue={setValue}
            label={label}
            placeholder={placeholder}
            margin={margin}
            required={required}
            showErrors={showErrors}
            error={error}
          />
        );
      case "us-state-code":
        return (
          <UsStateCodeFieldValueEditor
            disabled={disabled}
            value={state.value}
            setValue={setValue}
            label={label}
            placeholder={placeholder}
            margin={margin}
            required={required}
            showErrors={showErrors}
            error={error}
          />
        );
      case "us-county-code":
        return (
          <UsCountyCodeFieldValueEditor
            disabled={disabled}
            value={state.value}
            setValue={setValue}
            label={label}
            placeholder={placeholder}
            margin={margin}
            required={required}
            showErrors={showErrors}
            error={error}
            parentState={parentState}
          />
        );
    }
  },
);

export const PlainStringFieldValueEditor = React.memo(
  ({
    value,
    setValue,
    label,
    placeholder,
    margin,
    required,
    showErrors,
    error,
    disabled = false,
  }: {
    value: string;
    setValue: Setter<string>;
    label?: string;
    placeholder?: string;
    margin?: "normal" | "dense";
    required?: boolean;
    showErrors: boolean;
    error?: boolean;
    disabled?: boolean;
  }) => {
    const C = useStringEditorStyles();
    const maxLength = 2048;

    return (
      <TextField
        disabled={disabled}
        className={C.field}
        label={label}
        placeholder={placeholder}
        margin={margin}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        value={value}
        error={
          showErrors &&
          ((required && !value.trim()) || value.length > maxLength || error)
        }
        onChange={(e) => setValue(e.target.value)}
      />
    );
  },
);

export const UsStateCodeFieldValueEditor = React.memo(
  ({
    value,
    setValue,
    label,
    placeholder,
    margin,
    required,
    showErrors,
    error,
    disabled = false,
  }: {
    value: string;
    setValue: Setter<string>;
    label?: string;
    placeholder?: string;
    margin?: "normal" | "dense";
    required?: boolean;
    showErrors: boolean;
    error?: boolean;
    disabled?: boolean;
  }) => {
    return (
      <SearchableDropdown
        disabled={disabled}
        label={label}
        placeholder={placeholder}
        margin={margin}
        getOptionLabel={displayUsState}
        options={ALL_US_STATES}
        value={findUsStateByTwoLetterCode(value)}
        error={showErrors && ((required && !value.trim()) || error)}
        setValue={(state: UsState | null) =>
          setValue(state?.twoLetterCode || "")
        }
      />
    );
  },
);

export const UsCountyCodeFieldValueEditor = React.memo(
  ({
    value,
    setValue,
    label,
    placeholder,
    margin,
    required,
    showErrors,
    error,
    parentState,
    disabled = false,
  }: {
    value: string;
    setValue: Setter<string>;
    label?: string;
    placeholder?: string;
    margin?: "normal" | "dense";
    required?: boolean;
    showErrors: boolean;
    error?: boolean;
    parentState: FieldValueState | null;
    disabled?: boolean;
  }) => {
    const usState =
      parentState?.type === "string"
        ? findUsStateByTwoLetterCode(parentState.value)
        : null;

    const filteredCounties = usState
      ? ALL_US_COUNTIES_SORT_BY_STATE_CODE.filter(
          (c) => c.usState.twoLetterCode === usState.twoLetterCode,
        )
      : ALL_US_COUNTIES_SORT_BY_STATE_CODE;

    const selected = findUsCountyByFiveDigitCode(value);
    return (
      <SearchableDropdown
        disabled={disabled}
        label={label}
        placeholder={placeholder}
        margin={margin}
        getOptionLabel={displayUsCounty}
        options={filteredCounties}
        value={findUsCountyByFiveDigitCode(value)}
        error={
          showErrors &&
          ((required && !value.trim()) ||
            (usState &&
              selected &&
              selected.usState.twoLetterCode !== usState.twoLetterCode) ||
            error)
        }
        setValue={(county: UsCounty | null) =>
          setValue(county?.fiveDigitCode || "")
        }
      />
    );
  },
);

/*
  Number Editor
*/

const useNumberEditorStyles = makeStyles((t) =>
  createStyles({
    field: {
      display: "flex", // Override MUI's inline-flex
      "& input": {
        textAlign: "right",
      },
    },
  }),
);

export type NumberFieldValueEditorProps = {
  className?: string;
  state: NumberFieldValueState;
  setState: Setter<NumberFieldValueState>;
  label?: string;
  defaultValue?: string | null;
  margin?: "normal" | "dense";
  required?: boolean;
  showErrors: boolean;
  error?: boolean;
  precision: number;
  style: T.NumberFieldStyle;
  disabled?: boolean;
};

export const NumberFieldValueEditor = React.memo(
  ({
    className,
    state,
    setState,
    label,
    defaultValue,
    margin,
    required,
    showErrors,
    error,
    precision,
    style,
    disabled = false,
  }: NumberFieldValueEditorProps) => {
    const C = useNumberEditorStyles();

    let prefix = null;
    let suffix = null;

    switch (style) {
      case "plain":
        break;
      case "percent":
        suffix = "%";
        break;
      case "dollar":
        prefix = "$";
        break;
    }

    let placeholder = "";
    if (defaultValue) {
      const numberFormat = Intl.NumberFormat("en-US", {
        minimumFractionDigits: precision,
      });
      placeholder = numberFormat.format(+defaultValue);
    }

    return (
      <TextField
        disabled={disabled}
        className={C.field + ` ${className || ""}`}
        label={label}
        placeholder={placeholder}
        margin={margin}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        error={showErrors && ((required && !state.value.trim()) || error)}
        InputProps={{
          // This comment was generated when upgrading react-scripts and eslint
          // TODO: fix the lint rule and remove this eslint-disable comment
          // TODO: document why `any` is needed here
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
          inputComponent: NumberFormatCustom as any,
          inputProps: {
            precision,
            numberFieldStyle: style,
          },
          startAdornment: prefix && (
            <InputAdornment position="start">{prefix}</InputAdornment>
          ),
          endAdornment: suffix && (
            <InputAdornment position="end">{suffix}</InputAdornment>
          ),
        }}
        value={state.value}
        onChange={(e) => {
          const updatedNumberState = {
            type: "number" as const,
            value: e.target.value,
          };
          setState(updatedNumberState);
        }}
      />
    );
  },
);

/*
  Duration Editor
*/

const useDurationEditorStyles = makeStyles((t) =>
  createStyles({
    durationFields: {
      display: "flex",
      margin: 0,
    },
    durationInputField: {
      flex: 1,
      "& .MuiOutlinedInput-root": {
        borderTopRightRadius: "0",
        borderBottomRightRadius: "0",
      },
      "& input": {
        textAlign: "right",
      },
    },
    durationUnitField: {
      backgroundColor: "",
      "& .MuiOutlinedInput-root": {
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0",
        marginLeft: -1,
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
      "& .MuiSelect-outlined": {
        paddingRight: "45px",
      },
    },
  }),
);

export type DurationFieldValueEditorProps = {
  state: DurationFieldValueState;
  setState: Setter<DurationFieldValueState>;
  label?: string;
  defaultValue?: T.DurationValue | null;
  placeholder?: string;
  margin?: "normal" | "dense";
  required?: boolean;
  showErrors: boolean;
  error?: boolean;
  minimumDays: string | null;
  maximumDays: string | null;
  minimumMonths: string | null;
  maximumMonths: string | null;
  disabled?: boolean;
};

export const DurationFieldValueEditor = React.memo(
  ({
    state,
    setState,
    label,
    defaultValue,
    margin,
    required,
    showErrors,
    error,
    minimumDays,
    maximumDays,
    minimumMonths,
    maximumMonths,
    disabled = false,
  }: DurationFieldValueEditorProps) => {
    const classes = useDurationEditorStyles();

    const durationOptions = [
      {
        id: "days",
        name: "day(s)",
      },
      {
        id: "weeks",
        name: "week(s)",
      },
      {
        id: "fortnights",
        name: "fortnight(s)",
      },
      {
        id: "half-months",
        name: "half month(s)",
      },
      {
        id: "months",
        name: "month(s)",
      },
      {
        id: "quarters",
        name: "quarter(s)",
      },
      {
        id: "years",
        name: "year(s)",
      },
    ].filter((option) => {
      // If the count is blank and there's a default value for this field,
      // filter out all the units besides the one from the default value.
      // This prevents users from selecting a unit that doesn't make sense
      // with the default count.
      //
      // Another option would be to simply disable the unit selector, but
      // with the "grayness" of both it and the placeholder used for the
      // default value, this makes the whole field appear disabled.
      if (state.count !== "") return true;
      if (!defaultValue) return true;
      return defaultValue.unit === option.id;
    });

    // TODO reduce the variables to just 'minimum' and 'maximum'
    let minDays,
      minMonths,
      maxDays,
      maxMonths = null;
    let isDays = false;

    const selectedUnit = state.unit;
    switch (normalizeDurationFieldValue(state).unit) {
      case "days":
        if (minimumDays) {
          minDays = getEquivalentMinimumFromDays(
            parseFloat(minimumDays),
            selectedUnit,
          );
        }
        if (maximumDays) {
          maxDays = getEquivalentMaximumFromDays(
            parseFloat(maximumDays),
            selectedUnit,
          );
        }
        isDays = true;
        break;
      case "months":
        if (minimumMonths) {
          minMonths = getEquivalentMinimumFromMonths(
            parseFloat(minimumMonths),
            selectedUnit,
          );
        }
        if (maximumMonths) {
          maxMonths = getEquivalentMaximumFromMonths(
            parseFloat(maximumMonths),
            selectedUnit,
          );
        }
        break;
    }

    return (
      <div className={classes.durationFields}>
        <TextField
          disabled={disabled}
          className={classes.durationInputField}
          InputProps={{
            // TODO: document why `any` is needed here
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            // This comment was generated when upgrading react-scripts and eslint
            // TODO: fix the lint rule and remove this eslint-disable comment
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
            inputComponent: DurationFormatCustom as any,
            inputProps: {
              minimum: isDays ? minDays : minMonths,
              maximum: isDays ? maxDays : maxMonths,
            },
          }}
          InputLabelProps={{ shrink: true }}
          label={label}
          placeholder={defaultValue?.count}
          margin={margin}
          variant="outlined"
          value={state.count}
          onChange={(e) => {
            const updatedDurationState = {
              type: "duration" as const,
              unit: state.unit,
              count: e.target.value,
            };
            setState(updatedDurationState);
          }}
          error={
            showErrors &&
            ((required && !state.count.trim()) ||
              parseFloat(state.count.trim()) <= 0 ||
              error)
          }
        />
        <TextField
          disabled={disabled}
          className={classes.durationUnitField}
          select
          value={(() => {
            if (state.count.trim() !== "") {
              return selectedUnit;
            }

            if (defaultValue) {
              return defaultValue.unit;
            }

            return selectedUnit;
          })()}
          margin={margin}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const updatedDurationState = {
              type: "duration" as const,
              unit: e.target.value as T.DurationUnit,
              count: state.count,
            };
            setState(updatedDurationState);
          }}
        >
          {durationOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
    );
  },
);

const useDateEditorStyles = function (margin?: "normal" | "dense") {
  return makeStyles((t) =>
    createStyles({
      field: {
        display: "flex", // Override MUI's inline-flex
      },
      datePicker: {
        margin: margin === "dense" ? "0px" : "10px 0px",
      },
    }),
  )();
};

export type DateFieldValueEditorProps = {
  className?: string;
  state: DateFieldValueState;
  setState: Setter<DateFieldValueState>;
  label?: string;
  placeholder?: string;
  margin?: "normal" | "dense";
  required?: boolean;
  showErrors: boolean;
  error?: boolean;
  disabled?: boolean;
};

export const DateFieldValueEditor = React.memo(
  ({
    className,
    state,
    setState,
    label,
    placeholder,
    margin,
    required,
    showErrors,
    error,
    disabled = false,
  }: DateFieldValueEditorProps) => {
    const C = useDateEditorStyles(margin);

    const onChange = useCallback(
      (date: Date | Date[]): void => {
        // multi select not supported
        if (Array.isArray(date)) {
          date = date[0];
        }

        try {
          let updatedDateState: DateFieldValueState = {
            type: "date" as const,
            value: "",
          };

          if (date && date.getFullYear() > 1000) {
            updatedDateState = {
              type: "date" as const,
              value:
                date instanceof Date ? date.toISOString().substring(0, 10) : "",
            };
          }

          setState(updatedDateState);
        } catch (error) {
          const err = new Error("Invalid date was entered: ");
          console.error(err, error);
          newrelic.noticeError(err);
          newrelic.noticeError(getErrorMessage(error));
        }
      },
      [setState],
    );

    return (
      <div
        tabIndex={1}
        className="react-date-picker__lp-wrapper MuiTextField-root"
        style={{
          position: "relative",
        }}
      >
        <DatePicker
          minDate={new Date("1-1-1900")}
          maxDate={new Date("12-31-9999")}
          className={C.datePicker}
          disabled={disabled}
          onChange={onChange}
          value={
            state.value
              ? new Date(
                  new Date(state.value).getTime() -
                    new Date().getTimezoneOffset() * 60 * 1000 +
                    1000 * 60 * 60 * 24,
                )
              : undefined
          }
        />
        <fieldset>
          <legend>
            <span>{label}</span>
          </legend>
        </fieldset>
      </div>
    );
  },
);

/*
  Enumeration Editor
*/

const useEnumEditorStyles = makeStyles((t) =>
  createStyles({
    dropdown: {
      margin: 0,
    },
  }),
);

export const EnumFieldValueEditor = React.memo(
  ({
    state,
    setState,
    label,
    defaultValue,
    margin,
    required,
    showErrors,
    error,
    config,
    disabled = false,
  }: {
    state: EnumFieldValueState;
    setState: Setter<EnumFieldValueState>;
    label?: string;
    defaultValue?: {
      enumTypeId: T.EnumTypeId;
      variantId: T.EnumVariantId;
    } | null;
    margin?: "normal" | "dense";
    required?: boolean;
    showErrors: boolean;
    error?: boolean;
    config: Configuration;
    disabled?: boolean;
  }) => {
    const C = useEnumEditorStyles();

    const enumType = config.enumTypesById.get(state.enumTypeId)!;
    const variantIds = !!enumType ? enumType.variants.map((v) => v.id) : [];
    const variantsById = IMap(
      (!!enumType ? enumType.variants : []).map((v) => [v.id, v]),
    );

    let placeholder = "";
    if (defaultValue) {
      const enumType = config.enumTypesById.get(defaultValue.enumTypeId);
      if (enumType == null) {
        console.warn("Could not find enum type by ID", defaultValue.enumTypeId);
        placeholder = "<Unknown>";
      } else {
        const variant = enumType.variants.find(
          (variant) => variant.id === defaultValue.variantId,
        );
        if (variant == null) {
          console.warn(
            "Could not find variant type by ID",
            defaultValue.variantId,
          );
          placeholder = "<Unknown>";
        } else {
          placeholder = variant.name;
        }
      }
    }

    return (
      <SearchableDropdown
        disabled={disabled}
        className={C.dropdown}
        label={label || ""}
        placeholder={placeholder}
        margin={margin}
        options={variantIds}
        getOptionLabel={(variantId) =>
          variantsById.get(variantId)?.name || "<Unknown>"
        }
        value={state.variantId}
        error={showErrors && ((required && !state.variantId) || error)}
        setValue={(v) => {
          const updatedEnumState = {
            type: "enum" as const,
            enumTypeId: state.enumTypeId,
            variantId: v,
          };
          setState(updatedEnumState);
        }}
      />
    );
  },
);

/*
  Object Editor
*/

const useObjectEditorStyles = makeStyles((t) =>
  createStyles({
    dropdown: {
      margin: 0,
    },
  }),
);

export const ObjectRefFieldValueEditor = React.memo(
  ({
    state,
    setState,
    label,
    defaultValue,
    margin,
    required,
    showErrors,
    error,
    config,
    objectDetails,
    disabled = false,
  }: {
    state: ObjectRefFieldValueState;
    setState: Setter<ObjectRefFieldValueState>;
    label?: string;
    defaultValue?: T.ObjectRef | null;
    margin?: "normal" | "dense";
    required?: boolean;
    showErrors: boolean;
    error?: boolean;
    config: Configuration;
    objectDetails: ObjectDetails;
    disabled?: boolean;
  }) => {
    const C = useObjectEditorStyles();

    const visibleObjectRefs = getVisibleObjectRefsByObjectType(
      config,
      objectDetails,
      state.objectType,
      state.objectRef ? [state.objectRef] : [],
    );

    let placeholder = "";
    if (defaultValue) {
      placeholder = getObjectRefLabel(objectDetails, defaultValue);
    }

    return (
      <SearchableDropdown
        disabled={disabled}
        className={C.dropdown}
        label={label || ""}
        placeholder={placeholder}
        margin={margin}
        options={visibleObjectRefs}
        getOptionLabel={(ref) => getObjectRefLabel(objectDetails, ref)}
        value={state.objectRef}
        error={showErrors && ((required && !state.objectRef) || error)}
        setValue={(objectRef) => {
          setState({ ...state, objectRef });
        }}
      />
    );
  },
);
