import { unsetActiveRoleId } from "features/active-role-id";

export function setLoggedIn({
  sessionToken,
  accessId,
}: {
  sessionToken: string;
  accessId: string;
}) {
  // Set client access id to local storage, so we can redirect to the client-specific login
  // when the session expires.
  // this can be removed if we use hooks for all API requests (use a context instead),
  // or if we use a login modal (but that may require hooks anyway)
  localStorage.setItem("clientAccessId", accessId);

  // Save session token to local storage
  localStorage.setItem("sessionToken", sessionToken);

  // Unset roleId so that we can get a fresh one.
  // For example, if a login session were to time out, we'll want to log back in.
  // If we choose a different user when logging in, we want the role to be set
  // to the default for that user rather than attempt to use a role that could be incompatible.
  unsetActiveRoleId();
}
