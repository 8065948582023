import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as Api from "api";
import * as T from "types/engine-types";
import { createSelector } from "reselect";
import _ from "lodash";
import { UsersState } from "../users";
import { useSelector } from "react-redux";
import filterResults from "features/filter-list";
import applicationInitializationSlice, {
  ApplicationInitializationState,
  nonNullApplicationInitializationSelector,
} from "features/application-initialization";

export type RolesState = {
  roles: T.DecoratedRoleHeader[];
  searchTerm: string;
  sortField: string;
  sortDir: string;
  loading: boolean;
  errors: string;
};

const initialState: RolesState = {
  roles: [],
  loading: false,
  searchTerm: "",
  errors: "",
  sortField: "name",
  sortDir: "asc",
};

export const getRoles = createAsyncThunk("roles/getRoles", async () => {
  return await Api.getRoles();
});

export const createRole = createAsyncThunk(
  "roles/create",
  async (role: T.NewRole, thunkAPI) => {
    try {
      await Api.createRole(role);
      await thunkAPI.dispatch(getRoles());
      return "success";
    } catch (error) {
      if (error instanceof Error) {
        newrelic.noticeError(error);
        if (error.name === "NotUniqueError") {
          thunkAPI.dispatch(
            setErrors(
              "Save failed because a role with this name already exists.",
            ),
          );
        } else if (error.message) {
          thunkAPI.dispatch(setErrors(error.message));
        } else {
          thunkAPI.dispatch(setErrors("Save failed because of an error."));
        }
      }
      setTimeout(() => thunkAPI.dispatch(setErrors("")), 5000);

      return "error";
    }
  },
);

export const updateRole = createAsyncThunk(
  "roles/update",
  async (
    params: {
      role: { id: T.RoleId; changeset: T.RoleChangeset };
      myRoleId: T.RoleId;
      userIds?: T.UserId[];
      defaultValues?: T.DefaultFieldValue[];
    },
    thunkAPI,
  ) => {
    try {
      const { role, userIds, defaultValues } = params;
      const isAdmin = role.changeset.name.toLowerCase() === "admin";

      if (defaultValues) {
        await Api.updateRoleDefaultValues(role.id, defaultValues);
        if (params.myRoleId === role.id) {
          thunkAPI.dispatch(
            applicationInitializationSlice.actions.updateDefaultFieldValuesForRole(
              {
                roleId: role.id,
                defaultValues,
              },
            ),
          );
        }
      }

      if (!isAdmin) {
        await Api.updateRole(role.id, role.changeset);
      }

      if (userIds) {
        await Api.assignRoleUsers(role.id, { userIds });
      }

      await thunkAPI.dispatch(getRoles());

      return "success";
    } catch (error) {
      if (error instanceof Error) {
        newrelic.noticeError(error);
        if (error.name === "NotUniqueError") {
          thunkAPI.dispatch(
            setErrors(
              "Save failed because a role with this name already exists.",
            ),
          );
        } else if (error.message) {
          thunkAPI.dispatch(setErrors(error.message));
        } else {
          thunkAPI.dispatch(setErrors("Save failed because of an error."));
        }
      }
      setTimeout(() => thunkAPI.dispatch(setErrors("")), 5000);

      return "error";
    }
  },
);

export const deleteRoleById = createAsyncThunk(
  "roles/delete",
  async (roleId: T.RoleId, thunkAPI) => {
    await Api.deleteRole(roleId);
    thunkAPI.dispatch(getRoles());
  },
);

const roleSlice = createSlice({
  name: "Roles",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(deleteRoleById.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(getRoles.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(getRoles.fulfilled, (state, { payload }) => {
      state.roles = payload;
      state.loading = false;
    });
  },
  reducers: {
    setSort: (state, { payload }: PayloadAction<string>) => {
      let sortDir: string;

      if (payload === state.sortField) {
        if (state.sortDir === "asc") {
          sortDir = "desc";
        } else {
          sortDir = "asc";
        }
      } else {
        sortDir = "asc";
      }
      return {
        ...state,
        sortDir,
        sortField: payload,
      };
    },
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },
    setRoles: (state, { payload }: PayloadAction<T.Role[]>) => {
      state.roles = payload;
    },
  },
});

export const { setLoading, setErrors, setRoles, setSearchTerm, setSort } =
  roleSlice.actions;

export default roleSlice.reducer;

export const rolesSelector = (state: { roles: RolesState }) => state.roles;

export const filteredRolesSelector = createSelector(
  [
    (state: { users: UsersState }) => state.users.users,
    (state: { roles: RolesState }) => state.roles.roles,
    (state: { roles: RolesState }) => state.roles.searchTerm,
    (state: { roles: RolesState }) => state.roles.sortField,
    (state: { roles: RolesState }) => state.roles.sortDir,
  ],
  (users, roles, searchTerm, sortField, sortDir) => {
    const decoratedRoles = roles.map((role) => {
      return {
        ...role,
        users: users.filter((u) => u.roleId === role.id).length,
        isAdmin: role.name.toLowerCase() === "admin",

        hasProfitView: role.permissions.includes(
          "pricing-profit-margin-adjustments-view",
        ),

        hasApplicationScenariosView: role.permissions.includes(
          "application-scenarios-view",
        ),
        hasApplicationScenariosModifyClientScoped: role.permissions.includes(
          "application-scenarios-modify-client-scoped",
        ),
        hasApplicationScenariosModifyUserScoped: role.permissions.includes(
          "application-scenarios-modify-user-scoped",
        ),

        hasInvestorsCreate: role.permissions.includes("investors-create"),
        hasInvestorsEdit: role.permissions.includes("investors-edit"),
        hasInvestorsDelete: role.permissions.includes("investors-delete"),

        hasProductsCreate: role.permissions.includes("products-create"),
        hasProductsEdit: role.permissions.includes("products-edit"),
        hasProductsDelete: role.permissions.includes("products-delete"),

        hasFieldEnumLibraryModify: role.permissions.includes(
          "field-enum-library-modify",
        ),
        hasCalculationsModify: role.permissions.includes("calculations-modify"),

        hasDataTablesCreate: role.permissions.includes("data-tables-create"),
        hasDataTablesEdit: role.permissions.includes("data-tables-edit"),
        hasDataTablesDelete: role.permissions.includes("data-tables-delete"),

        hasRateSheetsCreate: role.permissions.includes("rate-sheets-create"),
        hasRateSheetsDelete: role.permissions.includes("rate-sheets-delete"),

        hasRulesCreate: role.permissions.includes("rules-create"),
        hasRulesEdit: role.permissions.includes("rules-edit"),
        hasRulesDelete: role.permissions.includes("rules-delete"),

        hasRolesCreate: role.permissions.includes("roles-create"),
        hasRolesEdit: role.permissions.includes("roles-edit"),
        hasRolesDelete: role.permissions.includes("roles-delete"),

        hasPricingProfilesCreate: role.permissions.includes(
          "pricing-profiles-create",
        ),
        hasPricingProfilesEdit: role.permissions.includes(
          "pricing-profiles-edit",
        ),
        hasPricingProfilesDelete: role.permissions.includes(
          "pricing-profiles-delete",
        ),

        loanPricingV1: role.permissions.includes("price-a-loan-v1-view"),
        loanPricingV2: role.permissions.includes("price-a-loan-v2-view"),

        hasUsersCreate: role.permissions.includes("users-create"),
        hasUsersEdit: role.permissions.includes("users-edit"),
        hasUsersEditEmail: role.permissions.includes("users-edit-email"),
        hasUsersDelete: role.permissions.includes("users-delete"),

        hasInvestorsMenuAccess: role.permissions.includes(
          "investors-menu-access",
        ),
        hasPricingProfileAccessAll: role.permissions.includes(
          "pricing-profiles-access-all",
        ),

        hasProductsMenuAccess: role.permissions.includes(
          "products-menu-access",
        ),
        hasFieldsMenuAccess: role.permissions.includes(
          "field-enum-library-menu-access",
        ),
        hasCalculationsMenuAccess: role.permissions.includes(
          "calculations-menu-access",
        ),
        hasDataTablesMenuAccess: role.permissions.includes(
          "data-tables-menu-access",
        ),
        hasRateSheetsMenuAccess: role.permissions.includes(
          "rate-sheets-menu-access",
        ),
        hasPricingProfilesMenuAccess: role.permissions.includes(
          "pricing-profiles-menu-access",
        ),
        hasRulesMenuAccess: role.permissions.includes("rules-menu-access"),
        hasRolesMenuAccess: role.permissions.includes("roles-menu-access"),
        hasUsersMenuAccess: role.permissions.includes("users-menu-access"),
        hasReleaseNotesMenuAccess: role.permissions.includes(
          "release-notes-menu-access",
        ),
        hasSupportMenuAccess: role.permissions.includes("support-menu-access"),
      };
    });

    const filtered: T.DecoratedRoleHeader[] = [];
    decoratedRoles.forEach((role: T.DecoratedRoleHeader) => {
      const { noNotQuoteMatches, quoteMatch, restMatch } = filterResults(
        searchTerm,
        [role.name || "", role.users?.toString() || ""],
      );

      if (noNotQuoteMatches && quoteMatch && restMatch) filtered.push(role);
    });

    let sorted = _.sortBy(filtered, [
      (o) => {
        const field = o[sortField as keyof T.DecoratedRoleHeader];
        return field && typeof field === "string" ? field.toLowerCase() : field;
      },
      (o) => o.name.toLowerCase(),
    ]);
    if (sortDir === "desc") {
      sorted = _.reverse(sorted);
    }
    return sorted;
  },
);

export const isAdminSelector = createSelector(
  [
    (state: { applicationInitialization: ApplicationInitializationState }) =>
      state.applicationInitialization.myRole,
  ],
  (myRole) => myRole?.name.toLowerCase() === "admin",
);

export const hasAdminSelector = createSelector(
  [
    (state: { applicationInitialization: ApplicationInitializationState }) =>
      state.applicationInitialization.assumableRoles,
  ],
  (assumableRoles) =>
    assumableRoles === null
      ? false
      : assumableRoles.some((r) => r.name.toLowerCase() === "admin"),
);

export function usePermissions() {
  const isAdmin = useSelector(isAdminSelector);
  const { myRole } = useSelector(nonNullApplicationInitializationSelector);

  const allowed = (requiredPermission?: T.PermissionKind) => {
    if (requiredPermission) {
      return myRole?.permissions.includes(requiredPermission);
    } else {
      return isAdmin;
    }
  };

  return allowed;
}

export function useVisibleFields() {
  const isAdmin = useSelector(isAdminSelector);
  const { myRole } = useSelector(nonNullApplicationInitializationSelector);

  const allowed = (requiredField?: T.FieldId) => {
    if (requiredField) {
      return myRole?.pricingVisibleFieldIds.includes(requiredField);
    } else {
      return isAdmin;
    }
  };

  return allowed;
}

export const profitView: T.PermissionKind =
  "pricing-profit-margin-adjustments-view";
export const applicationScenariosView: T.PermissionKind =
  "application-scenarios-view";
export const applicationScenariosModifyClientScoped: T.PermissionKind =
  "application-scenarios-modify-client-scoped";
export const applicationScenariosModifyUserScoped: T.PermissionKind =
  "application-scenarios-modify-user-scoped";
export const investorsCreate: T.PermissionKind = "investors-create";
export const investorsEdit: T.PermissionKind = "investors-edit";
export const investorsDelete: T.PermissionKind = "investors-delete";
export const productsCreate: T.PermissionKind = "products-create";
export const productsEdit: T.PermissionKind = "products-edit";
export const productsDelete: T.PermissionKind = "products-delete";
export const fieldEnumLibraryModify: T.PermissionKind =
  "field-enum-library-modify";
export const calculationsModify: T.PermissionKind = "calculations-modify";
export const dataTablesCreate: T.PermissionKind = "data-tables-create";
export const dataTablesEdit: T.PermissionKind = "data-tables-edit";
export const dataTablesDelete: T.PermissionKind = "data-tables-delete";
export const rateSheetsCreate: T.PermissionKind = "rate-sheets-create";
export const rateSheetsDelete: T.PermissionKind = "rate-sheets-delete";
export const rulesCreate: T.PermissionKind = "rules-create";
export const rulesEdit: T.PermissionKind = "rules-edit";
export const rulesDelete: T.PermissionKind = "rules-delete";
export const rolesCreate: T.PermissionKind = "roles-create";
export const rolesEdit: T.PermissionKind = "roles-edit";
export const rolesDelete: T.PermissionKind = "roles-delete";
export const usersCreate: T.PermissionKind = "users-create";
export const usersEdit: T.PermissionKind = "users-edit";
export const usersEditEmail: T.PermissionKind = "users-edit-email";
export const usersDelete: T.PermissionKind = "users-delete";
export const loanPricingV1MenuAccess: T.PermissionKind = "price-a-loan-v1-view";
export const loanPricingV2MenuAccess: T.PermissionKind = "price-a-loan-v2-view";
export const investorsMenuAccess: T.PermissionKind = "investors-menu-access";
export const productsMenuAccess: T.PermissionKind = "products-menu-access";
export const fieldsMenuAccess: T.PermissionKind =
  "field-enum-library-menu-access";
export const calculationsMenuAccess: T.PermissionKind =
  "calculations-menu-access";
export const dataTablesMenuAccess: T.PermissionKind = "data-tables-menu-access";
export const rateSheetsMenuAccess: T.PermissionKind = "rate-sheets-menu-access";
export const rulesMenuAccess: T.PermissionKind = "rules-menu-access";
export const rolesMenuAccess: T.PermissionKind = "roles-menu-access";
export const usersMenuAccess: T.PermissionKind = "users-menu-access";
export const releaseNotesMenuAccess: T.PermissionKind =
  "release-notes-menu-access";
export const supportMenuAccess: T.PermissionKind = "support-menu-access";
export const configurationView: T.PermissionKind = "configuration-view";
export const pricingProfilesMenuAccess: T.PermissionKind =
  "pricing-profiles-menu-access";
export const pricingProfilesCreate: T.PermissionKind =
  "pricing-profiles-create";
export const pricingProfilesEdit: T.PermissionKind = "pricing-profiles-edit";
export const pricingProfilesDelete: T.PermissionKind =
  "pricing-profiles-delete";
export const pricingProfilesAccessAll: T.PermissionKind =
  "pricing-profiles-access-all";
export const changePassword: T.PermissionKind = "change-password";

export const allPerms: T.PermissionKind[] = [
  applicationScenariosModifyClientScoped,
  applicationScenariosModifyUserScoped,
  applicationScenariosView,
  calculationsMenuAccess,
  calculationsModify,
  changePassword,
  dataTablesCreate,
  dataTablesDelete,
  dataTablesEdit,
  dataTablesMenuAccess,
  fieldEnumLibraryModify,
  fieldsMenuAccess,
  investorsCreate,
  investorsDelete,
  investorsEdit,
  investorsMenuAccess,
  loanPricingV1MenuAccess,
  loanPricingV2MenuAccess,
  pricingProfilesAccessAll,
  pricingProfilesCreate,
  pricingProfilesDelete,
  pricingProfilesEdit,
  pricingProfilesMenuAccess,
  productsCreate,
  productsDelete,
  productsEdit,
  productsMenuAccess,
  profitView,
  rateSheetsCreate,
  rateSheetsDelete,
  rateSheetsMenuAccess,
  releaseNotesMenuAccess,
  rolesCreate,
  rolesDelete,
  rolesEdit,
  rolesMenuAccess,
  rulesCreate,
  rulesDelete,
  rulesEdit,
  rulesMenuAccess,
  supportMenuAccess,
  usersCreate,
  usersDelete,
  usersEdit,
  usersEditEmail,
  usersMenuAccess,
];
