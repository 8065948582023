import styled from "styled-components";
import breakpoints from "design/subatomics/breakpoints";
import whitespace from "design/subatomics/whitespace";
import { fontWeights, styles } from "design/atoms/typography";

export const ProductResultsSummary = styled.div`
  justify-content: center;
  align-items: center;
  align-self: center;
  display: flex;

  > button {
    margin: 0 ${whitespace("less")};

    + span {
      margin: 0 ${whitespace("less")} 0 0;
    }
  }

  > span {
    justify-content: center;
    align-items: center;
    align-self: center;
    display: inline-flex;
    text-align: center;
  }

  .value {
    font-weight: ${fontWeights.boldest};
  }

  ${breakpoints.mobile} {
    text-align: center;
    > span {
      margin: ${whitespace("less")};

      > span {
        ${styles.smallText};
        margin-right: ${whitespace("least")};
      }
    }
  }

  ${breakpoints.desktop} {
    text-align: right;

    > span {
      margin: ${whitespace("less")};

      > span {
        ${styles.smallText};
        margin-right: ${whitespace("least")};
      }
    }
  }
`;
