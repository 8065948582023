import React, {
  ChangeEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "design/atoms/button";
import {
  PrimaryTitle,
  PrimaryTitleSub,
  ErrorText,
} from "design/atoms/typography";
import { ForgotPassword, Form, ActionButtons } from "./styles";
import TextInput from "design/atoms/text-input";
import {
  changePassword,
  changePasswordSelector,
} from "features/change-password";
import Icon from "design/atoms/icon";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

export default React.memo(
  ({ setOpen }: { setOpen: (value: SetStateAction<boolean>) => void }) => {
    const dispatch = useDispatch();
    const changePasswordState = useSelector(changePasswordSelector);
    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const passwordMismatch = newPassword !== confirmPassword;
    const saveDisabled =
      !currentPassword || !newPassword || !confirmPassword || passwordMismatch;
    let errorMessage;

    if (changePasswordState.error) {
      errorMessage = changePasswordState.error;
    } else if (passwordMismatch) {
      errorMessage = "New password and confirm password must match";
    }

    const save = () => {
      dispatch(
        changePassword({
          currentPassword,
          newPassword,
        }),
      );
    };

    const cancel = useCallback(() => {
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setOpen(false);
    }, [setCurrentPassword, setNewPassword, setConfirmPassword, setOpen]);

    useEffect(() => {
      if (changePasswordState.status) {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setTimeout(cancel, 4000);
      }
    }, [changePasswordState.status, cancel]);

    return (
      <ForgotPassword>
        <PrimaryTitle>Forgot Password</PrimaryTitle>
        <PrimaryTitleSub>
          {changePasswordState.status
            ? changePasswordState.status
            : "Enter your current password and the new password you would like to use."}
        </PrimaryTitleSub>

        {errorMessage && (
          <ErrorText>
            <Icon icon={faWarning} /> {errorMessage}
          </ErrorText>
        )}

        <Form>
          <TextInput
            inputProps={{
              type: "password",
              value: currentPassword,
              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                setCurrentPassword(e.target.value),
            }}
            label="Current Password"
          />

          <TextInput
            inputProps={{
              type: "password",
              value: newPassword,
              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                setNewPassword(e.target.value),
            }}
            label="New Password"
          />

          <TextInput
            inputProps={{
              type: "password",
              value: confirmPassword,
              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                setConfirmPassword(e.target.value),
            }}
            label="Confirm New Password"
          />

          <ActionButtons>
            <Button onClick={cancel}>Cancel</Button>

            <Button
              disabled={changePasswordState.loading || saveDisabled}
              onClick={save}
              isPrimary={true}
            >
              {changePasswordState.loading ? "Loading" : "Save"}
            </Button>
          </ActionButtons>
        </Form>
      </ForgotPassword>
    );
  },
);
