import _ from "lodash";
import { Map as IMap, Set as ISet } from "immutable";
import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Tabs,
  Tab,
  Typography,
} from "@material-ui/core";
import { useDrag, useDrop } from "react-dnd";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import * as Api from "api";
import {
  CalculatedFieldEditor,
  CalculatedFieldState,
  convertCalculatedFieldToState,
  convertStateToCalculatedField,
  newCalculatedFieldState,
  validateCalculatedField,
  validateCalculatedFieldState,
} from "./_components/calculated-field-editor";
import {
  DataTableLookupEditor,
  TableLookupState,
  newTableLookupState,
  convertTableLookupToState,
  convertStateToTableLookup,
  validateTableLookupState,
} from "../../design/organisms/data-table-lookup-editor";
import EnglishList from "design/atoms/english-list";
import UnloadPrompt from "design/atoms/unload-prompt";
import { Configuration, expandConfig } from "config";
import * as T from "types/engine-types";
import * as Execution from "features/execution";
import * as Formulas from "features/formulas";
import * as FormulasUtil from "features/formulas-util";
import {
  loadAppInit,
  expandedConfigSelector,
} from "features/application-initialization";
import {
  Setter,
  UiValidationError,
  enumerate,
  toMapById,
  useById,
  useGuardedSetter,
  useIMapSetter,
  useLazyMemo,
  useNonNullSetter,
  usePropertySetter,
  getErrorMessage,
} from "features/utils";
import {
  updateRole,
  rolesSelector,
  getRoles,
  usePermissions,
} from "features/roles";
import { DetailedInvalidRequest } from "api";
import { useSelector } from "react-redux";
import { useAppDispatch } from "features/store";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";

const useStyles = makeStyles((t) =>
  createStyles({
    container: {
      margin: t.spacing(2),
      overflow: "auto",
    },
  }),
);

export default function CalculationsPage() {
  const C = useStyles();
  const { myRole } = useSelector(nonNullApplicationInitializationSelector);

  const [domainValidationErrors, setDomainValidationErrors] = useState<{
    [key: number]: T.CalculationStageValidation;
  }>({});

  const [rolesToAdd, setRolesToAdd] = useState<{
    [key: string]: T.RoleId[];
  }>({});

  const dispatch = useAppDispatch();
  const config = useSelector(expandedConfigSelector);
  const savedConfig = config;
  const savedCalcStagesById = useById(savedConfig.calcStages);
  const calcStageIds = savedConfig.calcStages.map((s) => s.id);
  const rolesState = useSelector(rolesSelector);
  const { roles } = rolesState;

  const [calcStagesById, setCalcStagesById] = useState(() =>
    toMapById(savedConfig.calcStages),
  );
  const [selectedStageIdx, setSelectedStageIdx] = useState(0);

  const modifiedConfig = useMemo(
    () => expandConfig(getModifiedConfig(savedConfig, calcStagesById)),
    [savedConfig, calcStagesById],
  );
  const startingEnvironmentByStageId = useLazyMemo(
    (stageId: T.ExecutionStageId) =>
      Execution.predictExecutionEnvironment(modifiedConfig, "before", stageId),
    [modifiedConfig],
  );
  const isStageValidById = useMemo(
    () =>
      IMap(
        calcStageIds.map((stageId) => [
          stageId,
          validateStage(
            calcStagesById.get(stageId)!,
            startingEnvironmentByStageId.get(stageId)!,
            modifiedConfig,
          ) === null,
        ]),
      ),
    [
      calcStageIds,
      calcStagesById,
      startingEnvironmentByStageId,
      modifiedConfig,
    ],
  );
  const allValid = useMemo(
    () => isStageValidById.valueSeq().every((isValid) => isValid),
    [isStageValidById],
  );

  const isStageUnsavedById = useMemo(
    () =>
      IMap(
        calcStageIds.map((stageId) => [
          stageId,
          !_.isEqual(
            savedCalcStagesById.get(stageId)!.calculations,
            calcStagesById.get(stageId)!.calculations,
          ),
        ]),
      ),
    [calcStageIds, savedCalcStagesById, calcStagesById],
  );
  const anyUnsaved = useMemo(() => {
    return (
      Object.keys(rolesToAdd).length >= 1 ||
      isStageUnsavedById.valueSeq().some((isUnsaved) => isUnsaved)
    );
  }, [isStageUnsavedById, rolesToAdd]);

  const saveChanges = useCallback(() => {
    if (allValid) {
      (async () => {
        try {
          await Api.updateCalculations(
            modifiedConfig.stages
              .filter(
                (stage): stage is T.ExecutionStage.Calculations =>
                  stage.kind === "calculations",
              )
              .map(({ id, calculations }) => ({ oldId: id, id, calculations })),
          );

          Object.keys(rolesToAdd).forEach((calc) => {
            if (calc.includes("__REMOVE__")) {
              if (
                modifiedConfig.allFieldsById.find(
                  (field) => field.id === calc.replace("__REMOVE__", ""),
                )
              ) {
                const executeRoleUpdate: (roleId: T.RoleId) => void = (
                  roleId: T.RoleId,
                ) => {
                  const roleToEdit = roles.find((r) => r.id === roleId);

                  return (
                    roleToEdit &&
                    dispatch(
                      updateRole({
                        myRoleId: myRole.id,
                        role: {
                          id: roleToEdit && roleToEdit.id,
                          changeset: {
                            name: roleToEdit.name,
                            permissions: roleToEdit.permissions,
                            pricingVisibleFieldIds: _.without(
                              roleToEdit.pricingVisibleFieldIds,
                              calc.replace("__REMOVE__", "") as T.FieldId,
                            ),
                          },
                        },
                      }),
                    )
                  );
                };

                rolesToAdd[calc].reduce(
                  (p, x) => p.then(() => executeRoleUpdate(x)),
                  Promise.resolve(),
                );
              }
            } else if (calc.includes("__ADD__")) {
              if (
                modifiedConfig.allFieldsById.find(
                  (field) => field.id === calc.replace("__ADD__", ""),
                )
              ) {
                const executeRoleUpdate: (roleId: T.RoleId) => void = (
                  roleId: T.RoleId,
                ) => {
                  const roleToEdit = roles.find((r) => r.id === roleId);

                  return (
                    roleToEdit &&
                    dispatch(
                      updateRole({
                        myRoleId: myRole.id,
                        role: {
                          id: roleToEdit && roleToEdit.id,
                          changeset: {
                            name: roleToEdit.name,
                            permissions: roleToEdit.permissions,
                            pricingVisibleFieldIds: [
                              ...roleToEdit.pricingVisibleFieldIds,
                              calc.replace("__ADD__", "") as T.FieldId,
                            ],
                          },
                        },
                      }),
                    )
                  );
                };

                rolesToAdd[calc].reduce(
                  (p, x) => p.then(() => executeRoleUpdate(x)),
                  Promise.resolve(),
                );
              }
            }
          });

          // Refresh the roles because the new calcs and lookups will have been automatically added to them
          dispatch(getRoles());

          dispatch(loadAppInit());
        } catch (e) {
          newrelic.noticeError(getErrorMessage(e));
          if (
            e instanceof DetailedInvalidRequest &&
            e.errors.type === "calculation-stages"
          ) {
            console.dir(e);
            setDomainValidationErrors(e.errors.value);
          }
        }
      })();
    } else {
      alert(
        "Some fields have errors that must be corrected before your changes can be saved.",
      );
    }
  }, [
    allValid,
    modifiedConfig.stages,
    modifiedConfig.allFieldsById,
    rolesToAdd,
    dispatch,
    roles,
    myRole.id,
  ]);

  const handleTabSwitch = useCallback(
    (e, idx: React.SetStateAction<number>) => setSelectedStageIdx(idx),
    [],
  );
  const setCalcStageById = useIMapSetter(setCalcStagesById);
  const selectedStageId = calcStageIds[selectedStageIdx];
  const [tablesLoad] = Api.useDataTables();
  const hasPermission = usePermissions();
  const hasCalculationsModifyPerm = hasPermission("calculations-modify");

  if (tablesLoad.status === "loading") {
    return (
      <Box p={6}>
        <CircularProgress />
      </Box>
    );
  }

  if (tablesLoad.status === "error") {
    return <Box p={6}>Error loading data table list</Box>;
  }

  const tables = tablesLoad.value;

  return (
    <>
      <UnloadPrompt when={anyUnsaved} />
      <Paper className={C.container}>
        <Box display="flex" alignItems="center">
          <Tabs
            style={{ flex: "1" }}
            value={selectedStageIdx}
            onChange={handleTabSwitch}
          >
            {calcStageIds.map((stageId, stageIndex) => {
              const stage = calcStagesById.get(stageId)!;
              const unsaved = isStageUnsavedById.get(stageId)!;
              const error = !isStageValidById.get(stageId)!;

              return (
                <Tab
                  key={stageId}
                  label={
                    <Box display="flex">
                      {(error || stageIndex in domainValidationErrors) && (
                        <Box
                          mr={1}
                          display="flex"
                          alignItems="center"
                          title="Some fields on this tab have errors."
                          color="error.main"
                        >
                          <ErrorOutlineIcon fontSize="small" />
                        </Box>
                      )}
                      <Box
                        flex="1"
                        mr={1}
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {stage.name}
                        {unsaved && "*"}
                      </Box>
                    </Box>
                  }
                />
              );
            })}
          </Tabs>
          <Box px={1}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CheckIcon />}
              disabled={!anyUnsaved || !hasCalculationsModifyPerm}
              onClick={saveChanges}
            >
              {anyUnsaved ? "Save" : "Saved"}
            </Button>
          </Box>
        </Box>
        {calcStageIds.map((stageId) => {
          const stage = calcStagesById.get(stageId)!;

          let stageErrs: T.CalculationStageValidation = {
            id: [],
            calculations: {},
          };
          for (let i = 0; i < savedConfig.calcStages.length; ++i) {
            if (
              savedConfig.calcStages[i].id === stageId &&
              i in domainValidationErrors
            ) {
              stageErrs = domainValidationErrors[i];
            }
          }

          if (selectedStageId === stageId) {
            const startingEnv = startingEnvironmentByStageId.get(stageId);
            return (
              <CalcStageEditor
                key={stageId}
                stage={stage}
                domainValidationErrors={stageErrs}
                setStage={setCalcStageById.withKey(stageId)}
                startingEnvironment={startingEnv}
                tables={tables}
                config={modifiedConfig}
                rolesToAdd={rolesToAdd}
                setRolesToAdd={setRolesToAdd}
              />
            );
          }

          return null;
        })}
      </Paper>
    </>
  );
}

function getModifiedConfig(
  initialConfig: Configuration,
  calcStagesById: IMap<T.ExecutionStageId, T.ExecutionStage.Calculations>,
): T.EngineConfiguration {
  return {
    clientTimezone: initialConfig.clientTimezone,
    enumerations: [...initialConfig.enumTypes],
    rawEnumerations: [...initialConfig.rawEnumTypes],
    systemEnumerations: [...initialConfig.systemEnumTypes],
    rawProductFields: [...initialConfig.rawProductFields],
    rawCreditApplicationFields: [...initialConfig.rawCreditApplicationFields],
    rawPipelineOnlyFields: [...initialConfig.rawPipelineOnlyFields],
    productFields: [...initialConfig.productFields],
    creditApplicationFields: [...initialConfig.creditApplicationFields],
    pipelineOnlyFields: [...initialConfig.pipelineOnlyFields],
    systemProductFields: [...initialConfig.systemProductFields],
    systemCreditApplicationFields: [
      ...initialConfig.systemCreditApplicationFields,
    ],
    systemPipelineOnlyFields: [...initialConfig.systemPipelineOnlyFields],
    stages: initialConfig.stages.map((stage) => {
      if (calcStagesById.has(stage.id)) {
        return calcStagesById.get(stage.id)!;
      }

      return stage;
    }),
    settings: initialConfig.settings,
    roles: initialConfig.roles,
    pricingProfiles: initialConfig.pricingProfiles,
  };
}

function validateStage(
  stage: T.ExecutionStage.Calculations,
  startingEnvironment: ISet<T.FieldId>,
  config: Configuration,
): UiValidationError | null {
  if (!stage.name.trim()) {
    return new UiValidationError("Stage name is required");
  }

  const fieldsInStage: ISet<T.FieldId> = ISet.union(
    stage.calculations.map((c) =>
      Execution.getEnvironmentAdditionsForCalculation(c),
    ),
  );

  for (const [calc, calcIndex] of enumerate(stage.calculations)) {
    const env: ISet<T.FieldId> = startingEnvironment.concat(
      ISet.union(
        stage.calculations
          .slice(0, calcIndex)
          .map((c) => Execution.getEnvironmentAdditionsForCalculation(c)),
      ),
    );

    const err: UiValidationError | null = validateCalculation(
      calc,
      env,
      fieldsInStage,
      config,
    );

    if (err) {
      return err;
    }
  }

  return null;
}

function validateCalculation(
  calc: T.Calculation,
  environment: ISet<T.FieldId>,
  fieldsInStage: ISet<T.FieldId>,
  config: Configuration,
): UiValidationError | null {
  switch (calc.type) {
    case "field":
      return validateCalculatedField(
        calc.field,
        environment,
        fieldsInStage,
        config,
      );
    case "data-table-lookup":
      return validateTableLookup(
        calc.lookup,
        environment,
        fieldsInStage,
        config,
      );
  }
}

function validateTableLookup(
  lookup: T.DataTableLookup,
  environment: ISet<T.FieldId>,
  fieldsInStage: ISet<T.FieldId>,
  config: Configuration,
): UiValidationError | null {
  for (const pred of lookup.predicates) {
    if (pred.expression.kind !== "field-value") {
      throw new Error(
        "Unexpected expression inside data table column predicate: " +
          pred.expression.kind,
      );
    }

    const fieldId = pred.expression.fieldId;

    if (!environment.has(fieldId)) {
      const field = config.allFieldsById.get(fieldId);
      const fieldName = field?.name || "<Unknown Field>";

      const isInThisStage = fieldsInStage.has(fieldId);

      if (isInThisStage) {
        return new UiValidationError(
          "Table lookup uses the field " +
            fieldName +
            ", but that field is not available at this point in the calculations. " +
            "Try moving this calculation farther down the list so that " +
            fieldName +
            " is calculated first.",
        );
      }

      return new UiValidationError(
        "Table lookup uses the field " +
          fieldName +
          ", but that field is not available at this point in the calculations. " +
          "You may need to create this calculation in a different calculations tab.",
      );
    }
  }

  return null;
}

const CalcStageEditor = React.memo(
  ({
    stage,
    setStage,
    startingEnvironment,
    tables,
    config,
    domainValidationErrors,
    setRolesToAdd,
    rolesToAdd,
  }: {
    stage: T.ExecutionStage.Calculations;
    setStage: Setter<T.ExecutionStage.Calculations>;
    startingEnvironment: ISet<T.FieldId>;
    tables: T.DataTableHeader[];
    config: Configuration;
    domainValidationErrors: T.CalculationStageValidation;
    setRolesToAdd: React.Dispatch<
      React.SetStateAction<{
        [key: string]: T.RoleId[];
      }>
    >;
    rolesToAdd?: {
      [key: string]: T.RoleId[];
    };
  }) => {
    const setCalculations = usePropertySetter(setStage, "calculations");

    return (
      <>
        <Box m={3}>
          <Typography variant="h4">{stage.name}</Typography>
        </Box>
        <Box m={3}>{stage.description}</Box>
        <CalcList
          stageId={stage.id}
          calculations={stage.calculations}
          domainValidationErrors={domainValidationErrors}
          setCalculations={setCalculations}
          startingEnvironment={startingEnvironment}
          tables={tables}
          config={config}
          setRolesToAdd={setRolesToAdd}
          rolesToAdd={rolesToAdd}
        />
      </>
    );
  },
);

const CalcList = React.memo(
  ({
    stageId,
    calculations,
    setCalculations,
    startingEnvironment,
    tables,
    config,
    domainValidationErrors,
    setRolesToAdd,
    rolesToAdd,
  }: {
    stageId: T.ExecutionStageId;
    calculations: T.Calculation[];
    setCalculations: Setter<T.Calculation[]>;
    startingEnvironment: ISet<T.FieldId>;
    tables: T.DataTableHeader[];
    config: Configuration;
    domainValidationErrors: T.CalculationStageValidation;
    setRolesToAdd: React.Dispatch<
      React.SetStateAction<{
        [key: string]: T.RoleId[];
      }>
    >;
    rolesToAdd?: {
      [key: string]: T.RoleId[];
    };
  }) => {
    const hasPermission = usePermissions();
    const hasCalculationsModifyPerm = hasPermission("calculations-modify");
    const environments: ISet<T.FieldId>[] = useMemo(
      () =>
        calculations.map((calc, calcIndex) =>
          startingEnvironment.union(
            ISet.union(
              calculations
                .slice(0, calcIndex)
                .map((c) => Execution.getEnvironmentAdditionsForCalculation(c)),
            ),
          ),
        ),
      [calculations, startingEnvironment],
    );
    const fieldsInStage: ISet<T.FieldId> = useMemo(
      () =>
        ISet.union(
          calculations.map((c) =>
            Execution.getEnvironmentAdditionsForCalculation(c),
          ),
        ),
      [calculations],
    );
    const newFieldEnvironment = useMemo(
      () => startingEnvironment.union(fieldsInStage),
      [fieldsInStage, startingEnvironment],
    );
    const calcErrors = useMemo(
      () =>
        calculations.map((calc, calcIndex) =>
          validateCalculation(
            calc,
            environments[calcIndex],
            fieldsInStage,
            config,
          ),
        ),
      [calculations, environments, fieldsInStage, config],
    );

    const [newField, setNewField] = useState<CalculatedFieldState | null>(null);
    const setNonNullNewField = useNonNullSetter(setNewField);

    const [newTableLookup, setNewTableLookup] =
      useState<TableLookupState | null>(null);
    const setNonNullNewTableLookup = useNonNullSetter(setNewTableLookup);

    const [editingCalc, setEditingCalc] = useState<CalculationState | null>(
      null,
    );
    const setNonNullEditingCalc = useNonNullSetter(setEditingCalc);
    const [editingCalcIndex, setEditingCalcIndex] = useState<number | null>(
      null,
    );

    const openNewFieldDialog = useCallback(() => {
      setNewField(newCalculatedFieldState());
    }, []);
    const closeNewFieldDialog = useCallback(() => {
      setNewField(null);
    }, []);
    const addField = useCallback(
      (newField: T.CalculatedFieldDefinition) => {
        setCalculations((oldCalcs) => [
          ...oldCalcs,
          { type: "field", field: newField },
        ]);

        closeNewFieldDialog();
      },
      [setCalculations, closeNewFieldDialog],
    );

    const openNewTableLookupDialog = useCallback(() => {
      setNewTableLookup(newTableLookupState());
    }, []);
    const closeNewTableLookupDialog = useCallback(() => {
      setNewTableLookup(null);
    }, []);
    const addTableLookup = useCallback(
      (newTableLookup: T.DataTableLookup) => {
        setCalculations((oldCalcs) => [
          ...oldCalcs,
          { type: "data-table-lookup", lookup: newTableLookup },
        ]);

        closeNewTableLookupDialog();
      },
      [setCalculations, closeNewTableLookupDialog],
    );

    const openEditCalcDialog = useLazyMemo(
      (calcIndex: number) => () => {
        const calculationErrs =
          calcIndex in domainValidationErrors.calculations
            ? domainValidationErrors.calculations[calcIndex]
            : null;
        setEditingCalcIndex(calcIndex);
        setEditingCalc(
          convertCalculationToState(
            config,
            calculations[calcIndex],
            calculationErrs,
          ),
        );
      },
      [calculations, config, domainValidationErrors],
    );
    const closeEditCalcDialog = useCallback(() => {
      setEditingCalcIndex(null);
      setEditingCalc(null);
    }, []);
    const saveCalc = useCallback(
      (editedCalc: T.Calculation) => {
        setCalculations((oldCalcs) => {
          const newCalcs = [...oldCalcs];
          newCalcs[editingCalcIndex!] = editedCalc;
          return newCalcs;
        });

        closeEditCalcDialog();
      },
      [setCalculations, editingCalcIndex, closeEditCalcDialog],
    );

    const deleteCalcByIndex = useLazyMemo(
      (calcIndex: number) => () => {
        setCalculations((oldCalcs) => {
          const newCalcs = [...oldCalcs];
          newCalcs.splice(calcIndex, 1);
          return newCalcs;
        });
      },
      [setCalculations],
    );

    const [dragIndex, setDragIndex] = useState<number | null>(null);
    const dragIndexSetters = useLazyMemo(
      (dragIndex: number | null) => () => setDragIndex(dragIndex),
      [],
    );

    const [dropIndex, setDropIndex] = useState<number | null>(null);
    const dropIndexSetters = useLazyMemo(
      (dropIndex: number | null) => () => setDropIndex(dropIndex),
      [],
    );

    const dropHandler = useCallback(() => {
      if (dragIndex !== null && dropIndex !== null) {
        setCalculations((calcs) => {
          const newCalcs = [...calcs];
          newCalcs.splice(dropIndex, 0, newCalcs.splice(dragIndex, 1)[0]);
          return newCalcs;
        });
      }

      setDragIndex(null);
      setDropIndex(null);
    }, [dragIndex, dropIndex, setCalculations]);

    return (
      <Box px={3} pb={3}>
        {calculations.map((calc, calcIndex) => {
          if (calc.type === "field") {
            return (
              <CalcFieldItem
                key={calcIndex}
                field={calc.field}
                uiValidationError={calcErrors[calcIndex]}
                hasDomainValidationErrors={
                  calcIndex in domainValidationErrors.calculations
                }
                config={config}
                showEditor={openEditCalcDialog.get(calcIndex)}
                showDropTargetAbove={
                  dragIndex !== null &&
                  calcIndex === dropIndex &&
                  calcIndex < dragIndex
                }
                showDropTargetBelow={
                  dragIndex !== null &&
                  calcIndex === dropIndex &&
                  calcIndex >= dragIndex
                }
                onDragBegin={dragIndexSetters.get(calcIndex)}
                onDragEnd={dropHandler}
                onDropHover={dropIndexSetters.get(calcIndex)}
                deleteField={deleteCalcByIndex.get(calcIndex)}
              />
            );
          }

          return (
            <TableLookupItem
              key={calcIndex}
              lookup={calc.lookup}
              uiValidationError={calcErrors[calcIndex]}
              hasDomainValidationErrors={
                calcIndex in domainValidationErrors.calculations
              }
              tables={tables}
              config={config}
              showEditor={openEditCalcDialog.get(calcIndex)}
              showDropTargetAbove={
                dragIndex !== null &&
                calcIndex === dropIndex &&
                calcIndex < dragIndex
              }
              showDropTargetBelow={
                dragIndex !== null &&
                calcIndex === dropIndex &&
                calcIndex >= dragIndex
              }
              onDragBegin={dragIndexSetters.get(calcIndex)}
              onDragEnd={dropHandler}
              onDropHover={dropIndexSetters.get(calcIndex)}
              deleteLookup={deleteCalcByIndex.get(calcIndex)}
            />
          );
        })}
        <Box pt={3}>
          <Button
            disabled={!hasCalculationsModifyPerm}
            onClick={openNewFieldDialog}
          >
            New Field
          </Button>
          <Button
            onClick={openNewTableLookupDialog}
            disabled={!hasCalculationsModifyPerm}
          >
            New Data Table Lookup
          </Button>
        </Box>

        {newField && (
          <NewFieldDialog
            state={newField}
            setState={setNonNullNewField}
            addField={addField}
            close={closeNewFieldDialog}
            fieldsInStage={fieldsInStage}
            environment={newFieldEnvironment}
            config={config}
            rolesToAdd={rolesToAdd}
            setRolesToAdd={setRolesToAdd}
          />
        )}

        {newTableLookup && (
          <NewTableLookupDialog
            state={newTableLookup}
            setState={setNonNullNewTableLookup}
            addTableLookup={addTableLookup}
            close={closeNewTableLookupDialog}
            fieldsInStage={fieldsInStage}
            environment={newFieldEnvironment}
            config={config}
          />
        )}

        {editingCalcIndex !== null && editingCalc !== null && (
          <EditCalcDialog
            state={editingCalc}
            setState={setNonNullEditingCalc}
            save={saveCalc}
            close={closeEditCalcDialog}
            fieldsInStage={fieldsInStage}
            environment={environments[editingCalcIndex]}
            config={config}
            rolesToAdd={rolesToAdd}
            setRolesToAdd={setRolesToAdd}
          />
        )}
      </Box>
    );
  },
);

type CalculationState = FieldCalculationState | TableLookupCalculationState;
type FieldCalculationState = { type: "field"; field: CalculatedFieldState };
type TableLookupCalculationState = {
  type: "data-table-lookup";
  lookup: TableLookupState;
};

function convertCalculationToState(
  config: Configuration,
  calc: T.Calculation,
  domainValidationErrors: T.CalculationValidation | null,
): CalculationState {
  switch (calc.type) {
    case "field":
      const fieldErrs =
        domainValidationErrors !== null &&
        domainValidationErrors.type === "field"
          ? domainValidationErrors.field
          : { id: [], name: [] };

      return {
        type: "field",
        field: convertCalculatedFieldToState(config, calc.field, fieldErrs),
      };
    case "data-table-lookup":
      const lookupErrs =
        domainValidationErrors !== null &&
        domainValidationErrors.type === "data-table-lookup"
          ? domainValidationErrors.lookup
          : { fields: {} };

      return {
        type: "data-table-lookup",
        lookup: convertTableLookupToState(calc.lookup, lookupErrs),
      };
  }
}

function convertStateToCalculation(
  state: CalculationState,
  newFieldEnvironment: ISet<T.FieldId>,
  fieldsInStage: ISet<T.FieldId>,
  config: Configuration,
): T.Calculation {
  switch (state.type) {
    case "field":
      return {
        type: "field",
        field: convertStateToCalculatedField(
          state.field,
          newFieldEnvironment,
          fieldsInStage,
          config,
        ),
      };
    case "data-table-lookup":
      return {
        type: "data-table-lookup",
        lookup: convertStateToTableLookup(
          state.lookup,
          newFieldEnvironment,
          config,
        ),
      };
  }
}

const useCalcFieldItemStyles = makeStyles((t) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "start",
      cursor: "pointer",
      minHeight: 32,
      fontSize: "1rem",
      "&:hover": {
        background: "#eee",
      },
      "&.drag": {
        transition: "0.2s ease-out height",
        height: 0,
        opacity: 0,
      },
    },
    dropTarget: {
      overflow: "hidden",
      height: 0,
      transition: "0.2s ease-out height",
      "&.drop": {
        height: 32,
      },
    },
    errorIndicator: {
      display: "flex",
      padding: "4px 8px 4px 4px",
      color: "hsl(4.1, 89.6%, 58.4%)", // palette: error.main
    },
    dragHandle: {
      display: "flex",
      padding: "4px 12px 4px 4px",
      cursor: "grab",
    },
    fieldName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 300,
    },
    valueType: {
      padding: "2px 8px 0 4px",
      color: "#666",
    },
    equals: {
      fontFamily: "'Roboto Mono', monospace",
      padding: t.spacing(0, 1),
      paddingTop: 2,
    },
    formula: {
      display: "flex",
      fontFamily: "'Roboto Mono', monospace",
      paddingTop: 4,
      paddingBottom: 4,
      paddingRight: 4,
    },
  }),
);

const CalcFieldItem = React.memo(
  ({
    field,
    uiValidationError,
    hasDomainValidationErrors,
    config,
    showEditor,
    showDropTargetAbove,
    showDropTargetBelow,
    onDragBegin,
    onDragEnd,
    onDropHover,
    deleteField,
  }: {
    field: T.CalculatedFieldDefinition;
    uiValidationError: UiValidationError | null;
    hasDomainValidationErrors: boolean;
    config: Configuration;
    showEditor: () => void;
    showDropTargetAbove: boolean;
    showDropTargetBelow: boolean;
    onDragBegin: () => void;
    onDragEnd: () => void;
    onDropHover: () => void;
    deleteField: () => void;
  }) => {
    const C = useCalcFieldItemStyles();
    const hasPermission = usePermissions();
    const hasCalculationsModifyPerm = hasPermission("calculations-modify");

    const dragDropRef = useRef<HTMLDivElement>(null);
    const [{ isDragging }, dragRef] = useDrag({
      item: {
        type: "calculation",
        fieldId: field.id,
      },
      begin(monitor) {
        onDragBegin();
      },
      end(item, monitor) {
        onDragEnd();
      },
      collect(monitor) {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    });
    const [, dropRef] = useDrop({
      accept: "calculation",
      hover(item, monitor) {
        onDropHover();
      },
    });
    dragRef(dropRef(dragDropRef));

    const identifier = FormulasUtil.getFieldIdentifier({
      ...field,
      description: null,
    });

    const handleDeleteClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        if (event) {
          event.stopPropagation();
          deleteField();
        }
      },
      [deleteField],
    );

    return (
      <>
        <div
          className={C.dropTarget + (showDropTargetAbove ? " drop" : "")}
        ></div>
        <div
          ref={dragDropRef}
          className={C.container + (isDragging ? " drag" : "")}
          onClick={showEditor}
        >
          <Box display="flex" py="8px">
            <div
              className={C.errorIndicator}
              style={{
                opacity: uiValidationError || hasDomainValidationErrors ? 1 : 0,
              }}
              title={uiValidationError?.message || undefined}
            >
              <ErrorOutlineIcon />
            </div>
            <div className={C.dragHandle}>
              <DragIndicatorIcon />
            </div>
            <div className={C.formula}>
              <div className={C.fieldName} title={field.name}>
                {identifier}
              </div>
              <Box px="8px"> = </Box>
              <Box flex="1">
                {Formulas.irToFormula(config, field.expression)}
              </Box>
            </div>
          </Box>
          <Box flex="1" />
          <IconButton
            disabled={!hasCalculationsModifyPerm}
            onClick={handleDeleteClick}
          >
            <DeleteIcon />
          </IconButton>
        </div>
        <div
          className={C.dropTarget + (showDropTargetBelow ? " drop" : "")}
        ></div>
      </>
    );
  },
);

const TableLookupItem = React.memo(
  ({
    lookup,
    uiValidationError,
    hasDomainValidationErrors,
    tables,
    config,
    showEditor,
    showDropTargetAbove,
    showDropTargetBelow,
    onDragBegin,
    onDragEnd,
    onDropHover,
    deleteLookup,
  }: {
    lookup: T.DataTableLookup;
    uiValidationError: UiValidationError | null;
    hasDomainValidationErrors: boolean;
    tables: T.DataTableHeader[];
    config: Configuration;
    showEditor: () => void;
    showDropTargetAbove: boolean;
    showDropTargetBelow: boolean;
    onDragBegin: () => void;
    onDragEnd: () => void;
    onDropHover: () => void;
    deleteLookup: () => void;
  }) => {
    const C = useCalcFieldItemStyles();
    const hasPermission = usePermissions();
    const hasCalculationsModifyPerm = hasPermission("calculations-modify");
    const dragDropRef = useRef<HTMLDivElement>(null);
    const [{ isDragging }, dragRef] = useDrag({
      item: {
        type: "calculation",
        lookupId: lookup.id,
      },
      begin(monitor) {
        onDragBegin();
      },
      end(item, monitor) {
        onDragEnd();
      },
      collect(monitor) {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    });
    const [, dropRef] = useDrop({
      accept: "calculation",
      hover(item, monitor) {
        onDropHover();
      },
    });
    dragRef(dropRef(dragDropRef));

    const table = tables.find((t) => t.id === lookup.tableId);

    const handleDeleteClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        if (event) {
          event.stopPropagation();
          deleteLookup();
        }
      },
      [deleteLookup],
    );

    return (
      <>
        <div
          className={C.dropTarget + (showDropTargetAbove ? " drop" : "")}
        ></div>
        <div
          ref={dragDropRef}
          className={C.container + (isDragging ? " drag" : "")}
          onClick={showEditor}
        >
          <Box display="flex" py="8px">
            <div
              className={C.errorIndicator}
              style={{
                opacity: uiValidationError || hasDomainValidationErrors ? 1 : 0,
              }}
              title={uiValidationError?.message || undefined}
            >
              <ErrorOutlineIcon />
            </div>
            <div className={C.dragHandle}>
              <DragIndicatorIcon />
            </div>
            <Box alignSelf="center">
              Data Table Lookup on [{table?.name || "<Unknown Data Table>"}]
              with {lookup.fields.length === 1 ? "output " : "outputs "}
              <EnglishList items={lookup.fields.map((f) => `[${f.name}]`)} />
            </Box>
          </Box>
          <Box flex="1" />
          <IconButton
            disabled={!hasCalculationsModifyPerm}
            onClick={handleDeleteClick}
          >
            <DeleteIcon />
          </IconButton>
        </div>
        <div
          className={C.dropTarget + (showDropTargetBelow ? " drop" : "")}
        ></div>
      </>
    );
  },
);

const NewFieldDialog = React.memo(
  ({
    state,
    setState,
    addField,
    close,
    environment,
    fieldsInStage,
    config,
    setRolesToAdd,
    rolesToAdd,
  }: {
    state: CalculatedFieldState;
    setState: Setter<CalculatedFieldState>;
    addField: (field: T.CalculatedFieldDefinition) => void;
    close: () => void;
    environment: ISet<T.FieldId>;
    fieldsInStage: ISet<T.FieldId>;
    config: Configuration;
    setRolesToAdd: React.Dispatch<
      React.SetStateAction<{
        [key: string]: T.RoleId[];
      }>
    >;
    rolesToAdd?: {
      [key: string]: T.RoleId[];
    };
  }) => {
    const uiValidationError = validateCalculatedFieldState(
      state,
      environment,
      fieldsInStage,
      config,
    );

    const [originalState] = useState(state);

    const handleCreate = useCallback(() => {
      try {
        addField(
          convertStateToCalculatedField(
            state,
            environment,
            fieldsInStage,
            config,
          ),
        );
      } catch (err) {
        if (err instanceof UiValidationError) {
          newrelic.noticeError(err);
          alert(err.message);
          return;
        }

        throw err;
      }
    }, [state, environment, fieldsInStage, config, addField]);

    // Allow Escape and click on background to close dialog when no changes
    // made yet.
    const softClose = useCallback(() => {
      if (state === originalState) {
        close();
      }
    }, [originalState, state, close]);

    return (
      <Dialog open={true} maxWidth="xl" fullWidth onClose={softClose}>
        <DialogTitle>Create a calculated field</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter a field name and formula:</DialogContentText>
          <CalculatedFieldEditor
            state={state}
            setState={setState}
            fieldsInStage={fieldsInStage}
            environment={environment}
            config={config}
            rolesToAdd={rolesToAdd}
            setRolesToAdd={setRolesToAdd}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Cancel</Button>
          <Button
            color="primary"
            disabled={!!uiValidationError}
            title={uiValidationError?.message}
            onClick={handleCreate}
          >
            Create field
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

const NewTableLookupDialog = React.memo(
  ({
    state,
    setState,
    addTableLookup,
    close,
    environment,
    fieldsInStage,
    config,
  }: {
    state: TableLookupState;
    setState: Setter<TableLookupState>;
    addTableLookup: (lookup: T.DataTableLookup) => void;
    close: () => void;
    environment: ISet<T.FieldId>;
    fieldsInStage: ISet<T.FieldId>;
    config: Configuration;
  }) => {
    const validationError = validateTableLookupState(
      state,
      environment,
      config,
    );

    const [originalState] = useState(state);

    const handleCreate = useCallback(() => {
      if (validationError) {
        alert(
          validationError.message ||
            "Some fields have missing or incorrect data",
        );
        return;
      }

      try {
        addTableLookup(convertStateToTableLookup(state, environment, config));
      } catch (err) {
        if (err instanceof UiValidationError) {
          newrelic.noticeError(err);
          alert(err.message);
          return;
        }

        throw err;
      }
    }, [state, environment, config, addTableLookup, validationError]);

    // Allow Escape and click on background to close dialog when no changes
    // made yet.
    const softClose = useCallback(() => {
      if (state === originalState) {
        close();
      }
    }, [originalState, state, close]);

    return (
      <Dialog open={true} maxWidth="lg" fullWidth onClose={softClose}>
        <DialogTitle>Create data table lookup</DialogTitle>
        <DialogContent>
          <DataTableLookupEditor
            state={state}
            setState={setState}
            environment={environment}
            config={config}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Cancel</Button>
          <Button color="primary" onClick={handleCreate}>
            Create field
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

const EditCalcDialog = React.memo(
  ({
    state,
    setState,
    save,
    close,
    environment,
    fieldsInStage,
    config,
    setRolesToAdd,
    rolesToAdd,
  }: {
    state: CalculationState;
    setState: Setter<CalculationState>;
    save: (c: T.Calculation) => void;
    close: () => void;
    environment: ISet<T.FieldId>;
    fieldsInStage: ISet<T.FieldId>;
    config: Configuration;
    setRolesToAdd: React.Dispatch<
      React.SetStateAction<{
        [key: string]: T.RoleId[];
      }>
    >;
    rolesToAdd?: {
      [key: string]: T.RoleId[];
    };
  }) => {
    const setFieldState = useGuardedSetter(
      setState,
      (c): c is FieldCalculationState => c.type === "field",
      [],
    );
    const setField = usePropertySetter(setFieldState, "field");

    const setTableLookupState = useGuardedSetter(
      setState,
      (c): c is TableLookupCalculationState => c.type === "data-table-lookup",
      [],
    );
    const setTableLookup = usePropertySetter(setTableLookupState, "lookup");

    const saveCalc = useCallback(
      () =>
        save(
          convertStateToCalculation(state, environment, fieldsInStage, config),
        ),
      [save, state, environment, fieldsInStage, config],
    );

    switch (state.type) {
      case "field":
        return (
          <EditFieldDialog
            state={state.field}
            setState={setField}
            save={saveCalc}
            close={close}
            fieldsInStage={fieldsInStage}
            environment={environment}
            config={config}
            setRolesToAdd={setRolesToAdd}
            rolesToAdd={rolesToAdd}
          />
        );
      case "data-table-lookup":
        return (
          <EditTableLookupDialog
            state={state.lookup}
            setState={setTableLookup}
            save={saveCalc}
            close={close}
            fieldsInStage={fieldsInStage}
            environment={environment}
            config={config}
          />
        );
    }
  },
);

const EditFieldDialog = React.memo(
  ({
    state,
    setState,
    save,
    close,
    environment,
    fieldsInStage,
    config,
    setRolesToAdd,
    rolesToAdd,
  }: {
    state: CalculatedFieldState;
    setState: Setter<CalculatedFieldState>;
    save: () => void;
    close: () => void;
    environment: ISet<T.FieldId>;
    fieldsInStage: ISet<T.FieldId>;
    config: Configuration;
    setRolesToAdd: React.Dispatch<
      React.SetStateAction<{
        [key: string]: T.RoleId[];
      }>
    >;
    rolesToAdd?: {
      [key: string]: T.RoleId[];
    };
  }) => {
    const validationError = validateCalculatedFieldState(
      state,
      environment,
      fieldsInStage,
      config,
    );
    const hasPermission = usePermissions();
    const hasCalculationsModifyPerm = hasPermission("calculations-modify");
    const [originalState] = useState(state);

    // Allow Escape and click on background to close dialog when no changes
    // made yet.
    const softClose = useCallback(() => {
      if (state === originalState) {
        close();
      }
    }, [originalState, state, close]);

    return (
      <Dialog open={true} maxWidth="xl" fullWidth onClose={softClose}>
        <DialogTitle>Edit calculated field</DialogTitle>

        <DialogContent>
          <DialogContentText>Enter a field name and formula:</DialogContentText>
          <CalculatedFieldEditor
            state={state}
            setState={setState}
            fieldsInStage={fieldsInStage}
            environment={environment}
            config={config}
            rolesToAdd={rolesToAdd}
            setRolesToAdd={setRolesToAdd}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Cancel</Button>
          <Button
            color="primary"
            disabled={!!validationError || !hasCalculationsModifyPerm}
            title={validationError?.message}
            onClick={save}
          >
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

const EditTableLookupDialog = React.memo(
  ({
    state,
    setState,
    save,
    close,
    environment,
    fieldsInStage,
    config,
  }: {
    state: TableLookupState;
    setState: Setter<TableLookupState>;
    save: () => void;
    close: () => void;
    environment: ISet<T.FieldId>;
    fieldsInStage: ISet<T.FieldId>;
    config: Configuration;
  }) => {
    const validationError = validateTableLookupState(
      state,
      environment,
      config,
    );

    const [originalState] = useState(state);

    // Allow Escape and click on background to close dialog when no changes
    // made yet.
    const softClose = useCallback(() => {
      if (state === originalState) {
        close();
      }
    }, [originalState, state, close]);

    return (
      <Dialog open={true} maxWidth="lg" fullWidth onClose={softClose}>
        <DialogTitle>Edit data table lookup</DialogTitle>
        <DialogContent>
          <DataTableLookupEditor
            state={state}
            setState={setState}
            environment={environment}
            config={config}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={close}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => {
              if (validationError) {
                alert(
                  validationError.message ||
                    "Some fields have missing or incorrect data",
                );
              } else {
                save();
              }
            }}
          >
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
