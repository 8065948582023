import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  TextField,
  Popover,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
  FormHelperText,
} from "@material-ui/core";
import * as Ta from "design/organisms/table";
import SearchInput from "design/atoms/search-input";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import DetailActions from "design/molecules/detail-actions";
import ArrowNextIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import FilterListIcon from "@material-ui/icons/FilterList";
import * as T from "types/engine-types";
import { useDispatch, useSelector } from "react-redux";
import {
  loadScenarios,
  loansSelector,
  setOpenProduct,
  loanStatusToString,
} from "features/loans";
import {
  resetFiltersToNone,
  summarySelector,
  filteredSummaryProductsSelector,
  uniqueStatusSelector,
  uniqueLockSelector,
  uniqueInvestorNamesSelector,
  isFilteringByInvestorNameSelector,
  isFilteringByRateLockPeriodSelector,
  isFilteringByStatusSelector,
  setSearchTerm,
  setSort,
  setStatusIncluded,
  setInvestorNameIncluded,
  setMinRate,
  setMaxRate,
  setMinPrice,
  setMaxPrice,
  setRateLockPeriodIncluded,
} from "features/pricing-summaries";
import { durationValueToString } from "features/fields";
import { expandedConfigSelector } from "features/application-initialization";
import ProductResultsCount from "design/atoms/product-results-count";
import { useParams } from "react-router-dom";

type Params = {
  productId: string;
  pricingScenarioId: string;
  pricingScenarioRate: string;
  pricingScenarioLock: string;
};

const ProductResultsStatusBar = React.memo(
  ({
    totals,
    onBack,
  }: {
    totals: T.ExecutionSummaryTotals;
    onBack?: () => void;
  }) => {
    const params = useParams<Params>();
    const dispatch = useDispatch();
    const { scenarios, openProduct } = useSelector(loansSelector);
    const summaryState = useSelector(summarySelector);
    const summaryProducts = useSelector(filteredSummaryProductsSelector);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [statusFilterEl, setStatusFilterAnchorEl] = React.useState<
      (EventTarget & Element) | null
    >(null);
    const [investorNameFilterEl, setInvestorNameFilterAnchorEl] =
      React.useState<(EventTarget & Element) | null>(null);
    const [priceFilterEl, setPriceFilterAnchorEl] = React.useState<
      (EventTarget & Element) | null
    >(null);
    const [rateFilterEl, setRateFilterAnchorEl] = React.useState<
      (EventTarget & Element) | null
    >(null);
    const [lockFilterEl, setLockFilterAnchorEl] = React.useState<
      (EventTarget & Element) | null
    >(null);
    const statusOptions = useSelector(uniqueStatusSelector);
    const lockOptions = useSelector(uniqueLockSelector);
    const investorNameOptions = useSelector(uniqueInvestorNamesSelector);
    const withApproved = summaryState?.summary?.products.some(
      (s) => s.status === "approved" || s.status === "review-required",
    );

    const config = useSelector(expandedConfigSelector);

    const { filters } = summaryState;
    const isFilteringByStatus = useSelector(isFilteringByStatusSelector);
    const isFilteringByInvestorName = useSelector(
      isFilteringByInvestorNameSelector,
    );
    const isFilteringByRateLockPeriod = useSelector(
      isFilteringByRateLockPeriodSelector,
    );
    const isFilteringByRate =
      filters.minRate !== null || filters.maxRate !== null;
    const isFilteringByPrice =
      filters.minPrice !== null || filters.maxPrice !== null;

    useEffect(() => {
      if (summaryProducts && openProduct) {
        setCurrentIndex(
          summaryProducts.findIndex((p) => p.productId === openProduct),
        );
      }
    }, [dispatch, summaryProducts, openProduct]);

    useEffect(() => {
      if (
        summaryState.summary?.products.some(
          (p) => p.status === "approved" || p.status === "review-required",
        ) &&
        (summaryState.sortField === "productName" ||
          summaryState.sortField === "investorName")
      ) {
        dispatch(setSort("adjustedRate"));
      } else if (
        summaryState.summary?.products.every(
          (p) => p.status !== "approved" && p.status !== "review-required",
        ) &&
        (summaryState.sortField === "adjustedRate" ||
          summaryState.sortField === "adjustedPrice" ||
          summaryState.sortField === "arlp")
      ) {
        dispatch(setSort("productName"));
      }
      // do not recompute on sort set
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config, dispatch, openProduct, summaryState.summary]);

    useEffect(() => {
      if (scenarios.clientScoped === null || scenarios.userScoped === null) {
        dispatch(loadScenarios());
      }
    }, [dispatch, scenarios]);

    return (
      <>
        <Ta.TableActions>
          {!onBack && (
            <div style={{ alignSelf: "flex-start", flex: "1 1 auto" }}>
              <SearchInput
                label="products"
                searchTerm={summaryState.searchTerm}
                setSearchTerm={setSearchTerm}
              />
              <Button
                style={{
                  marginTop: "-2px",
                  textTransform: "capitalize",
                }}
                data-selector="clear-column-filters"
                variant="text"
                color="primary"
                onClick={() => dispatch(resetFiltersToNone())}
              >
                Clear Column Filters
              </Button>
            </div>
          )}

          {onBack && (
            <DetailActions
              style={{ flex: "1 1 auto", justifyContent: "flex-start" }}
            >
              <Button
                variant="outlined"
                data-selector="back-to-loan-products"
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  onBack();
                }}
              >
                Back to Loan Products ({summaryProducts.length})
              </Button>

              <Button
                variant="outlined"
                data-selector="previous-loan-product"
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  if (summaryProducts[currentIndex - 1].productId)
                    dispatch(
                      setOpenProduct(
                        summaryProducts[currentIndex - 1].productId,
                      ),
                    );
                }}
                disabled={!!!summaryProducts[currentIndex - 1]}
              >
                Previous Loan Product
              </Button>

              <Button
                variant="outlined"
                data-selector="next-loan-product"
                endIcon={<ArrowNextIcon />}
                onClick={() => {
                  if (summaryProducts[currentIndex + 1].productId)
                    dispatch(
                      setOpenProduct(
                        summaryProducts[currentIndex + 1].productId,
                      ),
                    );
                }}
                disabled={!!!summaryProducts[currentIndex + 1]}
              >
                Next Loan Product
              </Button>
            </DetailActions>
          )}

          <ProductResultsCount showCounts={!params.productId} />
        </Ta.TableActions>

        {!onBack && (
          <Ta.TableHeader>
            <Ta.TableHeaderCell style={{ flexBasis: "10%" }}>
              <span>Status</span>
              <Tooltip
                title={
                  isFilteringByStatus
                    ? "Filtering by Status. Click to view or modify Status filters."
                    : "Click to filter by Status"
                }
              >
                <FilterListIcon
                  data-selector="status-filter-icon"
                  style={{
                    color: isFilteringByStatus ? "#90d18c" : "inherit",
                    marginLeft: "2px",
                  }}
                  onClick={(e) => setStatusFilterAnchorEl(e.currentTarget)}
                />
              </Tooltip>
              <Popover
                open={!!statusFilterEl}
                anchorEl={statusFilterEl}
                onClose={() => setStatusFilterAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <FormControl
                  style={{ padding: "0 16px 8px" }}
                  component="fieldset"
                >
                  <FormLabel
                    style={{ padding: "16px 0 8px" }}
                    component="legend"
                  >
                    Filter by Status
                  </FormLabel>
                  <FormGroup>
                    {statusOptions.map(
                      (status: T.ProductSummaryStatus["status"], i) => {
                        const checked =
                          !filters.statuses ||
                          filters.statuses.includes(status);
                        return (
                          <FormControlLabel
                            key={i}
                            label={loanStatusToString(status)}
                            control={
                              <Checkbox
                                data-selector="status-filter-checkbox"
                                checked={checked}
                                onChange={(e, newChecked) => {
                                  dispatch(
                                    setStatusIncluded({
                                      status,
                                      included: newChecked,
                                    }),
                                  );
                                }}
                              />
                            }
                          />
                        );
                      },
                    )}
                  </FormGroup>
                </FormControl>
              </Popover>
            </Ta.TableHeaderCell>

            <Ta.TableHeaderCell style={{ flexBasis: "12.5%" }}>
              <span>Next Required Field</span>
            </Ta.TableHeaderCell>

            <Ta.TableHeaderCell
              style={{ flexBasis: "30.5%" }}
              data-sorting-state={
                summaryState.sortField === "productName"
                  ? "sorting"
                  : "not-sorting"
              }
            >
              <span>Product Name</span>
              <SwapVertIcon
                data-selector="product-name-filter"
                style={{
                  marginLeft: "2px",
                  color:
                    summaryState.sortField === "productName"
                      ? "#90d18c"
                      : "inherit",
                }}
                onClick={() => {
                  if (summaryState.sortField === "productName") {
                    summaryState.sortDir === "asc"
                      ? dispatch(setSort("desc"))
                      : dispatch(setSort("asc"));
                  } else {
                    dispatch(setSort("productName"));
                  }
                }}
              />
            </Ta.TableHeaderCell>

            <Ta.TableHeaderCell
              style={{ flexBasis: "15%" }}
              data-sorting-state={
                summaryState.sortField === "investorName"
                  ? "sorting"
                  : "not-sorting"
              }
            >
              <span>Investor Name</span>
              <Tooltip
                title={
                  isFilteringByInvestorName
                    ? "Filtering by Investor Name. Click to view or modify Investor Name filters."
                    : "Click to filter by Investor Name"
                }
              >
                <>
                  <FilterListIcon
                    data-selector="investor-filter-icon"
                    style={{
                      color: isFilteringByInvestorName ? "#90d18c" : "inherit",
                      marginLeft: "2px",
                    }}
                    onClick={(e) =>
                      setInvestorNameFilterAnchorEl(e.currentTarget)
                    }
                  />
                </>
              </Tooltip>
              <Popover
                open={!!investorNameFilterEl}
                anchorEl={investorNameFilterEl}
                onClose={() => setInvestorNameFilterAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <FormControl
                  style={{ padding: "0 16px 8px" }}
                  component="fieldset"
                >
                  <FormLabel
                    style={{ padding: "16px 0 8px" }}
                    component="legend"
                  >
                    Filter by Investor Name
                  </FormLabel>
                  <FormGroup>
                    {investorNameOptions.map((investorName, i) => {
                      if (!investorName) return false;
                      const checked =
                        !filters.investorNames ||
                        filters.investorNames.includes(investorName);
                      return (
                        <FormControlLabel
                          key={investorName}
                          style={{ textTransform: "capitalize" }}
                          label={investorName}
                          control={
                            <Checkbox
                              data-selector="investor-filter-checkbox"
                              checked={checked}
                              onChange={(e, newChecked) => {
                                dispatch(
                                  setInvestorNameIncluded({
                                    investorName,
                                    included: newChecked,
                                  }),
                                );
                              }}
                            />
                          }
                        />
                      );
                    })}
                  </FormGroup>
                </FormControl>
              </Popover>
              <SwapVertIcon
                data-selector="investor-name-filter"
                style={{
                  marginLeft: "2px",
                  color:
                    summaryState.sortField === "investorName"
                      ? "#90d18c"
                      : "inherit",
                }}
                onClick={() => {
                  if (summaryState.sortField === "investorName") {
                    summaryState.sortDir === "asc"
                      ? dispatch(setSort("desc"))
                      : dispatch(setSort("asc"));
                  } else {
                    dispatch(setSort("investorName"));
                  }
                }}
              />
            </Ta.TableHeaderCell>

            {withApproved && (
              <>
                <Ta.TableHeaderCell
                  style={{ flexBasis: "8.5%", textAlign: "right" }}
                  data-sorting-state={
                    summaryState.sortField === "adjustedPrice"
                      ? "sorting"
                      : "not-sorting"
                  }
                >
                  {withApproved && (
                    <Tooltip title="To arrive at our Best Ex valuation, we get pricing closest to 100 with the shortest lock. We present these products sorted by rate in ascending order with pricing in descending order as a secondary sort.">
                      <HelpOutlineIcon
                        style={{
                          marginRight: "4px",
                          fontSize: "14px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                    </Tooltip>
                  )}
                  <span>Price</span>
                  <Tooltip
                    title={
                      isFilteringByPrice
                        ? "Filtering by Price. Click to view or modify Price filters."
                        : "Click to filter by Price"
                    }
                  >
                    <FilterListIcon
                      data-selector="approved-price-filter-icon"
                      style={{
                        color: isFilteringByPrice ? "#90d18c" : "inherit",
                        marginLeft: "2px",
                      }}
                      onClick={(e) => setPriceFilterAnchorEl(e.currentTarget)}
                    />
                  </Tooltip>
                  <Popover
                    open={!!priceFilterEl}
                    anchorEl={priceFilterEl}
                    onClose={() => setPriceFilterAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <FormControl
                      style={{ padding: "0 16px 8px" }}
                      component="fieldset"
                    >
                      <FormLabel
                        style={{ padding: "16px 0 0" }}
                        component="legend"
                      >
                        Filter by Price
                      </FormLabel>
                      <FormHelperText style={{ paddingBottom: "8px" }}>
                        (Only affects approved products)
                      </FormHelperText>
                      <FormGroup>
                        <TextField
                          style={{ marginBottom: "16px" }}
                          value={filters.minPrice}
                          autoComplete="never"
                          name="priceMin"
                          label="Price Min"
                          data-selector="approved-price-min-input"
                          InputLabelProps={{ shrink: true }}
                          fullWidth={true}
                          onChange={(e) => {
                            dispatch(setMinPrice(e.target.value || null));
                          }}
                        />

                        <TextField
                          value={filters.maxPrice}
                          autoComplete="never"
                          name="priceMax"
                          label="Price Max"
                          data-selector="approved-price-max-input"
                          InputLabelProps={{ shrink: true }}
                          fullWidth={true}
                          onChange={(e) => {
                            dispatch(setMaxPrice(e.target.value || null));
                          }}
                        />
                      </FormGroup>
                    </FormControl>
                  </Popover>
                  {withApproved && (
                    <SwapVertIcon
                      data-selector="approved-price-sort"
                      style={{
                        marginLeft: "2px",
                        color:
                          summaryState.sortField === "adjustedPrice"
                            ? "#90d18c"
                            : "inherit",
                      }}
                      onClick={() => {
                        if (withApproved) {
                          if (summaryState.sortField === "adjustedPrice") {
                            summaryState.sortDir === "asc"
                              ? dispatch(setSort("desc"))
                              : dispatch(setSort("asc"));
                          } else {
                            dispatch(setSort("adjustedPrice"));
                          }
                        }
                      }}
                    />
                  )}
                </Ta.TableHeaderCell>

                <Ta.TableHeaderCell
                  style={{ flexBasis: "8.5%", textAlign: "right" }}
                  data-sorting-state={
                    summaryState.sortField === "adjustedRate"
                      ? "sorting"
                      : "not-sorting"
                  }
                >
                  <span>Rate</span>
                  <Tooltip
                    title={
                      isFilteringByRate
                        ? "Filtering by Rate. Click to view or modify Rate filters."
                        : "Click to filter by Rate"
                    }
                  >
                    <FilterListIcon
                      data-selector="approved-rate-filter-icon"
                      style={{
                        color: isFilteringByRate ? "#90d18c" : "inherit",
                        marginLeft: "2px",
                      }}
                      onClick={(e) => setRateFilterAnchorEl(e.currentTarget)}
                    />
                  </Tooltip>
                  <Popover
                    open={!!rateFilterEl}
                    anchorEl={rateFilterEl}
                    onClose={() => setRateFilterAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <FormControl
                      style={{ padding: "0 16px 8px" }}
                      component="fieldset"
                    >
                      <FormLabel
                        style={{ padding: "16px 0 0" }}
                        component="legend"
                      >
                        Filter by Rate
                      </FormLabel>
                      <FormHelperText style={{ paddingBottom: "8px" }}>
                        (Only affects approved products)
                      </FormHelperText>
                      <FormGroup>
                        <TextField
                          style={{ marginBottom: "16px" }}
                          value={filters.minRate}
                          autoComplete="never"
                          name="rateMin"
                          label="Rate Min"
                          data-selector="approved-rate-min-input"
                          InputLabelProps={{ shrink: true }}
                          fullWidth={true}
                          onChange={(e) => {
                            dispatch(setMinRate(e.target.value || null));
                          }}
                        />

                        <TextField
                          value={filters.maxRate}
                          autoComplete="never"
                          name="rateMax"
                          label="Rate Max"
                          data-selector="approved-rate-max-input"
                          InputLabelProps={{ shrink: true }}
                          fullWidth={true}
                          onChange={(e) => {
                            dispatch(setMaxRate(e.target.value || null));
                          }}
                        />
                      </FormGroup>
                    </FormControl>
                  </Popover>
                  {withApproved && (
                    <SwapVertIcon
                      data-selector="approved-rate-sort"
                      style={{
                        marginLeft: "2px",
                        color:
                          summaryState.sortField === "adjustedRate"
                            ? "#90d18c"
                            : "inherit",
                      }}
                      onClick={() => {
                        if (withApproved) {
                          if (summaryState.sortField === "adjustedRate") {
                            summaryState.sortDir === "asc"
                              ? dispatch(setSort("desc"))
                              : dispatch(setSort("asc"));
                          } else {
                            dispatch(setSort("adjustedRate"));
                          }
                        }
                      }}
                    />
                  )}
                </Ta.TableHeaderCell>

                <Ta.TableHeaderCell
                  style={{ flexBasis: "8.5%", textAlign: "right" }}
                  data-sorting-state={
                    summaryState.sortField === "arlp"
                      ? "sorting"
                      : "not-sorting"
                  }
                >
                  <span>Lock</span>
                  <Tooltip
                    title={
                      isFilteringByRateLockPeriod
                        ? "Filtering by Lock. Click to view or modify Lock filters."
                        : "Click to filter by Lock"
                    }
                  >
                    <FilterListIcon
                      data-selector="approved-lock-filter-icon"
                      style={{
                        color: isFilteringByRateLockPeriod
                          ? "#90d18c"
                          : "inherit",
                        marginLeft: "2px",
                      }}
                      onClick={(e) => setLockFilterAnchorEl(e.currentTarget)}
                    />
                  </Tooltip>
                  <Popover
                    open={!!lockFilterEl}
                    anchorEl={lockFilterEl}
                    onClose={() => setLockFilterAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <FormControl
                      style={{ padding: "0 16px 8px" }}
                      component="fieldset"
                    >
                      <FormLabel
                        style={{ padding: "16px 0 0" }}
                        component="legend"
                      >
                        Filter Lock Duration
                      </FormLabel>
                      <FormHelperText style={{ paddingBottom: "8px" }}>
                        (Only affects approved products)
                      </FormHelperText>
                      <FormGroup>
                        {lockOptions.map((rateLockPeriod, i) => {
                          const checked =
                            !filters.rateLockPeriods ||
                            filters.rateLockPeriods.some((rlp) =>
                              _.isEqual(rateLockPeriod, rlp),
                            );
                          return (
                            <FormControlLabel
                              key={i}
                              style={{ textTransform: "capitalize" }}
                              label={durationValueToString(rateLockPeriod)}
                              control={
                                <Checkbox
                                  data-selector="approved-lock-checkbox"
                                  checked={checked}
                                  onChange={(e, newChecked) => {
                                    dispatch(
                                      setRateLockPeriodIncluded({
                                        rateLockPeriod,
                                        included: newChecked,
                                      }),
                                    );
                                  }}
                                />
                              }
                            />
                          );
                        })}
                      </FormGroup>
                    </FormControl>
                  </Popover>
                  {withApproved && (
                    <SwapVertIcon
                      data-selector="approved-lock-sort"
                      style={{
                        marginLeft: "2px",
                        color:
                          summaryState.sortField === "arlp"
                            ? "#90d18c"
                            : "inherit",
                      }}
                      onClick={() => {
                        if (withApproved) {
                          if (summaryState.sortField === "arlp") {
                            summaryState.sortDir === "asc"
                              ? dispatch(setSort("desc"))
                              : dispatch(setSort("asc"));
                          } else {
                            dispatch(setSort("arlp"));
                          }
                        }
                      }}
                    />
                  )}
                </Ta.TableHeaderCell>
              </>
            )}

            <Ta.TableHeaderCell style={{ flexBasis: "6.5%" }} />
          </Ta.TableHeader>
        )}
      </>
    );
  },
);

export default ProductResultsStatusBar;
