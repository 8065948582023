import _ from "lodash";
import { Map as IMap, Set as ISet } from "immutable";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Configuration } from "config";
import * as T from "types/engine-types";
import * as FieldValueTypes from "types/field-value-types";
import * as Formulas from "features/formulas";
import {
  InvalidFormulaError,
  UnknownFieldError,
  UnknownEnumVariantError,
} from "features/formulas";
import * as FormulasBuiltins from "features/formulas-builtins";
import * as FormulasTypeCheck from "features/formulas-typecheck";
import * as FormulasTypes from "types/formulas-types";
import {
  UnknownFieldIdError,
  InvalidFunctionCallError,
  NotInEnvironmentError,
  UnknownFunctionError,
  TypeCheckError,
} from "features/formulas-typecheck";
import * as FormulasUtil from "features/formulas-util";
import {
  LazyMemoCache,
  Setter,
  UiValidationError,
  getValidationError,
  useLazyMemo,
  usePropertySetter,
  useTargetValue,
  createSlugId,
  unPrefixId,
  getErrorMessage,
} from "features/utils";
import { CalculatedFieldDefinitionValidation } from "types/generated-types";
import { getMessageNode } from "features/server-validation";
import { AssociatedRolesViewer } from "design/organisms/associated-role-viewer";
import { RefSourcesViewer } from "design/atoms/ref-sources-viewer";

const { newrelic } = window;

export type CalculatedFieldState = {
  oldId: T.FieldId | null;
  id: T.FieldId;
  name: string;
  precision: number;
  style: T.NumberFieldStyle;
  formula: string;
  domainValidationErrors: CalculatedFieldDefinitionValidation;
};

export function validateCalculatedField(
  calcField: T.CalculatedFieldDefinition,
  environment: ISet<T.FieldId>,
  fieldsInStage: ISet<T.FieldId>,
  config: Configuration,
): UiValidationError | null {
  const fieldValueType = calcField.valueType;

  // Based on the FieldValueType, determine formula output type
  let expectedType;
  switch (fieldValueType.type) {
    case "enum":
      expectedType = {
        kind: "enum" as const,
        enumTypeId: fieldValueType.enumTypeId,
      };
      break;
    case "number":
    case "duration":
    case "date":
    case "string":
      expectedType = { kind: fieldValueType.type };
      break;
    default:
      // Other types of calculated fields aren't supported yet
      return new UiValidationError("Unsupported field value type");
  }

  // Run type checker
  let outputType;
  try {
    outputType = FormulasTypeCheck.getExpressionOutputType(
      config,
      environment,
      calcField.expression,
    );
  } catch (err) {
    newrelic.noticeError(getErrorMessage(err));
    return convertFormulaTypeCheckErrorToValidation(
      config,
      fieldsInStage,
      err as Error,
    );
  }

  // Check actual formula output type against expected
  if (!FormulasTypes.isSubtype(expectedType, outputType)) {
    return new UiValidationError(
      "This formula produces a value of type " +
        FormulasTypes.printValueType(config, outputType) +
        ", but the field was last saved with a value of type " +
        FormulasTypes.printValueType(config, expectedType) +
        ". Opening and saving this formula may fix the problem.",
    );
  }

  return null;
}

export function newCalculatedFieldState(): CalculatedFieldState {
  return {
    oldId: null,
    id: "" as T.FieldId,
    name: "",
    formula: "",
    precision: 2,
    style: "plain",
    domainValidationErrors: { id: [], name: [] },
  };
}

export function convertStateToCalculatedField(
  state: CalculatedFieldState,
  newFieldEnvironment: ISet<T.FieldId>,
  fieldsInStage: ISet<T.FieldId>,
  config: Configuration,
): T.CalculatedFieldDefinition {
  const identifier = FormulasUtil.fieldNameToIdentifier(state.name);

  if (!state.name.trim() || !identifier) {
    throw new UiValidationError("Field name required");
  }

  // Check name uniqueness
  const existingField = config.getFieldByIdentifier(identifier);
  if (existingField) {
    if (existingField.id !== state.id) {
      throw new UiValidationError("Field name must be unique");
    }
  }

  let formulaIr;
  try {
    formulaIr = Formulas.formulaToIr(config, state.formula);
  } catch (err) {
    newrelic.noticeError(getErrorMessage(err));
    throw convertFormulaParseErrorToValidation(err as Error);
  }

  let formulaOutputType;
  try {
    formulaOutputType = FormulasTypeCheck.getExpressionOutputType(
      config,
      newFieldEnvironment,
      formulaIr,
    );
  } catch (err) {
    newrelic.noticeError(getErrorMessage(err));
    throw convertFormulaTypeCheckErrorToValidation(
      config,
      fieldsInStage,
      err as Error,
    );
  }

  let valueType;

  switch (formulaOutputType.kind) {
    case "enum":
      valueType = {
        type: "enum" as const,
        enumTypeId: formulaOutputType.enumTypeId,
      };
      break;
    case "number":
      valueType = {
        type: "number" as const,
        minimum: null,
        maximum: null,
        precision: state.precision,
        style: state.style,
      };
      break;
    case "string":
      valueType = {
        type: "string" as const,
        format: "plain" as const,
      };
      break;
    case "duration":
      valueType = {
        type: "duration" as const,
        minimumDays: null,
        maximumDays: null,
        minimumMonths: null,
        maximumMonths: null,
      };
      break;
    case "date":
      valueType = {
        type: "date" as const,
      };
      break;
    default:
      throw new UiValidationError(
        `This type of value cannot be placed in a calculated field: ${FormulasTypes.printValueType(
          config,
          formulaOutputType,
        )}.`,
      );
  }

  return {
    oldId: state.oldId,
    id: state.id,
    name: state.name,
    valueType,
    expression: formulaIr,
  };
}

export function convertCalculatedFieldToState(
  config: Configuration,
  field: T.CalculatedFieldDefinition,
  domainValidationErrors: T.CalculatedFieldDefinitionValidation | null,
): CalculatedFieldState {
  const valueType = field.valueType;
  const precision = valueType.type === "number" ? valueType.precision : 3;
  const style = valueType.type === "number" ? valueType.style : "plain";

  return {
    oldId: field.oldId,
    id: field.id,
    name: field.name,
    formula: Formulas.irToFormula(config, field.expression),
    precision,
    style,
    domainValidationErrors: domainValidationErrors || { id: [], name: [] },
  };
}

export function validateCalculatedFieldState(
  state: CalculatedFieldState,
  newFieldEnvironment: ISet<T.FieldId>,
  fieldsInStage: ISet<T.FieldId>,
  config: Configuration,
): UiValidationError | null {
  return getValidationError(() => {
    convertStateToCalculatedField(
      state,
      newFieldEnvironment,
      fieldsInStage,
      config,
    );
  });
}

const useFieldEditorStyles = makeStyles((t) =>
  createStyles({
    fieldContainer: {
      display: "grid",
      alignItems: "start",
      gridTemplateColumns: "300px min-content 1fr",
    },
    nameField: {
      gridColumn: "1",
      gridRow: "1",
    },
    idField: {
      gridColumn: "1",
      gridRow: "2",
    },
    equals: {
      gridColumn: "2",
      gridRow: "1",

      margin: "10px 16px 0",
      fontSize: 24,
    },
    formulaField: {
      gridColumn: "3",
      gridRow: "1",

      "& input": {
        fontFamily: "'Roboto Mono', monospace",
      },
    },
    identifierHint: {
      color: "#666",

      "& code": {
        fontFamily: "'Roboto Mono', monospace",
      },
    },
    formulaMessageBar: {
      minHeight: 60,
    },
    librariesContainer: {
      display: "flex",
    },
    settingsContainer: {
      display: "flex",
      padding: t.spacing(3, 0),
      minHeight: 110,
    },
  }),
);

export const CalculatedFieldEditor = React.memo(
  ({
    state,
    setState,
    environment,
    fieldsInStage,
    config,
    setRolesToAdd,
    rolesToAdd,
  }: {
    state: CalculatedFieldState;
    setState: Setter<CalculatedFieldState>;
    environment: ISet<T.FieldId>;
    fieldsInStage: ISet<T.FieldId>;
    config: Configuration;
    setRolesToAdd: React.Dispatch<
      React.SetStateAction<{
        [key: string]: T.RoleId[];
      }>
    >;
    rolesToAdd?: {
      [key: string]: T.RoleId[];
    };
  }) => {
    const C = useFieldEditorStyles();

    const formulaFieldRef = useRef<HTMLInputElement>(null);

    const [originalState] = useState(state);

    const setName = usePropertySetter(setState, "name");
    const setId = usePropertySetter(setState, "id");
    const setFormula = usePropertySetter(setState, "formula");

    const handleFormulaChange = useTargetValue(setFormula);

    const handleIdChange = useCallback(
      (event: { target: { value: string } }) =>
        setId(("calc@" + event.target.value) as T.FieldId),
      [setId],
    );

    const handleNameChange = useCallback(
      (event: { target: { value: string } }) => {
        setName(event.target.value);
        if (!state.oldId) {
          setId(createSlugId(event.target.value, "calc@") as T.FieldId);
        }
      },
      [setId, setName, state.oldId],
    );

    const formulaStringInserters = useLazyMemo(
      (str: string) => () => {
        setFormula((oldFormula) => {
          const insertionPoint = formulaFieldRef.current?.selectionStart || 0;
          const before = oldFormula.slice(0, insertionPoint);
          const after = oldFormula.slice(insertionPoint);
          return before + str + after;
        });

        if (formulaFieldRef.current) {
          formulaFieldRef.current.focus();
        }
      },
      [setFormula],
    );

    const identifier = FormulasUtil.fieldNameToIdentifier(state.name);

    const nameError = (() => {
      if (
        state.name.trim() &&
        state.name !== originalState.name &&
        config.getFieldByIdentifier(identifier)
      ) {
        const field = config.getFieldByIdentifier(identifier)!;
        return (
          "A field already exists with the identifier " +
          FormulasUtil.getFieldIdentifier(field) +
          "."
        );
      }

      return null;
    })();
    const formulaMessage = getFormulaHint(
      state.formula,
      environment,
      fieldsInStage,
      config,
    );

    const formulaOutputType = useMemo(() => {
      try {
        const formulaIr = Formulas.formulaToIr(config, state.formula);
        return FormulasTypeCheck.getExpressionOutputType(
          config,
          environment,
          formulaIr,
        );
      } catch (err) {
        newrelic.noticeError(getErrorMessage(err));
        return null;
      }
    }, [state.formula, environment, config]);

    const setStyle = usePropertySetter(setState, "style");
    const handleStyleChange = useCallback(
      (e: unknown, v: T.NumberFieldStyle | null) => {
        if (v !== null) {
          setStyle(v);
        }
      },
      [setStyle],
    );

    const setPrecision = usePropertySetter(setState, "precision");
    const handlePrecisionChange = useCallback(
      (e: { target: { value: string } }) => {
        setPrecision(+e.target.value);
      },
      [setPrecision],
    );

    return (
      <>
        <div className={C.fieldContainer}>
          <TextField
            className={C.nameField}
            autoFocus
            label="Field Name"
            value={state.name}
            onChange={handleNameChange}
            margin="dense"
            variant="outlined"
            error={!!state.domainValidationErrors.name.length}
            helperText={getMessageNode(state.domainValidationErrors.name)}
          />
          <TextField
            className={C.idField}
            InputProps={{ startAdornment: "calc@" }}
            label="Field ID"
            value={unPrefixId(state.id, "calc@")}
            onChange={handleIdChange}
            margin="dense"
            variant="outlined"
            disabled={!!state.oldId}
            error={!!state.domainValidationErrors.id.length}
            helperText={getMessageNode(state.domainValidationErrors.id)}
          />
          <div className={C.equals}>=</div>
          <TextField
            className={C.formulaField}
            multiline
            label="Formula"
            value={state.formula}
            inputRef={formulaFieldRef}
            onChange={handleFormulaChange}
            margin="dense"
            variant="outlined"
          />
          <div
            style={{
              gridRow: 2,
              gridColumn: 3,
            }}
          >
            <RefSourcesViewer
              objectId={{ type: "field", fieldId: state.id }}
              mt={2}
              style={{ marginBottom: "16px" }}
            />
          </div>
          <div className={C.identifierHint}>
            {nameError && (
              <Box
                color="text.secondary"
                display="flex"
                alignItems="center"
                p={1}
              >
                <Box display="flex" pr={1}>
                  <ErrorOutlineIcon />
                </Box>
                <Box flex="1">{nameError}</Box>
              </Box>
            )}
          </div>
          <div />
          <div className={C.formulaMessageBar}>
            {formulaMessage && (
              <Box
                color="text.secondary"
                display="flex"
                alignItems="center"
                p={1}
              >
                <Box display="flex" pr={1}>
                  <ErrorOutlineIcon />
                </Box>
                <Box flex="1">{formulaMessage}</Box>
              </Box>
            )}
          </div>
        </div>
        <div className={C.librariesContainer}>
          <AssociatedRolesViewer
            setRolesToAdd={setRolesToAdd}
            rolesToAdd={rolesToAdd}
            linkRoles={true}
            objectId={{ type: "field", fieldId: state.id }}
          />

          <FieldLibrary
            environment={environment}
            allFieldsById={config.allFieldsById}
            formulaStringInserters={formulaStringInserters}
          />
          <FunctionLibrary formulaStringInserters={formulaStringInserters} />
        </div>
        <div className={C.settingsContainer}>
          {formulaOutputType &&
            FormulasTypes.isSameType(formulaOutputType, { kind: "number" }) && (
              <>
                <Autocomplete<T.NumberFieldStyle>
                  options={FieldValueTypes.ALL_NUMBER_FIELD_STYLES}
                  getOptionLabel={(option) =>
                    FieldValueTypes.getNumberFieldStyleName(option)
                  }
                  value={state.style}
                  onChange={handleStyleChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth={false}
                      label="Number Format"
                      style={{ width: 300 }}
                      variant="outlined"
                      margin={"dense"}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
                <Box pl={2} />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Precision"
                  title="Number of digits after the decimal point"
                  margin="dense"
                  variant="outlined"
                  value={state.precision}
                  onChange={handlePrecisionChange}
                  style={{ width: 300 }}
                />
              </>
            )}
        </div>
      </>
    );
  },
);

const useFieldLibraryStyles = makeStyles((t) =>
  createStyles({
    fieldLibrary: {
      flex: "1 1 auto",
    },
    fieldList: {
      overflowX: "hidden",
      overflowY: "auto",
      height: 400,
    },
    fieldListItem: {
      padding: t.spacing(1),

      cursor: "pointer",
      fontSize: "1rem",

      "&:hover": {
        background: "#eee",
      },
    },
    identifier: {
      fontFamily: "'Roboto Mono', monospace",
      fontWeight: 700,
    },
  }),
);

const FieldLibrary = React.memo(
  ({
    environment,
    allFieldsById,
    formulaStringInserters,
  }: {
    environment: ISet<T.FieldId>;
    allFieldsById: IMap<T.FieldId, T.BaseFieldDefinition>;
    formulaStringInserters: LazyMemoCache<string, () => void>;
  }) => {
    const C = useFieldLibraryStyles();

    const sortedFields = _.sortBy(
      environment.toArray().map((fieldId) => allFieldsById.get(fieldId)!),
      (f) => FormulasUtil.getFieldIdentifier(f),
    );

    return (
      <div className={C.fieldLibrary}>
        <Typography variant="h5">Field Library</Typography>
        <div className={C.fieldList}>
          {sortedFields
            .filter((f) => f.valueType.type !== "header")
            .map((field) => {
              const identifier = FormulasUtil.getFieldIdentifier(field);
              const insertIdentifier = formulaStringInserters.get(identifier);
              const typeName = printFieldValueType(field);

              return (
                <div
                  key={field.id}
                  className={C.fieldListItem}
                  title={`Click to insert this field into the formula`}
                  onClick={insertIdentifier}
                >
                  <span className={C.identifier}>{identifier}</span> &middot;{" "}
                  {typeName}
                </div>
              );
            })}
        </div>
      </div>
    );
  },
);

const useFunctionLibraryStyles = makeStyles((t) =>
  createStyles({
    container: {
      flex: "1 1 auto",
      marginLeft: t.spacing(2),
    },
    list: {
      overflowX: "hidden",
      overflowY: "auto",
      height: 400,
    },
    entry: {
      padding: t.spacing(1),

      cursor: "pointer",
      fontSize: "1rem",

      "&:hover": {
        background: "#eee",
      },
    },
    name: {
      fontFamily: "'Roboto Mono', monospace",
    },
    params: {
      fontFamily: "'Roboto Mono', monospace",
    },
  }),
);

const FunctionLibrary = React.memo(
  ({
    formulaStringInserters,
  }: {
    formulaStringInserters: LazyMemoCache<string, () => void>;
  }) => {
    const C = useFunctionLibraryStyles();

    const sortedFunctions = useMemo(
      () => _.sortBy(FormulasBuiltins.builtinFunctions, "name"),
      [],
    );

    return (
      <div className={C.container}>
        <Typography variant="h5">Function Library</Typography>
        <div className={C.list}>
          {sortedFunctions.map((builtin) => {
            const textToInsert =
              builtin.name + "(" + builtin.paramNames.join(", ") + ")";
            const insertIdentifier = formulaStringInserters.get(textToInsert);

            return (
              <div
                key={builtin.name}
                className={C.entry}
                title={`Click to insert ${builtin.name}(...) into the formula`}
                onClick={insertIdentifier}
              >
                <div>
                  <strong className={C.name}>{builtin.name}</strong>
                  <span className={C.params}>
                    ({builtin.paramNames.join(", ")})
                  </span>
                </div>
                <div>{builtin.description}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  },
);

function getFormulaHint(
  formula: string,
  environment: ISet<T.FieldId>,
  fieldsInStage: ISet<T.FieldId>,
  config: Configuration,
): string | null {
  if (formula.trim().length === 0) {
    return null;
  }

  // Try to parse formula
  let formulaIr = null;
  try {
    formulaIr = Formulas.formulaToIr(config, formula);
  } catch (err) {
    newrelic.noticeError(getErrorMessage(err));
    return convertFormulaParseErrorToValidation(err as Error).message;
  }

  // Run type checker
  let outputType;
  try {
    outputType = FormulasTypeCheck.getExpressionOutputType(
      config,
      environment,
      formulaIr,
    );
  } catch (err) {
    newrelic.noticeError(getErrorMessage(err));
    return convertFormulaTypeCheckErrorToValidation(
      config,
      fieldsInStage,
      err as Error,
    ).message;
  }

  // Check if value type is allowed in calculated fields
  const outputTypeErr = validateFormulaOutputType(config, outputType);

  if (outputTypeErr) {
    return outputTypeErr.message;
  }

  return null;
}

function convertFormulaParseErrorToValidation(err: Error): UiValidationError {
  if (err instanceof UnknownFieldError) {
    return new UiValidationError("Unknown field: " + err.identifier);
  }

  if (err instanceof UnknownEnumVariantError) {
    return new UiValidationError("Unknown enumerated value: " + err.identifier);
  }

  if (err instanceof InvalidFormulaError) {
    return new UiValidationError(err.message);
  }
  console.error(err);
  newrelic.noticeError(err);
  return new UiValidationError(
    "The formula entered is not yet a valid formula. Check that the formula was entered correctly.",
  );
}

function convertFormulaTypeCheckErrorToValidation(
  config: Configuration,
  fieldsInStage: ISet<T.FieldId>,
  err: Error,
): UiValidationError {
  if (err instanceof InvalidFunctionCallError) {
    return new UiValidationError(
      "The function " +
        JSON.stringify(err.func.name) +
        " does not accept (" +
        err.argTypes
          .map((argType) => FormulasTypes.printValueType(config, argType))
          .join(", ") +
        ") as input.",
    );
  }

  if (err instanceof UnknownFunctionError) {
    return new UiValidationError("Unknown function: " + err.funcName);
  }

  if (err instanceof UnknownFieldIdError) {
    return new UiValidationError(
      "This formula contains a reference to a field that no longer exists.",
    );
  }

  if (err instanceof NotInEnvironmentError) {
    const field = config.allFieldsById.get(err.fieldId);
    const fieldName = field?.name || "<unknown>";

    const isInThisStage = fieldsInStage.has(err.fieldId);

    if (isInThisStage) {
      return new UiValidationError(
        "The formula uses the field " +
          fieldName +
          ", but that field is not available at this point in the calculations. " +
          "Try moving this calculation farther down the list so that " +
          fieldName +
          " is calculated first.",
      );
    }

    return new UiValidationError(
      "The formula uses the field " +
        fieldName +
        ", but that field is not available at this point in the calculations. " +
        "You may need to create this calculation in a different calculations tab.",
    );
  }

  if (err instanceof TypeCheckError) {
    return new UiValidationError(err.message);
  }
  newrelic.noticeError(err);
  console.error(err);
  return new UiValidationError(
    "The formula entered does not produce a valid result. Check that the formula was entered correctly.",
  );
}

function validateFormulaOutputType(
  config: Configuration,
  outputType: FormulasTypes.ValueType,
): UiValidationError | null {
  switch (outputType.kind) {
    case "enum":
    case "number":
    case "duration":
    case "date":
    case "string":
      return null;
    default:
      return new UiValidationError(
        `This type of value cannot be placed in a calculated field: ${FormulasTypes.printValueType(
          config,
          outputType,
        )}.`,
      );
  }
}

function printFieldValueType(field: T.BaseFieldDefinition): string {
  const valueType = field.valueType;
  switch (valueType.type) {
    case "enum":
      return "enumeration";
    case "object-ref":
      return "object-ref";
    case "number":
      return "number";
    case "string":
      return "text";
    case "duration":
      return "duration";
    case "date":
      return "date";
    case "header":
      return "header";
  }
}
