import styled from "styled-components";
import breakpoints from "design/subatomics/breakpoints";
import transitions from "design/subatomics/transitions";
import whitespace from "design/subatomics/whitespace";
import color from "design/subatomics/colors";

export const ProductsActionPanel = styled.div`
  background: ${color({ color: "gray", shade: 9 })};
  border-bottom: 1px solid ${color({ color: "border" })};
  transition: flex-basis ${transitions.default};
  flex: 0 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &.open {
    transition: height ${transitions.default}, padding ${transitions.default},
      flex-basis ${transitions.default};

    .page-component-loan-pricing-manage-filters {
      transition: height ${transitions.default}, padding ${transitions.default},
        flex-basis ${transitions.default};
    }
  }

  &.closed {
    transition: height ${transitions.default}, padding ${transitions.default},
      flex-basis ${transitions.default};

    .page-component-loan-pricing-manage-filters {
      transition: height ${transitions.default}, padding ${transitions.default},
        flex-basis ${transitions.default};
    }
  }

  .page-component-loan-pricing-manage-filters {
    overflow: hidden;
    flex: 1 0 auto;
    transition: height ${transitions.default}, padding ${transitions.default},
      flex-basis ${transitions.default};
  }

  ${breakpoints.mobile} {
    padding: 0 ${whitespace("less")} ${whitespace("less")};

    .page-component-loan-pricing-action-buttons {
      button {
        flex: 1 1 50%;
      }
    }

    &.open {
      flex-basis: calc(100vh - 48px);
      box-sizing: border-box;

      .page-component-loan-pricing-manage-filters {
        height: 300px;
        box-sizing: border-box;
      }
    }

    &.closed {
      flex-basis: 0px;
      padding: 0;
      box-sizing: border-box;

      .page-component-loan-pricing-manage-filters {
        height: 0px;
        padding: 0;
        box-sizing: border-box;
      }

      .page-component-loan-pricing-action-buttons {
        box-sizing: border-box;
        padding: ${whitespace("less")};
      }
    }
  }

  ${breakpoints.desktop} {
    padding: 0;

    &.open {
      box-sizing: border-box;

      .page-component-loan-pricing-manage-filters {
        flex-basis: 100vh;
        box-sizing: border-box;
      }
    }

    &.closed {
      box-sizing: border-box;

      .page-component-loan-pricing-manage-filters {
        flex-basis: 0vh;
        box-sizing: border-box;
        padding: 0;
      }

      .loan-pricing-component-product-results-summary {
        margin: 0;
      }
    }

    .page-component-loan-pricing-action-buttons {
      display: none;
    }
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  ${breakpoints.mobile} {
    flex-direction: column;
  }

  ${breakpoints.desktop} {
  }
`;
