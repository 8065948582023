import React, { useCallback, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Box, Button } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DeleteIcon from "@material-ui/icons/Delete";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import {
  ObjectWithId,
  Setter,
  UiValidationError,
  genericReactMemo,
  useArraySetter,
  useLazyMemo,
  usePropertySetter,
  generateSafeId,
} from "features/utils";
import {
  stateHasErrors,
  isStateWithValErrors,
} from "features/server-validation";

export type ObjectListState<Obj> = {
  objects: readonly Obj[];
  selectedIndex: number | null;
};

export function newObjectListState<Obj>(objects: Obj[]): ObjectListState<Obj> {
  return {
    objects,
    selectedIndex: null,
  };
}

const useStyles = makeStyles((t) =>
  createStyles({
    objectList: {
      overflow: "auto",
      scrollbarWidth: "thin",
      marginRight: t.spacing(2),
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4,
      width: 400,

      "&.error": {
        borderColor: "hsl(4.1, 89.6%, 58.4%)",
      },
    },
    objectListItemContainer: {
      transition: "height 0.2s ease-out",
    },
    objectListItem: {
      display: "flex",
      alignItems: "center",
      padding: "0 12px",
      cursor: "pointer",

      "&.dragging": {
        opacity: 0,
        height: 0,
      },

      "& .dragIcon": {
        display: "flex",
        marginLeft: -8,
        padding: 8,
        cursor: "grab",
      },

      "& .errorIcon": {
        padding: "8px 8px 8px 0",
      },

      "& .label": {
        flex: "1",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        padding: "8px 0",
      },

      "& .endIcon": {
        display: "flex",
        padding: "8px 0",
        opacity: 0,
      },

      "&:hover": {
        background: "#eee",

        "& .endIcon": {
          opacity: 0.4,
        },
      },

      "&.selected": {
        background: "#ddd",
        cursor: "default",

        "& .endIcon": {
          opacity: 0.8,
        },
      },

      "&.selected:hover": {
        background: "#ddd",
        cursor: "default",

        "& .endIcon": {
          opacity: 0.8,
        },
      },
    },
    objectListItemDragFiller: {
      display: "none",
      height: 40,
      background: "#8888ff",

      "&.show": {
        transition: "height 0.2s ease-out",
        display: "flex",
        height: 0,
      },
    },
    dropIndicator: {
      transition: "height 0.2s ease-out",
    },
  }),
);

export type ObjectListEditorNewButton<
  Id extends string,
  Obj extends ObjectWithId<Id>,
> = {
  name: string;
  icon?: JSX.Element;
  makeNewObject: () => Obj | null;
};

export const ObjectListEditor = genericReactMemo(
  <Id extends string, Obj extends ObjectWithId<Id>>({
    height,
    state,
    getObjectLabel,
    showErrors,
    validateObject,
    newButtons,
    makeObjectEditor,
    setState,
    sortBy,
    itemHasAdditionalErrors,
    emptyText,
    disableControls = false,
  }: {
    disableControls?: boolean;
    height?: string;
    state: ObjectListState<Obj>;
    newButtons: ObjectListEditorNewButton<Id, Obj>[];
    getObjectLabel: (obj: Obj) => JSX.Element;
    showErrors?: boolean;
    validateObject?: (obj: Obj, objIndex: number) => UiValidationError | null;
    makeObjectEditor: (props: {
      value: Obj;
      showErrors: boolean;
      index: number;
      setValue: Setter<Obj>;
    }) => React.ReactElement | null;
    setState: Setter<ObjectListState<Obj>>;
    sortBy?: (obj1: Obj, obj2: Obj) => number;
    itemHasAdditionalErrors?: (obj: Obj) => boolean;
    emptyText: string;
  }) => {
    const C = useStyles();

    const setObjects = usePropertySetter(setState, "objects");
    const setObject = useArraySetter(setObjects);

    const addObject = useCallback(
      (makeNewObject: () => Obj | null) => {
        if (makeNewObject === null) {
          return;
        }

        const newObj = makeNewObject();

        if (newObj === null) {
          return;
        }

        setState((state) => ({
          objects: state.objects.concat([newObj]),
          selectedIndex: state.objects.length,
        }));
      },
      [setState],
    );

    const deleteSelectedObject = useCallback(() => {
      setState((state) => {
        if (state.selectedIndex === null) {
          return state;
        }

        const newObjects = [...state.objects];
        newObjects.splice(state.selectedIndex, 1);
        return { objects: newObjects, selectedIndex: null };
      });
    }, [setState]);

    const uiValidationErrors = state.objects.map((obj, objIdx) =>
      showErrors !== false && validateObject
        ? validateObject(obj, objIdx)
        : null,
    );

    const anyError = uiValidationErrors.some((msg) => msg !== null);

    return (
      <Box display="flex" height={height || 400}>
        <Box
          minWidth="350px"
          className={C.objectList + (anyError ? " error" : "")}
        >
          {state.objects.length === 0 && (
            <Box py={4} color="text.disabled" textAlign="center">
              {emptyText}
            </Box>
          )}
          <DndList
            state={state}
            getObjectLabel={getObjectLabel}
            showErrors={showErrors !== false}
            uiValidationErrors={uiValidationErrors}
            setState={setState}
            sortBy={sortBy}
            itemHasAdditionalErrors={itemHasAdditionalErrors}
          />
        </Box>
        <Box flex="1" display="flex" flexDirection="column">
          <Box display="flex">
            {newButtons.map((btn, i) => {
              return (
                <Button
                  key={i}
                  disabled={disableControls}
                  style={{ marginRight: 16 }}
                  variant="outlined"
                  startIcon={btn.icon}
                  onClick={() => addObject(btn.makeNewObject)}
                >
                  {btn.name}
                </Button>
              );
            })}
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              disabled={disableControls || state.selectedIndex === null}
              onClick={deleteSelectedObject}
            >
              Delete
            </Button>
          </Box>

          <Box flex="1" overflow="auto" mt={2}>
            {state.selectedIndex !== null && (
              <>
                {makeObjectEditor({
                  value: state.objects[state.selectedIndex],
                  index: state.selectedIndex,
                  showErrors: showErrors !== false,
                  setValue: setObject.withIndex(state.selectedIndex),
                })}
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  },
);

const DndList = genericReactMemo(
  <Id extends string, Obj extends ObjectWithId<Id>>({
    state,
    getObjectLabel,
    showErrors,
    uiValidationErrors,
    setState,
    sortBy,
    itemHasAdditionalErrors,
  }: {
    state: ObjectListState<Obj>;
    getObjectLabel: (obj: Obj) => JSX.Element;
    showErrors: boolean;
    uiValidationErrors: (UiValidationError | null)[];
    setState: Setter<ObjectListState<Obj>>;
    sortBy?: (obj1: Obj, obj2: Obj) => number;
    itemHasAdditionalErrors?: (obj: Obj) => boolean;
  }) => {
    const C = useStyles();

    const setObjects = usePropertySetter(setState, "objects");

    const [listId] = useState<string>(() => generateSafeId() as string);

    const setSelectedIndex = usePropertySetter(setState, "selectedIndex");
    const selectionSettersByIndex = useLazyMemo(
      (selectedIndex: number) => () => setSelectedIndex(selectedIndex),
      [setSelectedIndex],
    );

    const [dragIndex, setDragIndex] = useState<number | null>(null);
    const dragIndexSetters = useLazyMemo(
      (dragIndex: number | null) => () => {
        setDragIndex(dragIndex);
      },
      [],
    );

    const [dropIndex, setDropIndex] = useState<number | null>(null);
    const dropIndexSetters = useLazyMemo(
      (dropIndex: number | null) => () => setDropIndex(dropIndex),
      [],
    );

    const dropHandler = useCallback(() => {
      if (dragIndex !== null && dropIndex !== null && dragIndex !== dropIndex) {
        const newIndex = dropIndex > dragIndex ? dropIndex - 1 : dropIndex;
        setObjects((objects) => {
          const newObjects = [...objects];
          newObjects.splice(newIndex, 0, newObjects.splice(dragIndex, 1)[0]);
          return newObjects;
        });
      }

      setDragIndex(null);
      setDropIndex(null);
    }, [dragIndex, dropIndex, setObjects]);

    const sortedObjects = [...state.objects];

    const indexMap: { [key: number]: number } = {};
    for (let i = 0; i < state.objects.length; ++i) {
      indexMap[i] = i;
    }

    if (!!sortBy) {
      // We have to implement our own bubble sort here so that we can keep track
      // of the original indices of the items as they move through the list during
      // sorting.
      let sorted = false;
      let iterations = 0;
      const maxIter = 1000;
      while (!sorted) {
        if (iterations > maxIter) {
          throw new Error(`
            Sorting the ObjectListEditor exceeded ${maxIter} iterations.
            This usually happens when the sort function returns a value 
            greater than 0 regardless of the ordering of the input arguments.
          `);
        }

        sorted = true;
        for (let i = 1; i < sortedObjects.length; ++i) {
          const aIndex = i - 1;
          const bIndex = i;
          const order = sortBy(sortedObjects[aIndex], sortedObjects[bIndex]);
          if (order === 1) {
            sorted = false;

            // Swap the list items
            const tmpObj = sortedObjects[aIndex];
            sortedObjects[aIndex] = sortedObjects[bIndex];
            sortedObjects[bIndex] = tmpObj;

            // Swap their list indices so we can keep track of them
            // in the original state object.
            const tmpIdx = indexMap[aIndex];
            indexMap[aIndex] = indexMap[bIndex];
            indexMap[bIndex] = tmpIdx;
          }
        }
        ++iterations;
      }
    }

    return (
      <>
        {sortedObjects.map((obj, sortedIdx) => {
          // Map the object index back to its index in the state object
          // (not the sorted version that we're displaying)
          const objIdx = indexMap[sortedIdx];

          const isDraggingAtInitialPosition =
            objIdx === dropIndex || dropIndex === null;

          return (
            <React.Fragment key={objIdx}>
              <Box
                className={C.dropIndicator}
                height={
                  dropIndex === objIdx && !isDraggingAtInitialPosition ? 40 : 0
                }
              ></Box>

              <DndListItem
                key={objIdx}
                listId={listId}
                label={getObjectLabel(obj) || "<New>"}
                itemIndex={objIdx}
                uiValidationError={uiValidationErrors[objIdx]}
                hasDomainValidationErrors={
                  (itemHasAdditionalErrors && itemHasAdditionalErrors(obj)) ||
                  (isStateWithValErrors(obj) ? stateHasErrors(obj) : false)
                }
                isSelected={objIdx === state.selectedIndex}
                isDraggingAtInitialPosition={isDraggingAtInitialPosition}
                select={selectionSettersByIndex.get(objIdx)}
                onDragBegin={dragIndexSetters.get(objIdx)}
                onDragEnd={dropHandler}
                onDropHoverAbove={dropIndexSetters.get(objIdx)}
                onDropHoverBelow={dropIndexSetters.get(objIdx + 1)}
                isDraggable={sortBy === undefined}
              />
            </React.Fragment>
          );
        })}

        <Box
          className={C.dropIndicator}
          height={dropIndex === sortedObjects.length ? 40 : 0}
        ></Box>
      </>
    );
  },
);

const DndListItem = genericReactMemo(
  ({
    listId,
    label,
    itemIndex,
    uiValidationError,
    hasDomainValidationErrors,
    isSelected,
    isDraggingAtInitialPosition,
    select,
    onDragBegin,
    onDragEnd,
    onDropHoverAbove,
    onDropHoverBelow,
    isDraggable,
  }: {
    listId: string;
    label: JSX.Element;
    itemIndex: number;
    uiValidationError: UiValidationError | null;
    hasDomainValidationErrors?: boolean;
    isSelected: boolean;
    isDraggingAtInitialPosition: boolean;
    select: () => void;
    onDragBegin: () => void;
    onDragEnd: () => void;
    onDropHoverAbove: () => void;
    onDropHoverBelow: () => void;
    isDraggable: boolean;
  }) => {
    const C = useStyles();

    const errorMessage = uiValidationError && uiValidationError.message;

    const dragDropRef = useRef<HTMLDivElement>(null);
    const [{ isDragging }, dragRef] = useDrag({
      item: {
        type: listId,
        itemIndex,
      },
      begin(monitor) {
        onDragBegin();
      },
      end(item, monitor) {
        onDragEnd();
      },
      collect(monitor) {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    });
    const [, dropRef] = useDrop({
      accept: listId,
      hover(item, monitor) {
        if (!dragDropRef.current) {
          return;
        }

        const cursorViewportOffset = monitor.getClientOffset();

        if (!cursorViewportOffset) {
          return;
        }

        const hoverItemRect = dragDropRef.current.getBoundingClientRect();
        const cursorOffsetY = cursorViewportOffset.y - hoverItemRect.top;

        if (cursorOffsetY < (hoverItemRect.bottom - hoverItemRect.top) / 2) {
          onDropHoverAbove();
        } else {
          onDropHoverBelow();
        }
      },
    });
    dragRef(dropRef(dragDropRef));

    return (
      <Box
        className={C.objectListItemContainer}
        height={!isDragging || isDraggingAtInitialPosition ? 40 : 0}
      >
        <div
          className={
            C.objectListItem +
            (isSelected ? " selected" : "") +
            (isDragging ? " dragging" : "")
          }
          ref={isDraggable ? dragDropRef : undefined}
          onClick={select}
        >
          {isDraggable && (
            <Box
              className="dragIcon"
              title="Click and drag to move this item up or down in the list"
            >
              <DragIndicatorIcon />
            </Box>
          )}
          <Box
            className="errorIcon"
            display={
              uiValidationError === null && !hasDomainValidationErrors
                ? "none"
                : "flex"
            }
            color="error.main"
            title={errorMessage || undefined}
          >
            <ErrorOutlineIcon />
          </Box>
          <Box className="label">{label}</Box>
          <Box className="endIcon">
            <ChevronRightIcon />
          </Box>
        </div>
      </Box>
    );
  },
);
