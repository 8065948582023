import React, { ReactNode } from "react";
import { ActionButtons } from "./styles";

export default React.memo(
  ({ children, className }: { children: ReactNode; className?: string }) => {
    return (
      <ActionButtons
        className={
          className
            ? `page-component-loan-pricing-action-buttons ${className}`
            : "page-component-loan-pricing-action-buttons"
        }
      >
        {children}
      </ActionButtons>
    );
  },
);
