import styled from "styled-components";

const ResultsCount = styled.div.attrs(
  ({ className }: { className?: string }) => ({
    className: className ? className + " results-count" : "results-count",
  }),
)`
  padding: 10px 0px;
  opacity: 0.65;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 16px;
  flex-wrap: wrap;

  .separator {
    margin: 0 4px;
  }

  span {
    display: flex;
    justify-content: center;
    line-height: 16px;
  }

  svg {
    margin-left: 4px;
    font-size: 16px;
  }

  &::after {
    margin-left: 4px;
  }

  &.v2Results {
    font-size: 12px;
  }
`;

export default ResultsCount;
