import React, { useRef, useState, useEffect } from "react";
import { useVirtual } from "react-virtual";
import { useSelector } from "react-redux";
import ResultsCount from "design/atoms/results-count";
import SearchInput from "design/atoms/search-input";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  FieldValueState,
  convertFieldValueToState,
  convertStateToFieldValue,
  newFieldValueState,
} from "design/organisms/field-value-editor";
import Field from "design/molecules/field";
import {
  Table,
  TableActions,
  TableHeader,
  TableHeaderCell,
  TableBodyWrapper,
  TableBody,
  TableRow,
  TableCell,
} from "design/organisms/table";
import * as T from "types/engine-types";
import { Checkbox } from "@material-ui/core";
import { expandedConfigSelector } from "features/application-initialization";

export default function DefaultValuesList({
  editMode,
  label,
  defaultValues,
  setDefaultValues,
}: {
  label: String;
  editMode: Boolean;
  defaultValues: T.DefaultFieldValue[];
  setDefaultValues: React.Dispatch<
    React.SetStateAction<T.DefaultFieldValue[] | null>
  >;
}) {
  const config = useSelector(expandedConfigSelector);
  const parentRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [creditApplicationFields, setCreditApplicationFields] = useState<
    T.CreditApplicationFieldDefinition[]
  >([]);
  const savedConfig = config;
  const fieldsToList = savedConfig.creditApplicationFields;

  useEffect(() => {
    const filtered = fieldsToList.filter(
      (p: T.CreditApplicationFieldDefinition) => {
        return (
          p.valueType.type !== "header" &&
          p.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      },
    );

    setCreditApplicationFields(filtered);
  }, [searchTerm, fieldsToList]);

  const rowVirtualizer = useVirtual({
    size: creditApplicationFields.length,
    parentRef,
    estimateSize: React.useCallback(() => 64, []),
  });

  return (
    <Table
      style={{ flex: "1 0 420px", borderRight: "1px solid rgba(0,0,0,.15)" }}
    >
      <TableActions style={{ padding: "8px" }}>
        <div style={{ alignSelf: "flex-start", flex: "1 1 auto" }}>
          <SearchInput
            label="fields"
            requireDispatch={false}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <ResultsCount>
          {creditApplicationFields.length} <VisibilityIcon />
          <span className="separator">|</span>
          {
            fieldsToList.filter((f) => {
              return f.valueType.type !== "header";
            }).length
          }{" "}
          Total
        </ResultsCount>
      </TableActions>

      <>
        <TableHeader>
          <TableHeaderCell style={{ flexBasis: "100%" }}>
            {label}
          </TableHeaderCell>
        </TableHeader>

        {creditApplicationFields.length && (
          <TableBodyWrapper ref={parentRef}>
            <TableBody style={{ height: `${rowVirtualizer.totalSize}px` }}>
              {rowVirtualizer.virtualItems.map((virtualRow) => {
                const field = creditApplicationFields[virtualRow.index];
                const currentDefaultValue = defaultValues.find(
                  (v) => v.fieldId === field.id,
                );
                const currentValue = currentDefaultValue?.value;
                const currentValueState =
                  currentValue != null
                    ? convertFieldValueToState(currentValue)
                    : newFieldValueState(field.valueType);

                return (
                  <TableRow
                    key={virtualRow.index}
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    <TableCell style={{ padding: "4px 0", flexBasis: "100%" }}>
                      <>
                        <Field
                          disabled={!editMode}
                          fieldState={currentValueState}
                          required={false}
                          showErrors={false}
                          margin="dense"
                          field={field}
                          parentState={null}
                          setState={(stateValue) => {
                            const newValueState: FieldValueState =
                              typeof stateValue === "object"
                                ? stateValue
                                : stateValue(currentValueState);
                            const newValue = convertStateToFieldValue(
                              field.valueType,
                              newValueState,
                              false,
                            );

                            const prunedDefaults = defaultValues.filter((v) => {
                              return v.fieldId !== field.id;
                            });

                            if (newValue) {
                              setDefaultValues([
                                ...prunedDefaults,
                                {
                                  fieldId: field.id,
                                  hidden: !!currentDefaultValue?.hidden,
                                  value: newValue,
                                },
                              ]);
                            } else {
                              setDefaultValues(prunedDefaults);
                            }
                          }}
                        />

                        <label>
                          <Checkbox
                            checked={!!currentDefaultValue?.hidden}
                            onChange={() => {
                              if (currentDefaultValue) {
                                const prunedDefaults = defaultValues.filter(
                                  (v) => {
                                    return v.fieldId !== field.id;
                                  },
                                );

                                setDefaultValues([
                                  ...(prunedDefaults || []),
                                  {
                                    ...currentDefaultValue,
                                    hidden: !currentDefaultValue.hidden,
                                  },
                                ]);
                              }
                            }}
                            disabled={!editMode || !currentValueState}
                          />{" "}
                          Hidden
                        </label>
                      </>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </TableBodyWrapper>
        )}
      </>
    </Table>
  );
}
