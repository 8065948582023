import React, { useCallback, useEffect, useState } from "react";
import { Box, BoxProps, Button, List, ListItem } from "@material-ui/core";
import { dataTablesSelector, getDataTables } from "features/data-tables";
import { productsSelector, getProducts } from "features/products";
import { rulesSelector, getRules } from "features/rules";
import * as Api from "api";
import * as T from "types/engine-types";
import { useSelector, useDispatch } from "react-redux";
import Link from "design/atoms/link";
import EnglishList from "design/atoms/english-list";
import { expandedConfigSelector } from "features/application-initialization";
import { localAccessId } from "features/access-id";
import { getErrorMessage } from "features/utils";

export const RefSourcesViewer = React.memo(
  ({ objectId, ...boxProps }: { objectId: T.ObjectId } & BoxProps) => {
    type State =
      | { status: "unopened" }
      | { status: "loading" }
      | { status: "error" }
      | { status: "loaded"; refSources: T.RefSource[] };

    const [state, setState] = useState<State>({ status: "unopened" });

    // reset state to unopened whenever objectId changes
    useEffect(() => {
      setState({ status: "unopened" });
    }, [setState, objectId]);

    // apparently the slices can be empty if these actions haven't been dispatched yet
    // so dispatch them the first time it's opened
    // if we loaded these on app init, or could make them load lazily,
    // we wouldn't need this.
    const dispatch = useDispatch();
    const [hasBeenOpenedOnce, setHasBeenOpenedOnce] = useState(false);
    useEffect(() => {
      if (hasBeenOpenedOnce) {
        dispatch(getProducts());
        dispatch(getRules());
        dispatch(getDataTables());
      }
    }, [dispatch, hasBeenOpenedOnce]);

    const open = useCallback(async () => {
      try {
        setHasBeenOpenedOnce(true);
        setState({ status: "loading" });
        const refSources = await Api.findReferences(objectId);
        setState({ status: "loaded", refSources });
      } catch (error) {
        newrelic.noticeError(getErrorMessage(error));
        setState({ status: "error" });
      }
    }, [objectId]);

    return (
      <Box {...boxProps}>
        {state.status === "unopened" && (
          <Button variant="outlined" onClick={open}>
            Show References
          </Button>
        )}
        {state.status !== "unopened" && "Referenced from:"}
        {state.status === "loading" && " loading..."}
        {state.status === "error" &&
          "An error occurred while loading field references."}
        {state.status === "loaded" && (
          <RefSourceItems refSources={state.refSources} />
        )}
      </Box>
    );
  },
);

const RefSourceItems = React.memo(
  ({ refSources }: { refSources: T.RefSource[] }) => {
    const config = useSelector(expandedConfigSelector);
    const { products } = useSelector(productsSelector);
    const { dataTables } = useSelector(dataTablesSelector);
    const { rules } = useSelector(rulesSelector);

    const fieldMarkup = (id: T.FieldId) => {
      const field = config.allFieldsById.get(id);
      return field ? `"${field.name}"` : "<Unknown Field>";
    };
    const accessId = localAccessId();
    const productMarkup = (id: T.ProductId) => {
      const product = products ? products.find((p) => p.id === id) : undefined;

      return product ? (
        <Link to={`/c/${accessId}/products/${id}`}>{product.name}</Link>
      ) : (
        "<Unknown>"
      );
    };

    const ruleMarkup = (id: T.RuleId) => {
      const rule = rules.find((r) => r.id === id);

      return rule ? (
        <Link to={`/c/${accessId}/rules/${id}`}>{rule.name}</Link>
      ) : (
        "<Unknown>"
      );
    };

    const dataTableMarkup = (id: T.DataTableId) => {
      const dataTable = dataTables.find((t) => t.id === id);

      return dataTable ? (
        <Link to={`/c/${accessId}/data-tables/${id}`}>dataTable.name</Link>
      ) : (
        "<Unknown>"
      );
    };

    const calcMatchesRefSource = (
      calc: T.Calculation,
      refSource:
        | T.RefSource.CalculationField
        | T.RefSource.CalculationLookup
        | T.RefSource.CalculationLookupField,
    ) => {
      switch (refSource.type) {
        case "calculation-field":
          return calc.type === "field" && calc.field.id === refSource.fieldId;
        case "calculation-lookup":
        case "calculation-lookup-field":
          return (
            calc.type === "data-table-lookup" &&
            calc.lookup.id === refSource.lookupId
          );
      }
    };

    const calculationGroupName = (
      refSource:
        | T.RefSource.CalculationField
        | T.RefSource.CalculationLookup
        | T.RefSource.CalculationLookupField,
    ) => {
      const stage = config.calcStages.find((stage) =>
        stage.calculations.some((calc) =>
          calcMatchesRefSource(calc, refSource),
        ),
      );
      return stage?.name || "<Unknown Calculation Group>";
    };

    const refSourceItem = (refSource: T.RefSource): JSX.Element => {
      switch (refSource.type) {
        case "product":
          return <>Product {productMarkup(refSource.productId)}</>;
        case "product-field-value":
          return (
            <>
              Field {fieldMarkup(refSource.fieldId)} on Product&nbsp;
              {productMarkup(refSource.productId)}
            </>
          );
        case "application-field":
          return <>Application Field {fieldMarkup(refSource.fieldId)}</>;
        case "product-field":
          return <>Product Field {fieldMarkup(refSource.fieldId)}</>;
        case "builtin-field":
          return <>Builtin Field {fieldMarkup(refSource.fieldId)}</>;
        case "adjusted-pricing-field-settings":
          return (
            <>
              Adjusted Rate, Adjusted Price, or Adjusted Rate Lock Period field
              settings (on Rate Sheets page)
            </>
          );
        case "calculation-field":
          return (
            <>
              Calculated Field {fieldMarkup(refSource.fieldId)} in{" "}
              {calculationGroupName(refSource)}
            </>
          );
        case "calculation-lookup":
          const calc = config.calcStages
            .flatMap((s) => s.calculations)
            .find((c) => calcMatchesRefSource(c, refSource));
          const fields =
            calc?.type === "data-table-lookup" ? calc.lookup.fields : [];
          const fieldNames = fields.map((f) => fieldMarkup(f.id));
          const fieldNamesMarkup =
            fieldNames.length === 0 ? (
              "no fields"
            ) : (
              <>
                {" "}
                fields <EnglishList items={fieldNames} />
              </>
            );

          return (
            <>
              A Data Table Lookup producing {fieldNamesMarkup} in{" "}
              {calculationGroupName(refSource)}
            </>
          );
        case "calculation-lookup-field":
          return (
            <>
              Output Field {fieldMarkup(refSource.fieldId)} in a data table
              lookup calculation in {calculationGroupName(refSource)}
            </>
          );
        case "rule":
          return <>Rule &nbsp;{ruleMarkup(refSource.ruleId)}</>;
        case "data-table":
        case "data-table-column":
        case "data-table-body":
          return <>Data Table {dataTableMarkup(refSource.tableId)}</>;
      }
    };

    return (
      <List>
        {refSources.map((refSource) => (
          <ListItem key={Object.values(refSource).join(",")}>
            {refSourceItem(refSource)}
          </ListItem>
        ))}
      </List>
    );
  },
);
