import { Map as IMap, Set as ISet } from "immutable";
import _ from "lodash";
import Moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, CircularProgress } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

import * as Api from "api";
import { SearchableDropdown } from "design/molecules/dropdown";
import { FileSave, SaveFileAs } from "design/atoms/save-file-as";
import SearchableMultiSelectList from "design/organisms/searchable-multi-select-list";
import * as T from "types/engine-types";
import * as ImportExport from "features/import-export";
import { Snapshot } from "features/import-export";
import { Setter, useById, useIMapSetter } from "features/utils";

export const ExportPage = React.memo(() => {
  const [snapshotLoad, setSnapshotLoad] = useState<Api.LoadState<Snapshot>>({
    status: "loading",
  });
  const [statusText, setStatusText] = useState("Starting...");

  useEffect(() => {
    async function load() {
      try {
        const snapshot: Snapshot = await ImportExport.getSnapshot(
          setStatusText,
        );
        setStatusText("Loaded.");
        setSnapshotLoad({
          status: "loaded",
          value: snapshot,
        });
      } catch (err) {
        if (err instanceof Error) {
          newrelic.noticeError(err);
          setSnapshotLoad({ status: "error", error: err });
        }
      }
    }

    load();
  }, []);

  if (snapshotLoad.status === "loading") {
    return (
      <Box
        px={3}
        my={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="600px"
        width="100%"
      >
        <Box flex="1" />
        <Box mb={2}>
          <CircularProgress />
        </Box>
        <Box fontSize="18px">{statusText}</Box>
        <Box flex="1" />
      </Box>
    );
  }

  if (snapshotLoad.status === "error") {
    return (
      <Box px={3} my={2}>
        Error loading data
      </Box>
    );
  }

  return <LoadedExportPage snapshot={snapshotLoad.value} />;
});

const LoadedExportPage = React.memo(({ snapshot }: { snapshot: Snapshot }) => {
  const [investor, setInvestor] = useState<T.InvestorHeader | null>(null);

  return (
    <Box>
      <Box px={3} my={2} fontSize="36px">
        Export account data
      </Box>

      <Box px={3} my={2}>
        Export data for a particular investor.
      </Box>
      <Box px={3} my={2} maxWidth="300px">
        <SearchableDropdown
          label="Select Investor"
          options={snapshot.investors}
          getOptionLabel={getInvestorName}
          value={investor}
          setValue={setInvestor}
        />
      </Box>
      {investor !== null && (
        <InvestorExporter
          key={investor.id}
          snapshot={snapshot}
          investor={investor}
        />
      )}
    </Box>
  );
});

function getInvestorName(investor: T.InvestorHeader): string {
  return investor.name;
}

const InvestorExporter = React.memo(
  ({
    snapshot,
    investor,
  }: {
    snapshot: Snapshot;
    investor: T.InvestorHeader;
  }) => {
    const productsById = useById(snapshot.products);
    const rulesById = useById(snapshot.rules);

    const sortedEnumTypeIds = useMemo(
      () => _.sortBy(snapshot.config.enumTypes, "name").map((e) => e.id),
      [snapshot],
    );
    const getEnumTypeName = useCallback(
      (id: T.EnumTypeId) =>
        snapshot.config.enumTypesById.get(id)?.name || "<Unknown Enumeration>",
      [snapshot],
    );
    const [selectedEnumTypeIds, setSelectedEnumTypeIds] = useState(() =>
      ISet(sortedEnumTypeIds),
    );

    const applicationFieldIds = useMemo(
      () => snapshot.config.creditApplicationFields.map((e) => e.id),
      [snapshot],
    );
    const [selectedApplicationFieldIds, setSelectedApplicationFieldIds] =
      useState(() => ISet(applicationFieldIds));
    const productFieldIds = useMemo(
      () => snapshot.config.productFields.map((e) => e.id),
      [snapshot],
    );
    const [selectedProductFieldIds, setSelectedProductFieldIds] = useState(() =>
      ISet(productFieldIds),
    );
    const pipelineFieldIds = useMemo(
      () => snapshot.config.pipelineOnlyFields.map((e) => e.id),
      [snapshot],
    );
    const [selectedPipelineFieldIds, setSelectedPipelineFieldIds] = useState(
      () => ISet(pipelineFieldIds),
    );
    const getFieldName = useCallback(
      (id: T.FieldId) =>
        snapshot.config.allFieldsById.get(id)?.name || "<Unknown Field>",
      [snapshot],
    );

    const sortedInvestorProductIds = useMemo(
      () =>
        _.sortBy(
          snapshot.products.filter((p) => p.investorId === investor.id),
          "name",
        ).map((p) => p.id),
      [investor, snapshot],
    );
    const getProductName = useCallback(
      (id: T.ProductId) => productsById.get(id)?.name || "<Unknown Product>",
      [productsById],
    );
    const [selectedProductIds, setSelectedProductIds] = useState(() =>
      ISet(sortedInvestorProductIds),
    );

    const [selectedRuleIdsByStageId, setSelectedRuleIdsByStageId] = useState(
      () =>
        IMap(
          snapshot.config.rulesStages.map((stage) => [
            stage.id,
            ISet(
              snapshot.rules
                .filter((r) => r.stageId === stage.id)
                .map((r) => r.id),
            ),
          ]),
        ),
    );
    const ruleIdsSetters = useIMapSetter(setSelectedRuleIdsByStageId);

    const ruleIdsSet = selectedRuleIdsByStageId.valueSeq().flatten(1).toSet();

    const [fileSave, openSaveAs] = useState<FileSave>();
    const saveExportFile = useCallback(() => {
      const exportedData = ImportExport.exportSelections(
        snapshot,
        {
          investorId: investor.id,
          enumTypeIds: selectedEnumTypeIds,
          applicationFieldIds: selectedApplicationFieldIds,
          productFieldIds: selectedProductFieldIds,
          pipelineFieldIds: selectedPipelineFieldIds,
          productIds: selectedProductIds,
          ruleIds: ruleIdsSet as ISet<T.RuleId>,
        },
        snapshot.config.allSystemFieldsById,
      );
      const exportFileBlob = new Blob([JSON.stringify(exportedData, null, 2)], {
        type: "application/octet-stream",
      });

      const date = Moment().format("YYYY-MM-DD");
      openSaveAs({
        filename: `${investor.code}_export_${date}.json`,
        url: URL.createObjectURL(exportFileBlob),
      });
    }, [
      snapshot,
      investor,
      selectedEnumTypeIds,
      selectedApplicationFieldIds,
      selectedProductFieldIds,
      selectedPipelineFieldIds,
      selectedProductIds,
      ruleIdsSet,
    ]);

    return (
      <>
        <SaveFileAs file={fileSave} />

        <Box px={3} my={2} fontSize="24px">
          Enumerations
        </Box>
        <Box px={3} my={2}>
          <SearchableMultiSelectList
            label="All Enumerations"
            width="600px"
            noItemsMessage="No enumerations found."
            noResultsMessage="No enumerations found matching this search query."
            options={sortedEnumTypeIds}
            selected={selectedEnumTypeIds}
            getOptionLabel={getEnumTypeName}
            onChange={setSelectedEnumTypeIds}
          />
        </Box>
        <Box px={3} my={2} fontSize="24px">
          Application Form Fields
        </Box>
        <Box px={3} my={2}>
          <SearchableMultiSelectList
            label="All Application Form Fields"
            width="600px"
            noItemsMessage="No application form fields found."
            noResultsMessage="No application form fields found matching this search query."
            options={applicationFieldIds}
            selected={selectedApplicationFieldIds}
            getOptionLabel={getFieldName}
            onChange={setSelectedApplicationFieldIds}
          />
        </Box>
        <Box px={3} my={2} fontSize="24px">
          Pipeline Fields
        </Box>
        <Box px={3} my={2}>
          <SearchableMultiSelectList
            label="All Pipeline Fields"
            width="600px"
            noItemsMessage="No pipeline fields found."
            noResultsMessage="No pipeline fields found matching this search query."
            options={pipelineFieldIds}
            selected={selectedPipelineFieldIds}
            getOptionLabel={getFieldName}
            onChange={setSelectedPipelineFieldIds}
          />
        </Box>
        <Box px={3} my={2} fontSize="24px">
          Product Fields
        </Box>
        <Box px={3} my={2}>
          <SearchableMultiSelectList
            label="All Product Fields"
            width="600px"
            noItemsMessage="No product fields found."
            noResultsMessage="No product fields found matching this search query."
            options={productFieldIds}
            selected={selectedProductFieldIds}
            getOptionLabel={getFieldName}
            onChange={setSelectedProductFieldIds}
          />
        </Box>
        <Box px={3} my={2} fontSize="24px">
          Products
        </Box>
        <Box px={3} my={2}>
          <SearchableMultiSelectList
            label={`${investor.name} Products`}
            width="600px"
            noItemsMessage="No products found under this investor."
            noResultsMessage="No products found matching this search query."
            options={sortedInvestorProductIds}
            selected={selectedProductIds}
            getOptionLabel={getProductName}
            onChange={setSelectedProductIds}
          />
        </Box>
        <Box px={3} my={2} fontSize="24px">
          Rules
        </Box>
        <Box px={3} my={2} display="flex">
          {snapshot.config.rulesStages.map((stage) => (
            <RuleSelector
              rulesById={rulesById}
              stage={stage}
              selected={selectedRuleIdsByStageId.get(stage.id)!}
              setSelected={ruleIdsSetters.withKey(stage.id)}
            />
          ))}
        </Box>
        <Box px={3} mt={3} mb={2} fontSize="24px">
          Export
        </Box>
        <Box px={3} mt={2} mb={3}>
          <Button
            variant="outlined"
            size="large"
            startIcon={<GetAppIcon />}
            onClick={saveExportFile}
          >
            Save Export File
          </Button>
        </Box>
      </>
    );
  },
);

const RuleSelector = React.memo(
  ({
    stage,
    rulesById,
    selected,
    setSelected,
  }: {
    rulesById: IMap<T.RuleId, T.Rule>;
    stage: T.ExecutionStage;
    selected: ISet<T.RuleId>;
    setSelected: Setter<ISet<T.RuleId>>;
  }) => {
    const availableRuleIds = rulesById
      .valueSeq()
      .filter((r) => r.stageId === stage.id)
      .sortBy((r) => r.name)
      .map((r) => r.id)
      .toArray();

    const getRuleName = useCallback(
      (id: T.RuleId) => rulesById.get(id)?.name || "<Unknown Rule>",
      [rulesById],
    );

    return (
      <SearchableMultiSelectList<T.RuleId>
        key={stage.id}
        label={stage.name + " Rules"}
        noItemsMessage="No rules available."
        noResultsMessage="No rules found with that name."
        options={availableRuleIds}
        selected={selected}
        getOptionLabel={getRuleName}
        onChange={setSelected}
      />
    );
  },
);
