import styled from "styled-components";
import breakpoints from "design/subatomics/breakpoints";
import whitespace from "design/subatomics/whitespace";
import color from "design/subatomics/colors";

export const LoanComparison = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;

  ${breakpoints.mobile} {
  }

  ${breakpoints.desktop} {
  }
`;

export const DetailsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  box-sizing: border-box;

  .page-component-loan-pricing-product-details {
    min-width: 360px;
    flex: 1 1 auto;
    border-right: 1px solid ${color({ color: "border" })};
    height: max-content;

    > div {
      overflow: hidden;
    }

    &:last-child {
      border-right: none;
    }
  }

  .organism-pricing-table {
    margin: ${whitespace("least")};
    box-sizing: border-box;
    width: auto;
  }

  ${breakpoints.mobile} {
    .page-component-loan-pricing-product-details {
      min-width: 100%;
    }
  }

  ${breakpoints.desktop} {
    .organism-pricing-table {
      margin: ${whitespace("least")};
    }
  }
`;
