import React, { ReactNode } from "react";
import { Label } from "./styles";

export default React.memo(
  ({ className, children }: { className?: string; children: ReactNode }) => {
    return (
      <Label className={`${className || ""} atom-label`}>{children}</Label>
    );
  },
);
