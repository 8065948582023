import { Map as IMap, Set as ISet } from "immutable";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CommentIcon from "@material-ui/icons/Comment";
import DescriptionIcon from "@material-ui/icons/Description";
import WarningIcon from "@material-ui/icons/Warning";
import EnglishList from "design/atoms/english-list";
import { Configuration } from "config";
import * as T from "types/engine-types";
import { getMissingFieldsOnPriceScenario } from "features/execution";
import * as Stages from "features/stages";
import { useDispatch, useSelector } from "react-redux";
import {
  exportLoan,
  loansSelector,
  resolveEffectiveFieldValues,
} from "features/loans";
import { investorsSelector } from "features/investors";
import { usePermissions } from "features/roles";
import iFrameMessageTransport from "features/iframe-message-transport";

import CauseLink from "../cause-link";
import MessageSection from "../message-section";
import AdjustmentsSection from "../adjustments-section";
import CalculationsSection from "../calculations-section";
import StipulationsSection from "../stipulations-section";
import { nonNullApplicationInitializationSelector } from "features/application-initialization";
import { UiValidationError } from "features/utils";
import { useVisibleFields } from "features/roles";
import {
  getRejectionReasonInfo,
  getMissingFieldInfo,
  convertExecutionErrorToString,
} from "features/loans";
import Icon from "design/atoms/icon";

import {
  faExclamationCircle,
  faCheckCircle,
  faCircleXmark,
  faCircleQuestion,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
const PriceScenarioDialog = React.memo(
  ({
    product,
    scenario,
    config,
    rulesById,
    close,
    result,
  }: {
    product: T.ProductHeader;
    scenario: T.PriceScenarioResult;
    result: T.ProductExecutionResult;
    config: Configuration;
    rulesById: IMap<T.RuleId, T.DecoratedRuleHeader>;
    close: () => void;
  }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [lockRequestSent, setLockRequestSent] = useState<boolean>(false);
    const { priceLockingConfig, currentFormValues } =
      useSelector(loansSelector);
    const hasPermission = usePermissions();
    const hasProfitViewPerm = hasPermission(
      "pricing-profit-margin-adjustments-view",
    );
    const calcStagesByScope = Stages.getCalcStagesByScope(config);
    const priceScenarioCalculatedFieldValuesById = IMap(
      [...scenario.calculatedFields].map((mapping) => [
        mapping.fieldId,
        mapping.value,
      ]),
    );
    const investorsState = useSelector(investorsSelector);
    const { myRole, myDefaultFieldValues, myPricingProfile } = useSelector(
      nonNullApplicationInitializationSelector,
    );
    const investor = investorsState.investors.find(
      (i) => i.id === product?.investorId,
    );
    const hasVisibleField = useVisibleFields();

    let title: string;
    let titleIcon: JSX.Element | null;
    let description: JSX.Element | null;
    let content: JSX.Element | null;
    const {
      client: { accessId },
    } = useSelector(nonNullApplicationInitializationSelector);

    switch (scenario.status) {
      case "missing-configuration":
        throw new Error(
          "we shouldn't be displaying a PriceScenarioDialog if missing configuration",
        );
      case "approved":
        title = "Approved";
        titleIcon = <Icon icon={faCheckCircle} />;
        description = null;
        content = (
          <Box mt={-3}>
            {calcStagesByScope.priceScenario.map((stage) => (
              <CalculationsSection
                config={config}
                stage={stage}
                fieldValuesById={priceScenarioCalculatedFieldValuesById}
              />
            ))}
            <AdjustmentsSection
              adjustments={ISet(scenario.rateAdjustments)}
              adjustmentKindName="Rate"
              rulesById={rulesById}
            />
            <AdjustmentsSection
              adjustments={ISet(scenario.priceAdjustments)}
              adjustmentKindName="Price"
              rulesById={rulesById}
            />
            {scenario.marginAdjustments && hasProfitViewPerm && (
              <AdjustmentsSection
                adjustments={ISet(scenario.marginAdjustments)}
                adjustmentKindName="Profit Margin Price"
                rulesById={rulesById}
              />
            )}
            <StipulationsSection
              stipulations={ISet(scenario.stipulations)}
              rulesById={rulesById}
            />
          </Box>
        );
        break;
      case "review-required": {
        const reviewRequirementReasons = getRejectionReasonInfo(
          config,
          rulesById,
          scenario.reviewRequirements,
        );

        title = "Review Required";
        titleIcon = <Icon icon={faCircleQuestion} />;
        description = (
          <>
            Given the information provided, this loan requires further review
            before it can be approved. Pricing may change upon review. The
            following{" "}
            {scenario.reviewRequirements.length === 1
              ? `reason was`
              : `${scenario.reviewRequirements.length} reasons were`}{" "}
            given for this requirement:
          </>
        );
        content = (
          <>
            {reviewRequirementReasons.map((reason, reasonIndex) => (
              <MessageSection
                key={reasonIndex}
                icon={<CommentIcon />}
                isListItem
                isInDialog
              >
                {reason.title}
                {reason.cause && (
                  <>
                    {" "}
                    (
                    <CauseLink
                      title={reason.cause.name}
                      link={reason.cause.link}
                    />
                    )
                  </>
                )}
              </MessageSection>
            ))}
            <Box mt={-3}>
              {calcStagesByScope.priceScenario.map((stage) => (
                <CalculationsSection
                  config={config}
                  stage={stage}
                  fieldValuesById={priceScenarioCalculatedFieldValuesById}
                />
              ))}
              <AdjustmentsSection
                adjustments={ISet(scenario.rateAdjustments)}
                adjustmentKindName="Rate"
                rulesById={rulesById}
              />
              <AdjustmentsSection
                adjustments={ISet(scenario.priceAdjustments)}
                adjustmentKindName="Price"
                rulesById={rulesById}
              />
              {scenario.marginAdjustments && hasProfitViewPerm && (
                <AdjustmentsSection
                  adjustments={ISet(scenario.marginAdjustments)}
                  adjustmentKindName="Profit Margin Price"
                  rulesById={rulesById}
                />
              )}
              <StipulationsSection
                stipulations={ISet(scenario.stipulations)}
                rulesById={rulesById}
              />
            </Box>
          </>
        );
        break;
      }
      case "rejected": {
        const rejectionReasons = getRejectionReasonInfo(
          config,
          rulesById,
          scenario.rejections,
        );

        const reviewRequirementReasons = getRejectionReasonInfo(
          config,
          rulesById,
          scenario.reviewRequirements,
        );

        title = "Rejected";
        titleIcon = <Icon icon={faCircleXmark} />;
        description = (
          <>
            Given the information provided, this loan would not meet this
            product's eligibility guidelines.
            {rejectionReasons.length === 1
              ? " The following reason was "
              : ` The following ${rejectionReasons.length} reasons were `}
            given for the rejection:
          </>
        );
        content = (
          <>
            {rejectionReasons.map((reason, reasonIndex) => (
              <MessageSection
                key={reasonIndex}
                icon={<ClearIcon />}
                isListItem
                isInDialog
              >
                {reason.title}
                {reason.cause && (
                  <>
                    {" "}
                    (
                    <CauseLink
                      title={reason.cause.name}
                      link={reason.cause.link}
                    />
                    )
                  </>
                )}
              </MessageSection>
            ))}
            {reviewRequirementReasons.length > 0 && (
              <>
                <p>
                  Furthermore, if the above rejection reasons were not present,
                  this loan would still be subject to further review for the
                  following{" "}
                  {reviewRequirementReasons.length === 1
                    ? "reason"
                    : `${reviewRequirementReasons.length} reasons`}{" "}
                  before it could be approved:
                </p>
                {reviewRequirementReasons.map((reason, reasonIndex) => (
                  <MessageSection
                    key={reasonIndex}
                    icon={<CommentIcon />}
                    isListItem
                    isInDialog
                  >
                    {reason.title}
                    {reason.cause && (
                      <>
                        {" "}
                        (
                        <CauseLink
                          title={reason.cause.name}
                          link={reason.cause.link}
                        />
                        )
                      </>
                    )}
                  </MessageSection>
                ))}
              </>
            )}
            <Box mt={-3}>
              {calcStagesByScope.priceScenario.map((stage) => (
                <CalculationsSection
                  config={config}
                  stage={stage}
                  fieldValuesById={priceScenarioCalculatedFieldValuesById}
                />
              ))}
              <AdjustmentsSection
                adjustments={ISet(scenario.rateAdjustments)}
                adjustmentKindName="Rate"
                rulesById={rulesById}
              />
              <AdjustmentsSection
                adjustments={ISet(scenario.priceAdjustments)}
                adjustmentKindName="Price"
                rulesById={rulesById}
              />
              {scenario.marginAdjustments && hasProfitViewPerm && (
                <AdjustmentsSection
                  adjustments={ISet(scenario.marginAdjustments)}
                  adjustmentKindName="Profit Margin Price"
                  rulesById={rulesById}
                />
              )}
              <StipulationsSection
                stipulations={ISet(scenario.stipulations)}
                rulesById={rulesById}
              />
            </Box>
          </>
        );
        break;
      }
      case "error":
        const isMissingData = scenario.errors.every(
          (err) => err.type === "blank-field",
        );

        if (isMissingData) {
          const missingFieldInfo = getMissingFieldInfo(
            config,
            rulesById,
            getMissingFieldsOnPriceScenario(scenario),
            accessId,
          );

          title = "Information needed";
          titleIcon = <Icon icon={faExclamationCircle} />;
          description = (
            <>
              More information is needed to produce an Approved or Rejected
              response at this price point. Fill out the following{" "}
              {missingFieldInfo.length === 1 ? "field" : "fields"} to continue:
            </>
          );
          content = (
            <>
              {missingFieldInfo.map((missingField) => (
                <MessageSection
                  key={missingField.fieldId}
                  icon={<DescriptionIcon />}
                  isInDialog
                >
                  <strong>{missingField.fieldName}</strong> is needed in{" "}
                  <EnglishList
                    items={missingField.causes.map((cause) => {
                      if (cause.link) {
                        return (
                          <CauseLink title={cause.title} link={cause.link} />
                        );
                      } else {
                        return cause.title;
                      }
                    })}
                  />
                  .
                </MessageSection>
              ))}
              {calcStagesByScope.priceScenario.map((stage) => (
                <CalculationsSection
                  config={config}
                  stage={stage}
                  fieldValuesById={priceScenarioCalculatedFieldValuesById}
                />
              ))}
            </>
          );
        } else {
          const errorMessages = scenario.errors.map((err) =>
            convertExecutionErrorToString(err, config.allFieldsById),
          );

          title = "Error";
          titleIcon = <Icon icon={faCircleExclamation} />;
          description = (
            <>
              {errorMessages.length === 1 ? "An error " : "Multiple errors "}
              occurred while evaluating this product.
            </>
          );
          content = (
            <>
              {errorMessages.map((msg, msgIndex) => (
                <MessageSection
                  key={msgIndex}
                  icon={<WarningIcon />}
                  isListItem
                  isInDialog
                >
                  {msg}
                </MessageSection>
              ))}
              {calcStagesByScope.priceScenario.map((stage) => (
                <CalculationsSection
                  config={config}
                  stage={stage}
                  fieldValuesById={priceScenarioCalculatedFieldValuesById}
                />
              ))}
            </>
          );
        }
    }

    return (
      // specify container to make print positioning a bit easier
      <Dialog open={true} maxWidth="md" fullWidth onClose={close}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            {titleIcon !== null && (
              <Box display="flex" mr="6px">
                {titleIcon}
              </Box>
            )}
            <Box>{title}</Box>
          </Box>
        </DialogTitle>
        <DialogContent data-selector="price-scenario-dialog-box">
          {description && <DialogContentText>{description}</DialogContentText>}
          <div style={{ minHeight: 200, marginTop: "24px" }}>{content}</div>
        </DialogContent>
        <DialogActions>
          {priceLockingConfig.enabled && (
            <Button
              disabled={lockRequestSent}
              onClick={() => {
                if (currentFormValues != null) {
                } else {
                  console.warn(
                    "currentFormValues was not set while sending price lock message",
                  );
                  return;
                }

                if (
                  investor &&
                  product &&
                  scenario &&
                  currentFormValues &&
                  myRole &&
                  myPricingProfile
                ) {
                  // Resolve fields based on the input field values, field
                  // conditions, and default values. For more details,
                  // see DEV-1137: https://loanpass.atlassian.net/browse/DEV-1137
                  const effectiveFieldValues = resolveEffectiveFieldValues(
                    config.creditApplicationFields,
                    currentFormValues,
                    myDefaultFieldValues ?? [],
                  );
                  iFrameMessageTransport.postMessage({
                    message: "price-lock",
                    role: {
                      id: myRole.id,
                      name: myRole.name,
                    },
                    pricingProfile: myPricingProfile,
                    investor,
                    product,
                    productSpecifications: config.productFields.filter(
                      (field) => hasVisibleField(field.id),
                    ),
                    scenario,
                    creditApplicationFields: effectiveFieldValues,
                  });

                  setLockRequestSent(true);
                } else {
                  throw new UiValidationError(
                    "Select at least one pricing table to import",
                  );
                }
              }}
            >
              {lockRequestSent ? (
                <>
                  {priceLockingConfig.lockRequestLabel} Sent <CheckIcon />
                </>
              ) : (
                <>{priceLockingConfig.lockRequestLabel}</>
              )}
            </Button>
          )}

          <Button
            data-selector="product-export-button"
            onClick={() => {
              dispatch(exportLoan({ scenario, product, result }));
              close();
              history.push(`/c/${accessId}/loan-pricing/export`);
            }}
          >
            Export Scenario to PDF
          </Button>
          <Button data-selector="dialog-close-button" onClick={close}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

export default PriceScenarioDialog;
