import styled from "styled-components";
import { styles } from "design/atoms/typography";
import breakpoints from "design/subatomics/breakpoints";

export const Label = styled.span`
  ${breakpoints.mobile} {
    ${styles.smallText}
    &.flex1 {
      flex-grow: 1;
    }
  }

  ${breakpoints.desktop} {
    ${styles.text}
    &.flex1 {
      flex-grow: 1;
    }
  }
`;
