import { stringifyUrl } from "query-string";
import { useState, useEffect, useCallback } from "react";
import Bottleneck from "bottleneck";
import * as T from "./types/engine-types";
import { DomainValidationErrors } from "./types/generated-types";
import { localAccessId } from "./features/access-id";
import { uploadJsonBlob } from "./features/blobs";
import { activeRoleId } from "./features/active-role-id";

// Limit how many API requests can be called concurrently
const API_CONCURRENCY_LIMIT = Number(
  process.env.REACT_APP_API_CONCURRENCY_LIMIT ?? 10,
);
const apiBottleneck = new Bottleneck({
  maxConcurrent: API_CONCURRENCY_LIMIT,
});

type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

// HACK: Workaround for TypeScript restrictions around `instanceof` and
// `Error` subclasses. Without this hack to set `__proto__` in ES5 mode,
// `(new ApiError) instanceof ApiError` returns false.
// https://github.com/Microsoft/TypeScript/issues/13965#issuecomment-278570200
export class ApiError extends Error {
  __proto__: Error;
  constructor(message?: string) {
    const trueProto = new.target.prototype;
    super(message);

    this.__proto__ = trueProto;
  }
}

export class DetailedInvalidRequest extends ApiError {
  public errors: DomainValidationErrors;

  constructor(errors: DomainValidationErrors, message?: string) {
    super(message || "Your request contains errors.");
    this.errors = errors;
  }
}

export class InvalidRequest extends ApiError {
  constructor(message: string) {
    super(message || "Your request contains errors.");
  }
}

/// the type of error thrown if there is no session / session is expired
export class UnauthorizedError extends ApiError {}
UnauthorizedError.prototype.name = "UnauthorizedError";

export class ForbiddenError extends ApiError {}
ForbiddenError.prototype.name = "ForbiddenError";

export class NotFoundError extends ApiError {}
NotFoundError.prototype.name = "NotFoundError";

export class NotUniqueError extends ApiError {}
NotUniqueError.prototype.name = "NotUniqueError";

export class HttpError extends ApiError {
  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }

  statusCode: number;
}
HttpError.prototype.name = "HttpError";

/**
 * Make an API request to the endpoint at `path` with the HTTP method `method` and parameters `params`, returning a Promise
 * containing the raw, untyped response payload. Performs no error handling on the response.
 * Exported to help with debugging.
 */
export async function rawRequest<P extends {} = {}>(
  method: HttpMethod,
  fullPath: string,
  params?: P,
  needSession: boolean = true,
  signal?: AbortSignal,
): Promise<Response> {
  let body: Blob | string | undefined;

  const contentType =
    params instanceof Blob ? "application/octet-stream" : "application/json";

  const headers: Headers = new Headers({
    "Content-Type": contentType,
  });

  if (needSession) {
    const sessionToken = window.localStorage.getItem("sessionToken");
    if (!sessionToken) {
      throw new UnauthorizedError("no session token found");
    }
    headers.append("Authorization", `Bearer ${sessionToken}`);

    const accessId = localAccessId();
    if (accessId) {
      headers.append("X-LoanPass-Client", accessId);
    }

    const roleId = activeRoleId();
    if (roleId) {
      headers.append("X-LoanPass-Role-Id", roleId);
    }
  }

  if (params) {
    switch (method) {
      case "GET":
      case "DELETE":
        fullPath = stringifyUrl({ url: fullPath, query: params as {} });
        break;
      default:
        if (params instanceof Blob) {
          body = params;
        } else {
          body = JSON.stringify(params);
        }
    }
  }

  return apiBottleneck.schedule(() =>
    fetch(fullPath, { method, headers, body, signal }),
  );
}

/**
 * Make an API request to the endpoint at `path` with the HTTP method `method` and parameters `params`, returning a Promise
 * containing a statically-typed, parsed payload. Includes error handling for common server error responses.
 * Exported to help with debugging.
 */
export async function request<T, P extends {} = {}>(
  method: HttpMethod,
  path: string,
  params?: P,
  needsSession: boolean = true,
  signal?: AbortSignal,
): Promise<T> {
  try {
    const fullPath = `/api/${path}`;

    const response = await rawRequest(
      method,
      fullPath,
      params,
      needsSession,
      signal,
    );

    if (response.status === 401) {
      throw new UnauthorizedError("Server responded with 401");
    }

    if (response.status >= 300) {
      const text = await response.text();

      if (response.status === 400) {
        // check for a machine-readable error
        const parsed = tryParseUserError(text);

        if (parsed) {
          switch (parsed.type) {
            case "not-unique":
              throw new NotUniqueError(parsed.value.message);
            case "validation":
              throw new DetailedInvalidRequest(parsed.value);
            case "custom":
            case "bad-client-header":
              throw new InvalidRequest(parsed.value.message);
          }
        }
      } else if (response.status === 403) {
        throw new ForbiddenError(text);
      } else if (response.status === 404) {
        throw new NotFoundError(text);
      }

      throw new HttpError(
        `server responded with ${response.status}: ${
          response.statusText
        }\non ${method.toUpperCase()} ${fullPath}\n${text}`,
        response.status,
      );
    }

    if (response.status === 204) {
      return undefined as unknown as T;
    }

    const value: unknown = await response.json();
    return value as T;
  } catch (err) {
    if (needsSession && err instanceof UnauthorizedError) {
      // for now, we just do a hard redirect to login, causing a full page load
      // and losing any form field state.
      localStorage.removeItem("sessionToken");
      const clientAccessId = localStorage.getItem("clientAccessId");

      const { pathname, search, hash } = window.location;
      const redirect = encodeURIComponent(`${pathname}${search}${hash}`);
      const query = `?redirect=${redirect}`;
      window.location.href = clientAccessId
        ? `/login/${clientAccessId}${query}`
        : `/login${query}`;
    }
    throw err;
  }
}

export function requestWithAbort<T, P extends {} = {}>(
  method: HttpMethod,
  path: string,
  params?: P,
  needSession: boolean = true,
): [Promise<T>, () => void] {
  const controller = new AbortController();

  const promise = request<T, P>(
    method,
    path,
    params,
    needSession,
    controller.signal,
  );

  return [promise, () => controller.abort()];
}

export async function requestWithBlobBody<T, P extends {} = {}>(
  method: HttpMethod,
  path: string,
  params: P,
): Promise<T> {
  const blobId = await uploadJsonBlob(params);
  const requestParams: T.BlobStoreReference<T> = {
    referenceBlobId: blobId,
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const response = await request<T>(method, path, requestParams);
  return response;
}

function tryParseUserError(text: string): T.UserError | false {
  let output: unknown;
  try {
    output = JSON.parse(text);
  } catch (e) {
    if (e instanceof SyntaxError) {
      return false;
    } else {
      throw e;
    }
  }

  if (typeof output !== "object" || output === null) return false;

  const object = output as { type?: unknown };

  if (typeof object.type !== "string" || !typeof object) return false;

  // at this point we know it's an object whose `type` property is a non-empty string.
  // let's assume that's good enough
  return object as T.UserError;
}

export type LoadState<T> =
  | {
      status: "loading";
    }
  | {
      status: "loaded";
      value: T;
    }
  | {
      status: "error";
      error: Error;
    };

export function combineLoadStates<A, B>(
  a: LoadState<A>,
  b: LoadState<B>,
): LoadState<[A, B]>;
export function combineLoadStates<A, B, C>(
  a: LoadState<A>,
  b: LoadState<B>,
  c: LoadState<C>,
): LoadState<[A, B, C]>;
export function combineLoadStates<A, B, C, D>(
  a: LoadState<A>,
  b: LoadState<B>,
  c: LoadState<C>,
  d: LoadState<D>,
): LoadState<[A, B, C, D]>;
export function combineLoadStates<A, B, C, D, E>(
  a: LoadState<A>,
  b: LoadState<B>,
  c: LoadState<C>,
  d: LoadState<D>,
  e: LoadState<E>,
): LoadState<[A, B, C, D, E]>;
export function combineLoadStates<T>(
  ...loadStates: LoadState<T>[]
): LoadState<T[]> {
  for (const loadState of loadStates) {
    if (loadState.status === "error") return loadState;
  }

  for (const loadState of loadStates) {
    if (loadState.status === "loading") return { status: "loading" };
  }

  const values: T[] = [];

  for (const loadState of loadStates) {
    if (loadState.status !== "loaded") {
      // This comment was generated when upgrading react-scripts and eslint
      // TODO: fix the lint rule and remove this eslint-disable comment
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`loadState should be loaded by now: ${loadState}`);
    }

    values.push(loadState.value);
  }

  return {
    status: "loaded",
    value: values,
  };
}

export function mapLoadState<T, U>(
  loadState: LoadState<T>,
  f: (value: T) => U,
): LoadState<U> {
  if (loadState.status === "error" || loadState.status === "loading") {
    return loadState;
  }

  return {
    status: "loaded",
    value: f(loadState.value),
  };
}

// reloads anytime makePromise changes
export function useLoadState<T>(
  promise: Promise<T>,
  abort?: () => void,
): LoadState<T> {
  const [loadState, setLoadState] = useState<LoadState<T>>({
    status: "loading",
  });

  useEffect(() => {
    setLoadState({ status: "loading" });

    promise
      .then((value) => setLoadState({ status: "loaded", value }))
      .catch((error: Error) => setLoadState({ status: "error", error }));

    return abort ?? abort;
  }, [promise, abort]);

  return loadState;
}

/**
 * Makes an API request to the endpoint at `path` with the HTTP method `method` and parameters `params`, and returns a LoadState value and a reload function to re-run the request and get new data.
 *
 * Use in a functional component, at the top level:
 *
 * ```
 * const [loadState, reload] = useRequest<Product[]>("GET", "products");
 * if (loadState.status === "loading") {
 *  return <p>Loading...</p>;
 * }
 *
 * if (loadState.status === "error") {
 * return <p>An error occurred :(</p>;
 * }
 *
 * return <ul>{products.map((p) => <li key={p.id}>p.name</li>)}</ul>;
 * ```
 */
function useRequest<T, P extends {} = {}>(
  method: HttpMethod,
  path: string,
  params?: P,
): [LoadState<T>, () => void] {
  const [loadState, setLoadState] = useState<LoadState<T>>({
    status: "loading",
  });

  // will be set to true in the effect below
  const [shouldMakeRequest, setShouldMakeRequest] = useState<boolean>(false);

  const reload = useCallback(() => {
    setShouldMakeRequest(true);
  }, []);

  // the first time, and any time after that if the inputs have changed, we need to do (or re-do) the request.
  useEffect(() => {
    setShouldMakeRequest(true);
  }, [method, path, params]);

  // if shouldMakeRequest is set to true, we run the request, and
  // switch to status: "loading"
  useEffect(() => {
    if (shouldMakeRequest) {
      request<T, P>(method, path, params)
        .then((value) => {
          setLoadState({
            status: "loaded",
            value,
          });
        })
        .catch((error: Error) => {
          setLoadState({
            status: "error",
            error,
          });
          console.error(`Error during ${method} ${path}: ${error.message}`);
          console.error(error);
        });

      // in the case of when `reload` is called, the load state will
      // probably be "loaded". So we need to set it back to "loading"
      // here.
      setLoadState({ status: "loading" });
      // we've made the request, so we don't need to make it again.
      // When `reload` is called, it will set this back to `true`
      // and the effect will run again
      setShouldMakeRequest(false);
    }

    // NOTE: eslint wants me to add `method`, `path` and `params` to this list,
    // because they are used inside this effect function. They in fact should not
    // be listed here. When they change, they will cause the first effect to fire,
    // setting `shouldMakeRequest` to true, and will indirectly cause this hook
    // to fire.
    //
    // The reason for this separation is so that `reload` can cause this
    // effect to fire again, by setting `shouldMakeRequest` to true.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldMakeRequest]);

  return [loadState, reload];
}

export function usePromise<T>(
  makePromise: () => Promise<T>,
): [LoadState<T>, () => void] {
  const [loadState, setLoadState] = useState<LoadState<T>>({
    status: "loading",
  });

  // will be set to true in the effect below
  const [shouldMakeRequest, setShouldMakeRequest] = useState<boolean>(false);

  const reload = useCallback(() => {
    setShouldMakeRequest(true);
  }, []);

  // the first time, and any time after that if makePromise has changed, we need to do (or re-do) the request.
  useEffect(() => {
    setShouldMakeRequest(true);
  }, [makePromise]);

  // if shouldMakeRequest is set to true, we run the request, and
  // switch to status: "loading"
  useEffect(() => {
    if (shouldMakeRequest) {
      makePromise()
        .then((value) => {
          setLoadState({
            status: "loaded",
            value,
          });
        })
        .catch((error: Error) => {
          setLoadState({
            status: "error",
            error,
          });
          console.log("usePromise: promise rejected with the following error:");
          console.error(error);
        });

      // in the case of when `reload` is called, the load state will
      // probably be "loaded". So we need to set it back to "loading"
      // here.
      setLoadState({ status: "loading" });
      // we've made the request, so we don't need to make it again.
      // When `reload` is called, it will set this back to `true`
      // and the effect will run again
      setShouldMakeRequest(false);
    }

    // NOTE: eslint wants me to add `method`, `path` and `params` to this list,
    // because they are used inside this effect function. They in fact should not
    // be listed here. When they change, they will cause the first effect to fire,
    // setting `shouldMakeRequest` to true, and will indirectly cause this hook
    // to fire.
    //
    // The reason for this separation is so that `reload` can cause this
    // effect to fire again, by setting `shouldMakeRequest` to true.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldMakeRequest]);

  return [loadState, reload];
}

// login/logout

export function login(input: T.Credentials): Promise<T.SessionDetails> {
  return request("POST", "login", input, false);
}

// heartbeat

export function heartbeat(): Promise<void> {
  return request("POST", "heartbeat");
}

// log out

export async function logout(): Promise<void> {
  await request("POST", "logout");
  window.localStorage.removeItem("sessionToken");
}

// performance

export async function setMetric(metric: string, durationMilliseconds: number) {
  const durationMicroseconds = durationMilliseconds * 1000;
  const data = { durationMicroseconds: Math.round(durationMicroseconds) };
  const seconds = durationMilliseconds / 1000;
  return request("POST", `performance/${metric}/${seconds.toFixed(3)}s`, data);
}

// user info

export function getUserInfo(): Promise<T.UserInfo> {
  return request("GET", "user-info");
}

export function useUserInfo(): [LoadState<T.UserInfo>, () => void] {
  return useRequest("GET", "user-info");
}

// current user

export function useCurrentUser(): [LoadState<T.UserInfo>, () => void] {
  return useRequest("GET", "current-user");
}

export function updateCurrentUser(changeSet: T.UserChangeset): Promise<T.User> {
  return request("PUT", "current-user", changeSet);
}

export function changeCurrentUserPassword(
  input: T.ChangePasswordRequest,
): Promise<void> {
  return request("POST", "current-user/change-password", input);
}

export function getCurrentUserPricingProfiles(): Promise<
  T.PricingProfileHeader[]
> {
  return request("GET", "current-user/pricing-profiles");
}

// users

export function useUsers(): [LoadState<T.DetailedUser[]>, () => void] {
  return useRequest("GET", "users");
}

export function getUsers(): Promise<T.DetailedUser[]> {
  return request("GET", "users");
}

export function useUser(id: T.UserId): [LoadState<T.User>, () => void] {
  return useRequest("GET", `users/${id}`);
}

export function getUser(id: T.UserId): Promise<T.User> {
  return request("GET", `users/${id}`);
}

export function createUser(user: T.NewUser): Promise<T.User> {
  return request("POST", "users", user);
}

export function updateUser(
  id: T.UserId,
  changeset: T.UserChangeset,
): Promise<T.User> {
  return request("PUT", `users/${id}`, changeset);
}

export function deleteUser(id: T.UserId): Promise<void> {
  return request("DELETE", `users/${id}`);
}

export function getUserPricingProfiles(
  id: T.UserId,
): Promise<T.PricingProfileHeader[]> {
  return request("GET", `users/${id}/pricing-profiles`);
}

export function assignUserPricingProfiles(
  id: T.UserId,
  pricingProfiles: T.PricingProfileId[],
): Promise<void> {
  return request("PUT", `users/${id}/pricing-profiles`, pricingProfiles);
}

// password reset

export function forgotPassword(input: T.ForgotPassword): Promise<void> {
  return request("POST", "user-password-reset-tokens/forgot", input, false);
}

export function resetPassword(input: T.ResetPassword): Promise<void> {
  return request("POST", "user-password-reset-tokens/reset", input, false);
}

// roles

export function useRoles(): [LoadState<T.Role[]>, () => void] {
  return useRequest("GET", "roles");
}

export function getRoles(): Promise<T.Role[]> {
  return request("GET", "roles");
}

export function useRole(id: T.RoleId): [LoadState<T.Role>, () => void] {
  return useRequest("GET", `roles/${id}`);
}

export function getRole(id: T.RoleId): Promise<T.Role> {
  return request("GET", `roles/${id}`);
}

export function createRole(role: T.NewRole): Promise<T.Role> {
  return request("POST", "roles", role);
}

export function updateRole(
  id: T.RoleId,
  changeset: T.RoleChangeset,
): Promise<T.Role> {
  return request("PUT", `roles/${id}`, changeset);
}

export function updateFieldRoleAssignments(
  assignments: [[T.FieldId, T.RoleId[]]],
): Promise<void> {
  return request(
    "PUT",
    `roles/assign-to-fields`,
    Object.fromEntries(assignments),
  );
}

export function saveRole(role: T.Role): Promise<T.Role> {
  const { id, ...changeset } = role;
  return updateRole(id, changeset);
}

export function assignRoleUsers(
  id: T.RoleId,
  roleUsers: T.RoleUsers,
): Promise<T.RoleUsers> {
  return request("PUT", `roles/${id}/users`, roleUsers);
}

export function deleteRole(id: T.RoleId): Promise<void> {
  return request("DELETE", `roles/${id}`);
}

export function getRoleDefaultValues(
  id: T.RoleId,
): Promise<T.DefaultFieldValue[]> {
  return request("GET", `roles/${id}/default-values`);
}

export function useRoleDefaultValues(
  id: T.RoleId,
): [LoadState<T.DefaultFieldValue[]>, () => void] {
  return useRequest("GET", `roles/${id}/default-values`);
}

export function updateRoleDefaultValues(
  id: T.RoleId,
  defaultValues: T.DefaultFieldValue[],
): Promise<void> {
  return request("POST", `roles/${id}/default-values`, defaultValues);
}

// integrations

export function getIntegrations(): Promise<T.IntegrationHeader[]> {
  return request("GET", "integrations");
}

export function getIntegration(id: T.IntegrationId): Promise<T.Integration> {
  return request("GET", `integrations/${id}`);
}

export function createIntegration(
  integration: T.NewIntegration,
): Promise<T.Integration> {
  return request("POST", "integrations", integration);
}

export function updateIntegration(
  id: T.IntegrationId,
  changeset: T.IntegrationChangeset,
): Promise<T.Integration> {
  return request("PUT", `integrations/${id}`, changeset);
}

export function saveIntegration(
  integration: T.Integration,
): Promise<T.Integration> {
  const { id, tokens, ...changeset } = integration;

  return updateIntegration(id, changeset);
}

export function deleteIntegration(id: T.IntegrationId): Promise<void> {
  return request("DELETE", `integrations/${id}`);
}

export function getIntegrationTokens(
  integrationId: T.IntegrationId,
): Promise<T.IntegrationTokenHeader[]> {
  return request("GET", `integrations/${integrationId}/tokens`);
}

export function getIntegrationToken(
  integrationId: T.IntegrationId,
  tokenId: T.IntegrationTokenId,
): Promise<T.IntegrationToken> {
  return request("GET", `integrations/${integrationId}/tokens/${tokenId}`);
}

export function createIntegrationToken(
  integrationId: T.IntegrationId,
  newIntegrationToken: T.NewIntegrationToken,
): Promise<T.IntegrationToken> {
  return request(
    "POST",
    `integrations/${integrationId}/tokens`,
    newIntegrationToken,
  );
}

export function deactivateIntegrationToken(
  integrationId: T.IntegrationId,
  tokenId: T.IntegrationTokenId,
): Promise<void> {
  return request(
    "POST",
    `integrations/${integrationId}/tokens/${tokenId}/deactivate`,
  );
}

// config

export function getConfig(): Promise<T.EngineConfiguration> {
  return request("GET", "config");
}

export function useConfig(): [LoadState<T.EngineConfiguration>, () => void] {
  return useRequest("GET", "config");
}

export function useNotifications(): [LoadState<T.Notification[]>, () => void] {
  return useRequest("GET", "notifications/since/86400");
}

export function updateFieldDefinitions(
  fields: T.RawFieldDefinitions,
): Promise<void> {
  return request("PUT", "field-definitions", fields);
}

export function updateFieldDefinitionId(
  req: T.FieldIdChangeRequest,
): Promise<T.FieldIdChangeRequest> {
  return request("PUT", `field-definitions/update-id`, req);
}

export function updateCalculations(
  calculationStages: T.CalculationStage[],
): Promise<void> {
  return request("PUT", "calculations", calculationStages);
}

export function updateEnumerations(
  enumerations: readonly T.RawEnumType[],
): Promise<void> {
  return request("PUT", "enumerations", enumerations);
}

export function listRawEnumerations(): Promise<T.RawEnumType[]> {
  return request("GET", "enumerations/raw");
}

export function listSystemEnumerations(): Promise<T.EnumType[]> {
  return request("GET", "enumerations/system");
}

export function updateClientSettings(
  settings: T.ClientSettings,
): Promise<T.ClientSettings> {
  return request("PUT", "settings", settings);
}

// notifications

export function getNotificationsSince(
  sinceSeconds: number,
): Promise<T.Notification[]> {
  return request("GET", `notifications/since/${sinceSeconds}`);
}

// find references

export function findReferences(objectId: T.ObjectId): Promise<T.RefSource[]> {
  return request("GET", "find-references", {
    objectId: JSON.stringify(objectId),
  });
}

// pricing profiles

export function getPricingProfiles(): Promise<T.PricingProfileHeader[]> {
  return request("GET", "pricing-profiles");
}

export function getPricingProfile(
  id: T.PricingProfileId,
): Promise<T.PricingProfile> {
  return request("GET", `pricing-profiles/${id}`);
}

export function createPricingProfile(
  pricingProfile: T.NewPricingProfile,
): Promise<T.PricingProfile> {
  return request("POST", "pricing-profiles", pricingProfile);
}

export function savePricingProfile(
  pricingProfile: T.PricingProfile,
): Promise<T.PricingProfile> {
  const { id, ...changeset } = pricingProfile;
  return updatePricingProfile(id, changeset);
}

export function updatePricingProfile(
  id: T.PricingProfileId,
  changeset: T.PricingProfileChangeset,
): Promise<T.PricingProfile> {
  return request("PUT", `pricing-profiles/${id}`, changeset);
}

export function deletePricingProfile(id: T.PricingProfileId): Promise<void> {
  return request("DELETE", `pricing-profiles/${id}`);
}

export function repositionPricingProfile(
  id: T.PricingProfileId,
  repositionRequest: T.PricingProfileRepositionRequest,
): Promise<void> {
  return request("PUT", `pricing-profiles/${id}/reposition`, repositionRequest);
}

export function listPricingProfileProducts(
  profileId: T.PricingProfileId,
): Promise<T.ProductId[]> {
  return request("GET", `pricing-profiles/${profileId}/products`);
}

export function listPricingProfileUsers(
  profileId: T.PricingProfileId,
): Promise<T.UserId[]> {
  return request("GET", `pricing-profiles/${profileId}/users`);
}

export function assignPricingProfileUsers(
  profileId: T.PricingProfileId,
  userIds: T.UserId[],
): Promise<T.UserId[]> {
  return request("PUT", `pricing-profiles/${profileId}/users`, userIds);
}

export function assignPricingProfileProducts(
  profileId: T.PricingProfileId,
  productIds: T.ProductId[],
): Promise<T.UserId[]> {
  return request("PUT", `pricing-profiles/${profileId}/products`, productIds);
}

// products

export function getProducts(): Promise<T.DecoratedProductHeader[]> {
  return request("GET", "products");
}

export function useProducts(): [
  LoadState<T.DecoratedProductHeader[]>,
  () => void,
] {
  return useRequest("GET", "products");
}

export function getProduct(id: T.ProductId): Promise<T.Product> {
  return request("GET", `products/${id}`);
}

export function useProduct(
  id: T.ProductId,
): [LoadState<T.Product>, () => void] {
  return useRequest("GET", `products/${id}`);
}

export function createProduct(product: T.NewProduct): Promise<T.Product> {
  return request("POST", "products", product);
}

export function saveProduct(product: T.Product): Promise<T.Product> {
  const { id, ...changeset } = product;
  return updateProduct(id, changeset);
}

export function updateProduct(
  id: T.ProductId,
  changeset: T.ProductChangeset,
): Promise<T.Product> {
  return request("PUT", `products/${id}`, changeset);
}

export function deleteProduct(id: T.ProductId): Promise<void> {
  return request("DELETE", `products/${id}`);
}

export function getProductPricingProfiles(
  id: T.ProductId,
): Promise<T.PricingProfileHeader[]> {
  return request("GET", `products/${id}/pricing-profiles`);
}

export function assignProductPricingProfiles(
  id: T.ProductId,
  pricingProfiles: T.PricingProfileId[],
): Promise<void> {
  return request("POST", `products/${id}/pricing-profiles`, pricingProfiles);
}

// investors

export function getInvestors(): Promise<T.DecoratedInvestorHeader[]> {
  return request("GET", "investors");
}

export function useInvestors(): [
  LoadState<T.DecoratedInvestorHeader[]>,
  () => void,
] {
  return useRequest("GET", "investors");
}

export function getInvestor(id: T.InvestorId): Promise<T.Investor> {
  return request("GET", `investors/${id}`);
}

export function useInvestor(
  id: T.InvestorId,
): [LoadState<T.Investor>, () => void] {
  return useRequest("GET", `investors/${id}`);
}

export function createInvestor(investor: T.NewInvestor): Promise<T.Investor> {
  return request("POST", "investors", investor);
}

export function saveInvestor(investor: T.InvestorHeader): Promise<T.Investor> {
  const { id, ...changeset } = investor;
  return updateInvestor(id, changeset);
}

export function updateInvestor(
  id: T.InvestorId,
  changeset: T.InvestorChangeset,
): Promise<T.Investor> {
  return request("PUT", `investors/${id}`, changeset);
}

export function deleteInvestor(id: T.InvestorId): Promise<void> {
  return request("DELETE", `investors/${id}`);
}

// data tables

export function getDataTables(): Promise<T.DataTableHeader[]> {
  return request("GET", "data-tables");
}

export function useDataTables(): [LoadState<T.DataTableHeader[]>, () => void] {
  return useRequest("GET", "data-tables");
}

export function getDataTableColumnNamesAndValueTypes(): Promise<
  T.DataTableColumnNameAndValueType[]
> {
  return request("GET", "data-tables/column-names-and-value-types");
}

export function getDataTable(id: T.DataTableId): Promise<T.DataTable> {
  return request("GET", `data-tables/${id}`);
}

export function useDataTable(
  id: T.DataTableId,
): [LoadState<T.DataTable>, () => void] {
  return useRequest("GET", `data-tables/${id}`);
}

export function createDataTable(
  newDataTable: T.NewDataTable,
): Promise<T.DataTable> {
  return requestWithBlobBody("POST", "data-tables", newDataTable);
}

export function updateDataTable(
  id: T.DataTableId,
  changeset: T.DataTableChangeset,
): Promise<T.DataTable> {
  return requestWithBlobBody("PUT", `data-tables/${id}`, changeset);
}

export function saveDataTable(dataTable: T.DataTable): Promise<T.DataTable> {
  const { id, ...changeset } = dataTable;

  return updateDataTable(id, changeset);
}

export function deleteDataTable(id: T.DataTableId): Promise<void> {
  return request("DELETE", `data-tables/${id}`);
}

// rules

export function getRules(): Promise<T.DecoratedRuleHeader[]> {
  return request("GET", "rules");
}

export function useRules(): [LoadState<T.DecoratedRuleHeader[]>, () => void] {
  return useRequest("GET", "rules");
}

export function getRule(id: T.RuleId): Promise<T.Rule> {
  return request("GET", `rules/${id}`);
}

export function useRule(id: T.RuleId): [LoadState<T.Rule>, () => void] {
  return useRequest("GET", `rules/${id}`);
}

export function createRule(rule: T.NewRule): Promise<T.Rule> {
  return request("POST", "rules", rule);
}

export function saveRule(rule: T.Rule): Promise<T.Rule> {
  const { id, ...changeset } = rule;
  return updateRule(id, changeset);
}

export function updateRule(
  id: T.RuleId,
  changeset: T.RuleChangeset,
): Promise<T.Rule> {
  return request("PUT", `rules/${id}`, changeset);
}

export function deleteRule(id: T.RuleId): Promise<void> {
  return request("DELETE", `rules/${id}`);
}

// rate sheets

export function getRateSheets(): Promise<T.DecoratedRateSheetHeader[]> {
  return request("GET", "rate-sheets");
}

export function useRateSheets(): [
  LoadState<T.DecoratedRateSheetHeader[]>,
  () => void,
] {
  return useRequest("GET", "rate-sheets");
}

export function getRateSheet(id: T.RateSheetId): Promise<T.RateSheet> {
  return request("GET", `rate-sheets/${id}`);
}

export function useRateSheet(
  id: T.RateSheetId,
): [LoadState<T.RateSheet>, () => void] {
  return useRequest("GET", `rate-sheets/${id}`);
}

export function createRateSheet(
  rateSheet: T.NewRateSheet,
): Promise<T.RateSheet> {
  return request("POST", "rate-sheets", rateSheet);
}

export function deleteRateSheet(id: T.RateSheetId): Promise<void> {
  return request("DELETE", `rate-sheets/${id}`);
}

export async function getRateSheetDownloadUrl(
  rateSheetId: T.RateSheetId,
): Promise<string> {
  const resp = await request<T.RateSheetFileDownloadUrl>(
    "GET",
    `rate-sheets/${rateSheetId}/download-url`,
  );
  return resp.downloadUrl;
}

export async function triggerRatesheetAutoUpload(key: string) {
  await request("POST", "tasks/upload-ratesheet-human", { key });
}

export async function spreadsheetFromBucketAndKey(
  bucket: string,
  key: string,
): Promise<T.Spreadsheet> {
  return await request(
    "GET",
    `admin/spreadsheets/from-bucket-and-key?key=${key}&bucket=${bucket}`,
  );
}

export function processPdfRateSheet(
  investorId: T.InvestorId,
  blobId: T.BlobId,
  disablePricingOnFailure?: boolean,
  sendEmailOnFailure?: boolean,
): Promise<T.ProcessPdfRateSheetResponse> {
  const req: T.ProcessPdfRateSheetRequest = {
    investorId,
    blobId,
    disablePricingOnFailure:
      disablePricingOnFailure !== undefined ? disablePricingOnFailure : false,
    sendEmailOnFailure:
      sendEmailOnFailure !== undefined ? sendEmailOnFailure : false,
  };
  return request("POST", `rate-sheets/process-pdf`, req);
}

export function processSpreadsheetRateSheet(
  investorId: T.InvestorId,
  blobId: T.BlobId,
  spreadsheet: T.Spreadsheet,
  disablePricingOnFailure?: boolean,
  sendEmailOnFailure?: boolean,
): Promise<T.ProcessSpreadsheetRateSheetResponse> {
  const req: T.ProcessSpreadsheetRateSheetRequest = {
    investorId,
    blobId,
    spreadsheet,
    disablePricingOnFailure:
      disablePricingOnFailure !== undefined ? disablePricingOnFailure : false,
    sendEmailOnFailure:
      sendEmailOnFailure !== undefined ? sendEmailOnFailure : false,
  };
  return request("POST", `rate-sheets/process-spreadsheet`, req);
}

// blobs

export function createBlob(): Promise<T.PreparedBlobUpload> {
  return request<T.PreparedBlobUpload>("POST", `blobs`);
}

// admin

export const Admin = {
  createClient(req: T.CreateClientRequest): Promise<T.JobId> {
    return request("POST", `admin/clients`, req);
  },

  getClients(): Promise<T.Client[]> {
    return request("GET", `admin/clients`);
  },

  getObjectPatterns(): Promise<T.ObjectPattern[]> {
    return request("GET", `admin/object-patterns`);
  },

  useObjectPatterns(): [LoadState<T.ObjectPattern[]>, () => void] {
    return useRequest("GET", `admin/object-patterns`);
  },

  useObjectPatternInvestorJoins(): [
    LoadState<T.ObjectPatternInvestorJoin[]>,
    () => void,
  ] {
    return useRequest("GET", `admin/object-patterns/investors`);
  },

  createObjectPattern(req: T.NewObjectPattern): Promise<T.ObjectPattern> {
    return request("POST", `admin/object-patterns`, req);
  },

  createObjectPatternInvestorJoin(
    req: T.ObjectPatternInvestorJoin,
  ): Promise<T.ObjectPatternInvestorJoin> {
    return request("POST", `admin/object-patterns/investors`, req);
  },

  clientDeactivate(req: T.ClientDeactivationRequest): Promise<T.Client> {
    return request("POST", `admin/clients/deactivate`, req);
  },

  useClients(): [LoadState<T.Client[]>, () => void] {
    return useRequest("GET", `admin/clients`);
  },

  getRateSheetFormat(id: T.RateSheetFormatId): Promise<T.RateSheetFormat> {
    return request("GET", `admin/rate-sheet-formats/${id}`);
  },

  useRateSheetFormat(
    id: T.RateSheetFormatId,
  ): [LoadState<T.RateSheetFormat>, () => void] {
    return useRequest("GET", `admin/rate-sheet-formats/${id}`);
  },

  getRateSheetFormats(): Promise<T.RateSheetFormatHeader[]> {
    return request("GET", `admin/rate-sheet-formats`);
  },

  useRateSheetFormats(): [LoadState<T.RateSheetFormatHeader[]>, () => void] {
    return useRequest("GET", `admin/rate-sheet-formats`);
  },

  createRateSheetFormat(
    format: T.NewRateSheetFormat,
  ): Promise<T.RateSheetFormat> {
    return request("POST", "admin/rate-sheet-formats", format);
  },

  updateRateSheetFormat(
    id: T.RateSheetFormatId,
    changeset: T.RateSheetFormatChangeset,
  ): Promise<T.RateSheetFormat> {
    return request("PUT", `admin/rate-sheet-formats/${id}`, changeset);
  },

  deleteRateSheetFormat(id: T.RateSheetFormatId): Promise<void> {
    return request("DELETE", `admin/rate-sheet-formats/${id}`);
  },

  renderPdfPages(
    req: T.RenderPdfPagesRequest,
  ): Promise<T.RenderPdfPagesResponse> {
    return request("POST", "admin/rate-sheet-formats/render-pdf-pages", req);
  },

  extractPdfTables(
    req: T.ExtractPdfTablesRequest,
  ): Promise<T.ExtractPdfTablesResponse> {
    return request("POST", "admin/rate-sheet-formats/extract-pdf-tables", req);
  },
};

// execute

export function executeSummary(
  executionRequest: T.ExecutionRequest,
): [Promise<T.ExecutionSummary>, () => void] {
  return requestWithAbort("POST", "execute-summary", executionRequest);
}

export function executeProduct(
  productExecutionRequest: T.ProductExecutionRequest,
): Promise<T.ProductExecutionResult> {
  return request("POST", "execute-product", productExecutionRequest);
}

// application scenarios
export function createApplicationScenario(
  newScenario: T.NewApplicationScenario,
): Promise<T.ApplicationScenario> {
  return request("POST", `application-scenarios`, newScenario);
}

export function getApplicationScenarios(): Promise<T.ApplicationScenario[]> {
  return request("GET", "application-scenarios");
}

export function getApplicationScenario(
  id: T.ApplicationScenarioId,
): Promise<T.ApplicationScenario> {
  return request("GET", `application-scenarios/${id}`);
}

export function updateApplicationScenario(
  id: T.ApplicationScenarioId,
  changeset: T.ApplicationScenarioChangeset,
): Promise<T.ApplicationScenario> {
  return request("PUT", `application-scenarios/${id}`, changeset);
}

export function promoteScenarioToClientScope(
  scenario: T.ApplicationScenario,
): Promise<T.ApplicationScenario[]> {
  return request("PUT", `application-scenarios/${scenario.id}`, {
    scope: "client",
    displayName: scenario.displayName,
    fields: scenario.fields,
  });
}

export function demoteScenarioToUserScope(
  scenario: T.ApplicationScenario,
): Promise<T.ApplicationScenario[]> {
  return request("PUT", `application-scenarios/${scenario.id}`, {
    scope: "user",
    displayName: scenario.displayName,
    fields: scenario.fields,
  });
}

export function deleteApplicationScenario(
  id: T.ApplicationScenarioId,
): Promise<T.ApplicationScenario[]> {
  return request("DELETE", `application-scenarios/${id}`);
}

// client branding

export function getClientBrand(
  accessId: string,
): Promise<T.ClientBrandDetails> | null {
  return request("GET", `clients/${accessId}/brand`, {}, false);
}

// eval-expression

export function evalExpression(
  expr: T.Expression,
): Promise<T.EvalResult<T.Value>> {
  return request("POST", "eval-expression", expr);
}

// jobs

export function jobStatus(jobId: T.JobId): Promise<T.JobStatus> {
  return request("GET", `jobs/${jobId as string}`);
}
