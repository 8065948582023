import * as T from "types/engine-types";
import { expandConfig, Configuration } from "../../config";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as Api from "../../api";
import { ObjectDetailsV2, objectDetailsV2FromData } from "../objects";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { Notification } from "types/generated-types";
import { User, Client } from "types/engine-types";

import { ObjectDetails } from "features/objects";
import { setActiveRoleId } from "features/active-role-id";

export type LoggedInInfo = {
  user: User;
  client: Client;
  config: Configuration;
  objectDetails: ObjectDetails;
  notifications: Notification[];
};

export type ApplicationInitializationState = {
  loading: boolean;
  errorName: string | null;
  errorMessage: string | null;
  user: T.User | null;
  client: T.Client | null;
  config: T.EngineConfiguration | null;
  notifications: T.Notification[] | null;
  objectDetails: ObjectDetailsV2 | null;
  myPricingProfile: T.PricingProfileHeader | null;
  myPricingProfiles: T.PricingProfileHeader[] | null;
  myDefaultFieldValues: T.DefaultFieldValue[] | null;
  myRole: T.DecoratedRoleHeader | null;
  assumableRoles: T.RoleHeader[] | null;
};

const initialState: ApplicationInitializationState = {
  loading: true,
  errorName: null,
  errorMessage: null,
  user: null,
  client: null,
  config: null,
  notifications: null,
  objectDetails: null,
  myPricingProfile: null,
  myPricingProfiles: null,
  myDefaultFieldValues: null,
  myRole: null,
  assumableRoles: null,
};

const applicationInitializationSlice = createSlice({
  name: "ApplicationInitialization",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(loadAppInit.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadAppInit.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = payload.user;
      state.client = payload.client;
      state.config = payload.config;
      state.notifications = payload.notifications;
      state.objectDetails = payload.objectDetails;
      state.myPricingProfile = payload.myPricingProfile;
      state.myPricingProfiles = payload.objectDetails.pricingProfiles;
      state.myDefaultFieldValues = payload.myDefaultFieldValues;
      state.myRole = payload.myRole;
      state.assumableRoles = payload.assumableRoles;
    });
    builder.addCase(loadAppInit.rejected, (state, { error }) => {
      state.loading = false;
      state.errorName = error.name || "Unknown error";
      state.errorMessage = error.message || "Unknown error";
    });
    builder.addCase(getMyPricingProfiles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMyPricingProfiles.fulfilled, (state, { payload }) => {
      state.myPricingProfiles = payload;
      state.loading = false;
    });
  },
  reducers: {
    setMyPricingProfile: (
      state,
      { payload }: PayloadAction<T.PricingProfileHeader>,
    ) => {
      state.myPricingProfile = payload;
    },
    setMyPricingProfiles: (
      state,
      { payload }: PayloadAction<T.PricingProfileHeader[]>,
    ) => {
      state.myPricingProfiles = payload;
    },
    setMyRole: (state, { payload }: PayloadAction<T.DecoratedRoleHeader>) => {
      state.myRole = payload;
    },
    setMyDefaultFieldValues: (
      state,
      { payload }: PayloadAction<T.DefaultFieldValue[]>,
    ) => {
      state.myDefaultFieldValues = payload;
    },
    updateDefaultFieldValuesForRole: (
      state,
      { payload }: PayloadAction<UpdateDefaultFieldValuesForRolePayload>,
    ) => {
      state.myDefaultFieldValues = payload.defaultValues;
    },
  },
});

export const getMyPricingProfiles = createAsyncThunk(
  "pricingProfiles/getMyPricingProfiles",
  async () => {
    return await Api.getCurrentUserPricingProfiles();
  },
);

export const overrideMyRole = createAsyncThunk(
  "roles/overrideMyRole",
  async (roleId: T.RoleId, thunkAPI) => {
    setActiveRoleId(roleId);
    window.location.reload();
  },
);

interface UpdateDefaultFieldValuesForRolePayload {
  roleId: T.RoleId;
  defaultValues: T.DefaultFieldValue[];
}

export const loadAppInit = createAsyncThunk(
  "applicationInitialization/load",
  async () => {
    const [
      { user, role, client, pricingProfiles, assumableRoles },
      config,
      notifications,
    ] = await Promise.all([
      Api.getUserInfo(),
      Api.getConfig(),
      Api.getNotificationsSince(86400),
    ]);
    const objectDetails = objectDetailsV2FromData(pricingProfiles);
    const myPricingProfile = pricingProfiles[0];
    const myDefaultFieldValues = await Api.getRoleDefaultValues(role.id);
    setActiveRoleId(role.id);
    return {
      user,
      client,
      myRole: role,
      config,
      notifications,
      objectDetails,
      myPricingProfile,
      myDefaultFieldValues,
      assumableRoles,
    };
  },
);

// Utility functions

// none of the properties of ApplicationInitializationState should be null post load
// utility function for throwing an error if null is found
function assertNotNull<T>(data: T | null): asserts data is T {
  if (data === null || data === undefined) {
    throw new Error(`null was found unexpectedly`);
  }
}

// Evaluate if the passed pathname matches new code patterns
export const pathIsNextGen = (pathname: string) =>
  pathname.includes("/v2/loan-pricing");

// Logout and reload page
export async function logout(accessId: string) {
  await Api.logout();
  window.location.href = "/login/" + accessId;
}

// Selectors

// raw nullable ApplicationInitializationState
export const applicationInitializationSelector = (state: {
  applicationInitialization: ApplicationInitializationState;
}) => state.applicationInitialization;

export const myRoleSelector = (state: {
  applicationInitialization: ApplicationInitializationState;
}) => state.applicationInitialization.myRole;

// maps objectDetails
export const objectDetailsMapSelector = createSelector(
  [
    (state: { applicationInitialization: ApplicationInitializationState }) =>
      state.applicationInitialization?.objectDetails?.pricingProfiles,
  ],
  (pricingProfiles) => {
    assertNotNull(pricingProfiles);
    return {
      pricingProfiles: pricingProfiles
        ? new Map(pricingProfiles.map((p) => [p.id, p]))
        : new Map(),
    };
  },
);

// expands config
export const expandedConfigSelector = createSelector(
  [
    (state: { applicationInitialization: ApplicationInitializationState }) =>
      state.applicationInitialization?.config,
  ],
  (config) => {
    assertNotNull(config);
    return expandConfig(config);
  },
);

export const isAdminSelector = createSelector([myRoleSelector], (myRole) => {
  return myRole?.name.toLowerCase() === "admin";
});

// selector for accessing non-nullable ApplicationInitializationState
export const nonNullApplicationInitializationSelector = createSelector(
  [applicationInitializationSelector, expandedConfigSelector],
  (appInit, expandedConfig) => {
    assertNotNull(appInit.user);
    assertNotNull(appInit.client);
    assertNotNull(appInit.notifications);
    assertNotNull(appInit.objectDetails);
    assertNotNull(appInit.myPricingProfile);
    assertNotNull(appInit.myPricingProfiles);
    assertNotNull(appInit.myDefaultFieldValues);
    assertNotNull(appInit.myRole);
    assertNotNull(appInit.assumableRoles);

    return {
      loading: false,
      user: appInit.user,
      client: appInit.client,
      config: expandedConfig,
      notifications: appInit.notifications,
      objectDetails: appInit.objectDetails,
      myPricingProfile: appInit.myPricingProfile,
      myPricingProfiles: appInit.myPricingProfiles,
      myDefaultFieldValues: appInit.myDefaultFieldValues,
      myRole: appInit.myRole,
      assumbableRoles: appInit.assumableRoles,
    };
  },
);

export const getBuildEnvironment = () => {
  let environment;

  if (
    window.location.hostname.includes("app") ||
    process.env.REACT_APP_PROXY?.includes("app")
  ) {
    environment = "PRODUCTION";
  } else if (
    window.location.hostname.includes("integrationsandbox") ||
    process.env.REACT_APP_PROXY?.includes("integrationsandbox")
  ) {
    environment = "SANDBOX";
  } else if (
    window.location.hostname.includes("staging") ||
    process.env.REACT_APP_PROXY?.includes("staging")
  ) {
    environment = "STAGING";
  } else {
    environment = "DEVELOPMENT";
  }

  return environment;
};

export const getBuildNumber = () => {
  // This will be set to the Git commit hash during CI/CD builds, but likely
  // won't be set locally.
  const loanpassVersion = process.env.REACT_APP_LOANPASS_VERSION;

  if (loanpassVersion && loanpassVersion.length > 0) {
    return loanpassVersion.substr(0, 10);
  } else {
    return "LOCAL";
  }
};

export function usePermissions() {
  const isAdmin = useSelector(isAdminSelector);
  const myRole = useSelector(myRoleSelector);

  const allowed = (requiredPermission?: T.PermissionKind) => {
    if (requiredPermission) {
      return myRole?.permissions.includes(requiredPermission);
    } else {
      return isAdmin;
    }
  };

  return allowed;
}

export default applicationInitializationSlice;
