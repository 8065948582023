import { Map as IMap } from "immutable";
import React, { useState } from "react";
import Field from "design/molecules/field";
import * as T from "types/engine-types";
import {
  FieldValueState,
  newFieldValueState,
} from "design/organisms/field-value-editor";
import { IMapSetter } from "features/utils";
import { getParentState } from "features/loans";
import Accordion from "design/molecules/accordion";

const FieldGroup = React.memo(
  ({
    applicationFieldsById,
    applicationFieldValueSetter,
    applicationFieldValueStatesById,
    defaultFieldValuesByFieldId,
    fields,
    header,
  }: {
    applicationFieldsById: IMap<T.FieldId, T.BaseFieldDefinition>;
    applicationFieldValueSetter: IMapSetter<T.FieldId, FieldValueState>;
    applicationFieldValueStatesById: IMap<T.FieldId, FieldValueState>;
    defaultFieldValuesByFieldId: IMap<T.FieldId, T.DefaultFieldValue>;
    fields: T.BaseFieldDefinition[];
    header: string;
  }) => {
    const [open] = useState<boolean>(true);

    return (
      <Accordion
        open={open}
        label={header}
        manualTargets={fields.map((f) => f.id)}
      >
        {fields.map((field) => (
          <Field
            key={field.id}
            required={false}
            showErrors={true}
            margin="dense"
            field={field}
            fieldState={
              field
                ? applicationFieldValueStatesById.get(field.id) ||
                  newFieldValueState(field.valueType)
                : null
            }
            defaultValue={defaultFieldValuesByFieldId.get(field.id, null)}
            parentState={getParentState(
              field.conditions || [],
              applicationFieldsById,
              applicationFieldValueStatesById,
              defaultFieldValuesByFieldId,
            )}
            setState={(e) => {
              return applicationFieldValueSetter.withKey(field.id)(e);
            }}
          />
        ))}
      </Accordion>
    );
  },
);

export default FieldGroup;
